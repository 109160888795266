<!-- 睡眠趋势分析 -->
<template>
    <div>
        <div class="section-time">{{ section.name }}</div>
        <div v-if="section.arr && section.arr.length > 0" class="section-list">
            <div v-for="(item, index) in section.arr" :key="index">
                <span>{{ item.value }}</span>
                <span>{{ item.name }}</span>
            </div>
        </div>
        <div id="sleepTrend" :class="className" :style="{ height: height, width: width }" />
    </div>
</template>
  
<script>
import * as echarts from "echarts";
import resize from './mixins/resize.js'
import { dayList, dataZoom } from '@/utils/tool.js'
export default {
    mixins: [resize],
    props: {
        className: {
            type: String,
            default: 'chart'
        },
        width: {
            type: String,
            default: '100%'
        },
        height: {
            type: String,
            default: '100%'
        },
        autoResize: {
            type: Boolean,
            default: true
        },
        sleepData: {
            type: Object,
            default: {}
        },
        addTime: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            chart: null,
            section: []
        }
    },
    watch: {

    },
    created() {

    },
    mounted() {
        this.$nextTick(() => {
            this.initChart()
        })
    },
    beforeDestroy() {
        if (!this.chart) {
            return
        }
        this.chart.dispose()
        this.chart = null
    },
    methods: {
        initChart() {
            this.chart = echarts.init(document.getElementById('sleepTrend'))
            this.setOptions()
        },
        setOptions() {
            let that = this
            var t = this.sleepData
                , a = new Array(30)
                , i = new Array(30)
                , s = new Array(30)
                , r = new Array(30)
                , n = new Array(30)
                , o = t.arrayList;

            o && dayList(this.addTime).last16.forEach(function (e, t) {
                o.forEach(function (o, l) {
                    if (e === o.data_date) {
                        var c = o.deep_sleep.split("小时")[0]
                            , d = o.deep_sleep.split("小时")[1].split("分钟")[0]
                            , u = parseInt(c) + parseFloat(d / 60)
                            , p = o.all_sleep.split("小时")[0]
                            , m = o.all_sleep.split("小时")[1].split("分钟")[0]
                            , g = parseInt(p) + parseFloat(m / 60);
                        a[t] = u,
                            i[t] = g - u,
                            s[t] = g,
                            r[t] = o.deep_sleep,
                            n[t] = o.all_sleep
                    }
                })
            });
            var l = !1;
            i.forEach(function (e) {
                e && (l = !0)
            });
            let uesTemp = dayList(this.addTime).ecUse
            let dataTemp = dayList(this.addTime).ecShow
            this.chart.setOption({
                grid: {
                    top: 30,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    containLabel: !0
                },
                tooltip: {
                    trigger: "axis",
                    formatter: function (t) {
                        for (var a = r[t[0].dataIndex], i = n[t[0].dataIndex], s = dataTemp[t[0].dataIndex].split("/"), o = "<div><p>" + (s[0] + "月" + s[1] + "日") + "</p>", l = 0; l < t.length; l++) {
                            var c = t[l].marker;
                            0 === l ? t[l].data ? o += "<p>" + c + "深睡：" + a + "</p>" : o += "<p>" + c + "深睡：---</p>" : 1 === l && (t[l].data ? o += "<p>" + c + "总长：" + i + "</p>" : o += "<p>" + c + "总长：---</p>")
                        }
                        return o += "</div>"
                    }
                },
                xAxis: {
                    type: "category",
                    data: uesTemp,
                    axisLabel: {
                        interval: 0,
                        color: "#999"
                    },
                    axisLine: {
                        lineStyle: {
                            color: "#e7e5e4"
                        }
                    },
                    axisTick: {
                        show: !1
                    },
                    splitArea: {
                        show: !1
                    }
                },
                yAxis: {
                    scale: !0,
                    min: 0,
                    max: l ? null : 10,
                    type: "value",
                    axisLabel: {
                        show: !0,
                        formatter: function (e) {
                            return e
                        },
                        color: "#999"
                    },
                    axisLine: {
                        show: !1
                    },
                    axisTick: {
                        show: !1
                    }
                },
                color: ["#86d9e0", "#13b5b1"],
                legend: {
                    selectedMode: !1,
                    x: "left",
                    padding: [10, 0, 0, 35],
                    itemWidth: 15,
                    itemHeight: 6,
                    itemGap: 27,
                    data: [{
                        name: "总长",
                        icon: "rect"
                    }, {
                        name: "深睡",
                        icon: "rect"
                    }]
                },
                toolbox: {
                    show: !0,
                    feature: {
                        dataZoom: {
                            yAxisIndex: "none",
                            title: {
                                back: "还原"
                            }
                        }
                    }
                },
                series: [{
                    name: "深睡",
                    type: "bar",
                    stack: "步数",
                    barWidth: 6,
                    data: a,
                    itemStyle: {
                        color: "#13b5b1"
                    }
                }, {
                    name: "总长",
                    type: "bar",
                    stack: "步数",
                    barWidth: 6,
                    data: i,
                    itemStyle: {
                        color: "#86d9e0"
                    }
                }, {
                    name: "深睡趋势",
                    type: "line",
                    data: a,
                    itemStyle: {
                        color: "#e38788"
                    },
                    lineStyle: {
                        color: "#e38788"
                    }
                }, {
                    name: "背景板",
                    type: "line",
                    markLine: {
                        silent: !0,
                        animation: !1,
                        symbol: ["none", "none"],
                        lineStyle: {
                            color: "green"
                        },
                        data: [{
                            yAxis: 6
                        }]
                    }
                }]
            })

            this.section = []
            dataZoom({
                echart: this.chart,
                series: [{
                    name: "深睡时长",
                    data: a
                }, {
                    name: "总睡眠时长",
                    data: s
                }],
                addTime: this.addTime,
                sectionArr: function (t) {
                    that.section = t
                }
            })
        },
    }
}
</script>
<style lang="scss" scoped>
.section-time {
    position: absolute;
    top: -60px;
    line-height: 32px;
    right: 0;
    font-size: 16px;
    color: #000;
}

.section-list {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;

    div {
        border-right: 1px solid #e9e9e9;
        padding: 0 40px;

        &:last-child {
            border: none;
        }

        span {
            display: block;
            text-align: center;

            &:first-child {
                font-size: 18px;
                font-weight: 700;
                margin-bottom: 10px;
            }

            &:last-child {
                font-size: 14px;
                color: #7f7f7f;
            }
        }
    }
}
</style>