<template>
  <div class="palmistry">
    <div class="palmistry-body" :class="{ is_full: isFull }">
      <div class="win-btn">
        <i @click="close" v-if="showClose" class="xszy-icon icon-close"></i>
        <i @click="fullFun(false)" v-if="isFull" class="xszy-icon icon-zuixiaohua"></i>
        <i @click="fullFun(true)" v-else class="xszy-icon icon-quanpingzuidahua"></i>
      </div>
      <div class="mark" v-if="!medicalRecordScan.length">
        <uEmpty />
      </div>
      <div class="h-body" :class="{ hide: !isPic }">
      <div class="h-left">
        <div class="h-title">
          <i class="iconfont icon-shouzhang"></i>
          <span class="label">手相解读</span>
        </div>
        <div class="h-menu">
          <div
            @click="selectMenuItem(item, index)"
            class="h-menu-item"
            :class="{ active: item.active }"
            v-for="(item, index) in infoList3"
            :key="index"
          >
            <span>{{ item.title }}</span>
          </div>
        </div>
        <div class="h-button-group" v-if="showZoomBtn">
          <div class="h-button-title">手相识别</div>
          <div class="h-button-list">
            <CheckboxGroup v-model="checkBox" @on-change="checkBoxChange">
              <Checkbox
                :label="item.value"
                v-for="(item, index) in checkBoxList"
                :key="index"
              >
                <i class="icon1 iconfont icon-yanjing"></i>
                <i class="icon2 iconfont icon-yanjing1"></i>
                <span>{{ item.label }}</span>
              </Checkbox>
            </CheckboxGroup>
          </div>
        </div>
      </div>
      <div class="h-center">
        <div class="h-center-body" v-if="infoList3.length">
          <div
            @click="clickItem(item)"
            class="mk"
            :class="[{ active: item.active }, 'infoList-' + index]"
            v-for="(item, index) in infoList3"
            :key="index"
          >
            <div class="mk-title">
              <i class="icon iconfont" :class="item.icon"></i>
              <span>{{ item.title }}</span>
            </div>
            <div class="mk-body" v-if="item.value.length">
              <div class="mk-body-item" :class="{'showMore': showMore, 'isMore': unscrambles.showMore}" v-for="(unscrambles, h_index) in item.value" :key="h_index" >
                <div v-html="unscrambles.text"></div>
                <div class="more-btn" v-if="unscrambles.showMore" @click="showMore = !showMore">
                  <template v-if="!showMore">
                    <Icon type="ios-arrow-down" />
                    <span>展示更多</span>
                  </template>
                  <template v-else>
                    <Icon type="ios-arrow-up" />
                    <span>收起更多</span>
                  </template>
                </div>
              </div>
            </div>
            <empty v-else />
          </div>
        </div>
        <div class="h-center-body" v-else>
          <empty />
        </div>
      </div>
      <div class="h-right">
        <div class="h-right-body">
          <div class="h-right-content">
            <div ref="container" id="container2">
              <div v-if="systemVersionTooLow" class="systemVersionTooLow">
                <div class="text">系统版本过低</div>
                <div class="text-other">请换windows10或以上的系统</div>
                <Spin v-if="isLogin" class="openApp_loader" fix>
                  <div class="u_loader_k">
                    <div class="u_loader"></div>
                    <img src="@/assets/images/logo.png" alt />
                  </div>
                  <div>手相识别中</div>
                </Spin>
              </div>
            </div>
          </div>
          <div class="footer">
            <template v-if="isPic">
              <template v-if="showZoomBtn && !systemVersionTooLow">
                <div class="icon" @click="showNiceFun">
                  <i v-if="showNice" class="iconfont icon-yanjing"></i>
                  <i v-else class="iconfont icon-yanjing1"></i>
                </div>
                <div class="icon" @click="zoom(1)">
                  <i class="iconfont icon-zoomin"></i>
                </div>
                <div class="icon" @click="zoom(0)">
                  <i class="iconfont icon-zoomout"></i>
                </div>
              </template>
            </template>
          </div>
        </div>
      </div>
    </div>
      
      <div class="fail" v-if="isFail">解读失败</div>
     
      <div class="info">
        <span>解读时间:{{ activeItem.crDt }}</span>
      </div>
      <i v-show="isLeft" class="pagechange xszy-icon icon-left" @click="setPageIndex(-1)"></i>
      <i v-show="isRight" class="pagechange xszy-icon icon-right" @click="setPageIndex(1)"></i>
      <Page @on-change="pageChange" :current="pageIndex" class="page" :page-size="1" :total="medicalRecordScan.length"
        size="small" />
    </div>
  </div>
</template>
<script>

import { getImgBase64 } from './common';
import Hander from "./Hander2.js";
import { Scene } from "spritejs";
import vueScroll from 'vuescroll';
import uEmpty from './uEmpty.vue';
export default {
  components: {
    vueScroll,
    uEmpty
  },
  props: {
    patientCode: {
      type: String,
      default: ""
    },
    showClose: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showMore: false,
      height: 700,
      proportion: 0.75,

      systemVersionTooLow: false, //操作系统版本是否过低
      picUrl: "",
      djs: null,
      showNice: false,
      infoList: [],

      base64: "",
      imageHeight: "",
      imageWidth: "",

      showZoomBtn: false,
      showHelp: false,
      isInfo: false,
      showCamera: false,
      showInfo: true,
      isOpen: false,
      scene: null,
      layer: null,
      isPic: true, //有图
      hander: null,
      showPicList: false,
      oldPics: [],
      isLogin: false,

      checkBox: [],
      checkBoxList: [
        {
          value: 1,
          label: "九丘一平原",
        },
        {
          value: 2,
          label: "三大主线",
        },
        {
          value: 3,
          label: "纹路",
        },
        {
          value: 4,
          label: "斑点",
        },
        {
          value: 5,
          label: "青筋",
        },
        {
          value: 6,
          label: "指节纹",
        },
      ],
      unscrambleList: {},
      colorList: [],
      // 解读来源 1健康 2 婚姻 3 家庭 4 财富 5 事业 6 其他
      infoList2: [
        {
          icon: "icon-xunhuan",
          title: "健康",
          value: [],
          id: 1,
          active: true,
          isShow: false,
        },
        {
          icon: "icon-hunyinzhuangkuangbiangeng",
          title: "婚姻",
          value: [],
          id: 2,
          active: false,
          isShow: false,
        },
        {
          icon: "icon-home1",
          title: "家庭",
          value: [],
          id: 3,
          active: false,
          isShow: false,
        },
        {
          icon: "icon-qianbao",
          title: "财富",
          value: [],
          id: 4,
          active: false,
          isShow: false,
        },
        {
          icon: "icon-renshiyewu",
          title: "事业",
          value: [],
          id: 5,
          active: false,
          isShow: false,
        },
        {
          icon: "icon-qita",
          title: "其他",
          value: [],
          id: 6,
          active: false,
          isShow: false,
        },
      ],
      
      
      isFull: false,
      ops: {
        bar: {
          background: '#ccc'
        }
      },
      handData: {},
      showNice: false,
      infoList: [],
      scene: null,
      layer: null,
      isPic: false, //有图
      hander: null,
      activeItem: {},
      medicalRecordScan: [],
      pageIndex: 1,
      isFail: false, //解读失败否
    };
  },
  computed: {
    isRight() {
      return (this.pageIndex + 1) <= this.medicalRecordScan.length;
    },
    isLeft() {
      return (this.pageIndex - 1) > 0;
    },
    infoList3() {
      return this.infoList2.some(i=>i.value) ? this.infoList2 : [];
    },
  },
  methods: {
    selectMenuItem(item, index) {
      this.clickItem(item);
      // 滚动到指定位置
      let node = this.$el.querySelector(".infoList-" + index);
      if (node) {
        node.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    },
    clickItem(item) {
      this.infoList2.forEach((i) => {
        i.active = item == i ? true : false;
      });
    },
    checkBoxChange(ids) {
      this.hander.clickBtn(ids);
    },
    close() {
      this.$emit("close")
    },
    fullFun(type) {
      this.isFull = type;
      if (this.layer) this.layer.remove();
      setTimeout(() => {
        this.clickPic();
      }, 300);
    },
    setPageIndex(nb) {
      let index = this.pageIndex + nb;
      if (index > 0 && index <= this.medicalRecordScan.length) {
        this.pageIndex = index;
      }
      this.pageChange(this.pageIndex);
    },
    pageChange(index) {
      this.pageIndex = index;
      this.activeItem = this.medicalRecordScan[this.pageIndex - 1];
      this.clickPic();
    },
    newlineToBr(str) {
      if (str) return str.replace(/\n/g, '<br>');
    },
    async clickPic() {
      this.isFail = false;
      if (this.layer) this.layer.remove();
      this.createHander();

      // 如果有图片数据的话就直接读取不需要调接口
      if (!this.activeItem.base64) {
        let url = /^http/.test(this.activeItem.picUrl) ? this.activeItem.picUrl : (this.cloudPic + "/hand/" + this.activeItem.picUrl);
        let imgdata = await getImgBase64(url).catch(err => console.log(err));
        if (!imgdata) {
          this.$Message.error("无效图片链接，不能解读");
          if (this.layer) this.layer.remove();
          this.layer = null;
          this.isFail = true;
          return;
        }
        this.activeItem.base64 = imgdata.base64;
      }
      // 如果有手相数据的话就直接读取不需要调接口
      if (this.activeItem.handData) {
        this.isPic = true;
        this.init();
      } else {
        this.api2('getHandUnscrambleByAdiId', {
          aidId: this.activeItem.aidId, //	明细主键Id
          patientCode: this.patientCode, //	患者编码
        }).then(res => {
          this.activeItem.handData = res.result;
          this.isPic = true;
          this.init();
        })
      }
    },
    getData() {
      let obj = {
        patientCode: this.patientCode,
        picType: 1,
        handFlag: 0,
      };
      this.api2("queryMedicalRecordScan", obj).then(res => {
        this.medicalRecordScan = res.result;
        // 有手相图片直接解读第一张
        if (this.medicalRecordScan.length) {
          this.activeItem = this.medicalRecordScan[0];
          this.clickPic();
        } else {
          this.isPic = false;
        }
      });
    },

    cancel() {
      this.isPic = true;
      if (this.layer) this.layer.remove();
      this.layer = null;
    },

    createHander() {
      let container = this.$el.querySelector("#container2");
      this.scene = new Scene({
        container,
        mode: "stickyWidth"
      });
      this.layer = this.scene.layer();
      this.hander = new Hander(this.layer);
    },

    init() {
      if (!this.activeItem.handData.nineHillsPlains.length) {
        this.$Message.warning("无解读数据哦")
        return;
      }
      setTimeout(() => {
        Object.hasOwnProperty.call(this.activeItem.handData, "texture")
          ? this.formatData2()
          : this.formatData1();
      }, 100);
    },

    reset() {
      this.unscrambleList = {};
      this.unscrambleColorList = {};
      this.infoList2.forEach((i) => {
        i.value = [];
        i.isShow = false;
      });
    },

     // 新版本格式化数据
     formatData2() {
      this.isLogin = false;
      this.reset();

      let obj = {
        graphical: [], //图形
        texture: [], //纹路
        speckle: [], //斑点
        ninePoint: [], //九区中点和名称
        finger: [], //手指数据
        qingjin: [], // 青筋
        version: 2, // 版本
      };

      // 整理区块名称和区块中心点坐标
      let nine = this.activeItem.handData.nineHillsPlains;
      obj.ninePoint = nine
        .filter((i) => i.regionName)
        .map((i) => {
          let p = i.graphical.coordinate;
          let point = [
            p.reduce((sum, j) => {
              return (sum += j[0]);
            }, 0) / p.length,
            p.reduce((sum, j) => {
              return (sum += j[1]);
            }, 0) / p.length,
          ];
          return {
            label: i.regionName,
            point,
          };
        });

      this.setPoint2(this.activeItem.handData, obj);

      obj.threeMainLine = [];
      let handLine = this.activeItem.handData.handLine;

      // 三大主线
      handLine.forEach((i) => {
        let arr = i.graphical.metadata;

        // 找断点
        let indexs = [];
        arr.forEach((j, index) => {
          if (j.breakPointFlag) {
            indexs.push(index);
          }
        });
        indexs.unshift(0);
        indexs.push(arr.length - 1);
        let arr3 = this.common.chunkArray(indexs, 2); //按长度为2切割数组
        arr3.forEach((i) => {
          let list = arr.slice(i[0], i[1] + 1);
          let points = list
            .filter((j) => j.splitObjIndex == -1)
            .map((i) => i.coordinate);

          obj.threeMainLine.push({
            text: i.lineName,
            points,
          });
        });
      });

      // 分支线
      handLine.forEach((i) => {
        let list = i.graphical.metadata;
        // 1、先找到分叉点，即branchpointflg为true的
        let list2 = list.filter(
          (j) => j.branchPointFlag && j.splitObjIndex == -1
        );
        // 找到所有分叉线上的点，即splitobjindex不等于-1的
        let list3 = list.filter((j) => j.splitObjIndex != -1);
        let g = [];
        list2.filter((i) => {
          let arr = list3.filter(
            (j) => j.parentPointIndex == i.branchPointIndex
          );
          let o = this.groupBy(arr, "splitObjIndex");
          for (const key in o) {
            if (Object.hasOwnProperty.call(o, key)) {
              let l = o[key];
              // l.unshift(i)
              g.push(l);
            }
          }
        });
        g.forEach((j) => {
          let points = j.map((i) => i.coordinate);
          obj.threeMainLine.push({
            text: "",
            points,
          });
        });
      });

      // 青筋
      if (this.activeItem.handData.palm) {
        this.activeItem.handData.palm.qingjin.forEach((j) => {
          let str = this.formatUnscramble(j.privateUnscrambles);
          obj.qingjin.push({
            text: str ? "青筋：" + str : "青筋",
            points: j.graphical.coordinate,
          });
        });
      }

      // 添加手指数据
      this.activeItem.handData.finger.forEach((i) => {
        i.jointWrinkle.forEach((j) => {
          obj.finger.push({
            text: "",
            points: j.graphical.coordinate,
            // isFinger: true, // 是指节纹
          });
        });
      });

      Object.assign(obj, {
        base64: this.activeItem.base64,
        imageHeight: 3840,
        imageWidth: 2160
      });

      if (this.hander) this.hander.setData(obj);
      this.showInfo = true;
      this.showZoomBtn = true;
    },

    // 旧版本格式化数据
    formatData1() {
      setTimeout(async () => {
        this.infoList = [];
        let obj = {
          graphical: [], //图形
          texture: [], //纹路
          speckle: [], //斑点
          ninePoint: [], //九区中点和名称
          version: 1, // 版本
        };

        // 整理区块名称和区块中心点坐标
        let nine = this.activeItem.handData.nineHillsPlains;
        // 合并手指的数据
        // nine = nine.concat(this.activeItem.handData.finger)

        obj.ninePoint = nine.filter(i => i.graphical).map(i => {
          let p = i.graphical.coordinate;
          let point = [
            p.reduce((sum, j) => {
              return (sum += j[0]);
            }, 0) / p.length,
            p.reduce((sum, j) => {
              return (sum += j[1]);
            }, 0) / p.length
          ];
          return {
            label: i.regionName,
            point
          };
        });

        this.setPoint(nine, obj);
        this.infoList = this.toRepeat(this.infoList);

        // 处理三大主级数据
        obj.threeMainLine = this.activeItem.handData.threeMainLine.map(i => {
          return {
            text: i.lineName,
            points: i.graphical.coordinate
          };
        });

        Object.assign(obj, {
          base64: this.activeItem.base64,
          imageHeight: 3264,
          imageWidth: 2448
        });

        this.hander.setData(obj);
        this.showInfo = true;
      }, 500);
    },

    // 是否显视区块名称
    showNiceFun() {
      this.showNice = !this.showNice;
      this.hander.setNiceTipVisibility(this.showNice ? 1 : 0);
    },

    // 缩放
    zoom(isEnlarge) {
      let pos = {
        x: this.layer.width / 2,
        y: this.layer.height / 2
      };
      this.hander.zoom(pos, isEnlarge);
    },
    
    formatUnscramble(list) {
      if (!list) return "";
      let arr = [];
      list.forEach((i, index) => {
        let confidence =
          i.confidence && i.confidence.length
            ? this.round(i.confidence[0])
            : "";
        arr.push(`${index + 1}、${i.unscramble}${confidence}`);
      });
      return arr.join(" ");
    },

    // 按指定字段对对象数组分组
    groupBy(array, key) {
      return array.reduce((result, currentValue) => {
        (result[currentValue[key]] = result[currentValue[key]] || []).push(
          currentValue
        );
        return result;
      }, {});
    },
    
    setInfoList() {
      for (const key in this.unscrambleList) {
        if (Object.hasOwnProperty.call(this.unscrambleList, key)) {
          const i = this.unscrambleList[key];
          this.unscrambleList[key] = i.sort(
            (a, b) => b.confidence - a.confidence
          );
        }
      }

      this.infoList2.forEach((i) => {
        if (this.unscrambleList[i.id]) {
          i.value = [{
            text: this.unscrambleList[i.id]
              .map((i, index) => index + 1 + "、" + i.unscrambleStr)
              .join("<br/>"),
            showMore: false,
          }],
          i.isShow = true;
        } else {
          i.isShow = false;
        }
      });

      // 滚动高亮测试
      // this.$nextTick(()=>{
      //   this.cs();
      // })
    },
    
    round(nb) {
      return nb >= this.confidenceProportion
        ? `(置信度${Math.round(nb * 100)}%)`
        : "";
    },

    setUnscrambleList(list) {
      let unscrambleTypeStr = [
        "纹路",
        "斑点",
        "可识别可量化",
        "可识别不可量化",
        "颜色",
        "青筋",
        "脏腑段断裂"
      ];
      if (!list) return;
      list.forEach((i) => {
        let arr = this.unscrambleList[i.unscrambleResource];
        let confidence =
          i.confidence && i.confidence.length
            ? this.round(i.confidence[0])
            : "";
        let obj = {
          unscramble: i.unscramble,
          unscrambleType: i.unscrambleType,
          unscrambleStr: i.unscramble + confidence,
          confidence: i.confidence && i.confidence.length ? i.confidence : 0,
        };
        if(i.unscrambleType == 4){
          let findIndex= this.colorList.findIndex(item=>item.unscramble == obj.unscramble);
          if(findIndex!=-1){
            let item = this.colorList[findIndex]
            if(item.confidence < obj.confidence){
              this.colorList[findIndex] = obj;
            }
          }else{
            this.colorList.push(obj);
          }
        }else{
          if (arr) {
            let findIndex= arr.findIndex(item=>item.unscramble == obj.unscramble);
            if(findIndex!=-1){
              let item = arr[findIndex]
              if(item.confidence < obj.confidence){
                arr[findIndex] = obj;
              }
            }else{
              arr.push(obj);
            }
          } else {
            this.unscrambleList[i.unscrambleResource] = [obj];
          }
        }
      });
    },

    // 新版数据整理方法
    setPoint2(handData, obj) {
      this.colorList = [];

      // 收集解读信息
      this.setUnscrambleList(handData.jointWrinkleUnscrambles);

      handData.handLine.forEach((i) => {
        this.setUnscrambleList(i.unscrambles);
      });

      handData.finger.forEach((i) => {
        this.setUnscrambleList(i.unscrambles);
      });

      handData.nineHillsPlains.forEach((i) => {
        this.setUnscrambleList(i.unscrambles);
        if (i.graphical && i.regionCode != "ALL") {
          obj.graphical.push({
            text: i.regionName || "",
            points: i.graphical.coordinate,
          });

          // 收集纹解读数据
          let item = handData.texture.find((j) =>
            j.nineHillsRegion.includes(i.regionCode)
          );
          if (item) {
            item.regionList = item.regionList || [];
            item.regionList.push({
              regionName: i.regionName,
              textureUnscrambles: i.textureUnscrambles,
            });
          }
        }
      });

      this.setInfoList();
      let item = this.infoList2.find((i) => i.id == 1);
      if(handData.palmColorUnscramble){
        item.value.push( 
          {
            text: [
              `<span class="mk-title"><span>掌型颜色解读</span></span>`,
              handData.palmColorUnscramble.replace(/\s*\r*\n\s*/g, "</>"),
            ].join("</br>"),
            showMore: false,
          }
        );
      }
      if(this.colorList.length){
        item.value.push({
          text: this.colorList.map((i, index)=> index + 1 + "、" + i.unscrambleStr).join("</br>"),
          showMore: true,
        });
      }
      if(handData.palmUnscramble){
        item.value.push({
          text: [
            `<span class="mk-title"><span>掌型:${handData.palmName}</span></span>`,
            handData.palmUnscramble.replace(/\s*\r\n\s*/g, "</br>"),
          ].join("</br>"),
          showMore: false,
        });
      }

      // 纹
      handData.texture.forEach((j) => {
        let str = this.formatUnscramble(j.privateUnscrambles);
        let p = j.graphical.coordinate;
        obj.texture.push({
          text: j.textureName + ": " + str,
          points: [p[0], p[2]],
        });
      });

      // 斑点
      handData.speckle.forEach((j) => {
        let str = this.formatUnscramble(j.privateUnscrambles);
        let p = j.graphical.coordinate;
        obj.speckle.push({
          text: (j.unscramble || j.speckleName) + "：" + str,
          // 获取斑点中心点
          points: [
            p[0][0] + (p[1][0] - p[0][0]) / 2,
            p[0][1] + (p[2][1] - p[1][1]) / 2,
          ],
        });
      });
    },

    setPoint(list, obj) {
      list.forEach(i => {
        if (i.graphical) {
          obj.graphical.push({
            text: i.regionName || "",
            points: i.graphical.coordinate
          });
        }

        // 纹
        if (i.texture) {
          i.texture.forEach(j => {
            if (j.unscramble) {
              this.infoList.push({
                label: i.regionName,
                value: j.unscramble,
                values: []
              });
            }
            obj.texture.push({
              text: j.unscramble || j.textureName,
              points: j.graphical.coordinate
            });
          });
        }

        // 斑点
        if (i.speckle) {
          i.speckle.forEach(j => {
            if (j.unscramble) {
              this.infoList.push({
                label: i.regionName,
                value: j.unscramble,
                values: []
              });
            }
            let p = j.graphical.coordinate;
            obj.speckle.push({
              text: j.unscramble || j.speckleName,
              // 获取斑点中心点
              points: [
                p[0][0] + (p[1][0] - p[0][0]) / 2,
                p[0][1] + (p[1][1] - p[0][1]) / 2
              ]
            });
          });
        }

        if (i.subRegion && i.subRegion.length) {
          this.setPoint(i.subRegion, obj, i.regionName);
        }
      });
    },

    toRepeat(list) {
      let newList = [];
      list.forEach(i => {
        let item = newList.find(j => j.label == i.label);
        let value = i.value.replace(/^\d+、*/, "");
        if (item) {
          if (!item.values.find(x => x == i.value) && i.value)
            item.values.push(value);
        } else {
          newList.push({
            label: i.label,
            values: [value]
          });
        }
      });
      newList.forEach(i => {
        i.values = i.values
          .map((j, index) => {
            return (i.values[index] = `${index + 1}、${j}`);
          })
          .join("<br/>");
      });
      return newList;
    },
  },
  mounted() {
    this.getData();
  }
};
</script>

<style >
@import url("./iconfont.css");
@import url("./camera3.css");
</style>

<style lang="scss">
.palmistry{
  .ivu-modal-mask {
    background: none;
  }
  .ivu-modal-wrap {
    top: 50px;
  }
  .h-body {
    background: #f5f5f5;
    display: flex;
    height: 100%;
    &.hide {
      visibility: hidden;
    }
    .h-left {
      width: 150px;
      height: 100%;
      background: #fff;
      box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.05);
      display: flex;
      flex-direction: column;
      .h-menu {
        height: 100%;
        user-select: none;
        .h-menu-item {
          padding: 15px;
          text-align: center;
          cursor: pointer;
          &.active {
            background: #fbf7f5;
            color: #843a2f;
            position: relative;
            font-weight: bold;
            &::after {
              content: "";
              display: block;
              width: 3px;
              height: 30px;
              background: #843a2f;
              position: absolute;
              right: 0;
              top: 50%;
              transform: translateY(-50%);
              border-radius: 5px;
            }
          }
        }
      }
      .h-title {
        font-weight: bold;
        font-size: 16px;
        padding: 20px 10px 10px;
        color: #333;
        .iconfont {
          padding: 0 10px 0 20px;
          color: #843a2f;
          font-size: 20px;
        }
      }
      .h-button-group {
        margin: 10px;
        background: #fbf9f8;
        padding: 15px;
        user-select: none;
        .h-button-title {
          border-bottom: 1px solid #eee;
          margin-bottom: 5px;
          padding-bottom: 8px;
          font-weight: bold;
          text-align: center;
          color: #333;
        }
        .h-button-list {
          .ivu-checkbox {
            display: none;
          }
          .ivu-checkbox-group-item {
            padding: 5px 0;
            .iconfont {
              padding-right: 5px;
            }
            .icon2 {
              display: none;
            }
            &.ivu-checkbox-wrapper-checked {
              color: #843a2f;
              font-weight: bold;
              .icon2 {
                display: inline-block;
              }
              .icon1 {
                display: none;
              }
            }
          }
        }
      }
    }
    .h-center {
      flex: 1;
      padding: 15px 0 15px 15px;
      height: 100%;
      .h-center-body {
        height: 100%;
        overflow-y: auto;
      }
      .mk {
        padding: 15px;
        border: 1px solid #ede3e1;
        border-radius: 6px;
        margin-bottom: 15px;
        line-height: 2rem;
        &.active {
          background: #ede3e1;
        }
        .mk-title {
          font-weight: bold;
          columns: #222;
          font-size: 16px;
          .icon {
            padding-right: 10px;
            color: #843a2f;
            font-size: 18px;
          }
        }
      }
    }
    .h-right {
      flex: 1;
      padding: 15px;
    }
    .h-right-body,
    .h-center-body {
      height: 100%;
      background: #fff;
      box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      padding: 15px;
    }
    .h-right-body {
      display: flex;
      flex-direction: column;
    }
    .h-right-hender {
      padding-bottom: 10px;
    }
    .h-right-content {
      flex: 1;
      border-radius: 5px;
      overflow: hidden;
      border: 1px solid #eee;
      #container2{
        height: 100%;
      }
    }
    .footer {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 15px;
      .icon {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin: 0 10px;
        background: #f4f4f4;
        &:hover,
        &.actice {
          background: #843a2f;
          color: #fff;
        }
        i {
          font-size: 20px;
        }
      }
    }
  }
  .userinfo2 {
    .icon {
      color: #843a2f;
      padding-right: 10px;
      font-size: 20px;
    }
    span {
      padding-right: 10px;
    }
    .name {
      font-weight: bold;
      font-size: 16px;
    }
  }

  .modal_close {
    right: 20px;
    top: 20px;
  }
  .mk-body{
    .mk-body-item{
      position: relative;
      &.isMore{
        height: 100px; 
        overflow: hidden;
      }
      &.isMore.showMore{
        height: auto;
        overflow: visible;
        padding-bottom: 60px;
      }
    }
    .more-btn{
      text-align: center;
      font-size: 12px;
      color: #666;
      cursor: pointer;
      // 背景颜色从上到下渐变
      background: linear-gradient(to bottom, rgba(255,255,255, 0), rgba(255,255,255,.8), rgba(255,255,255,1));
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 40px 0 5px;
      line-height: 20px;
    }
  }
  .active{
    .mk-body{
      .more-btn{
        background: linear-gradient(to bottom, #ede3e100, #ede3e1dd, #ede3e1ff);
      }
    }
  }
}
</style>
