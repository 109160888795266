import router from '@/router'
import common from '../../common.js'
import io from 'socket.io-client'
import Aes from '@/utils/Aes'
import timeout from '@/utils/Timeout'
import iView from 'view-design'

iView.Message.config({
    duration: 5
})

// let count = 1;

const state = {
    resultDirectReturn: [], // 自动开发的挂号ID列表
    inspectJsq: null, //检测计时器
    inspectCont: 1, //检测次数
    socket: null,
    handPicList: [], //待返回解析数据的图片id
    handRefresh: 0, //数据变化后通知手相重新拍照
    user2: {},
    yzData: {},
    padIsConfirmName: false,
    yzConfirmData: {},
    publicSungoData: {},
    sungoLinkSuccess: {}
}

const getters = {
    socket: state => state.socket,
    handRefresh: state => state.handRefresh,
    yzData: state => state.yzData,
    padIsConfirmName: state => state.padIsConfirmName,
    yzConfirmData: state => state.yzConfirmData,
    publicSungoData: state => state.publicSungoData,
    sungoLinkSuccess: state => state.sungoLinkSuccess,
}

const mutations = {
    closeSocket(state, data) {
        if (state.socket) {
            state.socket.close();
            state.socket = null;
        }
    },

    // 追加自动开方的挂号ID列表
    addResultDirectReturn(state, registerCode) {
        let id = localStorage.getItem('waitAddTimeoutId');
        if (id == registerCode) {
            timeout.addId(
                registerCode,
                () => {
                    this.commit('sendMessage_symptom', {
                        registerCode
                    })
                },
                () => {
                    iView.Message.warning('处方数据获取失败，5秒后请重新提交症状信息')
                    setTimeout(async () => {
                        this.dispatch("updateRecallMedicalRecordSymptom", {
                            state: 0, // 	状态(0开方未结束 1开方已结束)
                            returnNum: 0, //0 没有限制次数召回 1 只能召回1次
                            registerCode //挂号ID
                        });
                    }, 5000);
                },
                10000);

            this.commit("sendLog", {
                remarks: "开始准备10秒后回退动作",
                inParams: {
                    registerCode
                }
            });
        }
    },

    // 删除自动开方的挂号ID列表
    delResultDirectReturn(state, registerCode) {
        let res = timeout.delId(registerCode);
        this.commit("delCondition", registerCode);
        this.commit('sendMessage_symptom', {
            success: true,
            registerCode
        })
        localStorage.removeItem('waitAddTimeoutId')
        if (res) {
            this.commit("sendLog", {
                remarks: "收到处方删除10秒后回退动作",
                inParams: {
                    registerCode
                }
            });
        }
    },

    setHandPicData(state, res) {
        this.handPicList = res;
    },
    saveHandPicData(state, obj) {
        state.inspectCont = 1;
        state.handPicList.push(obj)
        localStorage.setItem('handPicList', JSON.stringify(state.handPicList));
    },
    removeHandPicData(state, id) {
        this.commit('sendMessage_hand', {
            success: true,
            picId: id
        }) //图片解读信息获取成功
        let index = state.handPicList.findIndex(i => i.picId == id)
        if (index != -1) {
            state.handPicList.splice(index, 1)
            localStorage.setItem('handPicList', JSON.stringify(state.handPicList));
        }
    },
    setHandRefresh(state) {
        state.handRefresh = Math.random(); //通知重新拍照
    },
    //OML接口调成功判断有没有获取到解析数据 
    OMLSuccess(state, picId) {
        console.log('OML接口调成功', common.parseTime(new Date()));
        clearInterval(state.inspectJsq)
        state.inspectJsq = setInterval(() => {
            this.commit('inspectHandData', picId)
            if (state.inspectCont++ >= 3) {
                clearInterval(state.inspectJsq) //移除定时检测功能
                this.commit('setHandRefresh');
            }
        }, 5000)
    },
    //检查手相数据是否获取成功
    inspectHandData(state, picId) {
        let item = state.handPicList.find(i => i.picId == picId)
        let obj = {};
        if (item) {
            obj = item; //图片解读信息获取失败
        } else {
            obj = {
                success: true,
                picId
            }; //图片解读信息获取成功
        }
        this.commit('sendMessage_hand', obj)
    },
    // 发是否获取手相解析消息
    sendMessage_hand(state, obj) {
        // 收到就发送received_hand:ok ，没有就发送 not_received_hand:10   (10为图片id) 
        if (!state.socket) return;
        let msg = '';
        // let str = `|${obj.picId}|${state.user2.husDoctorId}`;
        let str = `|${obj.picId}|${state.user2.socketClientId}`;
        if (obj.success) {
            msg = `received_hand${str}|0`;
            clearInterval(state.inspectJsq)
        } else {
            // sex 1.男 2.女
            msg = `not_received_hand${str}|${obj.sex}`;
        }
        state.socket.emit('handMsg', msg);
    },

    /***
     * 前端发送提交症状是否接收成功  received_symptom--症状数据接收成功  not_received_symptom--症状数据接收不成功
     * 判断症状数据是否需要重新发送
     * data参数 状态|患者编码|医馆编码|挂单号|userId  可以接收成功: received_symptom|ok|-1|-1|-1   接收未成功:  not_received_symptom|100001|20|R22222|153
     * 消息返回是否有处方 isPrescription=1(有)，isPrescription=0(无)
     */
    async sendMessage_symptom(state, obj) {
        if (!state.socket) return;
        let msg = '';
        let userinfo = this.getters.userInfo;
        let list = this.getters.prescribeRegisterList;
        let details = list.Visit.details
        if (details.length) {
            let item = details.find(i => i.registerCode == obj.registerCode);
            if (item) {
                let str = `|${item.patientCode}|${userinfo.hosptialCode}|${item.registerCode}|${userinfo.husDoctorId}`;
                if (obj.success) { // ok
                    msg = `received_symptom${str}`
                } else {
                    msg = `not_received_symptom${str}`
                }
            }
        }
        state.socket.emit('addMedicalRecordSymptomMsg', msg);
    },

    sendMessage(state, res) {
        let id = localStorage.getItem("waitAddTimeoutId");
        if (id) {
            let item = res.result.visitList.find(i => i.registerCode == id)
            if (item) {
                let msg = `received_symptom|${item.patientCode}|${state.user2.hosptialCode}|${item.registerCode}|${state.user2.husDoctorId}|待就诊页在提交症状后收到消息`;
                state.socket.emit('addMedicalRecordSymptomMsg', msg);
            }
        }
    },

    async startSocket(state, userInfo) {
        if (state.socket || !userInfo) return;
        var _this = this;
        state.user2 = userInfo;

        let token = userInfo.token[0];
        var sys_str = this.getters.isLoginActive ? 'Hzy5iliJbwDHhjLs1L0j6w' : 'HzEgsyuYRXw7U7sBJjBp';
        var result = token + '&sys=' + sys_str;
        state.socket = io.connect(config.socketApi2 + '?params=' + result + '', {
            transports: ['websocket', 'xhr-polling', 'jsonp-polling']
        });

        this.commit('setIsLoginActive', false)

        //账号在别处登录自动退出账号
        state.socket.on('forcedreturn', (data) => {
            iView.Modal.warning({
                title: '下线通知',
                content: `您的账号在别处登录，您被迫下线。`,
                onOk: () => {
                    this.commit("setCameraInfo", {
                        showWin: false,
                        info: {}
                    });
                    this.commit("setCameraInfo2", {
                        showWin: false,
                        info: {}
                    });
                    // 操作子窗口vuex
                    this.commit("logOut");
                },
            });
        });
        // 给pad发送消息
        state.socket.on('YZ_TO_PAD', (data) => {
            state.yzData = {}
            this.commit('getDataFromYz',data)
        });
        state.socket.on('PAD_TO_YZ', (data) => {
            state.padIsConfirmName = {}
            this.commit('getDataFromPad',data)
        });
        state.socket.on('PAD_CONFIRM', (data) => {
            this.commit('setYzConfirmData',data)
        });
        
        //监听公用手环是否连接成功
        state.socket.on('LINK_SUCCESS', (data) => {
            this.commit('getSungoLinkSuccess',data)
        });

        //获取公共手环数据
        state.socket.on('UPLOAD_SUCCESS', (data) => {
            this.commit('getPublicSungoData',data)
        });
        
        if (this.getters.expert) { //true专家医生
            // 抢单成功后返回的数据
            state.socket.on('rocketmqGrabbingOrders', (data) => {
                this.commit('closeSpin');
                iView.Message.success('抢单成功!');
                this.commit('getOrderList')
            });
            state.socket.on('orderList', (data) => {
                this.commit('updateRegisterList2', data);
            });
            state.socket.on('reCallOrder', (data) => {
                iView.Modal.warning({
                    title: '此病历已被召回',
                    content: '<p>此病历开方将中断</p><p>病历重新提交后可再开方，您可继续接诊其他患者</p>',
                    onOk: () => {
                        this.commit('getOrderList')
                    },
                });
            });
            state.socket.on('aiReceivedOrder', (data) => {
                iView.Modal.warning({
                    title: '此病历已超时',
                    content: '<p>病历已经由AI开方，您可继续接诊其他患者</p>',
                    onOk: () => {
                        this.commit('getOrderList')
                    },
                });
            });
        } else { // false合作医生
            // 收到手相数据 
            state.socket.on('hand_method', (data) => {
                let obj = Aes.decrypt(data);
                console.log(obj);
                this.commit("sendLog", {
                    remarks: "收到手相数据",
                    outParams: obj
                });

                let d = obj.data || obj;
                if(d.nineHillsPlains.length){
                    this.commit("setHandData", d);
                }else{
                    this.commit('setHandRefresh');
                }
                setTimeout(() => {
                    this.commit('removeHandPicData', obj.picId);
                }, 1000);
            });

            // 提交症状后返回 resultDirectReturn=0 人工开方 resultDirectReturn=1自动开方
            state.socket.on('resultDirectReturn', (data) => {
                if (data.returnResult) {
                    localStorage.setItem('waitAddTimeoutId', data.registerCode); //缓存等待加入到超时程序去的挂号Id
                }
            });

            // 获取提交症状时返回的多处方
            state.socket.on('rocketmqSubmitSmptoms', (data) => {
                common.childrenWin.commit("closeSpin");
                if (common.isError(data)) return;
                this.commit("saveHundredYearsMapList", {
                    list: data.result.hundredYearsMapList,
                    id: data.result.registerCode
                });
                router.push("/prescription/" + data.result.registerCode);
            });
            //后台挂号更新数据接口
            state.socket.on('registerorder', (data) => {
                localStorage.setItem('isRegisterorder', 1);
                this.commit('sendMessage', data);
                this.commit('updateRegisterList2', data);
                localStorage.removeItem('isRegisterorder');

                this.commit("sendLog", {
                    remarks: "sokcet收到registerorder消息",
                    outParams: data
                });

            });

            //重新获取处方信息
            state.socket.on('symptomMsg', (data) => {
                if (data.isPrescription) {
                    this.commit('getOrderList')
                }
            });

            //后台医生提交处方后通知前端医生
            state.socket.on('completeorder', (data) => {
                this.commit('getOrderList')
            });

            // 抢单后更新接诊医生
            state.socket.on('RushDoctorName', (data) => {
                var list = this.getters.prescribeRegisterList.Visit.details;
                var item = list.find(i => i.symptomNo == data.symptomNo);
                if (item) item.doctorName = data.doctorName;
            });

        }
    },
    getOrderList(state) {
        this.dispatch('getReloadOrderList')
    },
    saveHundredYearsMapList(state, obj) {
        var {
            list,
            id
        } = obj;
        if (list) {
            let a = [];
            for (const key in list) {
                if (list.hasOwnProperty(key)) {
                    const element = list[key];
                    element.forEach(i => (i.amount = i.quantity));
                    a.push({
                        title: element[0].drugName,
                        remark: element[0].remark,
                        active: key == 1 ? true : false,
                        drugsList: element
                    });
                }
            }
            let o = {};
            o[id] = a;
            this.commit("addMedicalRecordSymptom", o);

        }
    },
    async updateRegisterList2(state, res) {
        res = res.result;
        var newData = {
            NoVisit: {
                details: res.noVisitList,
                noVisitNum: res.noVisitNum,
            },
            Visit: {
                details: res.visitList,
                visitNum: res.visitList.length,
            },
            drugList: res.drugList || [],
        }

        this.commit('getPrescribeRegisterList', {
            data: newData,
            meta: {
                code: 200
            },
        })
    },
    getDataFromYz(state,obj){
        state.yzData = obj
    },
    getDataFromPad(state,obj){
        state.padIsConfirmName = obj
    },
    setYzConfirmData(state,obj){
        state.yzConfirmData = obj
    },
    getPublicSungoData(state,obj){
        state.publicSungoData = obj
    },
    getSungoLinkSuccess(state,obj){
        state.sungoLinkSuccess = obj
    }
}

const actions = {

}


export default {
    state,
    getters,
    actions,
    mutations
}