<template>
  <!-- 病因分析 -->
  <div class="etiologicalAnalysis" :class="{ 'full-screen': isFullScreen }">
    <div class="ucard">
      <div class="ucard_hander">
        <span class="ucard_title">病因分析</span>
        <div class="pathent-info">(
          <span class="pathent-info-name">{{ info.patientName }}</span>
          <span class="pathent-info-sex">
            <Icon v-if="info.sex == '男'" type="md-male" />
            <Icon v-else type="md-female" /> 
            {{ info.sex }}
          </span>
          <span class="pathent-info-age">{{ info.patientAge }}岁</span>
          <span class="pathent-info-cellphoneNo">{{ common.phone(info.cellphoneNo) }}</span>
        )
        <remarks-input mode="btn" v-model="info.otherInfo" :patientCode="info.patientCode" />
      </div>
        <div class="ucard_title_right">
          <Button
            size="small"
            v-if="!isRecall"
            class="recallBtn"
            @click="recall(1)"
            >召回病历</Button
          >
          <Button size="small" type="primary" @click="close">
            拟定处方
            <Icon type="ios-arrow-forward" />
          </Button>
        </div>
      </div>
      <div class="ucard_body">
        <div class="full_btn" @click="isFullScreen = !isFullScreen">
          <template v-if="isFullScreen">
            退出全屏
            <Icon type="ios-contract" />
          </template>
          <template v-else>
            全屏模式
            <Icon type="md-qr-scanner" />
          </template>
        </div>
        <div class="etiologicalAnalysis_cont">
          <status-map v-if="showStatusMap" :info="apiData" />
        </div>
      </div>
    </div>
    <Spin class="u_spin" v-if="indexSpin.show && isFullScreen" fix>
      <Icon type="ios-loading" size="30" class="demo-spin-icon-load"></Icon>
      <div>{{ indexSpin.text || "加载中..." }}</div>
    </Spin>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import clickOutside from "view-design/src/directives/clickoutside";
import paintingHumanBody from "./com/paintingHumanBody.vue";
import utree from "./com/utree.vue";
import StatusMap from "./com/statusMap.vue";
import RemarksInput from "@/com/remarksInput.vue";
export default {
  components: { paintingHumanBody, utree, StatusMap, RemarksInput },
  props: {
    info: {
      type: Object,
      default: function () {
        return {};
      },
    },
    recall: {
      type: Function,
      default: () => {},
    },
  },
  watch: {
    info() {
      this.init();
    },
  },
  directives: { clickOutside },
  data() {
    return {
      apiData: {},
      isFullScreen: true,
      showStatusMap: false,
    };
  },
  computed: {
    ...mapGetters(["indexSpin", "userInfo", "isRecall"]),
  },
  methods: {
    close() {
      this.$emit("close");
    },

    setData() {
      let selectList = localStorage.getItem("selectList_" + this.info.registerCode)
      if(selectList){
        selectList = JSON.parse(selectList);
        let dialecticsIds = [];
        selectList.forEach(j=>{
          j.etiologyList.forEach(item=>{
            if(item.value && item.children.length) {
              dialecticsIds.push(item.dialecticsId)
            }
          })
        })

        let strDialecticsId = dialecticsIds.join(",") 
        this.apiData = {
          diagnosticName1: this.info.diagnosticName1, //诊断
          diagnosticId2: this.info.diagnosticId2, //诊断
          diagnosticName: this.info.diagnosticName2, //诊断
          patientCode: this.info.patientCode, // 病人年龄
          patientAge: this.info.patientAge, // 病人年龄
          patientSex: this.info.sex, // 病人性别
          symptomNo: this.info.symptomNo, // 患者症状号
          strDialecticsId, // 辨证Id字符串,多个用逗号隔开 如11,35,67
        };

        this.showStatusMap = true;
        // 标记已经分析过了
        this.$store.commit("addIsAnalysisList", this.info.registerCode);
      }else{
        this.$Message.warning("暂无分析内容");
        this.close();
      }
    },

    init() {
      this.setData();
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss">
.etiologicalAnalysis {
  background: #fff;
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  padding: 0 15px 15px;
  width: 100%;
  height: 100%;
  .tool {
    visibility: hidden;
  }

  &.full-screen {
    position: fixed;
    padding: 0;
    z-index: 1000;
    .tool {
      visibility: visible;
    }

    .human-body {
      padding: 0 100px;

      .home {
        transform: scale(1.3);
      }
    }
  }

  .lookpic {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 1) 100%
    );
    width: 100%;
    position: absolute;
    bottom: -1px;
    left: 0;
    z-index: 2;
    margin-left: 20px;
    padding: 30px 0 2px 40%;
    .ivu-btn {
      width: 100px;
    }
  }

  .lookpic2 {
    width: 100px;
    position: absolute;
    bottom: 0px;
    left: 40%;
    z-index: 2;
    margin-left: 20px;
  }

  .full_btn {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    z-index: 101;
    font-weight: bold;
    border: 1px solid #853a2f;
    padding: 1px 10px;
    background: #fff;
    border-radius: 3px;
  }

  .etiologicalAnalysis_cont {
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .human-body {
      width: 40%;
      box-sizing: border-box;
      text-align: center;

      .home {
        display: inline-block;
      }
    }
  }

  .bz {
    font-size: 13px;
    max-width: 80%;

    span {
      color: #853a2f;
      padding-right: 10px;
    }
  }

  .icon-g {
    display: flex;

    .icon {
      padding-right: 15px;
      font-size: 12px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        width: 30px;
      }
    }
  }

  .human-body-info {
    overflow-y: auto;
    max-height: 100%;
    width: 60%;
    animation: width2 0.5s ease-out;
    padding-left: 20px;
    padding-bottom: 50px;

    .title {
      display: flex;
      align-items: center;
      justify-content: inherit;
      padding-top: 20px;
    }
  }

  .utree {
    border-left: 1px solid #f00;
    background: #fff;
    position: relative;
    z-index: 2;
    width: 100%;
    white-space: nowrap;

    &.onlyone {
      border: none;
    }

    .icon {
      padding: 0 10px;
      position: relative;

      &.add-icon::after {
        content: " ";
        display: block;
        width: 100%;
        border-top: 1px solid #f00;
        // margin-top: -1px;
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 0;
      }

      .ivu-icon {
        position: relative;
        z-index: 2;
        background: #fff;
        cursor: pointer;
      }
    }

    &.disabled {
      border: none;

      & > .utree-item {
        padding-left: 0;
      }
    }

    .utree-item {
      display: flex;
      align-items: center;
      position: relative;
      padding: 5px 0 5px 40px;

      &:first-child {
        &::before {
          content: " ";
          height: 50%;
          display: block;
          position: absolute;
          left: -1px;
          top: 0;
          border-left: 1px solid #fff;
        }
      }

      &:last-child {
        &::before {
          content: " ";
          height: 50%;
          display: block;
          position: absolute;
          left: -1px;
          bottom: 0;
          border-left: 1px solid #fff;
        }
      }

      &::after {
        content: " ";
        display: block;
        width: 40px;
        border-bottom: 1px solid #f00;
        position: absolute;
        left: 0;
      }

      .label {
        padding-right: 30px;
        position: relative;
        z-index: 1;
        background: #fff;
        border: 1px solid #ddd;
        padding: 1px 15px;
        border-radius: 15px;
        cursor: pointer;
        text-align: center;
        min-height: 25px;

        &.active {
          background: #f00;
          color: #fff;
        }
      }
    }
  }
  .dialectics-name {
    color: #853a2f;
  }
  .pathent-info{
    &>span{
      padding: 0 5px;
    }
    .remarks_input{
      display: inline-block;
      margin-left: 15px;
    }
  }
}
</style>
