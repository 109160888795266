<template>
  <layer pagename="condition" pageClass="conditionPage">
    <div class="center">
      <prescription-entry v-if="info" :info="info" :quoteDurgList="drugList" />
    </div>
    <div class="right">
      <medicalRecordList isPrescriptionPage v-if="showMedicalRecordList" :info="info" :patientCode="info.patientCode" @quote="quote"
        :symptomNo="info.symptomNo" />
      <empty v-else text="无用户信息" />
    </div>
  </layer>
</template>

<script>
import patientList from '@/com/patientList.vue'
import medicalRecordList from '@/com/medicalRecordList.vue'
import PrescriptionEntry from '@/com/prescriptionEntry.vue'
import { mapGetters } from 'vuex'
export default {
  props: {
    id: {
      type: String,
      default: ''
    },
  },
  components: {
    patientList,
    medicalRecordList,
    PrescriptionEntry
  },
  computed: {
    ...mapGetters([
      "prescribeRegisterList", // 就诊中及未就诊挂号(处方单)
    ]),
    // info() {
    //   let list = this.prescribeRegisterList.Visit.details;
    //   let item = list.find(i => i.registerCode == this.id);
    //   return item;
    // }
  },
  watch: {
    prescribeRegisterList(newValue, oldValue) {
      if (!this.common.inArray([newValue.Visit.details, oldValue.Visit.details,], this.id)) {
        this.init();
      }
    },
    id() {
      this.init()
    },
  },
  data() {
    return {
      drugList: [],
      info: null,
      showMedicalRecordList: false,
    }
  },
  methods: {
    quote(drugList) {
      this.drugList = drugList;
    },
    init() {
      this.showMedicalRecordList = false;
      setTimeout(() => {
        this.showMedicalRecordList = true;
      }, 100)
      let list = this.prescribeRegisterList.Visit.details;
      this.info = list.find(i => i.registerCode == this.id);
    }
  },
  created() {
    this.init();
  },
}
</script>

<style lang="scss" scoped>
.u_content {
  display: flex;
  height: 100%;
  min-height: 0;
}
</style>