<template>
  <div class="success-case2-detail">
    <div class="hander">
      <div class="userinfo">
        <span>{{ info.patientName }}</span>
        <span>{{ info.genderName }}</span>
        <span>{{ info.patientAge }}岁</span>
      </div>
    </div>

    <div class="item">
      <span class="label">诊断:</span>
      <span class="value">{{ info.diseaseName }}</span>
    </div>
    <div class="item">
      <span class="label">医馆:</span>
      <span class="value">{{ info.hospitalName }}</span>
    </div>
    <div class="item">
      <span class="label">就诊次数:</span>
      <span class="value">{{ info.visitTimes }}次</span>
    </div>
    <div class="item">
      <span class="label">治愈疗程:</span>
      <span class="value">{{ info.cureDay }}天</span>
    </div>
    <div class="item">
      <span class="label">首诊时间:</span>
      <span class="value">{{ info.startDate }}</span>
    </div>
    <div class="item">
      <span class="label">末诊时间:</span>
      <span class="value">{{ info.endDate }}</span>
    </div>
    <div class="item">
      <span class="label">治愈描述:</span>
      <span class="value">{{ info.remarks }}</span>
    </div>
    <div class="img-body" ref="images_success_case">
      <div class="pic-item" @click="clickPic" v-for="(pic, index) in formatPicUrl" :key="index">
        <img :src="pic" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import viewer from "viewerjs";
export default {
  props: {
    info: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      picView: null,
    }
  },
  computed: {
    formatPicUrl() {
      let str = this.info.pictureUrl
      return str ? str.split(',').map(i => this.cloudPic + "/smedical/" + i) : [];
    },
  },
  methods: {
    clickPic() {
      if (this.picView) this.picView.destroy();
      this.picView = new viewer(this.$refs.images_success_case, { zIndex: 10000 });
    },
  },
};
</script>

<style lang="scss" scoped>
.success-case2-detail {
  background: #fff;
  padding: 15px;
  line-height: 2em;

  .hander {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    span {
      padding-right: 5px;
    }

    .userinfo {
      font-weight: bold;
      font-size: 16px;
    }
  }

  .label {
    padding-right: 10px;
    color: #666;
  }

  .img-body {
    padding: 15px 0;
    overflow: hidden;

    // .pic-item {
    //   width: 120px;
    //   height: 120px;
    //   border: 1px solid #ddd;
    //   padding: 3px;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   margin: 0 10px 10px 0;
    //   float: left;
    //   border-radius: 5px;
    // }

    img {
      max-width: 100%;
    }
  }
}
</style>