import request from './request'
import common from '@/common.js'
import Aes from '@/utils/Aes'
import store from '../store/index';

function searchParamsArr(arr) {
    var par = new FormData();
    arr.forEach(i => {
        par.append(i.key, i.file);
    })
    return par;
}

// 加密方法 
function encrypt(obj, appId, key) {
    obj = common.clone(obj);
    obj.appId = appId || "XSZY80d28e0682f846ee";
    let dateOffset = localStorage.getItem('dateOffset')
    obj.timestamp = new Date().getTime() + (dateOffset ? dateOffset * 1 : 0);
    let params = Aes.encrypt(obj, key);

    let newObj = {
        params,
    }
    if (config.debug) {
        newObj['请求参数'] = obj
    }
    return newObj;
}

export default function api(fun, data, method) {
    if (!data) {
        data = {}
    }
    if (!navigator.onLine) {
        var ts = "无法连接网络,请检查网络设置后重试!";
        return new Promise((resolve, reject) => {
            reject({
                "meta": {
                    "msg": ts,
                    "code": 400
                }
            })
        })
    }

    var token = store.getters.token || [],
        headers = {};
    headers['Authorization'] = token[0] || '';

    var apiUrl = {
        getDiagnosticList: 'base/getDiagnosticListNew', // 症状列表
        getSpecialSymptomsRemind: 'ap/getSpecialSymptomsRemind', // 特殊症状提醒

        getFLSQ_Calculate_Result: 'fivelucksixqi/getFLSQ_Calculate_Result', // 五云六气推算
        getFLSQ_Calculate_Result_New: 'fivelucksixqi/getFLSQ_Calculate_Result_New', // 五云六气推算
        getCustomerDetail: 'hzda/getCustomerDetail', // 详情 
        getReturnVisitList: 'hzda/getReturnVisitList', // 用户回访记录列表
        saveReturnVisit: 'hzda/saveReturnVisit', // 新增、修改回访记录
        getReturnVisitThemeList: 'hzda/getReturnVisitThemeList', // 查询回访主题
        getCustomerPrescribeList: 'hzda/getCustomerPrescribeList', // 处方列表 

        getDiagnosticAcupointInfo: 'base/getDiagnosticAcupointInfo', // 根据疾病Id获取穴位信息 
        getDirectHospitalList: 'base/getDirectHospitalList', // 查询直营医馆列表 

        addPatientHobby: '/zhbusiness/addPatientHobby', //新增患者喜好
        delPatientHobby: '/zhbusiness/delPatientHobby', //新删除患者喜好
        getPatientSuccessMedicalRecordInfoList: '/zhbusiness/getPatientSuccessMedicalRecordInfoList', //获取待补充、已录入列表
        addPatientSuccessMedicalRecord: '/zhbusiness/addPatientSuccessMedicalRecord', //新增或修改患者成功病历
        getPatientSuccessMedicalRecordDetail: '/zhbusiness/getPatientSuccessMedicalRecordDetail', //成功病历详细数据接口
        tempAddPatientSuccessMedicalRecord: '/zhbusiness/tempAddPatientSuccessMedicalRecord', //新增患者成功病历用户、治愈日期
        getAuxiliaryToolData: '/zhbusiness/getAuxiliaryToolDataNew', //新增五运六气、话术、穴位按摩是否有数据
        getZHMedicalRecordNewList: '/report/getZHMedicalRecordNewList', //慈脉云诊处方笺数据

        getLiverRenalFunctionReportList: '/zhbusiness/getLiverRenalFunctionReportList', //查询肝肾功能报告
        saveLiverRenalFunctionReport: '/zhbusiness/saveLiverRenalFunctionReport', //新增、修改肝肾功能报告

        addPrescription: '/zhbusiness/addPrescription', //
        getDrugStockByHospitalCode: '/zhbusiness/getDrugStockByHospitalCode', //根据医馆获取当前医馆药品库存
        getPatientDeepDiveHzdaList: '/hzda/getPatientDeepDiveHzdaList', //慈脉云诊患者档案深潜调研信息
        uploadPictureToOML_test: '/base/uploadPictureToOML_test', //OML手相 测试
        uploadPictureToOML_test_new: '/base/uploadPictureToOML_test_new', //OML手相 测试
        uploadPictureToOML: '/base/uploadPictureToOML', //OML手相 正式

        queryMedicalRecordScan: 'zhbusiness/queryMedicalRecordScan', // 查询病历扫描数据列表
        updatePatientTag: 'base/updatePatientTag', // 更新患者标签(客户生命周期系统-用户贴周期标签接口) 

        // ------可删除
        // getPatientSymptomDifferenceList: 'zhbusiness/getPatientSymptomDifferenceList', //患者复诊病历症状变化

        // 考试类
        insertSymptom: 'practice/insertSymptom', // 插入症状
        pauseOrStart: 'practice/pauseOrStart', // 暂停 
        getUserWrongQuestionsList: 'practice/getUserWrongQuestionsList', // 我的错题
        delMyWrongQuestions: 'practice/delMyWrongQuestions', // 移除我的错题
        updateStartEndExaminationPaper: 'practice/updateStartEndExaminationPaper', // 点击开始、结束考试按钮
        getSymptomAnswerResultBySubjectId: 'practice/getSymptomAnswerResultBySubjectId', // 症状试卷试题自已录入和系统症状列表
        getEndExaminationPaperList: 'practice/getEndExaminationPaperList', // 查看我的已考完试卷列表
        getAnswerResultBySubjectId: 'practice/getAnswerResultBySubjectId', // 试卷试题自已开方和徐氏开方列表
        getExaminationPaperList: 'ap/getExaminationPaperList', // 试卷题列表
        addPractice: 'practice/addPractice', // 病历练习保存

        getMedicalRecordAnalysisList: 'zhbusiness/getMedicalRecordAnalysisList', // 返回症状病因病机辨证列表
        updateMedicalRecordAnalysis: 'zhbusiness/updateMedicalRecordAnalysis', // 提交症状病因病机辨证
        getPatientMedicalRecordAnalysisList: 'zhbusiness/getPatientMedicalRecordAnalysisList', // 返回患者所有症状病因病机辨证列表
        getPrintMedicalRecordsData: 'zhbusiness/getPrintMedicalRecordsData', // 获取病历打印数据
        getDoctorCapabilityRank: 'report/getDoctorCapabilityRank', // 根据医生id获取能力指标
        listPatientMainSymptom: 'zhbusiness/listPatientMainSymptomNew', //获取患者病历列表 
        listPatientMainSymptomNew: 'zhbusiness/listPatientMainSymptomNew', //获取患者病历列表 

        getRecallAndDrugTaboo: 'zhbusiness/getRecallAndDrugTaboo', //获取召回状态和症状冲突 

        saveMedicalRecordSymptom: 'zhbusiness/saveMedicalRecordSymptom', //前端医生提交症状(新) 
        autoPrescription: 'zhbusiness/autoPrescription', //自动开方

        pagePatientHistory: 'report/pagePatientHistory', //患者列表接口
        // 患者档案
        getPatientBaseInfo: 'hzda/getPatientBaseInfo', //患者基本信息 
        getPatientMedicalRecord: 'hzda/getPatientMedicalRecord', //患者就诊记录 
        getHandUnscrambleByAdiId: 'zhbusiness/getHandUnscrambleByAdiId', //根据手相记录id获取解读信息 

        getQrCodeByDiseaseId: 'admin/getQrCodeByDiseaseId', //通过病种获取邀约打卡群二维码url(参数不用加密) 
        getUserCloudSystem: 'admin/getUserCloudSystem', //获取功能权限列表
        updateMedicalRecordSymptomStatus: 'zhbusiness/updateMedicalRecordSymptomStatus', //更新病历就诊状态为就诊中
        saveDrugTabooByDiscriminateLog: 'zhbusiness/saveDrugTabooByDiscriminateLog', //保存药物禁忌(辨证)确认使用的记录
        getVerifyCode: 'user/getVerifyCode', //更新病历就诊状态为就诊中
        saveFrontEndLog: 'ap/saveFrontEndLog', //保存前端日志
        listSymptomEtiologyDialectical: 'zhbusiness/listSymptomEtiologyDialecticalNew', // 症状病因病机辨证列表
        saveMrEtiologyPathogenesisExplain: 'zhbusiness/saveMrEtiologyPathogenesisExplain', // 保存病历病因病机解说
        saveMrEtiologyPathogenesisExplainList: 'zhbusiness/saveMrEtiologyPathogenesisExplainList', // 批量保存病历病因病机解说
        listEtiologyPathogenesisPicData: 'zhbusiness/listEtiologyPathogenesisPicData', // 生成病因病机图片所需数据
        getPatientMedicalRecordAnalysisList: 'zhbusiness/getPatientMedicalRecordAnalysisList', // 返回患者所有症状病因病机辨证列表
        getEtiologyReasonByName: 'zhbusiness/getEtiologyReasonByName', // 通过名称检索病因底层原因列表
        deleteMrEtiologyPathogenesisExplain: 'zhbusiness/deleteMrEtiologyPathogenesisExplain', // 删除病历病因病机解说或病历底层原因
        getMedicalDietPlanByBillNo: 'zhbusiness/getMedicalDietPlanByBillNo', // 通过病历单号获取饮食规划
        listDiseaseDietPlan: 'zhbusiness/listDiseaseDietPlan', // 诊断的饮食规划
        listEtiologyPathogenesisExplain: 'zhbusiness/listEtiologyPathogenesisExplain', // 按病历号查询病因病机辨证解说数据
        getCureNumberByDiseaseId: 'zhbusiness/getCureNumberByDiseaseId', // 通过诊断Id获取治愈人数
        saveMedicalRecordDiseaseMap: 'zhbusiness/saveMedicalRecordDiseaseMap', // 保存病历疾病发展现况图片
        listDiseaseEpPopularExplain: 'admin/listDiseaseEpPopularExplain', // 疾病病因病机通解
        updatePatientCourseCrowd: 'base/updatePatientCourseCrowd', // 更新患者病程人群分类
        listEnterprisePatientHealthRecord: 'hzda/listEnterprisePatientHealthRecord', // 患者成功病案列表(新)
        getDietaryPlan: 'zhbusiness/getDietaryPlan', // 获取当日饮食规划
        savaDiseaseCourseManage: 'admin/savaDiseaseCourseManage', // 获加入患者病程(云诊系统操作)
        savePatientTreatmentProgress: 'zhbusiness/savePatientTreatmentProgress', // 通过辨证计算并保存患者的疗程进度
        getPatientPrescriptionHistoryList: 'report/getPatientPrescriptionHistoryList', //患者处方历史列表
        getEtiologyPathogenesisData: 'zhbusiness/getEtiologyPathogenesisData',
        listLastTimeEtiologyPathogenesisExplain: 'zhbusiness/listLastTimeEtiologyPathogenesisExplain ', //查找上次病因病机解读数据 

        getSungoData: 'kanghome/getSungoData',   //获取松果数据
        getRecentlyDate: 'kanghome/getRecentlyDate',   //获取最近时间
        getVisitTimeList: 'kanghome/getVisitTimeList', //获取看诊时间段
        linkBracelet: 'zhbusiness/linkBracelet', //链接铁粉之家公用手环 
        linkSuccess: 'zhbusiness/linkSuccess ', //链接成功 
        uploadSuccess: 'zhbusiness/uploadSuccess ', //上传数据成功 
        getPatientList: 'base/getPatientList', // 挂号患者搜索列表

        modifyPassWord: 'user/modifyPassWord', // 密码修改
        getHospitalNoticeList: 'base/getHospitalNoticeList', // 公告列表
        updateHospitalNoticeIsRead: 'base/updateHospitalNoticeIsRead', // 公告列表
        getUserLogin: 'user/userLogin', // 登录
        getContradictDiagnosticSymptomList: 'base/getContradictDiagnosticSymptomList', // 症状互斥列表
        getDrug: 'base/getDrugList/', // 获取药物
        getDiseaseInfos: 'base/getDiseaseTypeList', // 获取疾病信息
        judgingSymptoms: 'zhbusiness/judgingSymptoms', // 根据症状判断是否怀孕
        getAddRegister: 'zhbusiness/addRegister', // 挂号新增
        addMedicalRecordSymptom: 'zhbusiness/addMedicalRecordSymptom', // 合作医生保存患者症状
        getDiagnosisBySymptoms: 'zhbusiness/getDiagnosisBySymptoms', // 通过症状名称集返回中医诊断
        getReloadOrderList: "zhbusiness/getReloadOrderList", // 获取就诊用户列表
        addDoctorRushBill: 'zhbusiness/addDoctorRushBill', // 抢单
        updateMedicalRecord: 'zhbusiness/updateMedicalRecord', // 合作医生保存处方
        addMedicalRecord: 'zhbusiness/addMedicalRecord', // 徐氏专家保存处方
        getPatientLastSymptom: 'zhbusiness/getPatientLastSymptom/', //获取最后一次症状
        updateRecallMedicalRecordSymptom: 'zhbusiness/updateRecallMedicalRecordSymptom', // 病历召回
        getPrescribeByBillNoList: 'report/getPrescribeByBillNoList/', // 获取徐氏专家处方
        getUserQuestionList: 'report/getUserQuestionList/', // 用户问题列表
        sendingSymptoms: 'zhbusiness/sendingSymptoms',
        confirmSymptoms: 'zhbusiness/confirmSymptoms',
        padConfirm: 'zhbusiness/padConfirm',
        getWideUseList: 'base/getWideUseList', // 查询通用字典表,如(服药方式、煎药方式等)
        deleteMedicalRecordScan: 'zhbusiness/deleteMedicalRecordScan', // 删除病历扫描数据
        addMedicalRecordScan: 'zhbusiness/addMedicalRecordScan', // 保存病历扫描图片
        fileUploadNew: 'base/fileUploadNew', // 腾讯云上传文件

        getPatientAnanAuth: 'kanghome/getPatientAnanAuth',   // 云诊-是否安安适用人群 
        bindPatientAnanAuth: 'kanghome/bindPatientAnanAuth',   // 云诊-绑定安安适用人群
        addPatientAnanMessage: 'saasbaihui/addPatientAnanMessage',   // 新增患者加入安安提醒

        getUserSystemConfig: 'zhbusiness/getUserSystemConfig', // 获取用户参数配置
        saveUserSystemConfig: 'zhbusiness/saveUserSystemConfig', // 保存病历扫描图片
        listEtiologyBottomReason: 'zhbusiness/listEtiologyBottomReason', // 获取病因底层原因列表
    };
    let url = apiUrl[fun];

    switch (fun) {
        case 'insertSymptom':
        case 'pauseOrStart':
        case 'getUserWrongQuestionsList':
        case 'delMyWrongQuestions':
        case 'updateStartEndExaminationPaper':
        case 'getSymptomAnswerResultBySubjectId':
        case 'getEndExaminationPaperList':
        case 'getAnswerResultBySubjectId':
        case 'getExaminationPaperList':
        case 'addPractice': {
            url = config.newapi2 + '/' + url;
        }
            break;

        default:
            break;
    }

    switch (fun) {
        // 参数不加密
        case 'modifyPassWord':
        case 'getHospitalNoticeList':
        case 'updateHospitalNoticeIsRead':
        case 'getUserLogin':
        case 'getContradictDiagnosticSymptomList':
        case 'getDrug':
        case 'getDiseaseInfos':
        case 'judgingSymptoms':
        case 'getAddRegister':
        case 'addMedicalRecordSymptom':
        case 'getDiagnosisBySymptoms':
        case 'getReloadOrderList':
        case 'addDoctorRushBill':
        case 'updateMedicalRecord':
        case 'addMedicalRecord':
        case 'getPatientLastSymptom':
        case 'updateRecallMedicalRecordSymptom':
        case 'getPrescribeByBillNoList':
        case 'getUserQuestionList':
        case 'getSpecialSymptomsRemind':
        case 'sendingSymptoms':
        case 'confirmSymptoms':
        case 'padConfirm':
        case 'getWideUseList':
        case 'deleteMedicalRecordScan':
        case 'addMedicalRecordScan':

        case 'getPatientList':
        case 'getPatientPrescriptionHistoryList':
        case 'getQrCodeByDiseaseId':
        case 'getDirectHospitalList':
        case 'getExaminationPaperList': { }
            break;

        case 'fileUploadNew': {
            data = searchParamsArr(data);
        } break;

        case 'uploadPictureToOML_test_new':
        case 'uploadPictureToOML_test':
        case 'uploadPictureToOML': {
            data.params.userdata.token = token[0];
            data.params.userdata = encrypt(data.params.userdata).params;
            data.params = encrypt(data.params, 'CIMAI80d28e0222f846ff', 'L/MNRHpGFIV95VKD').params
            let arr = [];
            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    arr.push({
                        key,
                        file: data[key]
                    })
                }
            }
            data = searchParamsArr(arr);
            url = config.omlApi + url;
            headers['Authorization'] = '';
        }
            break;

        default:
            data = encrypt(data);
            break;
    }
    return request({
        url,
        headers,
        method: method || 'post',
        data
    })
}