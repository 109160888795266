<!-- 脏腑状态 -->
<template>
    <div>
        <Card class="mb-15">
            <template #title>
                <div class="card-header">
                    <span>状态分析</span>
                </div>
            </template>
            <Row :gutter="16">
                <Col span="14">
                <Carousel class="viscera-carousel" v-model="value" ref="radios" @on-change="carouselChange">
                    <CarouselItem v-for="(item, index) in organs0Data1" :key="index">
                        <div :id="'organs_sub' + index" style="width: 100%; height: 400px;"></div>
                    </CarouselItem>
                </Carousel>
                </Col>
                <Col span="10">
                <Table ref="currentRowTable" class="rate-table" :row-class-name="rowClassName" :columns="columns"
                    :data="visceraTables.statusTable" width="100%" height="400" @on-row-click="tableClick"
                    highlight-row>
                </Table>
                </Col>
            </Row>
        </Card>
        <Card>
            <template #title>
                <div class="card-header">
                    <span>精神分析</span>
                </div>
            </template>
            <Table :columns="spiritColumns" :data="visceraTables.spiritTable" width="100%"></Table>
        </Card>
    </div>
</template>

<script>
import * as echarts from "echarts";
import resize from './mixins/resize.js'
export default {
    mixins: [resize],
    props: {
        className: {
            type: String,
            default: 'chart'
        },
        width: {
            type: String,
            default: '100%'
        },
        height: {
            type: String,
            default: '100%'
        },
        autoResize: {
            type: Boolean,
            default: true
        },
        visceraData: {
            type: Object,
            default: {}
        },
        ecgData: {
            type: Object,
            default: {}
        },
    },
    data() {
        return {
            chart: null,
            tableData: [],
            visceraTip: "",
            visceraReading: [],
            visceraDay: "今日",
            time: "",
            type: 1,
            organs0Data1: [],
            visceraTables: {
                statusTable: [],
                spiritTable: [],
                xyTable: []
            },
            xyRecord: {
                list: [],
                total: 0
            },
            xyParam: {
                pageNum: 1,
                pageSize: 10
            },
            columns: [
                {
                    title: '测试时间',
                    key: 'tableDate',
                },
                {
                    title: '呼吸率',
                    key: 'respiratoryRate',
                },
                {
                    title: '静息心率',
                    key: 'restingHeartRate',
                },
                {
                    title: '状态',
                    key: 'dayNightLabel',
                }
            ],
            value: 0,
            spiritColumns: [
                {
                    title: '测试时间',
                    key: 'ecgTime',
                },
                {
                    title: '应变能力',
                    key: 'eyHrPressure',
                },
                {
                    title: '放松程度',
                    key: 'eyTensionDegree',
                },
            ],
        }
    },
    watch: {

    },
    created() {

    },
    mounted() {
        this.$nextTick(() => {
            this.getVisData()
        })
    },
    beforeDestroy() {
        if (!this.chart) {
            return
        }
        this.chart.dispose()
        this.chart = null
    },
    methods: {
        rowClassName(row, index) {
            if (row.dayNight === 2) {
                return 'day-night-sleep';
            } else if (row.dayNight === 1) {
                return 'day-night-sober';
            }
        },
        carouselChange(oldval, val) {
            this.$refs.currentRowTable.$refs.tbody.clickCurrentRow(val)
        },
        visceraTablesClass: function (e) {
            var t = e.row;
            e.rowIndex;
            return 1 == t.dayNight ? "viscera-day" : "viscera-night"
        },
        tableClick: function (e) {
            var t = this;
            this.organs0Data1.forEach(function (a, i) {
                if (e.dataDate === a.addTime) {
                    t.visceraTip = t.visceraTables.statusTable[i].visceraCode
                    t.value = i
                }
            })
        },
        getVisData: function () {
            let e = this
                , t = this.visceraData;
            this.visceraTip = t.visceraCode
            this.visceraReading = t.visceraReading;
            let a = []
            let i = t.visceraArrayList || []
            //过滤静心心率和呼吸率为0的数据
            let s = i.filter(function (e) {
                if (Number(e.dayNight) === 1) {
                    e.dayNightLabel = '清醒'
                }
                if (Number(e.dayNight) === 2) {
                    e.dayNightLabel = '静眠'
                }
                if (e.respiratoryRate !== 0 && e.restingHeartRate !== 0) {
                    return e
                }
            })
            let r = s.filter(function (e) {
                return 1 === e.dayNight
            })
            let n = s.filter(function (e) {
                return 2 === e.dayNight
            })
            let o = this.sumAvg(r)
            let l = this.sumAvg(n);
            if (s.length > 0) {
                s.forEach(function (e, t) {
                    e.tableDate = e.dataDate.split(" ")[1]
                    var i = {
                        value: []
                    };
                    i.addTime = e.dataDate
                    i.dayNight = e.dayNight
                    i.itemStyle = {}
                    i.itemStyle = {}
                    // i.value[0] = e.intHeart
                    // i.value[1] = e.intLiver
                    // i.value[2] = e.intKidney
                    // i.value[3] = e.intSpleen
                    // i.value[4] = e.intLungs
                    i.value[0] = e.intLiver
                    i.value[1] = e.intHeart
                    i.value[2] = e.intSpleen
                    i.value[3] = e.intLungs
                    i.value[4] = e.intKidney
                    1 == e.dayNight ? (i.itemStyle.color = "#FF8523",
                        i.avg = o) : (i.itemStyle.color = "#2582CB",
                            i.avg = l),
                        a[t] = i
                })
                this.time = a[0].addTime.split(" ")[1]
            } else {
                this.time = ""
                a.push({})
            }
            this.organs0Data1 = a
            this.visceraDay = "本次"
            setTimeout(function () {
                e.visceraDayValue("本次")
                e.$refs.currentRowTable.$refs.tbody.clickCurrentRow(0)
            }, 100);
            var c = (this.ecgData.ecgDay || []).filter(function (e) {
                return "noshow_abn" !== e.status
            });
            this.visceraTables.xyTable = i.filter(function (e) {
                return "noshow_abn" !== e.status && e.systolicPressure
            })
            this.visceraTables.statusTable = s
            this.visceraTables.spiritTable = c
        },
        star_number: function (e) {
            return e ? "100" === e ? 5 : e >= 90 ? 4.5 : e >= 80 ? 4 : e >= 70 ? 3.5 : e >= 60 ? 3 : e >= 50 ? 2.5 : e >= 40 ? 2 : e >= 30 ? 1.5 : e > 0 ? 1 : 0 : 0
        },
        visceraDayValue: function () {
            let e = {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: "#F9C178"
                }, {
                    offset: 1,
                    color: "#FFE7BC"
                }])
            }
            let t = {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: "#C8E7FF"
                }, {
                    offset: 1,
                    color: "#5BB5FC"
                }])
            }
            let a = {
                grid: {
                    y: 10,
                    x2: 14,
                    y2: 10,
                    x: 14
                },
                tooltip: {
                    show: !1
                },
                radar: {
                    axisName: {
                        fontSize: 14,
                        color: "#999"
                    },
                    splitNumber: 3,
                    indicator: [{
                        name: "肝脏",
                        max: 190,
                        min: 10
                    }, {
                        name: "心脏",
                        max: 190,
                        min: 10
                    }, {
                        name: "脾脏",
                        max: 190,
                        min: 10
                    }, {
                        name: "肺脏",
                        max: 190,
                        min: 10
                    }, {
                        name: "肾脏",
                        max: 190,
                        min: 10
                    }]
                },
                series: [{
                    name: "当前值",
                    type: "radar",
                    data: [],
                    zlevel: 2
                }, {
                    name: "平均值",
                    type: "radar",
                    data: [],
                    itemStyle: {},
                    areaStyle: {}
                }]
            }
            for (let i = this.organs0Data1, s = 0; s < i.length; s++) {
                a.series[0].data[0] = i[s]

                if ((i[s].dayNight) == 1) {
                    a.series[1].itemStyle.color = "#FEC87E"
                    a.series[1].areaStyle = e
                } else {
                    a.series[1].itemStyle.color = "#5BB5FC"
                    a.series[1].areaStyle = t
                }
                a.series[1].data[0] = i[s].avg
                this.getEchartisInit("organs_sub" + s).setOption(a, !0)
            }
        },
        getEchartisInit: function (e) {
            var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "canvas"
                , a = echarts.getInstanceByDom(document.getElementById(e));
            return null == a && (a = echarts.init(document.getElementById(e), null, {
                renderer: t
            })),
                a
        },
        sumAvg: function (e) {
            for (var t = 0, a = 0, i = 0, s = 0, r = 0, n = 0; n < e.length; n++)
                t += parseInt(e[n].intHeart),
                    a += parseInt(e[n].intLiver),
                    i += parseInt(e[n].intKidney),
                    s += parseInt(e[n].intSpleen),
                    r += parseInt(e[n].intLungs);
            return [Math.floor(t / e.length), Math.floor(a / e.length), Math.floor(i / e.length), Math.floor(s / e.length), Math.floor(r / e.length)]
        },
        xyClickRecord: function () {
            var e = this
                , t = this.xyParam
                , a = t.pageNum
                , i = t.pageSize;
            this.xyParam.userId = this.$route.query.q.replace(/ /g, "+"),
                this.$service.userInfo.getBloodPressureAlign(this.xyParam).then(function (t) {
                    e.xyRecord.list = t.pages.list.map(function (e, t) {
                        return t += 1,
                            j()({}, e, {
                                id: a > 1 ? (a - 1) * i + t : t
                            })
                    }),
                        e.xyRecord.total = t.pages.total,
                        e.xyDialog = !0
                })
        },
        currentChange: function (e) {
            this.xyParam.pageNum = e,
                this.xyClickRecord()
        },
    }
}
</script>
<style lang="scss">
.ivu-table .day-night-sleep td {
    background-color: #c8e7ff;
}

.ivu-table .day-night-sober td {
    background-color: #ffe7bc;
}

.ivu-table .table-active-clicked td {
    background-color: red;
}

.ivu-table-row-highlight td {
    background-color: #ebf7ff !important;
}
</style>
<style lang="scss" scoped>
.mb-15 {
    margin-bottom: 15px;
}
</style>