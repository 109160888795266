<template>
	<div v-clickOutside="clickOutsideFun" class="chief_complaint">
		<div class="chief_complaint_value" @click="openWin">
			<template v-if="value.diagnosis1">
				{{ diagnosisName }}
				<span class="additional" v-if="value.chiefComplaintTime">
					持续:
					{{ value.chiefComplaintTime }}
					<i v-if="value.chiefComplaintTimeType !== ''">
						{{ common.idToLabel(chiefComplaintTimeTypeList, value.chiefComplaintTimeType) }}
					</i>
					<i v-if="value.chiefComplaintDegree">
						程度:
						{{ common.idToLabel(CHIEFCOMPLAINT_DEGREE, value.chiefComplaintDegree) }}
					</i>
				</span>
			</template>
			<span v-else class="placeholder">
				{{ placeholder }}
			</span>
		</div>

		<div v-if="show" class="chief_popup">
			<Icon class="close" @click="show = false" type="ios-close-circle-outline" />
			<div class="chief_popup_item">
				<div class="c_p_label">请选择主诉:</div>
				<div class="c_p_value">
					<RadioGroup v-model="postData.diagnosis1">
						<Radio v-for="item in symptomList" :key="item.dsId" :label="item.dsId" :valie="item.dsId" border>
							{{ item.dsName }}</Radio>
					</RadioGroup>
				</div>
			</div>

			<div class="chief_popup_item">
				<div class="c_p_label">持续时间:</div>
				<div class="c_p_value">
					<Input v-model="postData.chiefComplaintTime" type="number" placeholder="主诉持续时间"></Input>
					<RadioGroup v-model="postData.chiefComplaintTimeType">
						<Radio v-for="item in chiefComplaintTimeTypeList" :key="item.value" :label="item.value"
							:valie="item.value" border>{{ item.label }}</Radio>
					</RadioGroup>
					<div class="c_p_label">程度:</div>
					<RadioGroup v-model="postData.chiefComplaintDegree">
						<Radio v-for="item in CHIEFCOMPLAINT_DEGREE" :key="item.value" :label="item.value"
							:valie="item.value" border>{{ item.label }}</Radio>
					</RadioGroup>
				</div>
			</div>
			<div class="footer">
				<Button @click="submit" class="submit" type="primary">确定</Button>
			</div>
		</div>
	</div>
</template>

<script>
import clickOutside from "view-design/src/directives/clickoutside";
import { mapGetters } from 'vuex'
export default {
	directives: { clickOutside },
	computed: {
		...mapGetters(['chiefComplaintTimeTypeList', 'CHIEFCOMPLAINT_DEGREE']),
		diagnosisName() {
			if (this.value.diagnosis1) {
				let item = this.symptomList.find(i => i.dsId == this.value.diagnosis1)
				return item ? item.dsName : ''
			} else {
				return '';
			}
		}
	},
	props: {
		value: {
			type: Object,
			default: () => { }
		},
		symptomList: {
			type: Array,
			default: () => []
		},
		placeholder: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			show: false,
			postData: {
				diagnosis1: '',
				chiefComplaintTime: '',
				chiefComplaintTimeType: '',
				chiefComplaintDegree: '',
			}
		}
	},
	watch: {
		show(newValue, oldValue) {
			this.init();
		},
		symptomList(value) {
			this.listChange();
		},
	},
	methods: {
		// 列表更改后判断是否列表里是否有当前选中的症状，如果没有的话 就清空
		listChange() {
			let id = this.postData.diagnosis1;
			if (id) {
				if (!this.symptomList.find(i => i.dsId == id)) {
					this.postData.diagnosis1 = '';
					this.value.diagnosis1 = '';
					// this.send();
				}
			}
		},
		clickOutsideFun() {
			if (this.show) this.hideTip();
		},
		hideTip() {
			clearTimeout(this.jsq);
			this.jsq = setTimeout(() => {
				this.show = false;
			}, 200);
		},
		openWin() {
			if (!this.symptomList.length) {
				this.$Message.warning('请先选择症状')
			} else {
				this.show = true;
			}
		},
		send() {
			this.$emit('input', Object.assign({}, this.postData))
		},
		submit() {
			if (this.postData.chiefComplaintTime) {
				if (this.postData.chiefComplaintTimeType === "" || this.postData.chiefComplaintDegree === "") {
					this.$Message.warning("持续时间需要选完整")
					return
				}
			}
			this.show = false;
			this.send();
		},
		init() {
			Object.assign(this.postData, this.value)
		}
	},

	created() {
		this.$store.dispatch("CHIEFCOMPLAINT_DEGREE");
	},
}
</script>

<style lang="scss" >
.chief_complaint {
	height: 26px;
	position: relative;

	.chief_complaint_value {
		height: 100%;
		cursor: pointer;
	}

	.chief_popup {
		box-shadow: 0px 5px 10px rgba(0, 0, 0, .2);
		width: 100%;
		background: #fff;
		border: 1px solid #ccc;
		padding: 15px;
		position: absolute;
		z-index: 2;
		top: 31px;
	}

	.chief_popup_item {
		border-bottom: 1px solid #ddd;
		display: flex;
		padding: 10px 0;
		white-space: nowrap;

		.c_p_label {
			line-height: 32px;
			padding-right: 5px;
		}

		.c_p_value {
			display: flex;
			flex-wrap: wrap;

			.ivu-input-wrapper {
				width: 100px;

				.ivu-input {
					text-align: center;
				}
			}
		}
	}

	.ivu-radio {
		display: none;
	}

	.ivu-radio-group {
		padding-left: 10px;
		display: flex;
		flex-wrap: wrap;
	}

	.ivu-radio-border {
		margin-bottom: 10px;
	}

	.ivu-radio-wrapper-checked.ivu-radio-border {
		background: #853a2f;
		border-color: #853a2f;
		color: #fff;
	}

	.footer {
		text-align: center;
	}

	.submit {
		margin: 10px auto 0;
		width: 200px;
	}

	.additional {
		font-size: 12px;
		background: #FFFCE0;
		display: inline-block;
		line-height: 16px;
		padding: 0 8px;
		float: right;

		i {
			font-style: inherit;
		}
	}

	.close {
		position: absolute;
		top: 4px;
		right: 4px;
		cursor: pointer;
		padding: 5px;
		font-size: 22px;
	}
}
</style>