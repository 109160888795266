<!-- 日间、夜间、全天脏腑数据 -->
<template>
    <div>
        <div class="section-time">{{ section.name }}</div>
        <div v-if="section.arr && section.arr.length > 0" class="section-list">
            <div v-for="(item, index) in section.arr" :key="index">
                <span>{{ item.value }}</span>
                <span>{{ item.name }}</span>
            </div>
        </div>
        <Row :gutter="16">
            <Col span="8">
            <div :id="fiveId" :style="{ height, width }" />
            </Col>
            <Col span="16">
            <div :id="lineId" :style="{ height, width }" />
            </Col>
        </Row>
    </div>
</template>

<script>
import * as echarts from "echarts";
import resize from './mixins/resize.js'
import { ymd, dataZoom } from '@/utils/tool.js'
import dayjs from 'dayjs'
export default {
    mixins: [resize],
    props: {
        type: {
            type: String,
            default: ''
        },
        className: {
            type: String,
            default: 'chart'
        },
        width: {
            type: String,
            default: '100%'
        },
        height: {
            type: String,
            default: '100%'
        },
        autoResize: {
            type: Boolean,
            default: true
        },
        visceraData: {
            type: Object,
            default: {}
        },
        heartData: {
            type: Object,
            default: {}
        },
        addTime: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            fiveId: '',
            lineId: '',
            chart: null,
            chartFive: null,
            nightData: [],  //夜间数据
            dayData: [],    //日间数据
            nightAverage: [],   //夜间平均值
            dayAverage: [],     //日间平均值
            allDayAverage: [],  //全天平均值
            section: [],
        }
    },
    watch: {

    },
    created() {
        if (this.type === 'day') {
            this.lineId = 'dayLine'
            this.fiveId = 'dayFive'
        }
        if (this.type === 'night') {
            this.lineId = 'nightLine'
            this.fiveId = 'nightFive'
        }
        if (this.type === 'allDay') {
            this.fiveId = 'allDayFive'
            this.lineId = 'allDayLine'
        }
    },
    mounted() {
        this.initChart()
    },
    beforeDestroy() {
        if (!this.chart) {
            return
        }
        this.chart.dispose()
        this.chart = null

        if (!this.chartFive) {
            return
        }
        this.chartFive.dispose()
        this.chartFive = null
    },
    methods: {
        initChart() {
            let allDayHeartRateData = []
            if (this.heartData.today && this.heartData.today.length > 0) {
                this.heartData.today.map(item => {
                    if (this.type === 'day') {
                        //白天心率
                        if (Number(item.timeType) === 1) {
                            allDayHeartRateData.push({ [item.slot]: item.heartRate })
                        }
                    }
                    if (this.type === 'night') {
                        //晚上心率
                        if (Number(item.timeType) === 2) {
                            allDayHeartRateData.push({ [item.slot]: item.heartRate })
                        }
                    }
                    if (this.type === 'allDay') {
                        allDayHeartRateData.push({ [item.slot]: item.heartRate })
                    }
                })
            }
            // 整合日间和夜间数据   dayNight 1日间 2夜间
            if (this.visceraData.visceraArrayList && this.visceraData.visceraArrayList.length > 0) {
                this.visceraData.visceraArrayList.map(item => {
                    if (Number(item.dayNight) === 1) {
                        this.dayData.push(item)
                    } else {
                        this.nightData.push(item)
                    }
                })
            }
            // 整合日间和夜间平均数据
            if (this.visceraData.averageDayTimeVisceras && this.visceraData.averageDayTimeVisceras.length > 0) {
                this.visceraData.averageDayTimeVisceras.map(item => {
                    if (item.dataDate === this.addTime) {
                        this.dayAverage.push(item)
                    }
                })
            }
            if (this.visceraData.averageNightVisceras && this.visceraData.averageNightVisceras.length > 0) {
                this.visceraData.averageNightVisceras.map(item => {
                    if (item.dataDate === this.addTime) {
                        this.nightAverage.push(item)
                    }
                })
            }
            if (this.visceraData.averageVisceras && this.visceraData.averageVisceras.length > 0) {
                this.visceraData.averageVisceras.map(item => {
                    if (item.dataDate === this.addTime) {
                        this.allDayAverage.push(item)
                    }
                })
            }
            if (this.type === 'day') {
                this.dayEchart(this.dayData, [ymd(new Date(this.addTime)), this.addTime], allDayHeartRateData)
            }
            if (this.type === 'night') {
                this.nightEchart(this.nightData, [ymd(new Date(this.addTime)), this.addTime], allDayHeartRateData)
            }
            if (this.type === 'allDay') {
                this.allDataEchart(this.visceraData.visceraArrayList, [ymd(new Date(this.addTime)), this.addTime], allDayHeartRateData)
            }

        },
        getVisceraEchartsData(obj, objT, objA, ObjI) {
            var r = arguments.length > 5 && void 0 !== arguments[5] ? arguments[5] : "dataDate"
                , n = []
                , o = []
                , l = []
                , c = []
                , d = []
                , u = {}
                , p = []
                , m = {
                    heart: [],
                    liver: [],
                    kidney: [],
                    spleen: [],
                    lungs: []
                };
            if (obj && obj.length > 0) {
                obj.forEach(function (e, t) {
                    new Date(e[r]).getTime();
                    var a = e.intHeart
                        , i = e.intLiver
                        , s = e.intKidney
                        , p = e.intSpleen
                        , g = e.intLungs
                        , h = 'normal';
                    u[h += t] = [i, a, p, g, s]
                    if (a < 70 && i < 70 && s < 70 && p < 70 && g < 70) {
                        m.heart.push([e[r], a, h])
                        m.liver.push([e[r], i, h])
                        m.kidney.push([e[r], s, h])
                        m.spleen.push([e[r], p, h])
                        m.lungs.push([e[r], g, h])
                    } else {
                        n.push([e[r], a, h])
                        o.push([e[r], i, h])
                        l.push([e[r], s, h])
                        c.push([e[r], p, h])
                        d.push([e[r], g, h])
                    }
                });
            }
            var g = [[objT[0] + " 23:00:00", 0], [objT[1] + " 24:00:00", 0]];
            //将心率时间加上具体日期 ['2024-01-21 19:50:00', 68]
            if (objA && objA.length > 0) {
                objA.forEach(function (e) {
                    for (var a in e) {
                        p.push([objT[1] + " " + a, e[a]])
                    }
                });
            }

            let h = []    //脏腑平均值
            let v = ObjI //脏腑平均值
            if (v && v.length > 0) {
                h = [v[0].intLiver, v[0].intHeart, v[0].intSpleen, v[0].intLungs, v[0].intKidney]
            }
            return {
                radarData: u,
                radarAvg: h,
                heart: n,
                liver: o,
                kidney: l,
                spleen: c,
                lungs: d,
                hrData: p,
                dataDate: g,
                zeroDate: m
            }
        },
        dayEchart(chartData, t, allDayHeartRateData) {
            var i = this.getVisceraEchartsData(chartData, t, allDayHeartRateData, this.dayAverage);
            this.viceraEchart24({
                id1: "dayLine",
                id2: "dayFive",
                section: "dayViscera",
                radar: {
                    single: {
                        text: "单次脏腑分值",
                        color: "RGB(215,56,53)"
                    },
                    avg: {
                        text: "日间平均分值",
                        color: "RGBa(255,103,0,0.8)"
                    }
                },
                radarData: i.radarData,
                radarAvg: i.radarAvg,
                heart: i.heart,
                liver: i.liver,
                kidney: i.kidney,
                spleen: i.spleen,
                lungs: i.lungs,
                hrData: i.hrData,
                dataDate: i.dataDate,
                zeroDate: i.zeroDate
            })
        },
        nightEchart(chartData, t, allDayHeartRateData) {
            var i = this.getVisceraEchartsData(chartData, t, allDayHeartRateData, this.nightAverage);
            this.viceraEchart24({
                id1: "nightLine",
                id2: "nightFive",
                section: "nightViscera",
                radar: {
                    single: {
                        text: "单次脏腑分值",
                        color: "#f8d351"
                    },
                    avg: {
                        text: "夜间平均分值",
                        color: "#28f8de"
                    }
                },
                radarData: i.radarData,
                radarAvg: i.radarAvg,
                heart: i.heart,
                liver: i.liver,
                kidney: i.kidney,
                spleen: i.spleen,
                lungs: i.lungs,
                hrData: i.hrData,
                dataDate: i.dataDate,
                zeroDate: i.zeroDate
            })
        },
        allDataEchart(chartData, t, allDayHeartRateData) {
            var i = this.getVisceraEchartsData(chartData, t, allDayHeartRateData, this.allDayAverage);
            this.viceraEchart24({
                id1: "allDayLine",
                id2: "allDayFive",
                section: "allViscera",
                radar: {
                    single: {
                        text: "单次脏腑分值",
                        color: "#f8d351"
                    },
                    avg: {
                        text: "平均分值",
                        color: "#28f8de"
                    }
                },
                radarData: i.radarData,
                radarAvg: i.radarAvg,
                heart: i.heart,
                liver: i.liver,
                kidney: i.kidney,
                spleen: i.spleen,
                lungs: i.lungs,
                hrData: i.hrData,
                dataDate: i.dataDate,
                zeroDate: i.zeroDate
            })
        },
        viceraEchart24(e) {
            let that = this
            let t = e.id1
                , a = e.id2
                , i = e.radar
                , s = e.section
                , r = e.radarData
                , n = e.radarAvg
                , o = e.heart
                , l = e.liver
                , c = e.kidney
                , d = e.spleen
                , u = e.lungs
                , m = e.hrData
                , p = e.dataDate
                , g = e.zeroDate
                , f = ""
            this.chart = echarts.init(document.getElementById(t))
            this.chartFive = echarts.init(document.getElementById(a))
            let y = {
                tooltip: {
                    trigger: "item"
                },
                radar: {
                    center: ["50%", "50%"],
                    radius: "50%",
                    axisName: {
                        fontSize: 14,
                        color: "#333"
                    },
                    splitNumber: 3,
                    indicator: [{
                        name: "肝脏",
                        max: 190,
                        min: 10
                    }, {
                        name: "心脏",
                        max: 190,
                        min: 10
                    }, {
                        name: "脾脏",
                        max: 190,
                        min: 10
                    }, {
                        name: "肺脏",
                        max: 190,
                        min: 10
                    }, {
                        name: "肾脏",
                        max: 190,
                        min: 10
                    }]
                },
                series: [{
                    name: i.avg.text,
                    type: "radar",
                    symbolSize: 0,
                    data: [{
                        name: i.avg.text,
                        value: n,
                        itemStyle: {
                            color: i.avg.color
                        },
                        areaStyle: {}
                    }]
                }, {
                    name: i.single.text,
                    type: "radar",
                    symbolSize: 0,
                    data: [{
                        name: i.single.text,
                        value: [],
                        itemStyle: {
                            color: i.single.color
                        }
                    }]
                }]

            }
            let b = {
                tooltip: {
                    trigger: "axis",
                    formatter: function (e) {
                        var t = [];
                        if (e && e.length > 0) {
                            e.forEach(function (e) {
                                "心率" != e.seriesName && null != e.data[1] ? t.push(e.marker + e.seriesName + "：" + e.data[1]) : "心率" == e.seriesName && t.push(e.marker + e.seriesName + "：" + e.data[1])
                                // t.push(e.marker + e.seriesName + "：" + e.data[1])
                            })
                            if (e.length >= 1) {
                                var a = e[0];
                                if (a.data[0] !== f && (f = a.data[0], "心率" !== a.seriesName)) {
                                    var i = a.data[2];
                                    y.series[1].data[0].value = r[i]
                                    that.chartFive.setOption(y, !0)
                                }
                            }
                        }
                        let newT = ''
                        t.map((item) => {
                            newT = newT + item + '<br>'
                        })
                        return '时间：' + e[0].data[0].split(" ")[1] + '<br>' + newT
                    }
                },
                grid: {
                    top: 30,
                    right: 20,
                    bottom: 0,
                    left: 0,
                    containLabel: !0
                },
                color: ["#d73835", "#66bb6a", "#b28850", "#fad53e", "#78909c"],
                toolbox: {
                    show: !0,
                    feature: {
                        dataZoom: {
                            yAxisIndex: "none",
                            title: {
                                back: "还原"
                            }
                        }
                    }
                },
                legend: {
                    x: "center",
                    itemWidth: 15,
                    itemHeight: 6,
                    itemGap: 20,
                    data: [{
                        name: "肝脏",
                        icon: "rect"
                    }, {
                        name: "心脏",
                        icon: "rect"
                    }, {
                        name: "脾脏",
                        icon: "rect"
                    }, {
                        name: "肺脏",
                        icon: "rect"
                    }, {
                        name: "肾脏",
                        icon: "rect"
                    }, {
                        name: "心率",
                        icon: "rect"
                    }]
                },
                xAxis: {
                    type: "time",
                    position: "bottom",
                    axisLine: {
                        lineStyle: {
                            color: "#e7e5e4"
                        }
                    },
                    splitNumber: 10,
                    axisLabel: {
                        color: "#999",
                        fontSize: 12,
                        showMaxLabel: true,
                        formatter: "{HH}:{mm}"
                    },
                    min: function () {
                        // 让时间从前一天的00:00开始
                        let preDate = dayjs(that.addTime).add(-1, 'day').format('YYYY-MM-DD')
                        var time = new Date(preDate + ' 22:59:00').getTime();
                        return new Date(time).getTime();
                    },
                    max: function () {
                        //  让时间从后一天的00:00结束
                        let nextDay = dayjs(that.addTime).add(1, 'day').format('YYYY-MM-DD')
                        var time = new Date(nextDay + ' 00:00:00').getTime();
                        return new Date(time).getTime();
                    }
                },
                yAxis: {
                    max: 190,
                    min: 10,
                    interval: 30,
                    splitLine: {
                        show: !0
                    },
                    axisLine: {
                        show: !0,
                        lineStyle: {
                            color: "#e7e5e4"
                        }
                    },
                    axisTick: {
                        show: !1
                    },
                    axisLabel: {
                        color: "#999"
                    }
                },
                series: [{
                    name: "肝脏",
                    type: "line",
                    smooth: !1,
                    itemStyle: {
                        color: "#66bb6a"
                    },
                    data: l,
                    showSymbol: l.length <= 1
                }, {
                    name: "肝脏",
                    type: "line",
                    itemStyle: {
                        color: "#66bb6a"
                    },
                    lineStyle: {
                        width: 0
                    },
                    data: g.liver
                }, {
                    name: "心脏",
                    type: "line",
                    smooth: !1,
                    itemStyle: {
                        color: "#d73835"
                    },
                    data: o,
                    showSymbol: o.length <= 1
                }, {
                    name: "心脏",
                    type: "line",
                    itemStyle: {
                        color: "#d73835"
                    },
                    lineStyle: {
                        width: 0
                    },
                    data: g.heart
                }, {
                    name: "脾脏",
                    type: "line",
                    smooth: !1,
                    itemStyle: {
                        color: "#fad53e"
                    },
                    data: d,
                    showSymbol: d.length <= 1
                }, {
                    name: "脾脏",
                    type: "line",
                    itemStyle: {
                        color: "#fad53e"
                    },
                    lineStyle: {
                        width: 0
                    },
                    data: g.spleen
                }, {
                    name: "肺脏",
                    type: "line",
                    smooth: !1,
                    itemStyle: {
                        color: "#78909c"
                    },
                    data: u,
                    showSymbol: u.length <= 1
                }, {
                    name: "肺脏",
                    type: "line",
                    itemStyle: {
                        color: "#78909c"
                    },
                    lineStyle: {
                        width: 0
                    },
                    data: g.lungs
                }, {
                    name: "肾脏",
                    type: "line",
                    smooth: !1,
                    itemStyle: {
                        color: "#b28850"
                    },
                    data: c,
                    showSymbol: c.length <= 1
                }, {
                    name: "肾脏",
                    type: "line",
                    itemStyle: {
                        color: "#b28850"
                    },
                    lineStyle: {
                        width: 0
                    },
                    data: g.kidney
                }, {
                    name: "心率",
                    type: "line",
                    smooth: !1,
                    dataIndex: 1,
                    itemStyle: {
                        color: "#28ffb3"
                    },
                    data: m,
                    showSymbol: m.length === 1 ? true : false
                }, {
                    name: "占位背景",
                    type: "line",
                    itemStyle: {
                        show: !1,
                        color: "#fff"
                    },
                    tooltip: {
                        show: !1
                    },
                    silent: !1,
                    barGap: 0,
                    data: p,
                    animation: !1,
                    markLine: {
                        animation: !1,
                        silent: !0,
                        symbol: ["none", "none"],
                        lineStyle: {
                            color: "#8B897F"
                        },
                        label: {
                            show: !1
                        },
                        data: [{
                            yAxis: 70
                        }, {
                            yAxis: 100
                        }, {
                            yAxis: 130
                        }]
                    }
                }]
            }

            this.chart.setOption(b)
            this.chartFive.setOption(y)
            // this.chart[t] = new Object(h);
            this.section = []
            dataZoom({
                echart: this.chart,
                series: [{
                    name: "心脏",
                    data: o
                }, {
                    name: "肝脏",
                    data: l
                }, {
                    name: "肾脏",
                    data: c
                }, {
                    name: "脾脏",
                    data: d
                }, {
                    name: "肺脏",
                    data: u
                }, {
                    name: "心率",
                    data: m
                }],
                type: "time",
                addTime: this.addTime,
                sectionArr: function (e) {
                    that.section = e
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.section-time {
    position: absolute;
    top: -60px;
    line-height: 32px;
    right: 0;
    font-size: 16px;
    color: #000;
}

.section-list {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;

    div {
        border-right: 1px solid #e9e9e9;
        padding: 0 40px;

        &:last-child {
            border: none;
        }

        span {
            display: block;
            text-align: center;

            &:first-child {
                font-size: 18px;
                font-weight: 700;
                margin-bottom: 10px;
            }

            &:last-child {
                font-size: 14px;
                color: #7f7f7f;
            }
        }
    }
}
</style>