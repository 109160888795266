import { render, staticRenderFns } from "./newsCenter.vue?vue&type=template&id=4411eb2d&"
import script from "./newsCenter.vue?vue&type=script&lang=js&"
export * from "./newsCenter.vue?vue&type=script&lang=js&"
import style0 from "./newsCenter.vue?vue&type=style&index=0&id=4411eb2d&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.10.2@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports