<!-- 患者查询 -->
<template>
  <layer pagename="patientSearch" pageClass="successCase">
    <div class="successCase">
      <div class="search_input">
        <div class="from_b">
          <span class="label">病种</span>
          <Select @on-change="search" clearable v-model="diseaseId" placeholder="病种" filterable>
            <Option v-for="item in diseaseInfos2" :value="item.value" :key="item.value">{{ item.labelAndPy }}</Option>
          </Select>
          <span class="label">医馆</span>
          <Select @on-change="search" clearable v-model="hospitalCode" placeholder="请选择医馆">
            <Option v-for="item in hospitalList" :value="item.value" :key="item.value">{{ item.label }}</Option>
          </Select>
        </div>
        <Button type="primary" @click="search">查询</Button>
      </div>

      <div class="select_table">
        <Table @on-row-click="rowClick" stripe border :columns="columns1" :data="list" :height="u_table_heigh">
          <template slot-scope="{ row, index }" slot="action">
            <div class="btn_g">
              <Button @click.stop="lock(row)" type="default">查看</Button>
            </div>
          </template>
        </Table>
        <Page show-total show-sizer :page-size-opts="[5, 10, 20, 30, 40]" @on-change="pageChange" :total="totalCount"
          :page-size="pageSize" :current="pageIndex" @on-page-size-change="setPageSize"></Page>
      </div>
    </div>
    <success-case-detail v-model="showDetail" :info="activeItem" />
  </layer>
</template>
<script>
import { mapGetters } from "vuex";
import SuccessCaseDetail from "./com/successCaseDetail.vue";
export default {
  components: { SuccessCaseDetail },
  props: ["id"],
  data() {
    return {
      hospitalList: [],
      diseaseId: "", // 诊断Id 
      hospitalCode: "", // 医馆编码 
      activeItem: {},
      showDetail: false,
      info: {},
      pageSize: 10, //一页10条
      pageIndex: 1,
      totalCount: 0,
      list: [],
      searchType: 2, //查询类型
      //弹窗打开
      medicalRecordOpen: false,

      // 表格高度
      u_table_heigh: 500,
      columns1: [
        {
          title: "序号",
          type: "index",
          width: 70,
          align: "center",
        },
        {
          title: "患者",
          width: 120,
          key: "patientName",
        },
        {
          title: "年龄",
          width: 80,
          align: "center",
          key: "patientAge",
        },

        {
          title: "手机",
          width: 130,
          align: "center",
          key: "cellphoneNo",
        },
        {
          title: "病种",
          width: 120,
          align: "center",
          key: "diseaseName",
        },
        {
          title: "治愈疗程",
          width: 120,
          align: "center",
          key: "cureDayStr",
        },
        {
          title: "疗程就诊次数",
          width: 130,
          align: "center",
          key: "visitTimes",
        },
        {
          title: "疗程首诊时间",
          width: 130,
          key: "startDate",
        },
        {
          title: "疗程末诊时间",
          width: 130,
          key: "endDate",
        },
        {
          title: "医馆",
          width: 200,
          key: "hospitalName",
        },
        {
          title: "治愈描述",
          minWidth: 400,
          key: "remarks",
          align: "center",
        },
        {
          title: "操作",
          key: "action",
          align: "center",
          width: 100,
          slot: "action",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["expert", "userInfo", "diseaseInfos2"]),
  },
  methods: {
    pageChange(index) {
      this.pageIndex = index;
      this.getData();
    },
    rowClick(data, index) {
      this.activeItem = data;
      this.showDetail = true;
    },
    lock(row) {
      this.activeItem = row;
      this.showDetail = true;
    },
    setPageSize(size) {
      this.pageSize = size;
      this.search();
    },
    getDirectHospitalList() {
      let obj = {
        isRightShowHospital: 0,//	是否按权限显示医馆0否 1是
        pageIndex: 1, // 页码 
        pageSize: 1000, // 页大小 
      }
      this.api2("getDirectHospitalList", obj).then((res) => {
        this.hospitalList = res.result.map(i => {
          i.value = i.hospitalCode
          i.label = i.hospitalName
          return i;
        });
      });
    },
    getData() {
      let obj = {
        diseaseId: this.diseaseId, // 诊断Id 
        hospitalCode: this.hospitalCode, // 医馆编码 
        isSelf: 0, // 是否查自已的数据 0否 1是 
        pageIndex: this.pageIndex, // 页码 
        pageSize: this.pageSize, // 页大小 
        sysId: 0, //	系统Id 0云诊 1其他
      }
      this.$store.commit("openSpin");
      this.api2("listEnterprisePatientHealthRecord", obj).then((res) => {
        this.$store.commit("closeSpin");
        this.totalCount = res.total;
        res.result.forEach(i => {
          i.cureDayStr = i.cureDay + "天";
        })
        this.list = res.result;
      });
    },
    search() {
      this.pageIndex = 1;
      this.getData();
    },
    // 设置表格高度
    setTableHeight() {
      if ($(".successCase").length)
        this.u_table_heigh =
          $(".select_table")[0].clientHeight -
          $(".search_input")[0].clientHeight -
          35;
    },
  },
  created() {
    this.getData();
    this.getDirectHospitalList();
  },
  mounted: function () {
    this.setTableHeight();
    // 窗口大小调整
    $(window).resize(() => {
      this.setTableHeight();
    });
  },
};
</script>
<style lang="scss">
.successCase {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .search_input {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  .select_table {
    flex: 1;
    display: flex;
    flex-direction: column;

    .ivu-table-wrapper {
      flex: 1;
      margin-bottom: 10px;
    }
  }

  .from_b .label {
    padding-right: 5px;
  }

  .from_b .ivu-select {
    width: 200px;
    margin-right: 15px;
  }
}
</style>

