<!-- 精神状态 -->
<template>
    <div :class="className" :style="{ height: height, width: width }" />
</template>
  
<script>
import * as echarts from "echarts";
import resize from './mixins/resize.js'
import { ymd, getTimeInfo, dayList, dataZoom } from '@/utils/tool.js'
export default {
    mixins: [resize],
    props: {
        className: {
            type: String,
            default: 'chart'
        },
        width: {
            type: String,
            default: '100%'
        },
        height: {
            type: String,
            default: '100%'
        },
        autoResize: {
            type: Boolean,
            default: true
        },
        ecgData: {
            type: Object,
            default: {}
        },
        heartData: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            chart: null,
            spiritTip: "",
            ecgReading: [],
            spiritNorm: {
                hrSleep: "---",
                hrDay: "---",
                hrNight: "---"
            },
        }
    },
    created() {

    },
    mounted() {
        this.$nextTick(() => {
            this.initChart()
        })
    },
    beforeDestroy() {
        if (!this.chart) {
            return
        }
        this.chart.dispose()
        this.chart = null
    },
    methods: {
        initChart() {
            this.chart = echarts.init(this.$el)
            this.setOptions()
        },
        setOptions() {
            let e = this.ecgData;
            this.spiritTip = e.psychosisCode
            this.ecgReading = e.ecgReading ? e.ecgReading : []
            // 心率数据
            if (this.heartData) {
                this.heartData.hrSleep ? this.spiritNorm.hrSleep = this.heartData.hrSleep : this.spiritNorm.hrSleep = "---"
                this.heartData.hrDay ? this.spiritNorm.hrDay = this.heartData.hrDay : this.spiritNorm.hrDay = "---"
                this.heartData.hrNight ? this.spiritNorm.hrNight = this.heartData.hrNight : this.spiritNorm.hrNight = "---"
            }
            let t = e
            let a = []
            if (this.heartData.today) {
                this.heartData.today.map(item => {
                    a.push({ [item.slot]: item.heartRate })
                })
            }
            //心率十分钟一个数据,没有连续的时间不显示
            for (var i = [], s = 0; s < 145; s++)
                i[s] = 10 * s;
            var r = []
                , n = []
                , o = [];
            if (a) {
                a.forEach(function (e, t) {
                    var a = function (t) {
                        var a = e[t]
                            , s = Math.floor(60 * t.split(":")[0]) + Math.floor(t.split(":")[1]);

                        i.forEach(function (e, i) {
                            Math.abs(s - e) <= 5 && (
                                r[i] = a,
                                n[i] = a,
                                o[i] = t
                            )
                        })
                    };
                    for (var s in e) {
                        a(s)
                    }

                })
                r.length < 145 && (r[144] = "")
                o.length < 145 && (o[144] = "")
                n.length < 145 && (n[144] = "")

            }
            r && r.forEach(function (e, t) {
                !e && r[t - 1] && r[t + 1] ? r[t] = (parseInt(r[t - 1]) + parseInt(r[t + 1])) / 2 : !e && r[t - 1] && r[t + 2] && (r[t] = (parseInt(r[t - 1]) + parseInt(r[t + 2])) / 2,
                    r[t + 1] = (parseInt(r[t - 1]) + parseInt(r[t + 2])) / 2)
            });
            var l = void 0
                , c = void 0
                , d = void 0
                , u = void 0
                , m = void 0
                , p = void 0
                , g = void 0
                , h = void 0
                , v = [{
                    lte: 1,
                    color: "#86d9e0"
                }, {
                    gt: 1,
                    lte: 3,
                    color: "#86d9e0"
                }, {
                    gt: 2,
                    color: "#86d9e0"
                }];
            this.chart.setOption({
                grid: {
                    top: 10,
                    right: 20,
                    bottom: 0,
                    left: 0,
                    containLabel: !0
                },
                tooltip: {
                    trigger: "axis",
                    formatter: function (e) {
                        var t = o[e[0].dataIndex]
                            , a = n[e[0].dataIndex];
                        t || (t = "--:--:--");
                        for (var i = "<div><p>" + t + "</p>", s = 0; s < e.length; s++)
                            0 == s && (i += a ? "<p>" + e[s].marker + a + "bpm</p>" : "<p>" + e[s].marker + "---bpm</p>");
                        return i += "</div>"
                    }
                },
                xAxis: {
                    type: "category",
                    boundaryGap: !1,
                    data: ["0:00", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "3:00", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "6:00", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "9:00", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "12:00", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "15:00", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "18:00", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "21:00", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "24:00"],
                    axisLabel: {
                        interval: 17,
                        color: "#999",
                        fontSize: "12"
                    },
                    axisLine: {
                        lineStyle: {
                            color: "e7e5e4"
                        }
                    },
                    axisTick: {
                        show: !1
                    },
                    splitArea: {
                        show: !1
                    }
                },
                yAxis: {
                    scale: !0,
                    max: 160,
                    min: 0,
                    interval: 40,
                    type: "value",
                    axisLabel: {
                        show: !0,
                        color: "#999"
                    },
                    splitLine: {
                        show: !0,
                        lineStyle: {
                            type: "solid"
                        }
                    },
                    axisLine: {
                        show: !1
                    },
                    axisTick: {
                        show: !1
                    }
                },
                visualMap: {
                    show: !1,
                    type: "piecewise",
                    dimension: 0,
                    pieces: v
                },
                series: [{
                    type: "line",
                    smooth: !0,
                    showSymbol: !1,
                    data: r,
                    itemStyle: {
                        lineStyle: {
                            width: 2
                        }
                    }
                }]
            })
        },
    }
}
</script>
  