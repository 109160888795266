<template>
  <div>
    <Input
      v-model="pwd"
      id="inputValue"
      type="password"
      autocomplete="off"
      :placeholder="placeholder"
    ></Input>
    <div class="input_span">
      <span id="one"></span>
      <span id="two"></span>
      <span id="three"></span>
    </div>
    <div id="font">
      <span>弱</span>
      <span>中</span>
      <span>强</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      msgText: "",
      pwd: "",
    };
  },
  methods: {},
  watch: {
    value() {
      this.pwd = this.value;
    },
    pwd(newValue, oldValue) {
      this.msgText = this.common.checkStrong(newValue);
      if (this.msgText > 1 || this.msgText == 1) {
        $("#one").css("background", "red");
      } else {
        $("#one").css("background", "#eee");
      }
      if (this.msgText > 2 || this.msgText == 2) {
        $("#two").css("background", "orange");
      } else {
        $("#two").css("background", "#eee");
      }
      if (this.msgText == 4) {
        $("#three").css("background", "#00D1B2");
      } else {
        $("#three").css("background", "#eee");
      }
      this.$emit("input", this.pwd);
    },
  },
};
</script>

<style scoped>
.input_span {
  display: flex;
}
.input_span span {
  flex: 1;
  height: 10px;
  background: #eee;
  line-height: 20px;
}
#font {
  display: flex;
}
#font span {
  line-height: 20px;
  flex: 1;
  text-align: center;
  font-size: 12px;
}

#one {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

#three {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
#font span:nth-child(1) {
  color: red;
}
#font span:nth-child(2) {
  color: orange;
}
#font span:nth-child(3) {
  color: #00d1b2;
}
</style>