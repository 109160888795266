import router from '@/router'
import api2 from '@/api/api2.js'
import common from '../../common'
import ViewUI from 'view-design'

const state = {
    //百年方Drug_Code
    bnf: [
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31"
    ],
    dicts: {}, //数据字典
    Drug_Decoct_Pattern: [], //药品煎药方式
    CHIEFCOMPLAINT_DEGREE: [], //主诉程度
    Drug_Take_Pattern: [], //药品服用方式
    n_tip: {
        show: false,
        showFun: '',
        title: '',
        center: '',
        onOk: '',
        onCancel: '',
        okText: '',
    },
    recordSymptom: null,
    saveParams: {}, // 保存请求参数
    // 疾病列表
    diseaseInfos: [],
    diseaseInfos2: [],
    // 药物列表
    drug: [],
    //药物禁忌列表
    drugTabooList: [],
    hundredDrugs: [], //百年方
    // 症状列表
    diagnosticList: [],
    // 症状列表(扁平数据)
    diagnosticList2: [],
    // 症状互斥列表
    contradictDiagnosticList: {
        SubType: [],
        Symptom: [],
    },

    // 挂号窗口开关
    regForm: false,

    // 挂号患者搜索列表
    patientList: [],

    //  就诊中及未就诊挂号(处方单)
    prescribeRegisterList: {
        NoVisit: {
            details: []
        },
        Visit: {
            details: []
        },
    },

    //  就诊中及未就诊挂号(处方单) -- 临时
    prescribeRegisterList_str: '',


    //	临时保存挂号信息数据
    register_dq: {},

    // 待就诊
    visit_bz: {},

    // 就诊中
    visiting: {},

    // 导航高亮显视
    navActive: '',


    // 患者高亮显视
    menuActive: '',

    // 处方加载动画开关
    spinShow: true,

    // 当前开放方的患者信息
    visiting_zj: {
        visitNum: 0,
        noVisitNum: 0,
        symptomNo: '',
        symptom: [],
        patient: {},
    },

    // 当前患者的症状
    symptomVisitLastRecord: {
        Symptom: [],
        Visit: []
    },

    // 记录收到处方的患者ID
    patientId: [],

    // 患者处方历史列表
    patientPrescriptionHistoryList: [],

    // 患者查询临时患者数据
    patientSearch: {},

    // 症状是不加载标示
    symptomJz: false,

    audioUrl: [require('@/assets/mp3/hezuo.mp3'), require('@/assets/mp3/redioxu.mp3')],
    // 音频对象
    audio: null,
    audioOn: true,

    // 获取最后一次症状
    patientLastSymptom: {
        lastSymptomIds: [],
        lastSymptom: [],
        recallSymptom: [],
        isRecall: 0,
    },

    //打开打印弹窗
    medicalRecordOpen: false,

    // 处方号和用户id
    prescriptionNo: {},

    // 通知数组
    u_notice: {
        title: '',
        content: ''
    },

    patientVisitInfo: [], //患者就诊记录
    endExaminationPaperList: {}, //查看我的已考完试卷列表
    temporary: {}, //暂时数据
    examinationPaperDetail: [], //测试答案
    refresh: '',
    addDoctorRushBill: [], //挂号数据
    addMedicalRecordSymptom: {}, //症状保存

    djs: null, //倒计时
    u_bz_list: [],
    BODY_SHAPE: [],
    Hobby: [], //喜好
    FACE_COLOR: [],

    // 禁忌类型 0绝对禁忌  1慎用禁忌 2长期服用禁忌 3用量过多禁用
    tabooType: [{
        value: 0,
        label: '绝对禁忌'
    },
    {
        value: 1,
        label: '慎重使用'
    },
    {
        value: 2,
        label: '不宜久服'
    },
    {
        value: 3,
        label: '不宜过量'
    },
    ],
    sendMessageSecond: 1, //未收到处方的失败消息发送次数
    speech: "",
    // 处方提交后自动弹出处方打印框
    medicalRecordPringWin: {
        info: {},
        quantity: '',
        drugList: [],
        show: false,
    },
    treatmentProgressList: [],
}

const getters = {
    dicts: state => state.dicts,
    treatmentProgressList: state => state.treatmentProgressList,
    medicalRecordPringWin: state => state.medicalRecordPringWin,
    speech: state => state.speech.replace(/\n/g, '<br/>'),
    bnf: state => state.bnf,
    Drug_Take_Pattern: state => state.Drug_Take_Pattern,
    BODY_SHAPE: state => state.BODY_SHAPE,
    Hobby: state => state.Hobby,
    FACE_COLOR: state => state.FACE_COLOR,
    Drug_Decoct_Pattern: state => state.Drug_Decoct_Pattern,
    CHIEFCOMPLAINT_DEGREE: state => state.CHIEFCOMPLAINT_DEGREE,
    u_bz_list: state => state.u_bz_list,
    n_tip: state => state.n_tip,
    addDoctorRushBill: state => state.addDoctorRushBill,
    addMedicalRecordSymptom: state => state.addMedicalRecordSymptom,
    recordSymptom: state => state.recordSymptom,
    examinationPaperDetail: state => state.examinationPaperDetail,
    temporary: state => state.temporary,
    endExaminationPaperList: state => state.endExaminationPaperList,
    patientVisitInfo: state => state.patientVisitInfo,
    medicalRecordOpen: state => state.medicalRecordOpen,
    prescriptionNo: state => state.prescriptionNo,
    u_notice: state => state.u_notice,

    audio: state => state.audio,
    audioUrl: state => state.audioUrl,

    patientSearch: state => state.patientSearch,
    symptomVisitLastRecord: state => state.symptomVisitLastRecord,
    visiting_zj: state => state.visiting_zj,
    patientSymptomList: state => state.patientSymptomList,
    spinShow: state => state.spinShow,
    menuActive: state => state.menuActive,
    navActive: state => state.navActive,
    visiting: state => state.visiting,
    visit_bz: state => state.visit_bz,
    diseaseInfos: state => state.diseaseInfos,
    diseaseInfos2: state => state.diseaseInfos2,
    drug: state => state.drug,
    drugTabooList: state => state.drugTabooList,
    hundredDrugs: state => state.hundredDrugs,
    diagnosticList: state => state.diagnosticList,
    diagnosticList2: state => state.diagnosticList2,
    contradictDiagnosticList: state => state.contradictDiagnosticList,
    addRegister: state => state.addRegister,
    regForm: state => state.regForm,
    patientList: state => state.patientList,
    prescribeRegisterList: state => state.prescribeRegisterList,
    visitList: state => {
        let list1 = state.prescribeRegisterList.NoVisit.details;
        let list2 = state.prescribeRegisterList.Visit.details;
        let arr = [];
        list2.forEach(i => {
            arr.push({
                registerCode: i.registerCode,
                isVisit: true
            })
        })
        list1.forEach(i => {
            arr.push({
                registerCode: i.registerCode,
                isVisit: false
            })
        })
        return arr;
    },

    patientPrescriptionHistoryList: state => state.patientPrescriptionHistoryList,
    patientLastSymptom: state => state.patientLastSymptom,
    patientLastSymptomIds: state => state.patientLastSymptom.lastSymptomIds,
    saveParams: state => state.saveParams,
    refresh: state => state.refresh,
    tabooType: state => state.tabooType,
    saveDrugTaboo: state => {
        let item = localStorage.getItem('saveDrugTaboo');
        return item ? JSON.parse(item) : {};
    }
}

const mutations = {
    setTreatmentProgressList(state, res) {
        state.treatmentProgressList = res;
    },
    clickVisit(state) {
        let list = this.getters.visitList;
        if (list.length) {
            let item = list[0];
            let url = (item.isVisit ? "/prescription" : "/condition") + "/" + item.registerCode;
            router.push(url);
        } else {
            router.push('/index');
        }
    },
    setDicts(state, res) {
        let obj = common.clone(state.dicts);
        obj[res.key] = res.data;
        state.dicts = obj;
        localStorage.setItem('dicts', JSON.stringify(state.dicts));
    },
    setDicts2(state, res) {
        state.dicts = res;
    },
    // 药品禁忌本地保存
    saveDrugTaboo(state, res) {
        var {
            list,
            id
        } = res;
        let obj = {};
        let getSave = localStorage.getItem('saveDrugTaboo');
        if (getSave) {
            obj = JSON.parse(getSave);
        }
        obj[id] = list;
        localStorage.setItem('saveDrugTaboo', JSON.stringify(obj))
    },
    // 删除指定ID值的药品禁忌数据
    delDrugTaboo(state, id) {
        let getSave = localStorage.getItem('saveDrugTaboo');
        if (getSave) {
            let obj = JSON.parse(getSave);
            delete obj[id]
            localStorage.setItem('saveDrugTaboo', JSON.stringify(obj))
        }
    },
    setU_bz_list(state, data) {
        state.u_bz_list = data;
    },
    setaudioOn(state, data) {
        state.audioOn = true;
    },
    setPatientId(state, data) {
        state.patientId = data;
    },
    setN_tip(state, data) {
        this.commit('initN_tip');
        state.n_tip.title = data.title || '';
        state.n_tip.content = data.content;
        state.n_tip.show = true;
        state.n_tip.showFun = data.showFun || '';
        state.n_tip.onOk = data.onOk || '';
        state.n_tip.onCancel = data.onCancel || '';
        state.n_tip.okText = data.okText || '';
    },
    confirmN_tip(state, e) {
        this.commit('initN_tip');
        if (state.n_tip.onOk) {
            state.n_tip.onOk();
        }
    },
    initN_tip() {
        state.n_tip.title = '';
        state.n_tip.okText = '';
        state.n_tip.content = '';
        this.commit('hideN_tip');
    },
    showN_tip(state) {
        if (state.n_tip.showFun) {
            state.n_tip.showFun();
        } else {
            if (state.n_tip.content) {
                state.n_tip.show = true;
            }
        }
    },
    hideN_tip(state) {
        state.n_tip.show = false;
        if (state.n_tip.onCancel) {
            state.n_tip.onCancel();
        }
    },

    saveParams(state, obj) {
        Object.assign(state.saveParams, obj);
    },
    // 刷新数据用
    setRefresh(state) {
        state.refresh = Math.random();
    },
    // 设置患者查询临时患者数据
    setPatientSearch(state, data) {
        // 页面跳转
        router.push('/historyList/' + data.fPatientCode);
    },
    // 设置
    setPrescriptionNo(state, data) {
        state.prescriptionNo = data;
    },

    // 打开处方打印窗口
    setMedicalRecordOpen(state, data) {
        state.medicalRecordOpen = data;
    },

    // 处方提交后自动弹出处方打印框
    medicalRecordPringWin(state, data) {
        state.medicalRecordPringWin = data;
    },

    // 设置音频
    setAudio(state, data) {
        state.audio = data;
    },

    // 设置临时数据
    setTemporary(state, data) {
        state.temporary = data;
    },

    // 获取最后一次症状
    getPatientLastSymptom(state, res) {
        if (common.isError(res, true)) return;
        res.result.lastSymptomIds = res.result.lastSymptom.map(i => i.dsId + '');
        state.patientLastSymptom = res.result;
    },

    setRecordSymptom(state, data) {
        state.recordSymptom = data;
        localStorage.setItem('recordSymptom', JSON.stringify(data));
    },

    // 获取患者症状信息
    clearSymptomVisitLastRecord(state) {
        state.symptomVisitLastRecord = {
            Symptom: [],
            Visit: []
        }
    },

    // 设置处方加载动画开关
    setSpinShow(state, res) {
        state.spinShow = res;
    },

    // 设置导航高亮显视
    setNavActive(state, url) {
        state.navActive = url;
    },
    // 设置就诊中
    setVisiting(state, id) {
        state.menuActive = id;
        var self = this;
        if (state.prescribeRegisterList.Visit.visitNum >= 0) {
            var zd = 0;
            state.prescribeRegisterList.Visit.details.forEach(async i => {
                if (i.registerCode == id) {
                    zd = 1;
                    if (state.spinShow) { //是否处于等待加载处方状态 
                        state.visiting = i;
                    }
                }
            });
            if (!zd) {
                router.push('/index');
            }
        }
    },
    // 设置待就诊
    setVisit_bz(state, id) {
        state.menuActive = id;
        var self = this;
        if (state.prescribeRegisterList.NoVisit.noVisitNum >= 0) {
            var zd = 0;
            state.prescribeRegisterList.NoVisit.details.forEach(i => {
                if (i.registerCode == id) {
                    state.visit_bz = i;
                    zd = 1;
                }
            });
            if (!zd) {
                router.push('/index');
            }
        }
    },
    //  清除就诊中及未就诊挂号(处方单)列表
    clearPrescribeRegisterList(state) {
        state.prescribeRegisterList_str = '';
        state.prescribeRegisterList = {
            NoVisit: {
                noVisitNum: 0,
                details: []
            },
            Visit: {
                visitNum: 0,
                details: []
            },
        };
    },
    // 获取就诊用户列表
    getReloadOrderList(state, res) {
        this.commit('updateRegisterList2', res);
    },

    addMedicalRecordSymptom_remove(state, id) {
        delete state.addMedicalRecordSymptom[id]
        localStorage.setItem('addMedicalRecordSymptom', JSON.stringify(state.addMedicalRecordSymptom))
    },

    addMedicalRecordSymptom(state, data) {
        let getitem = JSON.parse(localStorage.getItem('addMedicalRecordSymptom'));
        let newData = getitem ? Object.assign(getitem, data) : data;
        state.addMedicalRecordSymptom = newData;
        localStorage.setItem('addMedicalRecordSymptom', JSON.stringify(state.addMedicalRecordSymptom))
    },

    addDoctorRushBill2(state, data) {
        state.addDoctorRushBill = data;
        localStorage.setItem('addDoctorRushBill', JSON.stringify(data))
    },
    //抢单
    addDoctorRushBill(state, res) {
        if (!config.queueUp) { //是否启用排队系统
            this.commit('closeSpin');
            this.dispatch('getReloadOrderList');
            let list = res.result.hundredYearsMapList;
            let a = [];
            for (const key in list) {
                if (list.hasOwnProperty(key)) {
                    const element = list[key];
                    element.forEach(i => i.amount = i.quantity);
                    a.push({
                        title: element[0].drugName,
                        remark: element[0].remark,
                        active: key == 1 ? true : false,
                        drugsList: element,
                    })
                }
            }
            this.commit('addDoctorRushBill2', a);
            //关闭加载动画
            ViewUI.Message.success('抢单成功!');
            //症状是否加载标示
            state.symptomJz = false;
            // 清除就诊中及未就诊挂号
            this.commit('clearSymptomVisitLastRecord');
        }
    },

    // 更新喜好本地信息
    updataPrescribeRegisterList(state, res) {
        let obj = state.prescribeRegisterList;
        ['NoVisit', 'Visit'].forEach(i => {
            let item = obj[i].details.find(j => j.patientCode == res.patientCode);
            if (item) {
                for (const key in res) {
                    if (res.hasOwnProperty(key) && item.hasOwnProperty(key)) {
                        item[key] = res[key];
                    }
                }
            }
        });
        let saveData = sessionStorage.getItem('getPrescribeRegisterList')
        if (saveData) {
            saveData = JSON.parse(saveData);
            saveData.data = obj;
            sessionStorage.setItem('getPrescribeRegisterList', JSON.stringify(saveData))
        }
    },

    //  就诊中及未就诊挂号(处方单)
    getPrescribeRegisterList(state, res) {
        if (common.isError(res)) return;

        this.commit("sendLog", {
            remarks: "收到就诊和待就就诊的数据列表",
            outParams: res
        });

        sessionStorage.setItem('getPrescribeRegisterList', JSON.stringify(res))

        let data = res.data;
        //数据不变就不更新
        if (state.prescribeRegisterList_str == JSON.stringify(data)) return;
        state.prescribeRegisterList_str = JSON.stringify(data);

        data.Visit.details.forEach(i => {
            if(i.symptom){
                var sArr = typeof (i.symptom) == 'string' ? JSON.parse(i.symptom) : i.symptom;
                var arr = [];
                if (/details/.test(i.symptom)) {
                    sArr.forEach(j => {
                        arr = arr.concat(j.details);
                    })
                } else {
                    arr = sArr;
                }
                i.symptom = arr;
                i.symptom.forEach(j => j.active = false);
            }else{
                console.log("出错的数据信息: ",i);
                ViewUI.Message.error(i.patientName + '的症状数据是空的');
            }
        });
        state.prescribeRegisterList = data;

        // 徐氏专家
        if (this.getters.expert) {
            let noVisitNum = data.NoVisit.noVisitNum;
            let visitNum = data.Visit.visitNum;
            if (visitNum || !noVisitNum) {
                if (state.audioOn) {
                    state.audio.pause();
                    state.audio.currentTime = 0;
                }
            } else {
                if (noVisitNum) {
                    if (state.audioOn) {
                        state.audio.play();
                    }
                }
            }

            let visit = {
                noVisitNum,
                visitNum,
                patient: null,
            }
            let detail = data.Visit.details;
            if (detail.length) {
                visit.patient = detail[0]
                // 页面跳转
                router.push('/prescription_zj/' + detail[0].registerCode);
            } else {
                if (state.navActive == 'prescription_zj') {
                    router.push('/index')
                }
            }
            state.visiting_zj = visit;
        } else { // 合作医生
            // 如果是刚挂号的话在这里搜索并加载信息
            if (state.register_dq) {
                this.commit('searchRegisterId');
            }
            this.commit('scanningPrescription');
        }
    },

    // 扫描徐氏处方
    scanningPrescription(state) {
        state.prescribeRegisterList.Visit.details.forEach(i => {
            if (state.patientId.indexOf(i.registerCode) == -1 && i.prescriptionNo) {
                ViewUI.Message.success({
                    content: '收到【' + i.patientName + '】的处方，<a target="_self" href="#/prescription/' + i.registerCode + '"> 请点击打开 </a>',
                });
                if (state.audioOn) {
                    try {
                        state.audio.play();
                    } catch (error) {
                        console.log(error);
                    }
                }
                state.patientId.push(i.registerCode);
            }
            if (window.location.hash == "#/prescription/" + i.registerCode) {
                if (!localStorage.getItem('isRegisterorder')) { //如果是后台因挂号推送过来的数据刷新就不处理
                    this.commit('setVisiting', i.registerCode);
                }
            }
        })
        localStorage.setItem('patientId', JSON.stringify(state.patientId));
    },
    // 删除收到处方的挂号id
    delPatientId(state, registerCode) {
        let index = state.patientId.findIndex(i => i == registerCode);
        if (index != -1) {
            state.patientId.splice(index, 1)
            localStorage.setItem('patientId', JSON.stringify(state.patientId));
        }
    },

    // 搜索最近一次挂号的患者ID
    searchRegisterId(state) {
        state.prescribeRegisterList.NoVisit.details.forEach(i => {
            if (i.patientName == state.register_dq.patientName && i.cellphoneNo == state.register_dq.cellphoneNo) {
                // 页面跳转
                router.push('/condition/' + i.registerCode)
            }
        });
        state.register_dq = null;
    },

    // 设置列表是否有图片标示
    setPicLabeling(staet, obj) {
        let item = state.prescribeRegisterList.NoVisit.details.find(i => i.registerCode == obj.id)
        if (item) item.picUrl = obj.value;
        item = state.prescribeRegisterList.Visit.details.find(i => i.registerCode == obj.id)
        if (item) item.picUrl = obj.value;
    },

    //  挂号患者搜索列表
    getPatientList(state, res) {
        if (common.isError(res)) return;
        state.patientList = res.result;
    },

    // 清空挂号患者搜索列表
    cliearPatientList(state) {
        state.patientList = [];
    },
    // 关闭挂号窗口
    setRegForm(state, data) {
        state.regForm = data;
        setTimeout(() => {
            // 焦点移到第一个输入框
            if (data) $(".patient_name").find('input').focus();
        }, 500)
    },
    // 获取疾病信息
    getDiseaseInfos(state, res) {
        state.diseaseInfos = res.result;
        state.diseaseInfos2 = res.result.map(i => {
            return {
                label: i.DiseaseName,
                value: i.DiseaseId,
                labelAndPy: i.DiseaseName + '_' + i.Pym,
            }
        });
        localStorage.setItem('diseaseInfos', JSON.stringify(res));
    },
    // 获取药物
    getDrug(state, res) {
        let list = res.result;
        state.drugTabooList = [];
        state.drug = list.map(i => {
            if (i.tabooType && i.tabooType != 3) {
                let tabooReminder = i.tabooReminder;
                if (i.tabooType == 3) {
                    tabooReminder = `不超过${i.dosageTaboo}克`;
                } else if (i.tabooType == 2) {
                    tabooReminder = i.remarks;
                }
                state.drugTabooList.push({
                    drugCode: i.drugCode,
                    tabooDetailList: [{
                        tabooReminder,
                        tabooType: i.tabooType,
                        dosageTaboo: i.dosageTaboo,
                    }]
                })
            }
            return {
                Amount: "",
                Quantity: "",
                Cost_Price: i.costPrice,
                drugtaboo: i.drugtaboo || '',
                drugAlias1: i.drugAlias1,
                drugAlias2: i.drugAlias2,
                Drug_Code: i.drugCode,
                Drug_Id: i.drugId,
                Drug_Name: i.drugName,
                Drug_Type: i.drugType,
                IsService: i.isService,
                pinyinCode: i.pinyinCode,
                pinyinCode1: i.pinyinCode1,
                pinyinCode2: i.pinyinCode2,
                Sales_Price: i.salesPrice,
                StockQty: i.stockQty, //库存
                Unit: i.unit,
                UnitName: i.unitName,
                specifications: i.specifications,
                drugTakePattern: i.drugTakePattern,
                drugDecoctPattern: i.drugDecoctPattern,
            }
        })
        state.hundredDrugs = state.drug.filter(i => i.Drug_Type == '06');
        //缓存信息
        localStorage.setItem('drug', JSON.stringify(res));
    },
    // 根据医馆获取当前医馆药品库存
    getDrugStockByHospitalCode(state, res) {
        let list = res.result;
        state.drug.forEach(i => {
            let item = list.find(j => j.drugId == i.Drug_Id);
            i.StockQty = item ? item.quantity : 0;
        })
    },
    // 获取症状列表
    getDiagnosticList(state, res) {
        localStorage.setItem('diagnosticList', JSON.stringify(res))

        this.dispatch("getContradictDiagnosticSymptomList")
        let list = [],
            order = 1,
            descList = [];
        res.result.diagnosticList.forEach(i => {
            i.symptomSubTypeList.forEach(x => {
                x.symptomList.forEach(y => {
                    descList.push({
                        desc: y.desc,
                        picUrl: y.picUrl,
                    })
                    // delete y['desc']
                    // delete y['picUrl']
                    y.symptomChanges = 0; // 症状变化 0 无 1 加重 2 减轻
                    y.etiologyList = [];
                    y.Other = false;
                    y.active = false;
                    y.parentId = x.dtId;
                    y.order = order
                    order += 1;
                    list.push(y);
                })
            })
        })
        state.diagnosticList2 = list;
        state.diagnosticList = res.result.diagnosticList;
        state.speech = res.result.speech;
    },
    // 症状互斥列表
    getContradictDiagnosticSymptomList(state, res) {
        localStorage.setItem('getContradictDiagnosticSymptomList', JSON.stringify(res))
        var data = res.result;
        // 处理数据
        var newList = [];
        data.Symptom.forEach(i => {
            newList.push({
                "ageE": i.ageE,
                "ageS": i.ageS,
                "dsId": i.dsId2,
                "dsId2": i.dsId,
                "dsName": i.dsName2,
                "dsName2": i.dsName,
                "sex": i.sex,
            })
        })
        data.Symptom = data.Symptom.concat(newList);

        var newList2 = [];
        data.SubType.forEach(i => {
            newList2.push({
                "ageE": i.ageE,
                "ageS": i.ageS,
                "dtId": i.dtId2,
                "dtId2": i.dtId,
                "dtName": i.dtName2,
                "dtName2": i.dtName,
                "sex": i.sex,
            })
        })
        data.SubType = data.SubType.concat(newList2);
        state.contradictDiagnosticList = data;
    },
    // 病历召回
    async updateRecallMedicalRecordSymptom(state, res) {
        this.commit('closeSpin');
        ViewUI.Message.success(res.result.msg);
        // 就诊中及未就诊挂号列表
        await this.dispatch('getReloadOrderList')
        let registerCode = res.result.symptomMain.registerCode

        // 删除缓存数据
        sessionStorage.removeItem("listSymptomEtiologyDialectical_" + registerCode)
        sessionStorage.removeItem("listPatientMainSymptom_submit_" + registerCode)
        // 页面跳转
        router.push('/condition/' + registerCode);
    },
    openMsage(state) {
        if (config.u_notice) state.u_notice = config.u_notice;
        if (state.u_notice.content) {
            ViewUI.Notice.destroy();
            ViewUI.Notice.open({
                title: state.u_notice.title,
                desc: state.u_notice.content,
                duration: 30,
            });
        }
    },
    setRegister_dq(state, data) {
        state.register_dq = data;
    },
    FACE_COLOR(state, res) {
        state.FACE_COLOR = res.result.map(i => {
            return {
                label: i.string1,
                value: i.mainKey
            }
        });
        localStorage.setItem('FACE_COLOR', JSON.stringify(res))
    },
    Hobby(state, res) {
        state.Hobby = res.result.map(i => {
            return {
                label: i.string1,
                value: i.mainKey
            }
        });
        localStorage.setItem('Hobby', JSON.stringify(res))
    },
    BODY_SHAPE(state, res) {
        state.BODY_SHAPE = res.result.map(i => {
            return {
                label: i.string1 + ': ' + i.string2,
                value: i.mainKey
            }
        });
        localStorage.setItem('BODY_SHAPE', JSON.stringify(res))
    },
    Drug_Take_Pattern(state, res) {
        state.Drug_Take_Pattern = res.result.map(i => {
            return {
                label: i.string1,
                value: i.mainKey
            }
        });
        localStorage.setItem('Drug_Take_Pattern', JSON.stringify(res))
    },
    Drug_Decoct_Pattern(state, res) {
        state.Drug_Decoct_Pattern = res.result.map(i => {
            return {
                label: i.string1,
                value: i.mainKey
            }
        });
        localStorage.setItem('Drug_Decoct_Pattern', JSON.stringify(res))
    },
    CHIEFCOMPLAINT_DEGREE(state, res) {
        state.CHIEFCOMPLAINT_DEGREE = res.result.map(i => {
            return {
                label: i.string1,
                value: i.mainKey
            }
        });
        localStorage.setItem('CHIEFCOMPLAINT_DEGREE', JSON.stringify(res))
    },
}

const actions = {
    // 获取最后一次症状
    getPatientLastSymptom({
        state,
        commit
    }, patientCode) {
        let obj = {
            patientCode
        }
        api2('getPatientLastSymptom', obj).then(res => {
            commit('getPatientLastSymptom', res)
        });
    },
    // 获取疾病信息
    getDiseaseInfos({
        state,
        commit
    }, userdata) {
        let data = localStorage.getItem('diseaseInfos');
        if (data) {
            return new Promise((resolve, rejects) => {
                data = JSON.parse(data);
                commit('getDiseaseInfos', data);
                resolve(data)
            })
        } else {
            return api2('getDiseaseInfos', userdata).then(res => {
                commit('getDiseaseInfos', res)
            });
        }
    },
    // 获取药物
    getDrug({
        state,
        commit,
        rootGetters
    }) {
        var drug = localStorage.getItem("drug");
        if (drug) {
            return new Promise((resolve, rejects) => {
                var data = JSON.parse(drug);
                commit('getDrug', data);
                resolve(data)
            })
        } else {
            var data = {
                Hospital_Code: rootGetters.userInfo.hosptialCode,
            }
            return api2('getDrug', data).then(res => {
                commit('getDrug', res)
            });
        }
    },
    // 症状列表
    getDiagnosticList({
        state,
        commit
    }) {
        let diagnosticList = localStorage.getItem('diagnosticList');
        if (diagnosticList) {
            return new Promise((resolve, rejects) => {
                var data = JSON.parse(diagnosticList);
                commit('getDiagnosticList', data);
                resolve(data)
            })
        } else {
            return api2('getDiagnosticList', {}).then(res => {
                commit('getDiagnosticList', res)
            });
        }
    },
    // 症状互斥列表
    getContradictDiagnosticSymptomList({
        state,
        commit
    }) {
        let getContradictDiagnosticSymptomList = localStorage.getItem('getContradictDiagnosticSymptomList')
        if (getContradictDiagnosticSymptomList) {
            commit('getContradictDiagnosticSymptomList', JSON.parse(getContradictDiagnosticSymptomList))
        } else {
            api2('getContradictDiagnosticSymptomList').then(res => {
                commit('getContradictDiagnosticSymptomList', res)
            });
        }
    },

    //  挂号患者搜索列表
    getPatientList({
        state,
        commit,
        rootGetters
    }, data) {
        data.hospitalCode = rootGetters.userInfo.hosptialCode; //医馆编码
        api2('getPatientList', data).then(res => {
            commit('getPatientList', res)
        });
    },

    // 获取就诊用户列表
    getReloadOrderList({
        state,
        commit,
        rootGetters
    }, data) {
        return api2('getReloadOrderList').then(res => {
            commit('getReloadOrderList', res)
        });
    },

    // 病历召回
    updateRecallMedicalRecordSymptom({
        state,
        commit,
        rootGetters
    }, data) {
        commit('openSpin');
        return api2('updateRecallMedicalRecordSymptom', data).then(res => {
            commit('removeIsAnalysisList', data.registerCode)
            commit('delPatientId', data.registerCode)
            commit('updateRecallMedicalRecordSymptom', res)
        });
    },

    //抢单
    addDoctorRushBill({
        state,
        commit,
        rootGetters
    }, data) {
        commit('openSpin');
        api2('addDoctorRushBill').then(res => {
            commit('addDoctorRushBill', res)
        });
    },
    FACE_COLOR({
        state,
        commit,
        rootGetters
    }, data) {
        let getStore = localStorage.getItem('FACE_COLOR')
        if (getStore) {
            commit('FACE_COLOR', JSON.parse(getStore))
            return Promise.resolve(JSON.parse(getStore));
        } else {
            let obj = {
                "pageSize": 100,
                "pageIndex": 1,
                "targetUse": "FACE_COLOR"
            }
            return api2('getWideUseList', obj).then(res => {
                commit('FACE_COLOR', res)
            });
        }
    },
    BODY_SHAPE({
        state,
        commit,
        rootGetters
    }, data) {
        let getStore = localStorage.getItem('BODY_SHAPE')
        if (getStore) {
            commit('BODY_SHAPE', JSON.parse(getStore))
            return Promise.resolve(JSON.parse(getStore));
        } else {
            let obj = {
                "pageSize": 100,
                "pageIndex": 1,
                "targetUse": "BODY_SHAPE"
            };
            return api2('getWideUseList', obj).then(res => {
                commit('BODY_SHAPE', res)
            });
        }
    },
    // 药品服用方式
    Hobby({
        state,
        commit,
        rootGetters
    }, data) {
        let getStore = localStorage.getItem('Hobby')
        if (getStore) {
            commit('Hobby', JSON.parse(getStore))
            return Promise.resolve('');
        } else {
            let obj = {
                "pageSize": 100,
                "pageIndex": 1,
                "targetUse": "PATIENT_HOBBY"
            };
            return api2('getWideUseList', obj).then(res => {
                commit('Hobby', res)
            });
        }
    },
    // 药品服用方式
    Drug_Take_Pattern({
        state,
        commit,
        rootGetters
    }, data) {
        let getStore = localStorage.getItem('Drug_Take_Pattern')
        if (getStore) {
            commit('Drug_Take_Pattern', JSON.parse(getStore))
        } else {
            let obj = {
                "pageSize": 100,
                "pageIndex": 1,
                "targetUse": "Drug_Take_Pattern"
            };
            return api2('getWideUseList', obj).then(res => {
                commit('Drug_Take_Pattern', res)
            });
        }
    },
    // 药品煎药方式
    Drug_Decoct_Pattern({
        state,
        commit,
        rootGetters
    }, data) {
        let getStore = localStorage.getItem('Drug_Decoct_Pattern')
        if (getStore) {
            commit('Drug_Decoct_Pattern', JSON.parse(getStore))
        } else {
            let obj = {
                "pageSize": 100,
                "pageIndex": 1,
                "targetUse": "Drug_Decoct_Pattern"
            };
            return api2('getWideUseList', obj).then(res => {
                commit('Drug_Decoct_Pattern', res)
            });
        }
    },
    // 主诉程度
    CHIEFCOMPLAINT_DEGREE({
        state,
        commit,
        rootGetters
    }, data) {
        let getStore = localStorage.getItem('CHIEFCOMPLAINT_DEGREE')
        if (getStore) {
            commit('CHIEFCOMPLAINT_DEGREE', JSON.parse(getStore))
        } else {
            let obj = {
                "pageSize": 100,
                "pageIndex": 1,
                "targetUse": "CHIEFCOMPLAINT_DEGREE"
            };
            return api2('getWideUseList', obj).then(res => {
                commit('CHIEFCOMPLAINT_DEGREE', res)
            });
        }
    },
    getDrugStockByHospitalCode({
        state,
        commit,
        rootGetters
    }, data) {
        let obj = {
            hospitalCode: rootGetters.userInfo.hosptialCode
        }
        return api2('getDrugStockByHospitalCode', obj).then(res => {
            commit('getDrugStockByHospitalCode', res)
        })
    },
    getWideUseList({
        state,
        commit,
        rootGetters
    }, key) {
        let dists = localStorage.getItem('dicts')
        let getStore = dists ? JSON.parse(dists) : '';
        if (getStore && getStore[key]) {
            return Promise.resolve(getStore[key]);
        } else {
            let obj = {
                "pageSize": 10000,
                "pageIndex": 1,
                "targetUse": key
            };
            return api2('getWideUseList', obj).then(res => {
                let list = res.result.map(i => {
                    i.value = i.mainKey;
                    i.label = i.string1;
                    return i;
                })
                commit('setDicts', {
                    data: list,
                    key
                })
            });
        }
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}