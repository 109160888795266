<template>
  <layer pagename="condition" pageClass="conditionPage">
    <div class="left">
      <patientList :id="id" />
    </div>
    <div class="center">
      <symptom-entry v-if="info" pageType :info="info" />
      <empty v-else text="无用户信息" />
    </div>
    <div class="right">
      <medicalRecordList v-if="showMedicalRecordList" :info="info" :patientCode="info.patientCode" :symptomNo="info.symptomNo" />
      <empty v-else text="无用户信息" />
    </div>
  </layer>
</template>

<script>
import patientList from '@/com/patientList.vue'
import medicalRecordList from '@/com/medicalRecordList.vue'
import { mapGetters } from 'vuex'
import SymptomEntry from '../../com/symptomEntry.vue'
export default {
  props: {
    id: {
      type: String,
      default: ''
    },
  },
  components: {
    patientList,
    medicalRecordList,
    SymptomEntry
  },
  computed: {
    ...mapGetters([
      "prescribeRegisterList", // 就诊中及未就诊挂号(处方单)
    ]),
    // info() {
    //   let list = this.prescribeRegisterList.NoVisit.details;
    //   let item = list.find(i => i.registerCode == this.id);
    //   return item;
    // }
  },
  watch: {
    prescribeRegisterList(newValue, oldValue) {
      if (!this.common.inArray([newValue.NoVisit.details, oldValue.NoVisit.details,], this.id)) {
        this.init();
      }
    },
    id() {
      this.init()
    },
  },
  data() {
    return {
      info: null,
      showMedicalRecordList: false,
    }
  },
  methods: {
    init() {
      this.showMedicalRecordList = false;
      setTimeout(() => {
        this.showMedicalRecordList = true;
      }, 100)
      // this.info = list.find(i => i.registerCode == this.id);
      let list = [
        ...this.prescribeRegisterList.NoVisit.details,
        ...this.prescribeRegisterList.Visit.details,
      ]
      let item = list.find(i => i.registerCode == this.id);
      if (item && item.symptomNo) {
        this.$router.push("/prescription/" + item.registerCode)
      } else {
        this.info = item;
      }
    }
  },
  created() {
    this.init()
  },
}
</script>

<style lang="scss" scoped>
.u_content {
  display: flex;
}
</style>