<template>
  <div class="camera3">
    <div class="camera_k">
      <div id="CameraCtl2" :style="style">
        <Icon type="ios-loading" class="demo-spin-icon-load" size="40" />
        <div class="rotate_mk" :class="rotate">
          <web-cam
            class="canvas rotate"
            ref="webcam"
            :device-id="deviceId"
            :style="styleVideo"
            :width="palmDeviceSize.width"
            :height="palmDeviceSize.height"
            @cameras="onCameras"
          />
          <div class="hand_mb" :class="{ pic2: palmDevice }"></div>
        </div>
      </div>
      <canvas
        ref="canvas"
        class="picture-canvas"
        :width="palmDeviceSize.height"
        :height="palmDeviceSize.width"
      ></canvas>

      <!-- 测试 -->
      <div class="btn_g">
        <div class="btn submitFun2" @click="submitFun2(1)"></div>
        <div class="submitFun4" @click="showPicInput = !showPicInput"></div>

        <div class="submitFun5" @click="showPicInput2 = !showPicInput2"></div>
      </div>

      <!-- 测试-->
      <div v-if="showPicInput" class="input-picurl">
        <Input
          v-model="input_picurl"
          placeholder="请输入图片地址"
          @on-enter="submitFun3(1)"
        ></Input>
        <Button type="primary" @click="submitFun3(1)">确定</Button>
      </div>

      <!-- 测试图片地址解读 -->
      <div v-if="showPicInput2" class="input-picurl input-picurl2">
        <Input
          v-model="input_picurl2"
          placeholder="请输入秒数"
          @on-enter="submitFun4"
        ></Input>
        <Button type="primary" @click="submitFun4">确定</Button>
      </div>

      <!-- 拍照 -->
      <div class="btn_g" v-if="isStart">
        <div v-if="!imgData" class="btn captureImage_btn" @click="takePhoto">
          <Tooltip content="拍照[F9]" placement="bottom">
            <Icon type="md-camera" />
          </Tooltip>
        </div>

        <template v-else>
          <div class="btn resetFun" @click="init">
            <Tooltip content="撤销重拍" placement="bottom">
              <Icon type="md-arrow-back" />
            </Tooltip>
          </div>

          <div class="btn submitFun" @click="submitFun">
            <Tooltip content="提交解读" placement="bottom">
              <Icon
                type="ios-loading"
                class="demo-spin-icon-load"
                v-if="loading"
              />
              <Icon type="md-checkmark" v-else />
            </Tooltip>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    showWin: {
      type: Boolean,
      default: false,
    },
    patientInfo: {
      type: Object,
      default: function () {
        return {};
      },
    },
    width: {
      type: Number,
      default: 0,
    },
    height: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters(["cameraInfo3", "palmDeviceSize", "palmDevice"]),
    styleVideo() {
      return {
        height: this.width + "px",
        width: this.height + "px",
      };
    },
    style() {
      return {
        width: this.width + "px",
        height: this.height + "px",
      };
    },
  },
  watch: {
    showWin(newValue, oldValue) {
      this.savePatientInfo();
      if (newValue) {
        this.init();
      } else {
        this.closeCamera();
      }
    },
    palmDeviceSize() {
      if (this.showWin) {
        this.isStart = false;
        this.init();
      }
    },
  },
  data() {
    return {
      deviceId: null,
      showPicInput: false,
      showPicInput2: false,
      input_picurl: "",
      input_picurl2: 5,
      imgData: null,
      canvas: null,
      isStart: false,
      loading: false,
      rotate: "rotate_90",
    };
  },
  methods: {
    onCameras(cameras) {
      this.devices = cameras;
      const [first, ...tail] = this.devices;
      if (first) {
        this.deviceId = first.deviceId;
      }
    },
    // 初始化
    init() {
      this.loading = false;
      this.imgData = null;
      this.isStart = true;
      this.$refs.webcam.start();
      this.clearCanvas();
    },
    // 拍照
    takePhoto() {
      this.clearCanvas();
      let video = this.$refs.webcam.getVideo();
      let ctx = this.canvas.getContext("2d");
      ctx.clearRect(
        0,
        0,
        this.palmDeviceSize.height,
        this.palmDeviceSize.width
      );
      ctx.translate(0, this.canvas.height);
      ctx.rotate((270 * Math.PI) / 180);
      ctx.drawImage(
        video,
        0,
        0,
        this.palmDeviceSize.width,
        this.palmDeviceSize.height
      );
      this.imgData = this.canvas.toDataURL("image/jpeg");
      this.$refs.webcam.pause();
    },
    // 清空画布
    clearCanvas() {
      this.canvas.getContext("2d");
      this.canvas.height = this.canvas.height;
    },
    // 关闭摄像头
    closeCamera() {
      this.isStart = false;
    },

    savePatientInfo() {
      let obj = {
        patientCode: this.patientInfo.patientCode,
        hospitalCode: this.patientInfo.hospitalCode,
      };
      localStorage.setItem("patientInfo", JSON.stringify(obj));
    },

    // 测试
    async submitFun2() {
      let path =
        "https://pic.cimaizhongyi.com/Picture/other/202407/6-20240725173120585.jpg";
      let imgdata = await this.common.getImgBase64(path);
      this.addMedicalRecordScan({
        base64: imgdata.base64,
        path,
      });
    },

    // 测试
    async submitFun3() {
      let imgdata = await this.common.getImgBase64(this.input_picurl);
      this.addMedicalRecordScan({
        base64: imgdata.base64,
        path: this.input_picurl,
      });
    },

    async submitFun4() {
      this.$refs.webcam.test(this.input_picurl2); 
    },

    // 拍照结果
    submitFun() {
      if (this.loading) return;
      let fileName =
        this.patientInfo.hospitalCode +
        this.patientInfo.patientCode +
        this.common.parseTime(new Date(), "{y}{m}{d}{h}{i}{s}.jpg");
      var fileObj = this.common.base64ToFile(this.imgData, fileName); //base64数据转为文
      this.upLoadImage([
        {
          key: "multipartFiles",
          file: fileObj,
        },
      ]);
    },

    // 上传图片
    upLoadImage(obj) {
      // typeId值，0病历图片 1手相图片 2面相图片 3穴位图片 4伙伴图片 5药品图片 6其他图片
      obj.push({
        key: "typeId",
        file: 1,
      });
      this.loading = true;
      this.api2("fileUploadNew", obj).then((res) => {
        this.addMedicalRecordScan({
          base64: this.imgData,
          path: res.result,
        });
      });
    },

    // 保存病历扫描图片信息
    addMedicalRecordScan(imageInfo) {
      let obj = {
        patientCode: this.cameraInfo3.info.patientCode,
        registerCode: this.cameraInfo3.info.registerCode,
        medicalRecordScanDetailDTOList: [
          {
            picUrl: imageInfo.path,
            picType: 1, // (0.病历扫描 1.手相)
          },
        ],
        registerDatetime: this.cameraInfo3.info.registerDateTime,
      };

      this.api2("addMedicalRecordScan", obj).then((res) => {
        this.loading = false;
        this.$store.commit("sendLog", {
          remarks: "保存病历扫描图片信息",
          apiName: "addMedicalRecordScan",
          inParams: obj,
          outParams: res,
        });
        if (this.common.isError(res)) return;
        let aidId = res.result.details[0]["aidId"];
        let picUrl = res.result.details[0]["picUrl"];

        this.$emit("showPic", {
          base64: imageInfo.base64,
          path: res.result,
          picUrl,
          aidId,
        });

        // 标记已拍手相
        this.$store.commit("setPicLabeling", {
          id: obj.registerCode,
          value: 1,
        });
      });
    },
  },
  mounted() {
    this.canvas = this.$refs.canvas;
    if (this.showWin) {
      this.savePatientInfo();
      this.init();
    }
    $(document).keydown((event) => {
      if (this.showWin) {
        if (event.keyCode == 120) {
          this.takePhoto();
        } else if (event.keyCode == 27) {
          this.closeCamera();
        }
      }
    });
  },
  beforeDestroy() {
    this.closeCamera();
  },
};
</script>

<style>
.camera3 .patientInfo {
  position: absolute;
  top: 14px;
  left: 110px;
  padding: 0;
  background: none;
}

.camera3 .patientInfo span {
  padding-left: 10px;
  color: #853a2f;
}

.camera3 .patientInfo span.name {
  font-weight: bold;
  font-size: 15px;
}

.camera3 .ivu-modal-body {
  display: flex;
}

.camera3 #CameraCtl2 {
  width: 450px;
  height: 600px;
}

.camera3 #CameraCtl2 img {
  width: 450px;
  height: 600px;
}

.camera3 #CameraCtl2 .demo-spin-icon-load {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -20px 0 0 -20px;
}

.boot_g {
  text-align: center;
  padding-top: 15px;
}

.boot_g .ivu-btn {
  padding: 3px 40px;
  height: auto;
  font-size: 14px;
}

.camera_status {
  position: absolute;
  background: #ffffff;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.camera_status .camera_status_content {
  width: 320px;
  line-height: 40px;
  font-size: 15px;
}

.camera_status .c_s_c_title {
  font-size: 18px;
  position: relative;
}

.camera_status .c_s_c_title .ivu-icon {
  position: absolute;
  color: #f00;
  left: -30px;
  top: 8px;
  font-size: 24px;
}

.camera_status .ivu-btn-primary {
  border: none;
  font-size: 12px;
}

.camera3 .u_pics {
  padding-top: 15px;
}

.camera3 .u_pics .u_pic {
  display: inline-block;
  width: 60px;
  height: 60px;
  border: 1px solid #cccccc;
  padding: 3px;
  position: relative;
  margin: 0 10px 10px 0;
}

.camera3 .u_pics .u_pic .date {
  display: none;
}

.camera3 .u_pics .u_pic .date {
  display: block;
}

.camera3 .u_pics .u_pic img {
  width: 100%;
  height: 100%;
}

.camera3 .u_pics .u_pic .del_btn {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  color: #f00;
  cursor: pointer;
  opacity: 0.7;
}

.camera3 .u_pics .u_pic .del_btn:hover {
  opacity: 1;
}

.camera3 .down_btn {
  float: left;
  color: #333333;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.camera3 .timeout_tip {
  flex-direction: column;
}

.camera3 .timeout_tip button {
  margin-top: 20px;
}

.camera3 .u_dropdown {
  position: absolute;
  right: 0px;
  top: 10px;
  z-index: 11;
  text-align: center;
  background: rgba(255, 255, 255, 0.3);
  /* border: 1px solid rgba(0, 0, 0, 0.3); */
  box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.3);
  border-right-width: 0;
  border-radius: 15px 0 0 15px;
}

.camera3 .u_dropdown .ivu-icon-md-more {
  font-size: 20px;
  cursor: pointer;
  display: inline-block;
  width: 30px;
  height: 30px;
  padding-left: 5px;
  box-sizing: border-box;
  line-height: 30px;
  text-align: center;
  color: #000;
}

.camera3 .modal_close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}

.camera3 .u_pics {
  padding: 0 0 0 15px;
  height: 600px;
  display: flex;
  flex-direction: column;
}

.camera3.emptyPic .u_pics {
  display: none;
}

.camera3 .u_pics .u_pic_k {
  height: 100%;
  overflow-y: auto;
}

.camera3 .u_pics .pic_title {
  text-align: center;
  width: 110px;
  background: #eccfad;
  line-height: 24px;
  font-size: 12px;
  margin-bottom: 10px;
}

.camera3 .u_pics .u_pic {
  width: 110px;
  height: 130px;
  text-align: center;
}

.camera3 .u_pics .u_pic .img_k {
  width: 100%;
  height: 100%;
}

.camera3 .u_pics .u_pic .img_k {
  width: 102px;
  height: 102px;
}

.camera3 .u_pics .u_pic .date {
  font-size: 10px;
  font-style: inherit;
}

.camera3 .picture-canvas {
  visibility: hidden;
  position: absolute;
}

.camera3 .input-picurl {
  display: flex;
  background: #ffffff;
  border: 1px solid #ccc;
  padding: 4px 8px;
  height: 42px;
  width: 80%;
  position: absolute;
  top: 10px;
  right: 10%;
}
.camera3 .input-picurl2{
  top: 100px
}

.camera3 .submitFun4 {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 100;
}

.camera3 .submitFun5{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 100;
}
</style>
