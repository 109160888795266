<template>
  <div class="home">
    <div class="convas" id="convas2"></div>
  </div>
</template>

<script>
import { Scene, Sprite, Group } from "spritejs";
export default {
  name: "HomeView",
  components: {},
  props: {
    highlightNameList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    group: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  watch: {
    group(newValue, oldValue) {
      this.setDisabled();
    },
  },
  data() {
    return {
      picList: [
        {
          groupName: "isWood",
          pics: [
            {
              point: [117, 288],
              start: 0,
              url: "",
              urls: [
                require("@/assets/images/qg2/qg1_03.png"),
                require("@/assets/images/qg2/qg2_03.png"),
                require("@/assets/images/qg2/qg3_03.png"),
                require("@/assets/images/qg2/qg4_03.png"),
              ],
              zIndex: 1,
            },
            {
              point: [108, 323],
              start: 0,
              url: "",
              urls: [
                require("@/assets/images/qg2/qg1_15.png"),
                require("@/assets/images/qg2/qg2_15.png"),
                require("@/assets/images/qg2/qg3_15.png"),
                require("@/assets/images/qg2/qg4_15.png"),
              ],
              zIndex: 1,
            },
          ],
        },
        {
          groupName: "isFire",
          zIndex: 3,
          pics: [
            {
              point: [157, 214],
              start: 0,
              url: "",
              urls: [
                require("@/assets/images/qg2/qg1_02.png"),
                require("@/assets/images/qg2/qg2_02.png"),
                require("@/assets/images/qg2/qg3_02.png"),
                require("@/assets/images/qg2/qg4_02.png"),
              ],
              zIndex: 3,
            },
            {
              point: [137, 403],
              start: 0,
              url: "",
              urls: [
                require("@/assets/images/qg2/qg1_08.png"),
                require("@/assets/images/qg2/qg2_08.png"),
                require("@/assets/images/qg2/qg3_08.png"),
                require("@/assets/images/qg2/qg4_08.png"),
              ],
              zIndex: 2,
            },
          ],
        },
        {
          groupName: "isSoil",
          pics: [
            {
              point: [181, 280],
              start: 0,
              url: "",
              urls: [
                require("@/assets/images/qg2/qg1_04.png"),
                require("@/assets/images/qg2/qg2_04.png"),
                require("@/assets/images/qg2/qg3_04.png"),
                require("@/assets/images/qg2/qg4_04.png"),
              ],
              zIndex: 1,
            },
            {
              point: [155, 308],
              start: 0,
              url: "",
              urls: [
                require("@/assets/images/qg2/qg1_05.png"),
                require("@/assets/images/qg2/qg2_05.png"),
                require("@/assets/images/qg2/qg3_05.png"),
                require("@/assets/images/qg2/qg4_05.png"),
              ],
              zIndex: 1,
            },
          ],
        },
        {
          groupName: "isGold",
          pics: [
            {
              point: [140, 215],
              start: 0,
              url: "",
              urls: [
                require("@/assets/images/qg2/qg1_01.png"),
                require("@/assets/images/qg2/qg2_01.png"),
                require("@/assets/images/qg2/qg3_01.png"),
                require("@/assets/images/qg2/qg4_01.png"),
              ],
              zIndex: 1,
            },
            {
              point: [137, 405],
              start: 0,
              url: "",
              urls: [
                require("@/assets/images/qg2/qg1_11.png"),
                require("@/assets/images/qg2/qg2_11.png"),
                require("@/assets/images/qg2/qg3_11.png"),
                require("@/assets/images/qg2/qg4_11.png"),
              ],
              zIndex: 1,
            },
          ],
        },
        {
          groupName: "isWater",
          pics: [
            {
              point: [109, 353],
              start: 0,
              url: "",
              urls: [
                require("@/assets/images/qg2/qg1_06.png"),
                require("@/assets/images/qg2/qg2_06.png"),
                require("@/assets/images/qg2/qg3_06.png"),
                require("@/assets/images/qg2/qg4_06.png"),
              ],
              zIndex: 1,
            },
            {
              point: [168, 353],
              start: 0,
              url: "",
              urls: [
                require("@/assets/images/qg2/qg1_07.png"),
                require("@/assets/images/qg2/qg2_07.png"),
                require("@/assets/images/qg2/qg3_07.png"),
                require("@/assets/images/qg2/qg4_07.png"),
              ],
              zIndex: 1,
            },
            {
              point: [137, 471],
              start: 0,
              url: "",
              urls: [
                require("@/assets/images/qg2/qg1_17.png"),
                require("@/assets/images/qg2/qg2_17.png"),
                require("@/assets/images/qg2/qg3_17.png"),
                require("@/assets/images/qg2/qg4_17.png"),
              ],
              zIndex: 1,
            },
          ],
        },
      ],
      container: null,
      scene: null,
      vw: 279,
      vh: 570,
      arr: [],
      layer: null,
      humanBodyGroup: null,
    };
  },
  methods: {
    init() {
      this.container = document.querySelector("#convas2");
      const scene = new Scene({
        container: this.container,
        width: this.vw,
        height: this.vh,
      });
      this.layer = scene.layer();
      this.humanBodyGroup = new Group({
        width: 279,
        height: 935,
        height: 570,
      });
      this.layer.append(this.humanBodyGroup);

      this.createSprite(
        {
          point: [140, 468],
          url: require("@/assets/images/qg2/rt.png"),
        },
        this.humanBodyGroup
      );

      this.picList.forEach((i) => {
        i.node = new Group({
          zIndex: i.zIndex,
        });

        i.pics.forEach((j) => {
          j.url = j.urls[j.start];
          this.createSprite(j, i.node, i);
        });

        this.layer.append(i.node);
      });
    },
    createSprite(item, layer, parent) {
      const s1 = new Sprite({
        anchor: [0.5, 0.5],
        pos: item.point,
        texture: item.url,
        zIndex: item.zIndex,
      });
      if (item.urls) this.moiusEven(s1, item, parent);
      layer.append(s1);
    },
    moiusEven(node, item, parent) {
      node.addEventListener("mouseenter", async (evt) => {
        if (item.start != 0) {
          node.attr({
            opacity: 0.6,
          });
        }
      });
      node.addEventListener("mouseleave", async (evt) => {
        if (item.start != 0) {
          node.attr({
            opacity: 1,
          });
        }
      });
      node.addEventListener("mousedown", async (evt) => {
        if (item.start != 0) {
          setTimeout(() => {
            this.setHighlight(parent, 2);
          }, 300);
        }
      });
    },
    setHighlight(item, start, noEven) {
      // this.picList.forEach((i) => {
      //   i.node.childNodes.forEach((j, index) => {
      //     let obj = i.pics[index];
      //     if (obj.start != 0) {
      //       obj.start = 1;
      //       j.attr({
      //         texture: obj.urls[obj.start],
      //       });
      //     }
      //   });
      // });
      if (!noEven) this.$emit("change", item.groupName);
      item.node.childNodes.forEach((i, index) => {
        let obj = item.pics[index];
        obj.start = start;
        i.attr({
          texture: obj.urls[obj.start],
        });
      });
    },
    setGroup(groupName) {
      this.setDisabled();
      if (groupName) {
        let item = this.picList.find((i) => i.groupName == groupName);
        if (item) {
          this.setHighlight(item, 2);
        }
      // } else {
      //   this.setDisabled();
      }
    },

    setDisabled() {
      this.picList.forEach((i) => {
        let item = this.group.find((j) => j.name == i.groupName);
        if (item) {
          let list = item.list.filter((j) => !j.wxActive || (j.wxActive == item.name));
          if (list.length) {
            let success = list.every((j) => j.list.some((k) => k.children.length))
            this.setHighlight(i, success ? 3 : 1, true);
          } else {
            this.setHighlight(i, 0, true);
          }
        }
      });
    },
  },
  mounted() {
    this.init();
    this.setDisabled();
  },
};
</script>
<style lang="scss" scoped>
.convas {
  width: 279px;
  height: 570px;
}
</style>
