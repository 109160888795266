<template>
    <!-- 病历打印 -->
    <div class="tabooPrint">
        <div ref="print5" class="tabooPrint_body">
            <div class="mk_title">饮食宜忌</div>
            <div class="hander">
                <div class="u_row">
                    <div class="u_item">
                        <span class="label">姓名:</span>
                        <span class="value">{{ info.patientName }}</span>
                    </div>
                    <div class="u_item">
                        <span class="label">性别:</span>
                        <span class="value">{{ info.sex }}</span>
                    </div>
                    <div class="u_item">
                        <span class="label">年龄:</span>
                        <span class="value" v-if="info.patientAge">{{ info.patientAge }}岁</span>
                    </div>
                </div>
                <div class="u_row">
                    <div class="u_item">
                        <span class="label">就诊日期:</span>
                        <span class="value">{{ info.registerDateTime }}</span>
                    </div>
                    <div class="u_item">
                        <span class="label">主诉:</span>
                        <span class="value">{{ info.diagnosticName1 }}</span>
                    </div>
                    <div class="u_item">
                        <span class="label">中医诊断:</span>
                        <span class="value">{{ info.diagnosticName2 }}</span>
                    </div>
                </div>
            </div>
            <div class="content">
                <div class="u_row">
                    <div class="u_item">
                        <div class="label">饮食宜进:</div>
                    </div>
                </div>
                <div class="u_row">
                    <div class="u_item">
                        <span class="value" v-html="taboo.yinShiList"></span>
                    </div>
                </div>
                <div class="u_row">
                    <div class="u_item">
                        <div class="label">饮食禁忌:</div>
                    </div>
                </div>
                <div class="u_row">
                    <div class="u_item">
                        <span class="value" v-html="taboo.jiKouList"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    props: {
        info: {
            type: Object,
            default: function () {
                return {};
            },
        },
        taboo: {
            type: Object,
            default: function () {
                return {};
            },
        },
        value: {
            type: Boolean,
            default: false,
        },

    },
    watch: {
        value(newValue, oldValue) {
            this.init();
        },
    },
    computed: {
        ...mapGetters(["userInfo"]),
    },
    data() {
        return {
            showWin: true,
            loading: false,
        };
    },
    methods: {
        getData() {
            this.$print(this.$refs.print5);
            setTimeout(() => {
                this.close();
            }, 300);
        },
        close() {
            this.$emit("input", false);
        },
        init() {
            if (this.value) {
                this.getData();
            }
        },
    },
    created() {
        this.init();
    },
};
</script>

<style lang="scss" >
.tabooPrint_body {
    color: #000;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    background: #fff;
    display: none;
    font-size: 15px;
    width: 100%;

    .drugDecoctPattern,
    .drugTakePattern {
        display: none;
    }

    .quantity {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 22px;
        color: #f00;
    }

    .drugs_format {
        font-size: 16px !important;
        line-height: 1.5em !important;
        width: 100%;
        padding-bottom: 10px;

        .drug {
            // min-width: 33%;
            flex: 1;
            white-space: nowrap;
            display: inline-block;
        }
    }

    .hander {
        border-bottom: 1px dotted #b20000;
        padding-bottom: 10px;
        margin-bottom: 10px;
    }

    .checkbox_1 {
        display: flex;
        align-items: center;

        span {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .fgx {
            padding: 0 10px;
        }

        i {
            display: inline-block;
            width: 16px;
            height: 16px;
            border: 1px solid #b20000;
            margin-right: 2px;
            position: relative;

            &.check::after {
                content: " ";
                display: inline-block;
                border: 2px solid #b20000;
                border-width: 0 0 2px 2px;
                width: 10px;
                height: 6px;
                transform: rotate(-40deg);
                position: absolute;
                top: 3px;
                left: 2px;
            }
        }
    }

    .mk_title {
        line-height: 50px;
        font-size: 30px;
        text-align: center;
        color: #b20000;
        font-family: 隶书;
    }

    .u_row {
        line-height: 26px;
        display: flex;
        justify-content: space-between;
        width: 100%;

        .u_item {
            display: flex;

            .label {
                color: #b20000;
                font-size: 14px;
                padding-right: 2px;
            }
        }
    }

    .content {
        min-height: 520px;

        .u_row {
            line-height: 24px;
        }

        .label {
            width: 64px;
            flex-shrink: 0;
            text-align: right;
        }

        .cf {

            .value,
            .u_item {
                width: 100%;
                position: relative;
            }
        }
    }

    .foot {
        border-top: 1px dotted #b20000;
        padding-bottom: 10px;

        .value {
            width: 120px;
        }
    }
}

@media print {
    .tabooPrint_body {
        display: block;
    }
}
</style>