<template>
  <!-- 处方笺打印 -->
  <Modal class="pSearch print_modal" :value="value" width="800" @on-cancel="close">
    <prescriptionPrint :value="true" ref="prescriptionPrint" :info="info" @exportDrugList="setDrug"
      @exportQuantity="setQuantity" />
    <medicalRecordPrint ref="medicalRecordPrint" v-model="showPrintWin" isSpin :quantity="quantity" :info="info"
      :drugList="drugList" :autoPrint="false" />
    <div slot="footer">
      <!-- <Button type="default" @click="showPrintWin = true">病历打印</Button> -->
      <Button type="default" @click="print2">病历打印</Button>
      <Button type="default" @click="print">处方打印</Button>
      <Button type="default" @click="close">关闭</Button>
    </div>
  </Modal>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  props: {
    info: {
      type: Object,
      default: function () {
        return {};
      },
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value(newValue, oldValue) {
      if (newValue) {
        this.$refs.medicalRecordPrint.init();
      }
    }
  },
  data() {
    return {
      taboo: "",
      quantity: "",
      showPrintWin: false,
      drugList: [],
    };
  },
  computed: {
    ...mapGetters(["userInfo", "drugTaboos", "expert"]),
    prescriptionHead() {
      return this.medicalRecordDetailByBillNo.prescriptionHead[0] || {};
    },
  },
  methods: {
    setQuantity(res) {
      this.quantity = res;
    },
    setTaboo(str) {
      this.taboo = str;
    },
    setDrug(list) {
      this.drugList = list;
      this.showPrintWin = true;
    },
    close() {
      this.showPrintWin = false;
      this.$emit("input", false);
      this.$emit("close");
    },
    print() {
      this.$refs.prescriptionPrint.print();
    },
    print2() {
      this.$refs.medicalRecordPrint.print();
    },
  },
  created() { },
};
</script>

