export default function isIpadFun() {
  //pad 2000*1200
  let isPad = false
  let width =  document.documentElement.clientWidth

  if(!IsPC() && width < 1201){
      isPad = true
  }
  
  // if(width < 1201){
  //   isPad = true
  // }
  
  return isPad
}
  
function IsPC() {
  var userAgentInfo = navigator.userAgent;
  var Agents = ["Android", "iPhone",
      "SymbianOS","harmonyos", "Windows Phone",
      "iPad", "iPod"
  ];
  var flag = true;
  for (var v = 0; v < Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
      }
  }
  return flag;
}