<!--美容机构等用于打印用户反馈-->
<template>
  <Modal :value="value" class="pSearch print_modal" width="800" @on-cancel="close">
    <div ref="print3" class="u_con medicalRecord">
      <div class="record">
        <div class="print_page cfj_2">
          <!-- <div class="cfq_title">
          <div>用户反馈单</div>
          </div>-->
          <img class="cfj_logo" src="@/assets/images/logo.png" alt />
          <div class="rec_head">
            <div class="rec_right">
              <div class="rec_han">
                <div>
                  <span class="han_title">日期：</span>
                  <span class="han_rn">{{info.registerDateTime}}</span>
                </div>
                <div>
                  <span class="han_title">手机号码：</span>
                  <span class="han_rn">{{info.cellphoneNo}}</span>
                </div>
              </div>
              <div class="rec_han">
                <div>
                  <span class="han_title">姓名：</span>
                  <span class="han_rn">{{info.patientName}}</span>
                </div>
                <div>
                  <span class="han_title">年龄：</span>
                  <span class="han_rn">{{info.patientAge}}</span>
                </div>
                <div>
                  <span class="han_title">性别：</span>
                  <span class="han_rn">{{info.sex}}</span>
                </div>
                <div>
                  <span class="han_title">主要反馈：</span>
                  <span class="han_rn">{{info.diagnosticName1}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="rec_zz">
            <div class="zz_title">用户反馈：</div>
            <div class="zz_nr">{{dsName}}</div>
          </div>
          <div class="hosptialCode">{{userInfo.hosptialCode}}</div>
        </div>
      </div>
    </div>
    <div slot="footer">
      <Button type="default" @click="print">打印</Button>
      <Button type="default" @click="close">关闭</Button>
    </div>
  </Modal>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  props: {
    info: {
      type: Object,
      default: function() {
        return {};
      }
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["userInfo"]),
    dsName() {
      let list = [];
      if (this.info.symptom) {
        list = this.info.symptom.map(i => i.dsName);
      }
      return list.join(", ");
    }
  },
  methods: {
    close() {
      this.$emit("input", false);
    },
    print() {
      this.$print(this.$refs.print3); 
    }
  },
  watch: {},
  created() {}
};
</script>

