<template>
  <div class="tab-body">
    <div class="card">
      <div class="card-header">
        <div class="card-h-title">内外向</div>
        <div class="card-h-other"></div>
      </div>
      <div class="card-body">
        {{ info.inwardAndOutward }}
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <div class="card-h-title">做事风格</div>
        <div class="card-h-other"></div>
      </div>
      <div class="card-body">
        {{ info.workStyle }}
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <div class="card-h-title">为人处世</div>
        <div class="card-h-other"></div>
      </div>
      <div class="card-body">
        {{ info.manLife }}
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <div class="card-h-title">脾气</div>
        <div class="card-h-other"></div>
      </div>
      <div class="card-body">
        {{ info.badTemper }}
      </div>
    </div>
</div>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>