<template>
  <layer pagename="myQuestion" pageClass="myQuestionPage">
    <div class="myQuestion">
      <div class="mq-wrapper">
        <div class="mq-title">
          <img src="@/assets/images/ico_quesion.png" />
          我的提问
        </div>
        <div class="team">
          <template v-for="(item, index) in userQuestionList">
            <div class="biaoti">
              <span class="wen">问</span>
              <span class="question">{{ item.question }}</span>
            </div>
            <div class="admin">管理员回复:</div>

            <p class="text">{{ item.replyContent }}</p>
            <div class="pic-team" :id="'images_' + index">
              <img v-for="pic in item.pictureList" @click="clickPic('images_' + index)"
                :src="picroot(pic.pictureUrl)" />
            </div>
            <div class="asker">
              <span class="time">{{ item.replyDt }}</span>
            </div>
          </template>
        </div>
      </div>`
    </div>
  </layer>
</template>

<script>
import viewer from "viewerjs";
import { mapGetters } from "vuex";
export default {
  props: ["id"],
  data() {
    return {
      searchList: [],
      bz_value: "",
      crDt: "",
      u_bz_list: [],
      picView: null,
      userQuestionList: []
    };
  },
  computed: {
  },
  watch: {},
  methods: {
    clickPic(ref) {
      if (this.picView) this.picView.destroy();
      this.picView = new viewer($("#" + ref)[0]);
    }
  },
  created() {
    this.$store.commit("openSpin");
    this.api2("getUserQuestionList", { pageIndex: 1 }).then(res => {
      this.userQuestionList = res.result;
    }).finally(() => {
      this.$store.commit("closeSpin");
    })
  },
  beforeDestroy() {
    // this.clip.destroy(); //停用组件前销毁
  }
};
</script>