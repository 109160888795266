import common from '../../common.js'
import introJs from "@/com/intro/intro.js";
import * as Sentry from "@sentry/vue";
import api2 from "@/api/api2.js";

let djs = null;

const state = {
    flsq: null, //五运六气数据
    diseaseEmotion: { //话术数据
        list: [],
        diagnosticName: '',
    },
    //穴位接口调用数据
    acupoint: {
        diagnosticId: "",
        diagnosticName: "",
        symptomsIds: ""
    },
    introStart: false,
    intro: null,
    cameraInfo: {
        showWin: false,
        info: {}
    },
    cameraInfo2: {
        showWin: false,
        typeId: 1,
        info: {}
    },
    cameraInfo3: {
        showWin: false,
        info: {}
    },
    // 症状选择页面数据临时保存
    saveCondition: {},
    // 处方页面数据临时保存
    savePrescription: {},
    // 分析过的列表
    isAnalysisList: [],
    acupointList: [], //穴位数据
    log: [],
    cacheLogList: [],
    pagename: '',
    pageClass: '',
    isRecall: 0, //是否招回
    tabooDiscriminate: {},
    //进度状态 
    progressStatus: [
        "提前",
        "正常",
        "延后",
        ""
        // "首次暂无状态"
    ],
}

const getters = {
    intro: state => state.intro,
    progressStatus: state => state.progressStatus,
    tabooDiscriminate: state => state.tabooDiscriminate,
    isRecall: state => state.isRecall,
    pagename: state => state.pagename,
    pageClass: state => state.pageClass,
    log: state => state.log,
    flsq: state => state.flsq,
    acupointList: state => state.acupointList,
    acupoint: state => state.acupoint,
    diseaseEmotion: state => state.diseaseEmotion,
    saveCondition: state => state.saveCondition,
    savePrescription: state => state.savePrescription,
    introStart: state => state.introStart,
    cameraInfo: state => state.cameraInfo,
    cameraInfo2: state => state.cameraInfo2,
    cameraInfo3: state => state.cameraInfo3,
    isAnalysisList: state => state.isAnalysisList,
}

const mutations = {
    setIsAnalysisList(state, list){
        state.isAnalysisList = list
    },
    addIsAnalysisList(state, registerCode){
        state.isAnalysisList.push(registerCode) 
        localStorage.setItem('isAnalysisList', JSON.stringify(state.isAnalysisList))
    },
    removeIsAnalysisList(state, registerCode){
        let list = state.isAnalysisList
        let index = list.findIndex(i => i == registerCode);
        if(index != -1){
            list.splice(index, 1);
        }
        localStorage.setItem('isAnalysisList', JSON.stringify(state.isAnalysisList))
    },
    setTabooDiscriminate(state, res) {
        if (res.getLocal) { //是否读取缓存
            state.tabooDiscriminate = res.data;
        } else {
            let drug = this.getters.drug;
            // console.log(drug);
            res.data.forEach(i => {
                let item = drug.find(j => j.Drug_Code == i.drugCode);
                i.discriminatesStr = i.discriminates.map(i => i.dialecticsName).join("，")
                i.drugName = item ? item.Drug_Name : ''
            })
            state.tabooDiscriminate[res.key] = res.data;
        }
        localStorage.setItem("tabooDiscriminate", JSON.stringify(state.tabooDiscriminate));
    },
    removeTabooDiscriminate(state, id) {
        delete state.tabooDiscriminate[id];
        localStorage.setItem("tabooDiscriminate", JSON.stringify(state.tabooDiscriminate));
    },
    setIsRecall(state, res) {
        state.isRecall = res;
    },
    // 记录打印日志方法
    sendLog__(state, obj) {
        if (config.debug) {
            obj.in && console.log('入参：', obj.in);
            obj.out && console.log('出参：', obj.out);
            console.log("");
        }

        if (!config.isLog) return;

        obj = common.clone(obj);
        obj.date = common.parseTime(new Date());
        if (obj.cache) { //是否缓存页面跳转后再发送
            this.commit('addCacheLog', obj)
        } else {
            let userInfo = this.getters.userInfo

            let registerCode = window.location.hash.match(/\d+$/);
            if (registerCode) {
                Sentry.setTag("registerCode", registerCode[0]);
                obj.title = `ID:${registerCode} ${obj.title}`
            }

            Sentry.setUser({
                id: userInfo.husDoctorId,
                username: userInfo.doctorName
            });
            ['in', 'out'].forEach(i => {
                let item = obj[i]
                if (item) {
                    for (const key in item) {
                        if (item.hasOwnProperty(key)) {
                            const element = item[key];
                            if (typeof element == 'object') {
                                item[key] = JSON.stringify(element)
                            }
                        }
                    }
                }
            });
            if (obj.in) Sentry.setContext("入参数", obj.in);
            if (obj.out) Sentry.setContext("出参数", obj.out);
            Sentry.captureMessage(obj.title);
            // Sentry.configureScope(scope => scope.clear());
        }
    },
    // 记录打印日志方法
    sendLog(state, obj) {
        if (!config.isLog) return;
        if (obj.apiName == "ap/saveFrontEndLog") return;
        obj = common.clone(obj);
        if (obj.cache) { //是否缓存页面跳转后再发送
            this.commit('addCacheLog', obj)
        } else {
            let userInfo = this.getters.userInfo
            let registerCode = window.location.hash.match(/\d+$/);

            let newObj = {
                registerCode: registerCode ? registerCode[0] : "", // 	挂号单号
                "hospitalCode": userInfo.hosptialCode, // 医馆编码
                "pageUrl": window.location.hash, // 页面url
                "bizBillNo": "", // 业务单号
                "apiName": obj.apiName, // 接口名称
                "patientCode": obj.patientCode, // 患者编码
                "inParams": JSON.stringify(obj.inParams), // 	入参
                "outParams": JSON.stringify(obj.outParams), // 出参
                "remarks": obj.remarks, // 备注
            }
            api2("saveFrontEndLog", newObj).then(res => {

            })
            return;

            if (registerCode) {
                Sentry.setTag("registerCode", registerCode[0]);
                obj.title = `ID:${registerCode} ${obj.title}`
            }
            Sentry.setUser({
                id: userInfo.husDoctorId,
                username: userInfo.doctorName
            });
            ['in', 'out'].forEach(i => {
                let item = obj[i]
                if (item) {
                    for (const key in item) {
                        if (item.hasOwnProperty(key)) {
                            const element = item[key];
                            if (typeof element == 'object') {
                                item[key] = JSON.stringify(element)
                            }
                        }
                    }
                }
            });
            if (obj.in) Sentry.setContext("入参数", obj.in);
            if (obj.out) Sentry.setContext("出参数", obj.out);
            Sentry.captureMessage(obj.title);
            // Sentry.configureScope(scope => scope.clear());
        }
    },
    setPagename(state, res) {
        state.pagename = res;
    },
    setPageClass(state, res) {
        state.pageClass = res;
    },
    // 缓存日志请发送 防止因页面跳转而发送不成功
    addCacheLog(state, obj) {
        state.cacheLogList.push(obj)
        localStorage.setItem('cacheLog', JSON.stringify(state.cacheLogList))
    },
    setCacheLog(state, obj) {
        state.cacheLogList = obj;
        if (state.cacheLogList.length) {
            let index = 0;
            clearInterval(djs)
            djs = setInterval(() => {
                let item = state.cacheLogList[index]
                if (item && item.cache) {
                    this.commit('sendLog', item);
                    item.cache = false
                }
                index++;
                if (index >= state.cacheLogList.length)
                    clearInterval(djs)
            }, 2000)
        }
        state.cacheLogList = state.cacheLogList.filter(i => i.cache);
        localStorage.setItem('cacheLog', JSON.stringify(state.cacheLogList))
    },
    setLog(state, res) {
        if (config.debug) {
            if (state.log.length > 100) {
                state.log = [];
            }

            state.log.unshift({
                msg: res.msg,
                type: res.type || 'input',
                date: common.parseTime(new Date(), '{y}-{m}-{d} {h}:{i}:{s}')
            })
        }
    },
    flsq(state, res) {
        state.flsq = res;
    },
    acupointList(state, res) {
        state.acupointList = res;
    },
    diseaseEmotion(state, res) {
        state.diseaseEmotion = res;
    },
    acupoint(state, res) {
        state.acupoint = res;
    },
    delCondition(state, id) {
        delete state.saveCondition[id]
        localStorage.setItem('saveCondition', JSON.stringify(state.saveCondition))
    },
    saveCondition(state, data) {
        let item = {};
        if (state.saveCondition.hasOwnProperty(data.key)) {
            item = state.saveCondition[data.key]
        } else {
            item = {
                symptom: [],
                info: {}
            }
            state.saveCondition[data.key] = item
        }
        if (data.symptom) {
            item.symptom = data.symptom;
        }
        if (data.info) {
            for (const key in data.info) {
                if (data.info.hasOwnProperty(key)) {
                    if (data.info[key]) item.info[key] = data.info[key];
                }
            }
        }
        localStorage.setItem('saveCondition', JSON.stringify(state.saveCondition))
    },
    setSaveCondition(state, data) {
        state.saveCondition = data;
    },

    delPrescription(state, id) {
        delete state.savePrescription[id]
        localStorage.setItem('savePrescription', JSON.stringify(state.savePrescription))
    },
    savePrescription(state, data) {
        // data = {key:'1234', prescription:''}
        state.savePrescription[data.key] = data.prescription
        localStorage.setItem('savePrescription', JSON.stringify(state.savePrescription))
    },
    setSavePrescription(state, data) {
        state.savePrescription = data;
    },

    setCameraInfo(state, data) {
        state.cameraInfo = data;
    },
    setCameraInfo3(state, data) {
        state.cameraInfo3 = data;
    },
    setCameraInfo2(state, data) {
        state.cameraInfo2 = data;
    },
    setIntroStart(state, data) {
        state.introStart = data;
        localStorage.setItem("introStart", data ? 1 : 0)
    },
    initIntro(state, data) {
        state.intro = introJs();
        state.intro.setOptions({
            nextLabel: '下一步',
            prevLabel: '上一步',
            skipLabel: '跳过',
            doneLabel: '完毕',
            exitOnOverlayClick: false,
            overlayOpacity: .5,
            showBullets: false,
            showStepNumbers: false,
            keyboardNavigation: false,
        })
    },

    async setIntro(state, data) {
        if (!this.getters.isNoviceGuide && state.introStart) {
            this.commit('initIntro');
        }
        if (!state.intro) return;
        let steps = [];
        if (this.getters.expert) {
            steps = [{
                element: "#e_step0",
                intro: "接诊患者",
                tooltipClass: 'step0',
                position: 'right',
                type: 'hint',
            },
            {
                element: "#e_step1",
                intro: "如是复诊，这里可以点开，看患者本次和上次就诊的症状变化",
                position: 'right',
                type: 'hint',
            },
            {
                element: "#e_step2",
                intro: "在这里，可以观察和修改处方",
                position: 'left',
            },
            {
                element: "#e_step3",
                intro: "确认后提交处方",
                position: 'left',
                type: 'hint',
                tooltipClass: 'step0',
            },
            {
                element: "#step3",
                intro: "在这里，AI助手可能会给出药物禁忌等辅助提醒，根据提示操作即可",
                position: 'left',
                tooltipClass: 'step0',
            },
            {
                element: "#e_step3",
                intro: "确认最终处方，然后提交",
                position: 'left',
                type: 'hint',
                tooltipClass: 'step0',
            },
            ]
        } else {
            let diagnosticList = await this.dispatch("getDiagnosticList");
            if (common.isError(diagnosticList)) return;
            let dList = diagnosticList.result;
            dList = common.diagnosticListFilter(dList, 20, 2)
            let arr = [];
            dList.forEach((i, index_1) => {
                i.symptomSubTypeList.forEach((j, index_2) => {
                    if (j.isnecessary)
                        arr.push({
                            name: j.diagnosticName,
                            id: `hint_${index_1}_${index_2}`
                        })
                })

            })
            /*let arr2 = [
                { name: '饮食', id: 'hint_2_1' },
                { name: '睡眠', id: 'hint_2_2' },
                { name: '舌苔', id: 'hint_3_1' },
                { name: '大便', id: 'hint_4_1' },
                { name: '月经', id: 'hint_7_1' },
            ]*/
            let list = arr.map(i => {
                return {
                    element: "#" + i.id,
                    intro: i.name + '类症状必选',
                    position: 'right',
                    tooltipClass: 'step0',
                    scrollBody: '.scroll_list',
                    type: 'hint',
                }
            })
            steps = [{
                element: "#step0",
                intro: "选择就诊患者",
                tooltipClass: 'step0',
                position: 'right',
                // type: 'hint',
            },
            {
                element: "#step1",
                intro: "在这里，勾选患者符合的症状",
            },
            ...list,
            {
                element: "#step2",
                intro: "选择主诉",
                position: 'left',
                tooltipClass: 'step0',
            },
            {
                element: "#step3",
                intro: "在这里，AI助手会提供特殊症状、药物禁忌、诊断预测等辅助提醒，根据提示操作即可",
                position: 'left',
            },
            {
                element: "#step4",
                intro: "选择中医诊断",
                position: 'left',
                // tooltipClass: 'step0',
            },
            {
                element: "#step5",
                intro: "在这里，可以观察和修改已选症状",
                position: 'left',
            },
            {
                element: "#step6",
                intro: "提交症状",
                position: 'left',
                type: 'hint',
                tooltipClass: 'step0',
            },
            // {
            //     element: "#step7",
            //     intro: "这里可以点开，看患者本次和上次就诊的症状变化",
            //     position: 'right',
            //     type: 'hint',
            // },
            {
                element: "#step8",
                intro: "在这里，可以观察和修改处方",
                position: 'left',
            },
            {
                element: "#step9",
                intro: "确认后提交处方",
                position: 'left',
                type: 'hint',
                tooltipClass: 'step0',
            },
            {
                element: "#step10",
                intro: "生成处方笺，结束一次看诊",
                position: 'top',
                type: 'hint',
                tooltipClass: 'step0',
                scrollBody: '.pSearch .ivu-modal-wrap',
                hedOverlay: true,
            },

            ];
        }
        steps.forEach((i, index) => {
            i.intro = index + 1 + '/' + steps.length + ' ' + i.intro;
        })
        state.intro.setOptions({
            steps,
        });
        state.intro.start();
        switch (data) {
            case 'condition':
                state.intro.goToStep(2);
                break;
            case 'prescription':
                state.intro.goToStep(12);
                break;
            case 'prescription_zj':
                state.intro.goToStep(2);
                break;
            default:
                break;
        }
    },
    nextIntro(state, data) {
        if (!state.intro) return;
        state.intro.nextStep();
    },
    hideIntro(state, data) {
        if (!state.intro) return;
        if (location.pathname == "/pages/condition/index.html") {
            this.commit('nextIntro')
        } else if (location.pathname == "/pages/index/index.html") {
            state.intro.exit();
        } else {
            state.intro.exit();
        }
    }
}
const actions = {

}

export default {
    state,
    getters,
    actions,
    mutations
}