<template>
  <div class="tab-body">
    <div class="card">
      <div class="card-header">
        <div class="card-h-title">年忌</div>
        <div class="card-h-other">
          准确度
          <Rate allow-half :value="info.accuracyStar" disabled />
        </div>
      </div>
      <div class="card-body">
        {{ info.yahoo || '年龄不在年忌之列' }}
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <div class="card-h-title">人神</div>
        <div class="card-h-other"></div>
      </div>
      <div class="card-body" v-html="newlineToBr(info.manGod)"></div>
    </div>
</div>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped></style>