<template>
    <div class="treatment-progress">
        <div class="t-left">
            <div>初诊</div>
            <div>{{ start.progressDate | clearYear }}</div>
        </div>
        <div class="t-center">
            <div v-if="isShow" class="info">
                <div class="timepoint expected hav-point" :style="{ left: dq_bfb + '%' }">
                    <div class="text " :class="{ 't-left': dq_bfb > 60 }">
                        <div>预期</div>
                        <div>{{ zc.visitDate | clearYear }}</div>
                    </div>
                    <Icon type="md-arrow-down" />
                </div>
            </div>
            <div class="jd">
                <Progress :percent="isShow ? dq_bfb : 0" hide-info status="success" :stroke-width="5" />
            </div>
            <div v-if="isShow" class="info info2 label-botton">
                <div class="timepoint expected hav-point" :style="{ left: zc_bfb + '%' }">
                    <Icon type="md-arrow-up" />
                    <div class="text " :class="dqclass">
                        实际<span class="text-green">({{ progressStatus[start.progressStatus] }})</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="t-right">
            <div>治愈</div>
            <div>{{ end.progressDate | clearYear }}</div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: {
        list: {
            type: Array,
            default: () => []
        },
        list2: {
            type: Array,
            default: () => {
                return [
                    {
                        "bianzCode": 93,
                        "bianzName": "心脾气血虚",
                        "bingYCode": 8,
                        "groupCode": "A         ",
                        "isJoin": 0,
                        "isSameEtiology": 1,
                        "joinDate": "2023-05-30",
                        "progressDate": "2023-06-15",
                        "progressDay": 16,
                        "progressStatus": 2,
                        "realityBianCode": 93,
                        "symptomNo": "2305300002",
                        "visitDate": "2023-06-09",
                        "wuXingCode": 3
                    },
                    {
                        "bianzCode": 56,
                        "bianzName": "脾虚气陷",
                        "bingYCode": 8,
                        "groupCode": "A         ",
                        "isJoin": 0,
                        "isSameEtiology": 1,
                        "joinDate": "2023-05-30",
                        "progressDate": "2023-07-01",
                        "progressDay": 16,
                        "progressStatus": 2,
                        "realityBianCode": 58,
                        "symptomNo": "2305300002",
                        "visitDate": "2023-05-30",
                        "wuXingCode": 3
                    },
                    {
                        "bianzCode": 58,
                        "bianzName": "脾不统血",
                        "bingYCode": 8,
                        "groupCode": "A         ",
                        "isJoin": 0,
                        "isSameEtiology": 1,
                        "joinDate": "2023-05-30",
                        "progressDate": "2023-07-17",
                        "progressDay": 16,
                        "progressStatus": 2,
                        "realityBianCode": 58,
                        "symptomNo": "2305300002",
                        "visitDate": "2023-05-30",
                        "wuXingCode": 3
                    },
                    {
                        "bianzCode": 55,
                        "bianzName": "脾气虚",
                        "bingYCode": 8,
                        "groupCode": "A         ",
                        "isJoin": 0,
                        "isSameEtiology": 1,
                        "joinDate": "2023-05-30",
                        "progressDate": "2023-08-02",
                        "progressDay": 16,
                        "progressStatus": 2,
                        "realityBianCode": 58,
                        "symptomNo": "2305300002",
                        "visitDate": "2023-05-30",
                        "wuXingCode": 3
                    },
                    {
                        "bianzCode": 76,
                        "bianzName": "胃气虚",
                        "bingYCode": 8,
                        "groupCode": "A         ",
                        "isJoin": 0,
                        "isSameEtiology": 1,
                        "joinDate": "2023-05-30",
                        "progressDate": "2023-08-18",
                        "progressDay": 16,
                        "progressStatus": 2,
                        "realityBianCode": 58,
                        "symptomNo": "2305300002",
                        "visitDate": "2023-05-30",
                        "wuXingCode": 3
                    },
                    {
                        "bianzCode": 80,
                        "bianzName": "寒饮停胃",
                        "bingYCode": 8,
                        "groupCode": "A         ",
                        "isJoin": 0,
                        "isSameEtiology": 1,
                        "joinDate": "2023-05-30",
                        "progressDate": "2023-08-29",
                        "progressDay": 11,
                        "progressStatus": 2,
                        "realityBianCode": 58,
                        "symptomNo": "2305300002",
                        "visitDate": "2023-05-30",
                        "wuXingCode": 3
                    }
                ]
            }
        },
    },
    filters: {
        clearYear: function (str) {
            return str.replace(/\d+-/, "");
        }
    },
    computed: {
        ...mapGetters(["progressStatus"]),
        start() {
            return this.list.length ? this.list[0] : {}
        },
        isShow() {
            return this.start.progressStatus != 3;
        },
        end() {
            return this.list.length ? this.list[this.list.length - 1] : {}
        },
        sumDay() {
            return this.list.reduce((sum, i) => {
                return sum + i.progressDay;
            }, 0)
        },
        // 正常进度
        zc() {
            return this.list.find(i => i.bianzCode == this.start.realityBianCode) || {};
        },
        // 正常进度百分比
        zc_bfb() {
            let d = new Date(this.zc.progressDate) - new Date(this.zc.joinDate)
            return d / (1000 * 60 * 60 * 24) / this.sumDay * 100
        },
        // 当前进度百分比
        dq_bfb() {
            let d = new Date(this.start.visitDate) - new Date(this.start.joinDate)
            let b = d / (1000 * 60 * 60 * 24) / this.sumDay * 100
            return b > 100 ? 100 : b
        },
        dqclass() {
            let str = "t-center";
            if (this.dq_bfb > 90) {
                str = "t-left"
            } else if (this.dq_bfb < 10) {
                str = ""
            }
            return str;
        }

    },
}
</script>

<style lang="scss" >
.treatment-progress {
    width: 100%;
    font-size: 12px;
    line-height: 14px;
    display: flex !important;
    align-items: center;
    padding: 5px 10px;
    background: rgba(133, 58, 47, 0.05);
    border-radius: 5px;
    white-space: nowrap;

    .t-right {
        text-align: right;
    }

    .ivu-progress-inner {
        background: #fff;
        overflow: hidden;
    }

    .t-center {
        position: relative;
        width: 100%;
        margin: 0 10px;
    }

    .info {
        padding-top: 20px;
        height: 34px;

        .timepoint {
            position: absolute;
            width: 0;
            height: 10px;

            .text {
                position: absolute;
                bottom: -4px;
                white-space: nowrap;

                &.t-left {
                    right: 0;
                    text-align: right;
                }

                &.t-center {
                    text-align: center;
                    width: 80px;
                    margin-left: -45px;
                    // left: -15px;
                }
            }

            &.hav-point {
                width: 10px;
                margin-left: -5px;

                .text {
                    padding: 0 15px;
                }

                i {
                    position: relative;
                    z-index: 2;
                }
            }
        }

        .expected {
            left: 70%;
            z-index: 2;
        }

        .start {
            left: 0;
        }

        .end {
            right: 0;
        }

        &.info2 {
            padding-top: 0;

            .hav-point {
                .text {
                    padding: 0;
                }
            }

            .ivu-icon {
                position: relative;
            }

            .ivu-icon::after {
                position: absolute;
                top: -14px;
                left: 0;
                content: " ";
                display: inline-block;
                width: 12px;
                height: 12px;
                background: red;
                border-radius: 50%;
            }
        }

        &.label-botton {
            .text {
                top: 15px;
            }
        }
    }
}
</style>