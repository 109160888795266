import { render, staticRenderFns } from "./visceraTrends.vue?vue&type=template&id=84aaa676&scoped=true&"
import script from "./visceraTrends.vue?vue&type=script&lang=js&"
export * from "./visceraTrends.vue?vue&type=script&lang=js&"
import style0 from "./visceraTrends.vue?vue&type=style&index=0&id=84aaa676&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.2@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84aaa676",
  null
  
)

export default component.exports