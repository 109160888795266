<template>
  <!-- 医生能力指标 -->
  <div v-if="show" class="capabilityIndicators">
    <div class="title">医生能力指标({{ month }}月)</div>
    <div class="item" v-for="(item, index) in list" :key="index">
      <span class="label">{{ item.label }}</span>
      <span v-if="item.type" class="value">
        已掌握
        <i v-if="item.value">{{ item.value }}</i>
        <em v-else>/</em>
        个
        <em v-if="item.rate">({{ item.rate }}%)</em>
      </span>
      <span v-else class="value">
        第
        <i v-if="item.value">{{ item.value }}</i>
        <em v-else>/</em>
        名
        <em v-if="item.rate">({{ item.rate }}%)</em>
      </span>
      <Icon v-if="item.isUp === 1" type="md-arrow-up" />
      <Icon v-else-if="item.isUp === -1" type="md-arrow-down" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['userInfo'])
  },
  data() {
    return {
      show: false,
      month: '',
      list: [
        {
          label: "未复诊有效",
          key: 'noFollowValidRank',
          flag: 'noFollowValidFlag',
          rateKey: 'noFollowValidRate', //效率
          rate: '',
          value: 1,
          isUp: 0, // 1 上升 0:平等 -1下降 
          type: 0 // 0：名次 1:其它
        },
        {
          label: "未复诊无效",
          key: 'noFollowInvalidRank',
          flag: 'noFollowInvalidFlag',
          rateKey: 'noFollowInvalidRate', //效率
          rate: '',
          value: 1,
          isUp: 0, // 1 上升 0:平等 -1下降 
          type: 0 // 0：名次 1:其它
        },
        // {
        //   label: "复诊率",
        //   key: 'returnVisitRank',
        //   flag: 'returnVisitFlag',
        //   value: 1,
        //   isUp: 0, // 1 上升 0:平等 -1下降 
        //   type: 0 // 0：名次 1:其它
        // },
        {
          label: "慈脉五行",
          key: 'competencyRank',
          flag: 'competencyFlag',
          rateKey: '', //效率
          rate: '',
          value: 2,
          isUp: 0,
          type: 0
        },
        {
          label: "传承技能",
          key: 'inheritSkillNumber',
          flag: 'inheritSkillFlag',
          rateKey: '', //效率
          rate: '',
          value: 7,
          isUp: 0,
          type: 1
        },
        {
          label: "电子病历规范",
          key: 'medicalStandardRank',
          flag: 'medicalStandardFlag',
          rateKey: '', //效率
          rate: '',
          value: 9,
          isUp: 0,
          type: 0
        },
        // {
        //   label: "主方准确",
        //   key: 'mainAccuracyRank',
        //   flag: 'mainAccuracyFlag',
        //   value: 5,
        //   isUp: 0,
        //   type: 0
        // },
        // {
        //   label: "加味准确",
        //   key: 'addDrugRank',
        //   flag: 'addDrugFlag',
        //   value: 7,
        //   isUp: 0,
        //   type: 0
        // },
        {
          label: "学习参与度",
          key: 'learnEngagementRank',
          flag: 'learnEngagementFlag',
          rateKey: '', //效率
          rate: '',
          value: 3,
          isUp: 0,
          type: 0
        },
        {
          label: "沟通艺术",
          key: 'communicationArtRank',
          flag: 'communicationArtFlag',
          rateKey: '', //效率
          rate: '',
          value: 12,
          isUp: 0,
          type: 0
        }
      ]
    };
  },
  methods: {
    getDoctorCapabilityRank() {
      this.api2('getDoctorCapabilityRank', {}).then(res => {
        let resobj = res.result;
        this.show = false;
        for (const key in resobj) {
          if (Object.hasOwnProperty.call(resobj, key)) {
            const element = resobj[key];
            if (element) this.show = true;
          }
        }

        // 有值才处理
        if (this.show) {
          // 设置月份
          let d = new Date();
          d.setMonth(d.getMonth() - 1)
          this.month = d.getMonth() + 1;

          this.list.forEach(i => {
            i.rate = i.rateKey ? resobj[i.rateKey] : ''
            i.value = resobj[i.key]
            i.isUp = resobj[i.flag]
          })
        }
      })
    },
  },
  created() {
    this.getDoctorCapabilityRank();
  },
};
</script>

<style lang="scss" scoped>
.capabilityIndicators {
  display: flex;
  background: #ffffff;
  padding: 2px 60px 1px 60px;
  color: #853a2f;
  border-bottom: 1px solid #853a2f33;
}

.capabilityIndicators .item {
  padding: 0 12px;
  height: 18px;
  line-height: 18px;
  // border-right: 1px solid #dddddd;
  position: relative;
}

.capabilityIndicators .item::after {
  content: ' ';
  display: block;
  height: 14px;
  position: absolute;
  right: 0;
  bottom: 0;
  border-right: 1px solid #dddddd;
}

.capabilityIndicators .item .value {
  padding-left: 5px;
}


.capabilityIndicators .item .value em {
  line-height: 22px;
  font-style: normal;
}

.capabilityIndicators .item .value i {
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
  padding-right: 3px;
}

.capabilityIndicators .ivu-icon-md-arrow-up {
  color: red;
  font-size: 16px;
}

.capabilityIndicators .ivu-icon-md-arrow-down {
  color: green;
  font-size: 16px;
}
</style>