<template>
  <div class="patientInfoModal speechTechnique" :class="{empty_mk:!diseaseEmotion.list.length}">
    <template v-if="diseaseEmotion.list.length">
      <div class="head_nr">
        <span class="qk_label">中医诊断：</span>
        <span class="qk_content">{{diseaseEmotion.diagnosticName}}</span>
      </div>
      <div class="u_card" v-for="(item,index) in diseaseEmotion.list" :key="index">
        <div class="u_card_head">中医证型：{{formatSyndrome(item.syndromeType)}}</div>
        <div class="u_card_content">
          <div class="qk_label">情绪：</div>
          <div class="qk_content">{{item.emotion}}</div>
          <div class="qk_label">信念：</div>
          <div class="qk_content">{{item.belief}}</div>
          <div class="qk_label">疏导：</div>
          <div class="qk_content">{{item.dredge}}</div>
        </div>
      </div>
    </template>
    <empty v-else />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(["diseaseEmotion"])
  },
  watch: {
    show(newValue, oldValue) {
      this.showWin = newValue;
    }
  },
  data() {
    return {
      showWin: false
    };
  },
  methods: {
    close(res) {
      if (!res) this.$emit("close");
    },
    formatSyndrome(str) {
      return str ? str.replace(/,$/, "") : "";
    },
    init() {
      if (this.diseaseEmotion.list.length) {
        this.showWin = this.show;
      } else {
        // this.$Message.info('此诊断暂无话术方向数据哦')
        this.$Message.info("暂无数据");
        this.close();
      }
    }
  },
  created() {
    this.init();
  }
};
</script>

<style lang="scss" scoped>
.speechTechnique{
  padding: 15px;
  background: #fff; 
  min-height: 100%;
}
.speechTechnique.empty_mk{
  height: 100%;
}
.head_nr {
  padding-bottom: 5px;
}
.u_card{
  background: #f5f5f5; 
}
</style>