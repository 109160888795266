<template>
  <div class="win_btn">
    <!-- <Icon @click="winChange('open_exe')" type="md-remove" /> -->
    <Icon @click="winChange('win_min')" type="md-remove" />
    <Icon @click="winChange('win_size')" :type="isMax ? 'ios-browsers-outline' : 'md-square-outline'" />
    <Icon @click="winChange('win_close')" type="md-close" />
  </div>
</template>

<script>
import common from "@/common.js";
var ele = null;
if (common.isElectron()) {
  ele = window.require("electron");
}
export default {
  data() {
    return {
      isMax: true,
    };
  },
  methods: {
    open_exe() {
      ele.ipcRenderer.send("open_exe");
    },
    winChange(msg) {
      if (msg == "win_size") {
        this.isMax = !this.isMax;
      }
      if (!ele) return;

      if (msg == "win_close") {
        this.$Modal.confirm({
          title: "提示",
          content: "确定要关闭吗?",
          onOk: () => {
            ele.ipcRenderer.send(msg);
          },
        });
      } else {
        ele.ipcRenderer.send(msg);
      }
    },
    message() {
      if (!ele) return;
      let res = ele.ipcRenderer.send("getVersion");
      ele.ipcRenderer.on("version", (res, data) => {
        this.$store.commit("setVersion", data);
      });
    },
  },
  mounted() {
    this.message();
  },
};
</script>

<style lang="scss" scoped></style>