<!-- 运动趋势分析 -->
<template>
    <div>
        <div class="section-time">{{ section.name }}</div>
        <div v-if="section.arr && section.arr.length > 0" class="section-list">
            <div v-for="(item, index) in section.arr" :key="index">
                <span>{{ item.value }}</span>
                <span>{{ item.name }}</span>
            </div>
        </div>
        <div id="sportTrend" :class="className" :style="{ height: height, width: width }" />
    </div>
</template>
  
<script>
import * as echarts from "echarts";
import resize from './mixins/resize.js'
import { dayList, dataZoom } from '@/utils/tool.js'
export default {
    mixins: [resize],
    props: {
        className: {
            type: String,
            default: 'chart'
        },
        width: {
            type: String,
            default: '100%'
        },
        height: {
            type: String,
            default: '100%'
        },
        autoResize: {
            type: Boolean,
            default: true
        },
        sportData: {
            type: Object,
            default: {}
        },
        addTime: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            chart: null,
            section: []
        }
    },
    watch: {
    },
    created() {

    },
    mounted() {
        this.$nextTick(() => {
            this.initChart()
        })
    },
    beforeDestroy() {
        if (!this.chart) {
            return
        }
        this.chart.dispose()
        this.chart = null
    },
    methods: {
        initChart() {
            this.chart = echarts.init(document.getElementById('sportTrend'))
            this.setOptions()
        },
        setOptions() {
            var e = this.sportData
                , t = this
                , a = new Array(30)
                , i = new Array(30)
                , s = new Array(30)
                , r = e.arrayList;
            r && dayList(this.addTime).last16.forEach(function (e, t) {
                r.forEach(function (r, n) {
                    e === r.data_date && (a[t] = r.valid_number,
                        i[t] = parseInt(r.total_number) - parseInt(r.valid_number),
                        s[t] = r.total_number)
                })
            });
            let uesTemp = dayList(this.addTime).ecUse
            let dataTemp = dayList(this.addTime).ecShow
            this.chart.setOption(
                {
                    grid: {
                        top: 40,
                        right: 40,
                        bottom: 0,
                        left: 0,
                        containLabel: !0
                    },
                    tooltip: {
                        trigger: "axis",
                        formatter: function (e) {
                            for (var a = dataTemp[e[0].dataIndex].split("/"), i = "<div><p>" + (a[0] + "月" + a[1] + "日") + "</p>", s = 0; s < e.length; s++) {
                                var r = Math.floor(e[0].data) + Math.floor(e[1].data)
                                    , n = e[s].marker;
                                0 === s ? e[s].data ? i += "<p>" + n + "有效步数：" + e[s].data + "</p>" : i += "<p>" + n + "有效步数：---</p>" : 1 === s && (e[s].data ? i += "<p>" + n + "总步数：" + r + "</p>" : i += "<p>" + n + "总步数：---</p>")
                            }
                            return i += "</div>"
                        }
                    },
                    xAxis: {
                        type: "category",
                        data: uesTemp,
                        axisLabel: {
                            interval: 0,
                            color: "#999"
                        },
                        axisLine: {
                            lineStyle: {
                                color: "#e7e5e4"
                            }
                        },
                        axisTick: {
                            show: !1
                        },
                        splitArea: {
                            show: !1
                        }
                    },
                    yAxis: {
                        scale: !0,
                        min: 0,
                        splitNumber: 5,
                        type: "value",
                        axisLabel: {
                            color: "#999"
                        },
                        axisLine: {
                            show: !1
                        },
                        axisTick: {
                            show: !1
                        }
                    },
                    color: ["#86d9e0", "#0091ea"],
                    legend: {
                        selectedMode: !1,
                        x: "left",
                        y: "5",
                        padding: [0, 0, 0, 35],
                        itemWidth: 15,
                        itemHeight: 6,
                        itemGap: 27,
                        data: [{
                            name: "总步数",
                            icon: "rect"
                        }, {
                            name: "有效步数",
                            icon: "rect"
                        }]
                    },
                    toolbox: {
                        show: !0,
                        feature: {
                            dataZoom: {
                                yAxisIndex: "none",
                                title: {
                                    back: "还原"
                                }
                            }
                        }
                    },
                    series: [{
                        name: "有效步数",
                        type: "bar",
                        stack: "步数",
                        barWidth: 6,
                        data: a,
                        itemStyle: {
                            color: "#0091ea"
                        }
                    }, {
                        name: "总步数",
                        type: "bar",
                        stack: "步数",
                        barWidth: 6,
                        data: i,
                        itemStyle: {
                            color: "#86d9e0"
                        }
                    }, {
                        name: "背景板",
                        type: "line",
                        markLine: {
                            silent: !0,
                            animation: !1,
                            symbol: ["none", "none"],
                            lineStyle: {
                                color: "green"
                            },
                            label: {
                                color: "green"
                            },
                            data: [{
                                yAxis: 6e3
                            }]
                        }
                    }]
                }
            )

            this.section = []
            dataZoom({
                echart: this.chart,
                series: [{
                    name: "有效步数",
                    data: a
                }, {
                    name: "总步数",
                    data: s
                }],
                addTime: this.addTime,
                sectionArr: function (e) {
                    t.section = e
                }
            })
        },
    }
}
</script>
<style lang="scss" scoped>
.section-time {
    position: absolute;
    top: -60px;
    line-height: 32px;
    right: 0;
    font-size: 16px;
    color: #000;
}

.section-list {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;

    div {
        border-right: 1px solid #e9e9e9;
        padding: 0 40px;

        &:last-child {
            border: none;
        }

        span {
            display: block;
            text-align: center;

            &:first-child {
                font-size: 18px;
                font-weight: 700;
                margin-bottom: 10px;
            }

            &:last-child {
                font-size: 14px;
                color: #7f7f7f;
            }
        }
    }
}
</style>
  