<!-- 百年方考试 -->
<template>
  <layer pagename="prescription" pageClass="prescriptionPage ks_page">
    <div class="prescription medicalPractise e_Symptom e_Prescription">
      <div class="z_title">
        <div class="label">
          百年方考试
          <span>错题查看</span>
        </div>
      </div>
      <Row class="info_k">
        <Col :md="24">
          <div class="cont">
            <Row class="bod">
              <Col class="user_k" :md="5" :sm="24">
                <div class="user_info">
                  <div class="name">
                    {{paper.patientName}}
                    <span>{{paper.patientSex}}</span>
                  </div>
                  <div class="item">
                    <span class="label">年龄：</span>
                    <span class="nr">{{paper.patientAge}}</span>
                  </div>
                  <div class="item">
                    <span class="label">主诉：</span>
                    <span class="nr">{{paper.patientPurpose}}</span>
                  </div>
                  <div class="item dsNames_t">
                    <span class="label">症状：</span>
                  </div>
                  <div class="item dsNames_k">
                    <span class="dsNames" v-for="(item,index) in dsNames" :key="index">{{item}}</span>
                  </div>
                </div>
              </Col>
              <Col class="zjdp" :md="19" :sm="24">
                <div class="title">专家点评</div>
                <div class="nr">{{paper.auditOpinions || '无'}}</div>
                <br />
                <div class="y_cf cfdb">
                  <div class="m_cf_title">
                    <span>
                      <i class="iconfont icon-jiageprice1"></i> 专家拟定处方
                    </span>
                  </div>
                  <div class="nr">
                    <div class="cf_bnf" :class="{cf_error:selfBnf!=yBnf}">{{yBnf}}</div>
                    <div class="cf_item_m">
                      <div class="t_bq">同：</div>
                      <div class="y_item">{{sysList_same || '无'}}</div>
                    </div>
                    <div class="cf_item_m yc_cf">
                      <div class="t_bq">异：</div>
                      <div class="y_item">{{sysList || '无'}}</div>
                    </div>
                    <!-- <div class="ties"><span>x</span><span class="sl">7</span></div> -->
                  </div>
                </div>
                <div class="m_cf cfdb">
                  <div class="m_cf_title">
                    <span>
                      <i class="iconfont icon-jiageprice1"></i> 我的处方
                    </span>
                  </div>
                  <div class="nr">
                    <div class="cf_bnf" :class="{cf_error:selfBnf!=yBnf}">{{selfBnf}}</div>
                    <div class="cf_item_m">
                      <div class="t_bq">同：</div>
                      <div class="y_item">{{selfList_same || '无'}}</div>
                    </div>
                    <div class="cf_item_m yc_cf">
                      <div class="t_bq">异：</div>
                      <div class="y_item">{{selfList || '无'}}</div>
                    </div>
                    <!-- <div class="ties"><span>x</span><span class="sl">{{self_Nb}}</span></div> -->
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <div @click="leftIndex" class="fy_btn b_left">
          <Icon type="ios-arrow-back" />
        </div>
        <div @click="rightIndex" class="fy_btn b_rigth">
          <Icon type="ios-arrow-forward" />
        </div>
      </Row>

      <br />
      <div class="e_bnt_g">
        <Button @click="rightIndex" type="primary">查看下一题</Button>
        <Button @click="del" class="e_del">移除此题</Button>
      </div>
      <div v-show="!p_list.length" class="mack_ts">当前无错题哦！</div>
    </div>
  </layer>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  props: ["type"],
  data() {
    return {
      userWrongQuestionsList: [],
      selfBnf: "",
      yBnf: "",
      selfList: "",
      selfList_same: "",
      sysList: "",
      sysList_same: "",
      sys_d: "",
      self_d: "",
      self_Nb: "",

      c_index: 0, //轮播序号
      p_index: 0, // 当前序号
      p_list: [], // 问题列表
      prescription_zj: "", //专家处方
      prescription_my: "", //我的处方
      dialectical_zj: "", //中医诊断
      dialectical_my: "", //中医诊断
      prescription: {}
    };
  },
  computed: {
    ...mapGetters([""]),
    paper() {
      let item = this.userWrongQuestionsList[this.p_index];
      return item || {};
      if (!item) return {};

      var se = item.userDrug,
        sy = item.sysDrug;
      var e = [];
      if (se.length) {
        e = se
          .filter(i => !/百年/.test(i.Drug_Name))
          .map(i => {
            return i.Drug_Name + " x " + i.Quantity;
          });
      }
      var y = [];
      if (se.length) {
        y = sy
          .filter(i => !/百年/.test(i.Drug_Name))
          .map(i => {
            return i.Drug_Name + " x " + i.Quantity;
          });
      }

      this.selfBnf = se
        .filter(i => /百年/.test(i.Drug_Name))
        .map(i => {
          return i.Drug_Name + " x " + i.Quantity;
        })
        .join("，");
      this.yBnf = sy
        .filter(i => /百年/.test(i.Drug_Name))
        .map(i => {
          return i.Drug_Name + " x " + i.Quantity;
        })
        .join("，");

      this.selfList = this.sameList(e, y, false).join("，");
      this.selfList_same = this.sameList(e, y, true).join("，");

      this.sysList = this.sameList(y, e, false).join("，");
      this.sysList_same = this.sameList(y, e, true).join("，");

      // this.sys_d=  res.diseaseName;
      this.self_d = se[0] ? se[0].Disease_Type : "";
      this.self_Nb = se[0] ? se[0].fNumber : "";

      return item;
    },
    dsNames() {
      return this.paper.dsNames
        ? this.paper.dsNames.replace(/,\s*$/, "").split(",")
        : [];
    }
  },
  watch: {
    type(value) {
      this.getData();
    },
    userWrongQuestionsList(value) {
      if (!value.length) {
        this.p_list = [];
        this.p_index = 0;
        return;
      }
      var newArr = value;
      newArr.forEach(i => {
        i.active = false;
        i.state = 0;
      });
      this.p_list = newArr;
      this.changeIndex();
    }
  },
  methods: {
    sameList(arr1, arr2, same) {
      if (same) {
        return arr1.filter(i => arr2.includes(i));
      } else {
        return arr1.filter(i => !arr2.includes(i));
      }
    },

    del() {
      this.$store.commit("openSpin");
      this.api2("delMyWrongQuestions", {
        wrongid: this.paper.wrongId
      }).then(res => {
        this.$store.commit("closeSpin");
        this.$Message.success(res.result);
        this.userWrongQuestionsList.splice(this.p_index, 1);
      });
    },

    // 提序号点击事件
    clickIndex(item, index) {
      this.p_index = index;
      this.changeIndex();
    },
    changeIndex() {
      // 清数据
      this.prescription.drugList = [];
      this.p_list.forEach(i => {
        i.active = false;
      });
      this.p_list[this.p_index].active = true;
    },
    // 下一题
    rightIndex() {
      if (this.p_index + 1 < this.p_list.length) {
        this.p_index++;
        this.changeIndex();
      } else {
        this.$Message.info("已经是最后一题!");
      }
    },
    // 下一题
    leftIndex() {
      if (this.p_index - 1 >= 0) {
        this.p_index--;
        this.changeIndex();
      } else {
        this.$Message.info("已经是第一题!");
      }
    },
    getData() {
      var obj = {
        pageIndex: 1, //	页码
        pageSize: 1000, //	页大小
        paperTypeId: 0 // 	试卷类型 0 百年方 1 症状
      };
      this.$store.commit("openSpin");
      this.api2("getUserWrongQuestionsList", obj)
        .then(res => {
          this.$store.commit("closeSpin");
          this.userWrongQuestionsList = res.result;
        })
        .catch(err => console.log(err));
    }
  },
  created() {
    this.getData();
  },
  mounted: function() {
    var self = this;
  }
};
</script>
