<!-- 病人信息 -->

<template>
    <div class="chart-container">
        <div class="watch-data-header clearfix">
            <div class="watch-owner-info" v-if="useType !== 'patientInfo'">
                <span>{{ sunGoUserInfo.patientName }}</span>
                <span>{{ sunGoUserInfo.sex }}</span>
                <span>{{ sunGoUserInfo.patientAge }}岁</span>
                <span>手机号：{{ sunGoUserInfo.cellphoneNo }}</span>
                <span v-if="linkStatus && socketData.patientName === sunGoUserInfo.patientName"
                    class="is-on-measure">公用手环测量中</span>
            </div>
            <div class="watch-operate" :style="useType === 'patientInfo' ? 'float: left;' : ''">
                <Button class="operate-item" @click="getBackDate" icon="ios-arrow-back"></Button>
                <DatePicker class="operate-select-date operate-item" :value="addTime" :clearable="false" type="date"
                    format="yyyy-MM-dd" :options="dateOption" @on-change="dateChange" />
                <Button class="operate-item" @click="getForwardDate" :disabled="today === addTime"
                    icon="ios-arrow-forward"></Button>
                <Button class="operate-item" @click="getLastedDate">最近</Button>
                <Button class="operate-item" @click="getCurrentDate">今日</Button>
                <span v-if="useType !== 'patientInfo'">
                    <span class="operate-line"></span>
                    <Button class="operate-item" @click="refreshData">刷新数据</Button>
                    <Button v-show="!showRetry" class="operate-item" type="primary"
                        @click="getPublicData">公用手环测量</Button>
                    <Button v-show="showRetry" class="operate-item" :disabled="showRetry" type="primary">{{ timeNum /
                1000
                        }}秒后重试</Button>
                </span>

            </div>
        </div>
        <div class="sungo-charts">
            <div class="sungo-section">
                <div class="section-title">24小时脏腑</div>
                <div class="section-content">
                    <Card class="mb-15">
                        <template #title>
                            <div class="card-header">
                                <span>日间脏腑数据</span>
                            </div>
                        </template>
                        <Row :gutter="16">
                            <Col span="24">
                            <visceraData v-if="showVisceraStatus && showHeartStatus" type="day" :addTime="addTime"
                                class="day-vicera-chart" :visceraData="visceraData" :heartData="heartData"
                                height="300px" width="100%" />
                            </Col>
                        </Row>
                    </Card>
                    <Card class="mb-15">

                        <template #title>
                            <div class="card-header">
                                <span>夜间脏腑数据</span>
                            </div>
                        </template>
                        <Row :gutter="16">
                            <Col span="24">
                            <visceraData v-if="showVisceraStatus && showHeartStatus" type="night" :addTime="addTime"
                                class="night-vicera-chart" :visceraData="visceraData" :heartData="heartData"
                                height="300px" width="100%" />
                            </Col>
                        </Row>
                    </Card>
                    <Card>

                        <template #title>
                            <div class="card-header">
                                <span>全天脏腑数据</span>
                            </div>
                        </template>
                        <Row :gutter="16">
                            <Col span="24">
                            <visceraData v-if="showVisceraStatus && showHeartStatus" type="allDay" :addTime="addTime"
                                class="all-day-vicera-chart" :visceraData="visceraData" :heartData="heartData"
                                height="300px" width="100%" />
                            </Col>
                        </Row>
                    </Card>
                </div>
            </div>
            <div class="sungo-section">
                <div class="section-title">趋势分析</div>
                <div class="section-content">
                    <Row :gutter="16" class="mb-15">
                        <Col span="24">
                        <Card>

                            <template #title>
                                <div class="card-header">
                                    <span>精神状态分析</span>
                                </div>
                            </template>
                            <Row :gutter="16">
                                <Col span="24">
                                <spiritTrends v-if="showEcgStatus" :ecgData="ecgData" :addTime="addTime"
                                    id="spiritTrendsChart" height="300px" width="100%" />
                                </Col>
                            </Row>
                        </Card>
                        </Col>
                    </Row>
                    <Row :gutter="16" class="mb-15">
                        <Col span="24">
                        <Card>

                            <template #title>
                                <div class="card-header">
                                    <span>脏腑趋势分析</span>
                                </div>
                            </template>
                            <Row :gutter="16">
                                <Col span="24">
                                <visceraTrends v-if="showVisceraStatus" :visitDateArr="visitDateArr"
                                    :visceraData="visceraData" type="allDay" :addTime="addTime" height="300px"
                                    width="100%" />
                                </Col>
                            </Row>
                        </Card>
                        </Col>
                    </Row>
                    <Row :gutter="16" class="mb-15">
                        <Col span="24">
                        <Card class="mb-15">

                            <template #title>
                                <div class="card-header">
                                    <span>脏腑日间趋势分析</span>
                                </div>
                            </template>
                            <Row :gutter="16">
                                <Col span="24">
                                <visceraTrends v-if="showVisceraStatus" :visitDateArr="visitDateArr"
                                    :visceraData="visceraData" type="day" :addTime="addTime" height="300px"
                                    width="100%" />
                                </Col>
                            </Row>
                        </Card>
                        <Card>

                            <template #title>
                                <div class="card-header">
                                    <span>日间脏腑异常</span>
                                </div>
                            </template>
                            <div v-if="dayData && dayData.length > 0">
                                <div class="analysis-list" v-for="(item, index) in dayData" :key="index">
                                    <span>{{ item.dataDate }}</span>
                                    <span>{{ item.analysisResult }}</span>
                                </div>
                                <Page style="margin-top: 10px;" show-total show-sizer :page-size-opts="[5, 10, 20]"
                                    :current="currentDayPage" :total="dayTotal" :page-size="dayPageSize"
                                    @on-page-size-change="handleSizeDayChange" @on-change="handleCurrentDayChange">
                                </Page>
                            </div>
                            <div v-else>暂无数据</div>
                        </Card>
                        </Col>
                    </Row>
                    <Row :gutter="16" class="mb-15">
                        <Col span="24">
                        <Card class="mb-15">

                            <template #title>
                                <div class="card-header">
                                    <span>脏腑夜间趋势分析</span>
                                </div>
                            </template>
                            <Row :gutter="16">
                                <Col span="24">
                                <visceraTrends v-if="showVisceraStatus" :visitDateArr="visitDateArr"
                                    :patientCode="patientCode" :visceraData="visceraData" type="night"
                                    :addTime="addTime" height="300px" width="100%" />
                                </Col>
                            </Row>
                        </Card>
                        <Card>

                            <template #title>
                                <div class="card-header">
                                    <span>夜间脏腑异常</span>
                                </div>
                            </template>
                            <div v-if="nightData && nightData.length > 0">
                                <div class="analysis-list" v-for="(  item, index  ) in   nightData  " :key="index">
                                    <span>{{ item.dataDate }}</span>
                                    <span>{{ item.analysisResult }}</span>
                                </div>
                                <Page style="margin-top: 10px;" show-total show-sizer :page-size-opts="[5, 10, 20]"
                                    :total="nightTotal" :page-size="nightPageSize"
                                    @on-page-size-change="handleSizeNightChange" :current="currentNightPage"
                                    @on-change="handleCurrentNightChange"></Page>
                            </div>
                            <div v-else>暂无数据</div>
                        </Card>
                        </Col>
                    </Row>
                    <Row :gutter="16" class="mb-15">
                        <Col span="24">
                        <Card>

                            <template #title>
                                <div class="card-header">
                                    <span>睡眠趋势分析</span>
                                </div>
                            </template>
                            <Row :gutter="16">
                                <Col span="24">
                                <sleepTrends v-if="showSleepStatus" id="sleepTrendsChart" :addTime="addTime"
                                    :sleepData="sleepData" height="300px" width="100%" />
                                </Col>
                            </Row>
                        </Card>
                        </Col>
                    </Row>
                    <Row :gutter="16" class="mb-15">
                        <Col span="24">
                        <Card>

                            <template #title>
                                <div class="card-header">
                                    <span>心率趋势分析</span>
                                </div>
                            </template>
                            <Row :gutter="16">
                                <Col span="24">
                                <heartRateTrends v-if="showHeartStatus" id="heartRateTrendsChart" :addTime="addTime"
                                    :heartData="heartData" height="300px" width="100%" />
                                </Col>
                            </Row>
                        </Card>
                        </Col>
                    </Row>
                    <Row :gutter="16" class="mb-15">
                        <Col span="24">
                        <Card>

                            <template #title>
                                <div class="card-header">
                                    <span>运动趋势分析</span>
                                </div>
                            </template>
                            <Row :gutter="16">
                                <Col span="24">
                                <sportTrends v-if="showSportStatus" id="sportTrendsChart" :sportData="sportData"
                                    :addTime="addTime" height="300px" width="100%" />
                                </Col>
                            </Row>
                        </Card>
                        </Col>
                    </Row>
                </div>
            </div>
            <div class="sungo-section">
                <div class="section-title">脏腑状态</div>
                <div class="section-content">
                    <visceraStatus v-if="showVisceraStatus && showEcgStatus" :visceraData="visceraData"
                        :ecgData="ecgData" id="visceraStatusChart" height="300px" width="100%" />
                </div>
            </div>

            <div class="sungo-section">
                <div class="section-title">精神状态</div>
                <div class="section-content">
                    <Row :gutter="16">
                        <Col span="6">
                        <Card class="mb-15 font-bold" style="height: 119px;">

                            <template #title>
                                <div class="card-header">
                                    <span>睡眠基础心率</span>
                                </div>
                            </template>
                            <span>{{ heartData.nightHr ? heartData.nightHr : '--' }}</span>
                        </Card>
                        <Card class="mb-15 font-bold" style="height: 119px;">

                            <template #title>
                                <div class="card-header">
                                    <span>日间平均心率</span>
                                </div>
                            </template>
                            <span>{{ heartData.dayHr ? heartData.dayHr : '--' }}</span>
                        </Card>
                        <Card class="mb-15 font-bold" style="height: 119px;">

                            <template #title>
                                <div class="card-header">
                                    <span>昼夜心率差</span>
                                </div>
                            </template>
                            <span v-if="heartData.nightHr && heartData.dayHr">{{ Number(heartData.dayHr) -
                Number(heartData.nightHr) }}</span>
                            <span v-else>--</span>
                        </Card>
                        </Col>
                        <Col span="18">
                        <Card>

                            <template #title>
                                <div class="card-header">
                                    <span>昼夜节律</span>
                                </div>
                            </template>
                            <Row :gutter="16">
                                <Col span="24">
                                <mentalStatus v-if="showEcgStatus && showHeartStatus" :ecgData="ecgData"
                                    :heartData="heartData" id="mentalStatusChart" height="300px" width="100%" />
                                </Col>
                            </Row>
                        </Card>
                        </Col>
                    </Row>
                </div>
            </div>
            <div class="sungo-section">
                <div class="section-title">睡眠状态</div>
                <div class="section-content">
                    <Row :gutter="16">
                        <Col span="6">
                        <Card class="mb-15 font-bold" style="height: 119px;">

                            <template #title>
                                <div class="card-header">
                                    <span>深睡</span>
                                    <span class="sleep-block-color deep"></span>
                                </div>
                            </template>
                            <span>{{ sleepData.deepSleep ? sleepData.deepSleep : '--' }}</span>
                        </Card>
                        <Card class="mb-15 font-bold" style="height: 119px;">

                            <template #title>
                                <div class="card-header">
                                    <span>浅睡</span>
                                    <span class="sleep-block-color light"></span>
                                </div>
                            </template>
                            <span>{{ sleepData.lightSleep ? sleepData.lightSleep : '--' }}</span>
                        </Card>
                        <Card class="font-bold" style="height: 119px;">

                            <template #title>
                                <div class="card-header">
                                    <span>异常</span>
                                    <span class="sleep-block-color error"></span>
                                </div>
                            </template>
                            <span>{{ sleepData.exception ? sleepData.exception : '--' }}</span>
                        </Card>
                        </Col>

                        <Col span="18">
                        <Card>

                            <template #title>
                                <div class="card-header">
                                    <span>睡眠状态分析</span>
                                </div>
                            </template>
                            <Row :gutter="16">
                                <Col span="24">
                                <sleepStatus v-if="showSleepStatus && showHeartStatus" id="sleepStatusChart"
                                    :sleepData="sleepData" :heartData="heartData" height="300px" width="100%" />
                                </Col>
                            </Row>
                        </Card>
                        </Col>
                    </Row>
                </div>
            </div>
            <div class="sungo-section">
                <div class="section-title">运动状态</div>
                <div class="section-content">
                    <Row :gutter="16">
                        <Col span="18">
                        <Card>

                            <template #title>
                                <div class="card-header">
                                    <span>运动状态和心率统计</span>
                                </div>
                            </template>
                            <Row :gutter="16">
                                <Col span="24">
                                <sportStatus v-if="showSportStatus && showHeartStatus" id="sportStatusChart"
                                    :sportData="sportData" :heartData="heartData" :addTime="addTime" height="300px"
                                    width="100%" />
                                </Col>
                            </Row>
                        </Card>
                        </Col>
                        <Col span="6">
                        <Card class="mb-15 font-bold" style="height: 119px;">

                            <template #title>
                                <div class="card-header">
                                    <span>总步数</span>
                                </div>
                            </template>
                            <span>{{ sportData.totalNumber ? sportData.totalNumber : '--' }}</span>
                        </Card>
                        <Card class="mb-15 font-bold" style="height: 119px;">

                            <template #title>
                                <div class="card-header">
                                    <span>有效运动时长</span>
                                </div>
                            </template>
                            <span>{{ sportData.validDuration ? sportData.validDuration : '--' }}</span>
                        </Card>
                        <Card class="mb-15 font-bold" style="height: 119px;">

                            <template #title>
                                <div class="card-header">
                                    <span>有效运动步数</span>
                                </div>
                            </template>
                            <span>{{ sportData.validNumber ? sportData.validNumber : '--' }}</span>
                        </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs'
import visceraData from './com/visceraData.vue'
import spiritTrends from './com/spiritTrends.vue'
import visceraTrends from './com/visceraTrends.vue'
import sleepTrends from './com/sleepTrends.vue'
import heartRateTrends from './com/heartRateTrends.vue'
import sportTrends from './com/sportTrends.vue'
import visceraStatus from './com/visceraStatus.vue'
import mentalStatus from './com/mentalStatus.vue'
import sleepStatus from './com/sleepStatus.vue'
import sportStatus from './com/sportStatus.vue'
import { mapGetters } from "vuex";
export default {
    props: {
        sunGoUserInfo: {
            type: Object,
            default: () => {
                return {};
            }
        },
        useType: {
            type: String,
            default: ''
        },
    },
    watch: {
        sungoLinkSuccess(newVal, oldVal) {
            // 监听是否连接手环成功
            let isPatientInfo = window.location.href.match('/patientSearch')
            if (!isPatientInfo) {
                // 监听手环是否连接成功
                if (newVal) {
                    this.linkStatus = true
                    this.socketData = newVal
                    this.$Message.success('手环开始测量')
                }
            }
        },
        publicSungoData(newVal, oldVal) {
            if (newVal) {
                this.linkStatus = false
                this.addTime = dayjs().format('YYYY-MM-DD')
                this.init()
            }
        },
    },
    components: {
        visceraData,
        spiritTrends,
        visceraTrends,
        sleepTrends,
        heartRateTrends,
        sportTrends,
        visceraStatus,
        mentalStatus,
        sleepStatus,
        sportStatus
    },
    computed: {
        ...mapGetters([
            'sungoLinkSuccess',
            'publicSungoData'
        ]),

    },
    data() {
        return {
            today: dayjs().format('YYYY-MM-DD'),
            showWatch: true,
            chartDate: {},
            ecgData: {},   //ecg数据
            visceraData: {},   //脏腑数据
            sleepData: {},   //睡眠数据
            sportData: {},   //运动数据
            heartData: {},   //心率数据
            showEcgStatus: false,
            showVisceraStatus: false,
            showSportStatus: false,
            showHeartStatus: false,
            showSleepStatus: false,
            patientCode: this.sunGoUserInfo.patientCode,
            addTime: dayjs().format('YYYY-MM-DD'),
            linkStatus: false,
            showRetry: false,
            timeNum: 5000,
            interval: null,
            dayAnalysis: [],
            nightAnalysis: [],
            dateOption: {
                disabledDate(date) {
                    return date && date.valueOf() > Date.now();//不能选今天之后的日期
                }
            },
            socketData: {},
            dayData: [],
            nightData: [],
            currentDayPage: 1, // 当前第1页
            dayPageSize: 5,// 每页10条
            dayTotal: 0,
            currentNightPage: 1, // 当前第1页
            nightPageSize: 5,// 每页10条
            nightTotal: 0,
            visitDateArr: []
        };
    },
    methods: {
        refreshData() {
            this.addTime = dayjs().format('YYYY-MM-DD')
            this.init()
        },
        //获取公用手环数据
        getPublicData() {
            let objParam = {
                "patientName": this.sunGoUserInfo.patientName,
                "patientCode": this.sunGoUserInfo.patientCode,
                "gender": this.sunGoUserInfo.sex,
                "hospitalCode": this.sunGoUserInfo.hospitalCode,
                "patientAge": this.sunGoUserInfo.patientAge,
                "cellphoneNo": this.sunGoUserInfo.cellphoneNo,
            }
            this.api2("linkBracelet", objParam).then((res) => {
                let that = this
                that.showRetry = true
                that.interval = setInterval(function () {
                    that.timeNum = Number(that.timeNum) - 1000
                    if (that.timeNum === 0) {
                        clearInterval(that.interval)
                        that.timeNum = 5000
                        that.showRetry = false
                        if (!that.linkStatus) {
                            that.$Message.error("请打开铁粉之家小程序的公用手环界面");
                        }
                    }
                }, 1000)
            })
        },
        dateChange(val, type) {
            this.addTime = val
            this.init()
        },
        getVisitTimeList() {
            // 获取看诊时间段
            this.api2("getVisitTimeList", {
                patientCode: this.sunGoUserInfo.patientCode,
                addTime: this.addTime
            }).then(res => {
                this.visitDateArr = res.result
                this.getVisceraData()
            }).catch(err => console.log(err));
        },
        getEcgData() {
            //获取运动数据
            this.api2("getSungoData", {
                addTime: this.addTime,
                type: '1',
                patientCode: this.patientCode
            })
                .then(res => {
                    this.ecgData = res.result.ecg
                    this.showEcgStatus = true
                })
                .catch(err => console.log(err));
        },
        getVisceraData() {
            //脏腑数据
            this.api2("getSungoData", {
                addTime: this.addTime,
                type: '2',
                patientCode: this.patientCode
            })
                .then(res => {
                    this.visceraData = res.result.viscera
                    this.dayData = []
                    this.nightData = []
                    if (this.visceraData.dayAnalysis && this.visceraData.dayAnalysis.length > 0) {
                        this.dayAnalysis = this.visceraData.dayAnalysis
                        this.dayTotal = this.visceraData.dayAnalysis.length;
                        if (this.dayAnalysis && this.dayAnalysis.length > 0) {
                            // 判断当前的每页条数和获取的数据的总数做相应的选择
                            if (this.dayAnalysis.length > this.dayPageSize) {
                                // 如果总条数大于当前每页条数，使用slice进行切割，吧数据复制给tableData渲染
                                this.dayData = this.dayAnalysis.slice(0, this.dayPageSize)
                            } else {
                                this.dayData = this.dayAnalysis
                            }
                        }
                    }
                    if (this.visceraData.nightAnalysis && this.visceraData.nightAnalysis.length > 0) {
                        this.nightAnalysis = this.visceraData.nightAnalysis
                        this.nightTotal = this.visceraData.nightAnalysis.length;
                        if (this.nightAnalysis && this.nightAnalysis.length > 0) {
                            // 判断当前的每页条数和获取的数据的总数做相应的选择
                            if (this.nightAnalysis.length > this.nightPageSize) {
                                // 如果总条数大于当前每页条数，使用slice进行切割，吧数据复制给tableData渲染
                                this.nightData = this.nightAnalysis.slice(0, this.nightPageSize)
                            } else {
                                this.nightData = this.nightAnalysis
                            }
                        }
                    }
                    this.showVisceraStatus = true
                })
                .catch(err => console.log(err));
        },
        // 每页多少条数据
        handleSizeDayChange(val) {
            this.currentDayPage = 1
            this.dayPageSize = val
            if (this.dayAnalysis && this.dayAnalysis.length > 0) {
                // 当点击每页多少条数据的时候，让从 当前页数-1 × 每页条数 得到起始位置 ，当前页数-1 × 每页条数+每页条数 得到切割多少条数据
                console.log((this.currentDayPage - 1) * this.dayPageSize, (this.currentDayPage - 1) * this.dayPageSize + val)
                this.dayData = this.dayAnalysis.slice((this.currentDayPage - 1) * this.dayPageSize, (this.currentDayPage - 1) * this.dayPageSize + val)
            }
        },
        // 点击按钮当前第几页
        handleCurrentDayChange(val) {
            if (this.dayAnalysis && this.dayAnalysis.length > 0) {
                // 当点击按钮的时候，让从 当前页数-1 × 每页条数 得到起始位置 ，当前页数-1 × 每页条数+每页条数 得到切割多少条数据
                this.dayData = this.dayAnalysis.slice((val - 1) * this.dayPageSize, (val - 1) * this.dayPageSize + this.dayPageSize)
            }
        },
        // 每页多少条数据
        handleSizeNightChange(val) {
            this.nightPageSize = val
            this.currentNightPage = 1
            if (this.nightAnalysis && this.nightAnalysis.length > 0) {
                // 当点击每页多少条数据的时候，让从 当前页数-1 × 每页条数 得到起始位置 ，当前页数-1 × 每页条数+每页条数 得到切割多少条数据
                this.nightData = this.nightAnalysis.slice((this.currentNightPage - 1) * this.nightPageSize, (this.currentNightPage - 1) * this.nightPageSize + val)
            }
        },
        // 点击按钮当前第几页
        handleCurrentNightChange(val) {
            if (this.nightAnalysis && this.nightAnalysis.length > 0) {
                // 当点击按钮的时候，让从 当前页数-1 × 每页条数 得到起始位置 ，当前页数-1 × 每页条数+每页条数 得到切割多少条数据
                this.nightData = this.nightAnalysis.slice((val - 1) * this.nightPageSize, (val - 1) * this.nightPageSize + this.nightPageSize)
            }
        },
        getSleepData() {
            //获取睡眠数据
            this.api2("getSungoData", {
                addTime: this.addTime,
                type: '3',
                patientCode: this.patientCode
            })
                .then(res => {
                    this.sleepData = res.result.sleep
                    this.showSleepStatus = true
                })
                .catch(err => console.log(err));
        },
        getSportData() {
            //获取运动数据
            this.api2("getSungoData", {
                addTime: this.addTime,
                type: '4',
                patientCode: this.patientCode
            })
                .then(res => {
                    this.sportData = res.result.sport
                    this.showSportStatus = true
                })
                .catch(err => console.log(err));
        },
        getHeartData() {
            //获取心率数据
            this.api2("getSungoData", {
                addTime: this.addTime,
                type: '5',
                patientCode: this.patientCode
            })
                .then(res => {
                    this.heartData = res.result.heartRate
                    this.showHeartStatus = true
                })
                .catch(err => console.log(err));
        },
        getCurrentDate() {
            this.addTime = dayjs().format('YYYY-MM-DD')
            this.init()
        },
        getLastedDate() {
            this.api2("getRecentlyDate", {
                patientCode: this.patientCode
            })
                .then(res => {
                    if (res.result) {
                        this.addTime = res.result.recentlyDate
                        this.init()
                    }
                })
                .catch(err => console.log(err));
        },
        getForwardDate() {
            this.addTime = dayjs(this.addTime).add(1, 'day').format('YYYY-MM-DD')
            this.init()
        },
        getBackDate() {
            this.addTime = dayjs(this.addTime).add(-1, 'day').format('YYYY-MM-DD')
            this.init()
        },
        init() {
            this.showEcgStatus = false
            this.showVisceraStatus = false
            this.showSportStatus = false
            this.showHeartStatus = false
            this.showSleepStatus = false
            this.getVisitTimeList()
            this.getEcgData()
            // this.getVisceraData()
            this.getSleepData()
            this.getSportData()
            this.getHeartData()
        }
    },
    created() {
    },
    mounted() {
        this.init()
    },
    beforeDestroy() {
        clearInterval(this.interval);
    }
};
</script>

<style lang="scss" scoped>
.clearfix:after {
    content: "";
    display: block;
    clear: both;
}

.font-bold {
    font-weight: bold;
}

.mb-15 {
    margin-bottom: 15px;
}

.is-on-measure {
    background: green;
    color: #fff !important;
    border-radius: 10px;
    font-size: 12px;
    line-height: 20px;
    padding: 5px;
}

.sleep-block-color {
    display: inline-block;
    width: 20px;
    height: 10px;

    margin-left: 10px;

    &.deep {
        background-color: #0091ea;
    }

    &.light {
        background-color: #86d9e0;
    }

    &.error {
        background-color: #cfa972;
    }

}

.sungo-section {
    .section-title {
        margin: 10px 0;
        padding: 12px;
        border-radius: 2px;
        font-size: 16px;
        color: #D7000F;
        background: rgb(255, 246, 246);
        text-align: center;
    }

    .section-content {
        ::v-deep .ivu-card-head {
            padding: 20px 15px;
        }

        .analysis-list {
            line-height: 28px;
            color: #000;

            span {
                margin-right: 10px;
            }
        }
    }
}


.watch-data-header {
    margin-bottom: 10px;

    .watch-owner-info {
        position: absolute;
        left: 120px;
        top: 0;
        // float: left;
        line-height: 50px;
        font-size: 16px;

        .title {
            color: #000;
            font-size: 18px;
            font-weight: bold;
            margin-right: 25px;
        }

        .calendar {
            position: relative;
            padding-left: 25px;
            margin-right: 20px;

            .ivu-icon {
                position: absolute;
                left: 0;
                top: 50%;
                font-size: 18px;
                margin-top: -11px;
            }
        }

        span {
            color: #515a6e;
            margin-right: 8px;
        }
    }

    .watch-operate {
        //  float: right;

        .operate-item {
            // float: right;
            margin: 0 5px;
        }

        .operate-line {
            //float: right;
            display: inline-block;
            width: 1px;
            height: 26px;
            background-color: #ccc;
            margin: -10px 10px;
        }

        .operate-select-date {
            width: 200px;

            ::v-deep .ivu-input-wrapper {
                margin-bottom: 0;
            }
        }
    }
}

.chart-container {
    height: 100%;
    overflow: hidden;

    .sungo-charts {
        height: calc(100% - 30px);
        overflow-y: auto;
        overflow-x: hidden;
        padding-right: 10px;

        &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
            border-radius: 6px;
        }

        /*定义滚动条轨道内阴影+圆角*/
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            background-color: #fff;
        }

        /*定义滑块内阴影+圆角*/
        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .2);
            background-color: #c8c9cc;
        }
    }
}
</style>
