<template>
	<div class="medicalRecord3">
		<div v-if="record.diseaseName" class="m_r_body">
			<div v-if="pageIndex > 1" @click="setPage(-1)" class="arrow arrow_left">
				<Icon type="ios-arrow-back" />
			</div>
			<div v-if="pageIndex < total" @click="setPage(1)" class="arrow arrow_right">
				<Icon type="ios-arrow-forward" />
			</div>

			<div class="urow">
				<div class="ucol">
					<div class="item">
						<div class="label">主诉</div>
						<div class="value">{{  record.diseaseName  }}
							<span class="additional" v-if="record.chiefComplaintTime">持续: {{  record.chiefComplaintTime  }} 程度: {{
								 record.chiefComplaintDegree 
								}}</span>
						</div>
					</div>
				</div>
				<div class="ucol">
					<div class="item">
						<div class="label">诊断</div>
						<div class="value">{{  record.diagnosticName  }}
							<westernMedicineDiseaseName :diagnosis="record.diagnosticId2" />
							<span class="additional">{{  Disease_Type(record.diagnosticName)  }}</span>
						</div>
					</div>
				</div>
				<div v-if="record.menstruationLastTime" class="ucol">
					<div class="item">
						<div class="label">末次月经</div>
						<div class="value">{{  record.menstruationLastTime  }}</div>
					</div>
				</div>
				<div class="ucol">
					<div class="item">
						<div class="label">治愈情况</div>
						<div class="value">{{  record.cureSituation  }}</div>
					</div>
				</div>
			</div>
			<div class="urow">
				<div class="ucol flex3">
					<div class="item">
						<div class="label">症状</div>
						<div class="value symptom_list">
							<!-- <Badge v-for="ds in record.dsVOList" :key="ds.dsId" :text="ds.isAddDs ? '增' : ''">
								<span class="symptom_name">{{  ds.dsName  }}</span>
							</Badge> -->
							<symptomChanges v-for="ds in record.dsVOList" :key="ds.dsId" :symptomInfo="ds">
								<span class="symptom_name">{{  ds.dsName  }}</span>
							</symptomChanges>

							<i class="line" v-if="record.removeDsVOList && record.removeDsVOList.length"></i>
							<Badge v-for="ds in record.removeDsVOList" :key="ds.dsId" class="symptom_remove" text="减">
								<span class="symptom_name">{{  ds.dsName  }}</span>
							</Badge>
						</div>
					</div>
					<div class="item">
						<div class="label">处方</div>
						<div class="value drug_body">
							<drugsFormat :list="record.drugList" :size="3"></drugsFormat>
							<div class="quantity">x{{  record.quantity  }}</div>
						</div>
					</div>
					<div class="item">
						<div class="label">病因病机</div>
						<div class="value">
							<!-- <etiological-analysis-table :analysisData="list" /> -->
							<etiological-analysis-table :analysisData="record.recordAnalysisVOList" />
						</div>
					</div>
				</div>
				<div class="ucol">
					<div class="item">
						<div class="label">患者自述</div>
						<div class="value">{{  record.chiefComplaint  }}</div>
					</div>
					<div class="item">
						<div class="label">病历图片</div>
						<div class="value">
							<div ref="images" class="bl_pic" v-if="picList.length">
								<div @click="clickPic" class="pic_item" v-for="(pic_item, p_i) in picList" :key="p_i">
									<img :src="cloudPic + '/medical/' + pic_item" alt />
								</div>
							</div>
							<div id="images" ref="images" v-else class="bl_pic lt">
								<div class="pic_item" v-for="(item, index) in 1" :key="index">
									<img @click="clickPic" src="@/assets/images/empty.jpg" alt />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<empty v-else />
		<div class="footer">
			<div class="text" v-if="record.diseaseName">
				医馆：{{  record.hospitalName  }} 医生：{{  record.doctorName  }} 就诊时间：{{  record.visitDateTime  }}
			</div>
			<div v-else></div>
			<Page :current="pageIndex" :total="total" :page-size="1" size="small" @on-change="pageChange" />
		</div>
	</div>
</template>

<script>
import viewer from "viewerjs";
import EtiologicalAnalysisTable from './etiologicalAnalysisTable.vue'
import { mapGetters } from 'vuex';

export default {
	components: {
		EtiologicalAnalysisTable
	},
	computed: {
		...mapGetters([
			"dicts",
		]),
		picList() {
			return this.record.picUrl ? this.record.picUrl.split(",").filter(i => i) : [];
		}
	},
	props: {
		patientCode: {
			type: String,
			default: ""
		},
	},
	watch: {
		patientCode(newValue, oldValue) {
			this.init();
		}
	},
	data() {
		return {
			record: {},
			total: 0,
			pageIndex: 1,
			picView: null,
			list: [
				{
					"analysisText": null,
					"etiologyId": 213,
					"etiologyName": "温热邪气，直入气分",
					"list": [
						{
							"dialecticsId": 114,
							"dialecticsName": "气分证",
							"pathogenesisId": 543,
							"pathogenesisName": "实热内结"
						}
					],
					"symptomId": 30288,
					"symptomName": "点刺舌"
				},
				{
					"analysisText": null,
					"etiologyId": 82,
					"etiologyName": "久病失养其他脏腑病变的影响",
					"list": [
						{
							"dialecticsId": 77,
							"dialecticsName": "胃阳虚证",
							"pathogenesisId": 353,
							"pathogenesisName": "虚寒内生，寒凝气机"
						}
					],
					"symptomId": 50375,
					"symptomName": "胃脘畏寒"
				},
				{
					"analysisText": null,
					"etiologyId": 212,
					"etiologyName": "温热火邪，灼伤心阴",
					"list": [
						{
							"dialecticsId": 35,
							"dialecticsName": "心阴虚证",
							"pathogenesisId": 130,
							"pathogenesisName": "心神失养，虚火扰神，神不守舍"
						}
					],
					"symptomId": 30145,
					"symptomName": "不寐"
				},
				{
					"analysisText": null,
					"etiologyId": 48,
					"etiologyName": "过用苦寒、泻下之品",
					"list": [
						{
							"dialecticsId": 77,
							"dialecticsName": "胃阳虚证",
							"pathogenesisId": 353,
							"pathogenesisName": "虚寒内生，寒凝气机"
						}
					],
					"symptomId": 50375,
					"symptomName": "胃脘畏寒"
				},
				{
					"analysisText": null,
					"etiologyId": 247,
					"etiologyName": "饮食不节",
					"list": [
						{
							"dialecticsId": 76,
							"dialecticsName": "胃气虚证",
							"pathogenesisId": 349,
							"pathogenesisName": "胃气亏虚，气滞中焦"
						},
						{
							"dialecticsId": 93,
							"dialecticsName": "心脾气血虚证",
							"pathogenesisId": 41,
							"pathogenesisName": "脾失健运"
						},
						{
							"dialecticsId": 95,
							"dialecticsName": "脾肺气虚证",
							"pathogenesisId": 445,
							"pathogenesisName": "脾虚运化失职"
						},
						{
							"dialecticsId": 101,
							"dialecticsName": "肝郁脾虚证",
							"pathogenesisId": 478,
							"pathogenesisName": "肝气犯脾"
						}
					],
					"symptomId": 44,
					"symptomName": "脘腹胀满"
				},
				{
					"analysisText": null,
					"etiologyId": 28,
					"etiologyName": "肝肾等脏阴亏，累及于心",
					"list": [
						{
							"dialecticsId": 35,
							"dialecticsName": "心阴虚证",
							"pathogenesisId": 131,
							"pathogenesisName": "阴虚失润内热"
						}
					],
					"symptomId": 30446,
					"symptomName": "手足心热"
				},
				{
					"analysisText": null,
					"etiologyId": 130,
					"etiologyName": "情志不遂",
					"list": [
						{
							"dialecticsId": 89,
							"dialecticsName": "胆郁痰扰证",
							"pathogenesisId": 418,
							"pathogenesisName": "痰热内扰心神"
						}
					],
					"symptomId": 30257,
					"symptomName": "五心烦热"
				},
				{
					"analysisText": null,
					"etiologyId": 111,
					"etiologyName": "脾胃素弱，阳气自衰",
					"list": [
						{
							"dialecticsId": 77,
							"dialecticsName": "胃阳虚证",
							"pathogenesisId": 353,
							"pathogenesisName": "虚寒内生，寒凝气机"
						}
					],
					"symptomId": 50375,
					"symptomName": "胃脘畏寒"
				},
				{
					"analysisText": null,
					"etiologyId": 180,
					"etiologyName": "他经传变",
					"list": [
						{
							"dialecticsId": 112,
							"dialecticsName": "厥阴病证",
							"pathogenesisId": 527,
							"pathogenesisName": "邪入厥阴，上热下寒"
						}
					],
					"symptomId": 30431,
					"symptomName": "消渴（糖尿病）"
				},
				{
					"analysisText": null,
					"etiologyId": 28,
					"etiologyName": "肝肾等脏阴亏，累及于心",
					"list": [
						{
							"dialecticsId": 35,
							"dialecticsName": "心阴虚证",
							"pathogenesisId": 130,
							"pathogenesisName": "心神失养，虚火扰神，神不守舍"
						}
					],
					"symptomId": 30145,
					"symptomName": "不寐"
				},
				{
					"analysisText": null,
					"etiologyId": 216,
					"etiologyName": "误用发汗津液耗损",
					"list": [
						{
							"dialecticsId": 85,
							"dialecticsName": "肠热腑实证",
							"pathogenesisId": 399,
							"pathogenesisName": "里热熏蒸，迫津外泄"
						}
					],
					"symptomId": 30160,
					"symptomName": "产后大汗"
				},
				{
					"analysisText": null,
					"etiologyId": 28,
					"etiologyName": "肝肾等脏阴亏，累及于心",
					"list": [
						{
							"dialecticsId": 35,
							"dialecticsName": "心阴虚证",
							"pathogenesisId": 130,
							"pathogenesisName": "心神失养，虚火扰神，神不守舍"
						}
					],
					"symptomId": 30257,
					"symptomName": "五心烦热"
				},
				{
					"analysisText": null,
					"etiologyId": 244,
					"etiologyName": "阴阳衰惫",
					"list": [
						{
							"dialecticsId": 111,
							"dialecticsName": "少阴病证",
							"pathogenesisId": 526,
							"pathogenesisName": "阴虚阳亢"
						}
					],
					"symptomId": 30523,
					"symptomName": "舌尖红"
				},
				{
					"analysisText": null,
					"etiologyId": 229,
					"etiologyName": "心肾阴虚阳亢",
					"list": [
						{
							"dialecticsId": 111,
							"dialecticsName": "少阴病证",
							"pathogenesisId": 526,
							"pathogenesisName": "阴虚阳亢"
						}
					],
					"symptomId": 30523,
					"symptomName": "舌尖红"
				},
				{
					"analysisText": null,
					"etiologyId": 196,
					"etiologyName": "外感火热温邪（高温劳作、感受温热、火热烧灼）致阳热内盛",
					"list": [
						{
							"dialecticsId": 6,
							"dialecticsName": "火热证",
							"pathogenesisId": 23,
							"pathogenesisName": "阳热之气过盛，迫津外泄"
						}
					],
					"symptomId": 30160,
					"symptomName": "产后大汗"
				},
				{
					"analysisText": null,
					"etiologyId": 206,
					"etiologyName": "卫分证不解，邪传入里",
					"list": [
						{
							"dialecticsId": 114,
							"dialecticsName": "气分证",
							"pathogenesisId": 543,
							"pathogenesisName": "实热内结"
						}
					],
					"symptomId": 30288,
					"symptomName": "点刺舌"
				},
				{
					"analysisText": null,
					"etiologyId": 99,
					"etiologyName": "慢性失血",
					"list": [
						{
							"dialecticsId": 93,
							"dialecticsName": "心脾气血虚证",
							"pathogenesisId": 41,
							"pathogenesisName": "脾失健运"
						}
					],
					"symptomId": 44,
					"symptomName": "脘腹胀满"
				},
				{
					"analysisText": null,
					"etiologyId": 143,
					"etiologyName": "伤脾（寒湿侵袭，饮食不节，劳倦过度，忧思日久，吐泻太过）",
					"list": [
						{
							"dialecticsId": 55,
							"dialecticsName": "脾气虚证",
							"pathogenesisId": 232,
							"pathogenesisName": "水湿下注"
						}
					],
					"symptomId": 5,
					"symptomName": "大便溏/大便不实"
				},
				{
					"analysisText": null,
					"etiologyId": 220,
					"etiologyName": "邪热炽盛汗出过多",
					"list": [
						{
							"dialecticsId": 85,
							"dialecticsName": "肠热腑实证",
							"pathogenesisId": 543,
							"pathogenesisName": "实热内结"
						}
					],
					"symptomId": 30288,
					"symptomName": "点刺舌"
				},
				{
					"analysisText": null,
					"etiologyId": 212,
					"etiologyName": "温热火邪，灼伤心阴",
					"list": [
						{
							"dialecticsId": 35,
							"dialecticsName": "心阴虚证",
							"pathogenesisId": 131,
							"pathogenesisName": "阴虚失润内热"
						}
					],
					"symptomId": 30446,
					"symptomName": "手足心热"
				},
				{
					"analysisText": null,
					"etiologyId": 10,
					"etiologyName": "从阳化热",
					"list": [
						{
							"dialecticsId": 111,
							"dialecticsName": "少阴病证",
							"pathogenesisId": 526,
							"pathogenesisName": "阴虚阳亢"
						}
					],
					"symptomId": 30523,
					"symptomName": "舌尖红"
				}
			],
		}
	},
	methods: {
		setPage(nb) {
			if (this.pageIndex + nb >= 1 && this.pageIndex + nb <= this.total) {
				this.pageIndex += nb;
				this.getData();
			}
		},
		Disease_Type(label) {
			let list = this.dicts["Disease_Type"] || [];
			let obj = list.find(i => i.label == label);
			if (obj) {
				return  obj.string5 ? `疗程：${obj.string5 + obj.string5Unit + "~" + obj.string6 + obj.string6Unit}` : ""
			} else {
				return '';
			}
		},
		pageChange(index) {
			this.pageIndex = index;
			this.getData();
		},
		clickPic() {
			if (this.picView) this.picView.destroy();
			this.picView = new viewer(this.$refs["images"]);
		},
		getData() {
			this.api2("getPatientMedicalRecord", {
				pageIndex: this.pageIndex, //	页码
				patientCode: this.patientCode, //	患者code
				// patientCode: '1000481', //	患者code
				// patientCode: '3065764', //	患者code
			}).then(res => {
				this.record = res.result[0] || {};
				this.total = res.total;
				this.$emit("updateInfo", this.record);
			})
		},
		init() {
			this.pageIndex = 1;
			this.getData();
		},
	},
	created() {
		this.init();
	},
	mounted() {
		//  键盘事件
		document.onkeydown = (event) => {
			if (this.picView && this.picView.viewed == true) {
				return;
			}
			if (this.focusState) return;
			var e = event || window.event || arguments.callee.caller.arguments[0];
			if (e && e.keyCode == 37) { // 按 左
				this.setPage(-1);
			}
			if (e && e.keyCode == 39) { // 按  右 
				this.setPage(1);
			}
		};
	},
}
</script>

<style lang="scss">
.medicalRecord3 {
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}


.medicalRecord3 .m_r_body {
	padding: 20px 50px;
	flex: 1;
	overflow-y: auto;
}

.medicalRecord3 .urow {
	display: flex;
	overflow-y: auto;
}

.medicalRecord3 .urow .ucol {
	flex: 1;
}

.medicalRecord3 .label {
	color: #999;
	margin-bottom: 5px;
}

.medicalRecord3 .urow .ucol.flex3 {
	flex: 3;
	margin-right: 15px;
}

.medicalRecord3 .item {
	margin-bottom: 15px;
}

.medicalRecord3 .footer {
	font-size: 12px;
	display: flex;
	justify-content: space-between;
	padding: 5px 50px;
}

.medicalRecord3 .additional {
	background: #fffce0;
	font-size: 12px;
	display: inline-block;
	padding: 2px 8px;
}


.medicalRecord3 {
	position: relative;

	.arrow {
		width: 40px;
		height: 100%;
		position: absolute;
		top: 0;
		font-size: 25px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}

	.arrow_left:hover {
		background: linear-gradient(90deg, #eee, #fff)
	}

	.arrow_right:hover {
		background: linear-gradient(90deg, #fff, #eee)
	}

	.arrow_left {
		left: 0;
	}

	.arrow_right {
		right: 0;
	}

	.symptom_list {
		display: flex;
		flex-wrap: wrap;
	}

	.symptom_name {
		display: inline-block;
		height: 30px;
		line-height: 30px;
		padding: 0 10px;
		border-radius: 3px;
		font-weight: 400;
		font-size: 14px;
		background: #f2e6d4;
		margin: 0 10px 10px 0;
		cursor: pointer;

		&.isError {
			background: #ffd4d3;
		}
	}

	.line {
		display: inline-block;
		height: 30px;
		margin: 0 15px 0 5px;
		border-left: 1px solid #ccc;
	}

	.symptom_remove {

		.ivu-badge-count,
		span {
			background: #eee;
		}

		.ivu-badge-count {
			color: #333;
		}
	}

	.ivu-badge-count {
		height: 16px;
		line-height: 14px;
		font-size: 12px;
		padding: 0 2px;
		right: 12px;
		top: -8px;
	}

	.drug_body {
		overflow: hidden;
	}

	.drugs_format {
		display: inline-block;
		float: left;
	}

	.quantity {
		line-height: 33px;
		font-size: 30px;
		color: red;
		padding-left: 50px;
		float: left;
	}

	.pic_item {
		width: 120px;
		height: 120px;
		float: left;
		margin: 0 10px 10px 0;

		border: 1px solid #ddd;
		padding: 3px;

		img {
			width: 100%;
			height: 100%;
		}
	}
}
</style>