<template>
	<!-- 处方录入 -->
	<div class="prescription_entry">
		<patient-info-1 v-if="showInfo" :pageType="pageType" :info="info" />
		<div class="prescription_entry_body">
			<prescription-input :pageType="pageType" :info="info" :quoteDurgList="quoteDurgList" />
		</div>
	</div>
</template>

<script>
import patientInfo1 from './patientInfo/patientInfo1.vue'

import { mapGetters } from "vuex";
import PrescriptionInput from './prescriptionInput.vue';
export default {
	components: { patientInfo1, PrescriptionInput },
	props: {
		//页面类型  true:前端医生 false:后端医生
		pageType: {
			type: Boolean,
			default: false
		},
		info: {
			type: Object,
			default: () => { return {} }
		},
		quoteDurgList: {
			type: Array,
			default: () => []
		}
	},
	watch: {
		info(newValue, oldValue) {
			this.init();	
		}
	},
	data() {
		return {
			showInfo: false,
		}
	},
	computed: {
		...mapGetters([
		]),
	},
	methods: {
		setAcupoint() {
			this.$store.commit("acupoint", {
				// diagnosticName: this.diagnosis2.DiseaseName,
				diagnosticId: this.info.diagnosticId2,
				symptomsIds: this.info.symptom.map(i => i.dsId).join("|")
			});
		},
		init() {
			this.setAcupoint();
		}
	},
	created() {
		this.init();
		this.showInfo = true;
	},

}
</script>

<style lang="scss">
.prescription_entry {
	position: relative;
	overflow: hidden;
	height: 100%;
	width: 100%;
	background: #fff;
	display: flex;
	flex-direction: column;

	.prescription_entry_body {
		height: 100%;
		overflow: hidden;
	}
}
</style>