<template>
	<!-- 上次症状选择  -->
	<div class="mk_popup last_symptom" v-if="value" :class="{ show: value }">
		<template v-if="dsIds.length">
			<div class="mk_p_title">
				<span>上次症状:</span>
				<Checkbox @on-change="selectAllon" v-model="selectAll">全选</Checkbox>
			</div>
			<div class="mk_p_body selectSymptom">
				<template v-for="(bz, index) in bz_list">
					<template v-for="(bz_item, b_index) in bz.symptomSubTypeList_x">
						<div v-for="(fxk, f_index) in bz_item.symptomList_x" class="section"
							:key="index + '_' + b_index + '_' + f_index">
							<div class="item_bz" :class="{ disabled: bz_item.exclude.id }">
								<div class="bz_title">
									<div @click="jump(bz_item.exclude.id, 'group')" v-if="bz_item.exclude.id" class="exclude_ts">
										<div class="nr">与大类目"{{ bz_item.exclude.name }}"互斥</div>
									</div>
								</div>
								<div class="bz_xx">
									<span class="symptomItem" :class="'id_' + fxk.dsId">
										<div @click="jump(fxk.exclude.id)" v-if="fxk.exclude.id" class="exclude_ts">
											<div class="nr">与症状"{{ fxk.exclude.name }}"互斥</div>
										</div>
										<Checkbox :class="
											bz_item.isnecessary && !f_index
												? 'hint_heidicon'
												: ''
										" :disabled="!!fxk.exclude.id"
											@on-change="clickItem(fxk, bz_item.isnecessary && !f_index ? 'hint_' + index + '_' + b_index : '')"
											:name="'id_' + fxk.dsId" v-model="fxk.Other">
											{{ fxk.dsName }}
											<img class="isnew" v-if="fxk.isNew" src="@/assets/images/new3.png" />
										</Checkbox>
									</span>
								</div>
							</div>
						</div>
					</template>
				</template>
			</div>
		</template>
		<div v-else class="mk_p_body">
			<empty />
		</div>
		<div class="mk_p_foot">
			<span class="btn" @click="close">关闭</span>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	props: {
		value: {
			type: Boolean,
			default: false
		},
		patientCode: {
			type: String,
			default: ''
		}
	},
	watch: {
		value(value) {
			if (value) {
				this.selectAll = false;
			}
		}
	},
	data() {
		return {
			selectAll: false,
			sList: [],
		}
	},
	computed: {
		...mapGetters([
			"u_bz_list",
			"patientLastSymptom",
		]),
		dsIds() {
			return this.patientLastSymptom.lastSymptom.map(i => i.dsId);
		},
		bz_list() {
			this.sList = [];
			let list = this.u_bz_list.filter(i => {
				i.symptomSubTypeList_x = i.symptomSubTypeList.filter(j => {
					j.symptomList_x = j.symptomList.filter(k =>
						this.dsIds.includes(k.dsId + "")
					);
					this.sList.push(...j.symptomList_x);
					return j.symptomList_x.length;
				});
				return i.symptomSubTypeList_x.length;
			});
			return list;
		},
	},
	methods: {
		close() {
			this.$emit('input', false)
		},
		selectAllon(e) {
			if (e) {
				this.$emit('selectAll', this.dsIds)
			} else {
				this.$emit('removeSymptom', this.dsIds)
			}
		},
		jump(id, type) {
			this.$emit('jump', { id, type })
		},
		clickItem(id, type) {
			this.$emit('clickItem', {
				id, type
			})
			if (this.sList.every(i => i.Other)) {
				this.selectAll = true;
			} else {
				this.selectAll = false;
			}
		},
	},
	created() {
	},
}
</script>

<style lang="scss" scoped>
</style>