<template>
  <Modal footer-hide class="notice_modal" width="60%" v-model="modal1" title="新功能" @on-cancel="cancel">
    <!-- <div v-for="item in 100">1111</div> -->
    <li v-for="(item, index) in list" class="uplog-item" :class="{ 'latest': !item.isRead }" :key="index">
      <div class="uplog-item-timeline">{{ item.noticeTime }}</div>
      <div class="uplog-item-titleline">{{ item.noticeTitle }}</div>
      <div class="uplog-item-des" v-html="item.noticeContent"></div>
    </li>
    <Spin v-if="loading" fix>
      <Icon type="ios-loading" size="30" class="demo-spin-icon-load"></Icon>
      <div>加载中</div>
    </Spin>
  </Modal>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    noticeType: {
      type: String,
      default: 2
    },
    userId: {
      type: String,
      default: ""
    },
    scrollToText: {
      // 定位到指定的标题内容上
      type: String,
      default: ""
    }
  },
  watch: {
    value(newValue, oldValue) {
      this.modal1 = this.value;
      if (!newValue) {
        //关闭弹框后更新已读
        this.updataNotice();
      } else {
        this.scrollTo();
      }
    }
  },
  data() {
    return {
      loading: true,
      modal1: false,
      list: [],
      ids: [],
      noticeListRes: {}
    };
  },
  computed: {
    ...mapGetters([
      "userInfo" // 挂号窗口开关
    ])
  },
  methods: {
    cancel() {
      this.$emit("input", false);
    },
    getData() {
      let getHospitalNoticeList = sessionStorage.getItem(
        "getHospitalNoticeList"
      );
      if (getHospitalNoticeList) {
        this.loading = false;
        this.setList(JSON.parse(getHospitalNoticeList));
      } else {
        this.loading = true;
        let obj = {
          userId: this.userId,
          noticeType: this.noticeType,
          pageIndex: 1,
          pageSize: 100
        };
        return this.api2("getHospitalNoticeList", obj).then(res => {
          this.loading = false;
          this.setList(res);
        });
      }
    },
    setList(res) {
      sessionStorage.setItem("getHospitalNoticeList", JSON.stringify(res));
      this.noticeListRes = res;
      this.list = res.result;
      this.ids = this.list.filter(i => !i.isRead).map(i => i.id);
      if (this.ids.length) this.$emit("input", true); //如果有新消息自动打开弹框
    },
    updataNotice(id) {
      if (!this.ids.length) return;
      let arr = this.ids.map(i => {
        return {
          id: i,
          userId: this.userId
        };
      });
      this.api2("updateHospitalNoticeIsRead", arr).then(res => {
        this.list.forEach(i => (i.isRead = 1));
        // 更新缓存数据
        sessionStorage.setItem(
          "getHospitalNoticeList",
          JSON.stringify(this.noticeListRes)
        );
      });
    },
    async scrollTo() {
      if (this.scrollToText) {
        await this.getData();
        setTimeout(() => {
          let node = $(this.$el).find(".uplog-item-titleline");
          let item = [...node].find(
            i => i.innerHTML.indexOf(this.scrollToText) != -1
          );
          if (item) {
            let top = $(item.parentNode).position().top;
            let mbody = $(this.$el).find(".ivu-modal-body");
            let oldtop = mbody.scrollTop();
            mbody.scrollTop(oldtop + top - 40);
          }
        }, 500);
      }
    }
  },
  created() {
    this.modal1 = this.value;
    if (!this.scrollToText) {
      setTimeout(() => {
        this.getData();
      }, 2000);
    }
  },
  mounted() { }
};
</script>

<style>
.uplog-item-des img {
  max-width: 100%;
}

.uplog {
  width: 80%;
  height: 60%;
  min-height: 608px;
  max-width: 474px;
  z-index: 0;
  opacity: 0;
  box-sizing: border-box;
  border-radius: 2px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  color: #4a4a4a;
  overflow: hidden;
  min-width: 320px;
  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  -ms-transition: all 0.15s ease-out;
  -o-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}

.uplog a {
  color: #4386f5;
}

.uplog-header {
  line-height: 30px;
  font-size: 24px;
  padding: 20px 40px;
  border-bottom: 1px solid #bbb;
  color: #333;
}

.uplog-header .close-btn {
  display: inline-block;
  font-size: 24px;
  position: absolute;
  right: 20px;
  top: 15px;
  color: #666;
  -webkit-transition: color 0.3s;
  -moz-transition: color 0.3s;
  -ms-transition: color 0.3s;
  -o-transition: color 0.3s;
  transition: color 0.3s;
}

.uplog-header .close-btn:hover {
  cursor: pointer;
  color: #000;
}

.uplog-content {
  height: calc(100% - 112px);
  height: -webkit-calc(100% - 112px);
  min-height: 500px;
  overflow-y: scroll;
}

.uplog-list {
  padding: 0 20px;
}

.uplog-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  text-align: justify;
  padding: 20px 0;
  position: relative;
}

.emojis {
  cursor: pointer;
  transition: all 0.2s;
  transform: scale(1);
}

.emojis:hover {
  transform: scale(1.8);
}

.uplog-content:hover .emojis {
  display: inline-block;
}

.uplog-item:last-child {
  border-bottom: 0;
}

.uplog-item.latest:after {
  content: "";
  display: block;
  position: absolute;
  left: -20px;
  top: 54px;
  width: 8px;
  height: 8px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: #69f;
}

.uplog-item-timeline {
  font-size: 14px;
  color: #9b9b9b;
}

.uplog-item-titleline {
  margin: 5px 0 6px;
  font-size: 20px;
  color: #555;
}

.uplog-item-des {
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 22px;
}

.uplog-item-des b {
  color: #444;
}

.uplog-item-imgcon {
  margin-top: 20px;
  display: block;
}

.uplog-item-imgcon>img {
  display: block;
  max-width: 100%;
}

.uplog-footer {
  border-top: 1px solid #bbb;
  padding: 0 40px;
  line-height: 60px;
}

.uplog-footer .readall {
  color: #69f;
  font-size: 12px;
}

.notice_modal .ivu-modal-body {
  overflow: auto;
  height: 100%;
}

.notice_modal .ivu-modal-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.notice_modal .ivu-modal {
  height: 80%;
  overflow: hidden;
  position: unset;
  margin-bottom: 0;
}

.notice_modal .ivu-modal .ivu-modal-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>