<template>
  <div class="showQRCode">
    <div class="text" @click="showFun">
      <span v-if="text">{{ text }}</span>
      <span v-else class="tip">请让患者扫码加入服药打卡群</span>
    </div>

    <Modal
      footer-hide
      class="showQRCode"
      v-model="show"
      title=""
      width="620"
      @on-cancel="close"
    >
      <div>
        <div class="patient-info">
          <span class="name">{{ info.patientName }}</span>
          <span class="sex"
            >{{ info.sex }}
            <Icon v-if="info.sex == '男'" type="md-male" />
            <Icon v-else type="md-female"
          /></span>
          <span class="age">
            <template v-if="info.patientAge > 1"
              >{{ info.patientAge }}岁</template
            >
            <template v-else>
              <template v-if="info.birthMonth">{{
                info.birthMonth + "个月"
              }}</template>
            </template>
          </span>
        </div>
        <div class="diagnosis-info">
          <div class="item">
            <span>主诉：{{ diagnosisName }}</span>
            <span class="qt">
              <span class="additional" v-if="diagnosisData.chiefComplaintTime">
                持续:
                {{ diagnosisData.chiefComplaintTime }}
                <i v-if="diagnosisData.chiefComplaintTimeType !== ''">
                  {{
                    common.idToLabel(
                      chiefComplaintTimeTypeList,
                      diagnosisData.chiefComplaintTimeType
                    )
                  }}
                </i>
                <i v-if="diagnosisData.chiefComplaintDegree">
                  程度:
                  {{
                    common.idToLabel(
                      CHIEFCOMPLAINT_DEGREE,
                      diagnosisData.chiefComplaintDegree
                    )
                  }}
                </i>
              </span>
            </span>
          </div>
          <div class="item">
            <span>诊断：{{ diagnosis2Str }}</span>
            <span class="qt" v-if="diseaseType">{{ diseaseType }}</span>
          </div>
        </div>
        <div class="qr-code">
          <img v-if="codeUrl" :src="cloudPic + '/other/' + codeUrl" alt="" />
          <img v-else src="@/assets/images/empty.jpg" alt="" />
        </div>

        <!-- 暂时关闭 -->
        <!-- <Form
          ref="formValidate"
          :model="formValidate"
          :rules="ruleValidate"
          label-position="top"
        >
          <FormItem label="Name" prop="value1">
            <RadioGroup v-model="formValidate.value1">
              <Radio
                :label="item.value"
                v-for="(item, index) in radios"
                :key="index"
                >{{ item.label }}</Radio
              >
            </RadioGroup>
          </FormItem>
        </Form>
        <div class="fooder">
          <Button type="primary" @click="handleSubmit('formValidate')"
            >我已邀约</Button
          >
        </div> -->

        <div class="fooder">
          <Button type="primary" @click="submit">我已邀约</Button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
    info: {
      type: Object,
      default: () => {
        return {};
      },
    },
    diagnosisData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    diseaseType: {
      type: String,
      default: "",
    },
    diagnosis2: {
      type: String,
      default: "",
    },
    symptomList: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    show(newValue, oldValue) {
      if (newValue) {
        if (this.value) this.formValidate.value1 = this.value;
        this.getData();
      }
    },
  },
  computed: {
    ...mapGetters([
      "diseaseInfos2",
      "chiefComplaintTimeTypeList",
      "CHIEFCOMPLAINT_DEGREE",
    ]),
    diagnosis2Str() {
      let item = this.diseaseInfos2.find((i) => i.value == this.diagnosis2);
      return item ? item.label : "";
    },
    text() {
      let item = this.radios.find((i) => i.value == this.value);
      return item ? item.label : "";
    },
    diagnosisName() {
      if (this.diagnosisData.diagnosis1) {
        let item = this.symptomList.find(
          (i) => i.dsId == this.diagnosisData.diagnosis1
        );
        return item ? item.dsName : "";
      } else {
        return "";
      }
    },
  },
  data() {
    return {
      codeUrl: "",
      radios: [
        {
          label: "我已邀约",
          value: "1",
        },
        {
          label: "之前加过",
          value: "2",
        },
      ],
      show: false,
      formValidate: {
        value1: "1",
      },
      ruleValidate: {
        value1: [
          {
            required: true,
            message: "请选择是否加过群",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    showFun() {
      if (this.diagnosis2) {
        this.show = true;
      } else {
        this.$Message.warning("请先选择诊断");
      }
    },
    getData() {
      this.$store.commit("openSpin");
      this.api2("getQrCodeByDiseaseId", {
        diseaseId: this.diagnosis2,
      }).then((res) => {
        this.$store.commit("closeSpin");
        this.codeUrl = res.result.qrCode;
      });
    },
    close() {
      this.show = false;
    },
    submit() {
      this.$emit("input", this.formValidate.value1);
      this.close();
    },
    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.$emit("input", this.formValidate.value1);
          this.close();
        } else {
          this.$Message.warning("请选择是否加过群");
        }
      });
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
  },
  created() {},
};
</script>

<style lang="scss" >
.showQRCode {
  .text {
    cursor: pointer;
  }
  .tip {
    color: #ccc;
  }
  .patient-info {
    font-size: 16px;
    margin-bottom: 15px;
    span {
      margin-right: 20px;
    }
    .name {
      font-size: 26px;
      font-weight: bold;
    }
  }

  .diagnosis-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    i {
      font-style: inherit;
    }
    .qt {
      font-size: 12px;
      padding: 1px 3px;
      background: #fffce088;
      margin-left: 5px;
    }
  }
  .qr-code {
    text-align: center;
    margin-bottom: 30px;
    img {
      width: 240px;
      box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.2);
      border-radius: 3px;
    }
  }
  .ivu-form {
    width: 240px;
    margin: 0 auto;
    padding-bottom: 15px;
    .ivu-form-item-error-tip {
      width: 100%;
      text-align: center;
    }
    .ivu-radio-group {
      display: flex;
      justify-content: space-between;
      .ivu-radio-wrapper {
        padding-right: 0;
        margin-right: 0;
        font-weight: bold;
      }
    }
  }

  .fooder {
    text-align: center;
    .ivu-btn {
      padding: 0 50px;
    }
    padding-bottom: 15px;
  }

  .ivu-form-item-label {
    display: none;
  }
}
</style>