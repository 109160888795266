<template>
	<Table class="table" :span-method="handleSpan" border :columns="column" :data="listFilter">
		<template slot-scope="{ row }" slot="pathogenesisName">
			<span v-if="!row.empty">{{ row.pathogenesisName }}</span>
		</template>
		<template slot-scope="{ row }" slot="dialecticsName">
			<span v-if="!row.empty">{{ row.dialecticsName }}</span>
		</template>

		<template slot-scope="{ row }" slot="index">
			<Icon v-if="row.active" class="jt" type="md-arrow-dropright" />
			<span v-else>{{ row.index }}</span>
		</template>
		<template slot-scope="{ row }" slot="etiologyName">
			<div class="symptom_body" :class="'symptom_body_' + row.index">
				<div class="symptom_text" v-if="!row.empty">
					<span>{{ row.etiologyName }}</span>
				</div>
				<div class="symptom_text symptom_text_empty" :class="{ symptom_text_error: row.error }" v-else>请选择
				</div>
			</div>
		</template>
	</Table>
</template>

<script>
export default {
	props: {
		analysisData: {
			type: Array,
			default: () => []
		},
	},
	data() {
		return {
			listFilter: [],
			etiologyList: [],
			list: [],
			marge: {}, //合并表格数据
			column: [
				{
					title: "序",
					width: 40,
					align: "center",
					key: "index",
					slot: "index",
					// fixed: "left"
				},
				{
					title: "症状",
					width: 160,
					key: "symptomName",
					// fixed: "left"
				},
				{
					className: "symptom",
					title: "病因",
					width: 240,
					key: "etiologyName",
					slot: "etiologyName"
				},
				{
					title: "病机",
					minWidth: 200,
					key: "pathogenesisName",
					slot: "pathogenesisName"
				},
				{
					title: "辨证",
					width: 120,
					key: "dialecticsName",
					slot: "dialecticsName"
				}
			],
		}
	},
	watch: {
		analysisData(newValue, oldValue) {
			this.generateData();
		}
	},
	methods: {
		generateData() {
			let newArr = [];
			this.etiologyList = []; //病因列表
			let arr = [];
			let symptomList = [];
			this.analysisData.forEach(i => {
				if (!symptomList.find(j => j.symptomId == i.symptomId)) {
					symptomList.push({
						symptomId: i.symptomId,
						symptomName: i.symptomName
					});
				}
				this.etiologyList.push({
					symptomId: i.symptomId,
					symptomName: i.symptomName,
					etiologyId: i.etiologyId,
					etiologyName: i.etiologyName,
					active: true,
				});
				arr.push(i);

				// i.list.forEach(j => {
				// 	j.symptomId = i.symptomId;
				// 	j.symptomName = i.symptomName;
				// 	j.etiologyId = i.etiologyId;
				// 	j.etiologyName = i.etiologyName;
				// 	arr.push(j);
				// });
			});

			symptomList.forEach((j, j_index) => {
				j_index++;
				let arr2 = arr.filter(i => i.symptomId == j.symptomId);
				if (arr2.length) {
					arr2.forEach(i => {
						i.editNow = false; //是否处理编辑状态
						i.active = false; //是否选中展示
						i.id = `${j_index}_${i.etiologyId}_${i.pathogenesisId}`;
						i.index = j_index; //序号
						i.empty = false;
						i.error = false;
						newArr.push(i);
					});
				} else {
					j.editNow = false; //是否处理编辑状态
					j.active = false; //是否选中展示
					j.id = j_index; //序号
					j.index = j_index; //序号
					i.error = false;
					i.empty = false;
					newArr.push(j);
				}
			});
			this.list = newArr;
			this.updateList();
		},
		handleSpan({ row, column, rowIndex, columnIndex }) {
			let key = `${rowIndex}-${columnIndex}`;
			return this.marge[key];
		},
		updateList() {
			let symptomNames = [];
			let ids = this.etiologyList
				.filter(i => i.active)
				.map(i => {
					symptomNames.push(i.symptomName);
					return i.etiologyId + "_" + i.symptomName;
				});

			this.listFilter = [];
			this.list.forEach(i => {
				if (ids.includes(i.etiologyId + "_" + i.symptomName)) {
					i.empty = false;
					this.listFilter.push(i);
				} else if (!symptomNames.includes(i.symptomName)) {
					if (!this.listFilter.find(j => j.symptomId == i.symptomId)) {
						i.empty = true;
						this.listFilter.push(i);
					}
				}
			});
			this.initMarge();
		},
		// 计数单元格合并数据
		initMarge() {
			let m1 = this.setMarge("symptomName", [0, 1]);
			let m2 = this.setMarge("etiologyName", [2], "symptomName");
			this.marge = Object.assign(m1, m2);
		},
		// 计数单元格合并方法
		setMarge(field, column, field2) {
			let obj = {};
			this.listFilter.forEach((i, index) => {
				if (i[field]) {
					let key = i[field] + "_" + (i[field2] || "");
					if (obj.hasOwnProperty(key)) {
						obj[key]++;
					} else {
						obj[key] = 1;
					}
				} else {
					//占位符
					obj["zwf_" + index] = 1;
				}
			});
			let resArr = [];
			for (const key in obj) {
				if (obj.hasOwnProperty(key)) {
					const i = obj[key];
					resArr.push([i, 1]);
					for (let j = 1; j < i; j++) {
						resArr.push([0, 1]);
					}
				}
			}
			let resObj = {};
			column.forEach(i => {
				resArr.forEach((j, index) => {
					resObj[`${index}-${i}`] = j;
				});
			});
			return resObj;
		},
	},
	created() {
		this.generateData();
	}

}
</script>

<style lang="scss" >
.table .ivu-table-cell {
	padding: 0 10px;
}
</style>