import { render, staticRenderFns } from "./u_formItem.vue?vue&type=template&id=31ce66e4&scoped=true&"
import script from "./u_formItem.vue?vue&type=script&lang=js&"
export * from "./u_formItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.2@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31ce66e4",
  null
  
)

export default component.exports