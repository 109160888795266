<template>
	<div class="utips">
		<Modal width="440" :closable="false" :mask-closable="false" footer-hide class-name="vertical-center-modal"
			:value="value">
			<div class="ut_body">
				<div class="title">
					<Icon type="md-alert" />
					请务必让该患者了解：
				</div>
				<div class="content" v-html="speech"></div>
				<div class="footer">
					<Button @click="close" type="primary">我会让患者了解</Button>
				</div>
			</div>
		</Modal>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	props: {
		value: {
			type: Boolean,
			default: false
		},
	},
	computed: {
		...mapGetters(["speech"]),
	},
	data() {
		return {
		}
	},
	methods: {
		close() {
			this.$emit('input', false);
		}
	},
}
</script>

<style lang="scss" scoped>
.ut_body {
	padding: 20px;

	.title {
		font-size: 16px;
		margin-bottom: 20px;
		position: relative;

		.ivu-icon {
			position: absolute;
			left: -25px;
			top: 2px;
			color: #FF9959;
			font-size: 20px;
		}
	}

	.content {
		line-height: 2em;
		margin-bottom: 30px;
	}

	.footer {
		text-align: center;
	}
}
</style>