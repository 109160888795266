<!-- 自定义自动完成功能 -->
<template>
  <div class="userAutoComplete">
    <!-- <Select v-model="u_input" filterable default-label="北京">
      <Option
        v-for="(option, index) in data"
        :value="option.DiseaseId"
        :label="option.DiseaseName + option.Pym"
        :key="index"
        filter-by-label
      >{{option.DiseaseName}}</Option>
    </Select> -->

    <input
      name="zyzd"
      autocomplete="off"
      :disabled="disabled"
      @blur="blur"
      @focus="focus"
      @keyup="select_item"
      class="ivu-input"
      placeholder="输入诊断拼音首字母"
      type="text"
      v-model="u_input"
    />
    <div v-show="show" class="select-dropdown-list ivu-select-dropdown">
      <ul class="select_ul ivu-select-dropdown-list">
        <li
          v-for="(item,index) in filteredData"
          @mouseover="move($event,index)"
          @mousedown.stop="clickItem"
          class="ivu-select-item item"
          :class="{'item-focus':index == active}"
          :key="index"
        >
          <div>{{item.DiseaseName}}</div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import u_common from "../common";
export default {
  name: "UserAutoComplete",
  props: {
    value: {
      type: [String, Number],
      default: ""
    },
    data: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      show: false,
      u_input: this.value,
      active: 0,
      disableEmitChange: false // for Form reset
    };
  },
  computed: {
    filteredData() {
      $(this.$el)
        .find(".select-dropdown-list")
        .scrollTop(0);
      return this.data.filter(
        item =>
          item.Pym.toUpperCase().indexOf(this.u_input.toUpperCase()) !== -1
      );
    }
  },
  watch: {
    value(v) {
      this.u_input = v;
    }
  },
  methods: {
    move(e, index) {
      this.active = index;
    },
    // 失去焦点
    blur() {
      if (!this.u_input) {
        // this.$emit('on-select');
      }
      this.u_input = this.value;
      this.show = false;
    },
    focus(e) {
      e.target.select();
    },
    // 选择内容
    select_item(e) {
      if (!e.target.value) {
        this.show = false;
      } else {
        this.show = true;
      }
      switch (e.keyCode) {
        case 38:
          {
            if (this.active > 0) {
              this.active -= 1;
            } else {
              this.active = this.filteredData.length - 1;
            }
            //移动光标到行尾
            u_common.moveEnd(e.target);
            this.scrollList();
          }
          break;
        case 40:
          {
            if (this.active < this.filteredData.length - 1) {
              this.active += 1;
            } else {
              this.active = 0;
            }
            //移动光标到行尾
            u_common.moveEnd(e.target);
            this.scrollList();
          }
          break;
        case 13:
          {
            this.clickItem();
          }
          break;
      }
    },
    // 滚动条 up  down
    scrollList(type) {
      var li = $(this.$el).find("li");
      if (!li.length) return "";
      var scrollNode = $(this.$el).find(".select-dropdown-list");
      var clientHeight = scrollNode[0].clientHeight;
      var liHeight = li[0].clientHeight;
      setTimeout(() => {
        var itemFocus = $(this.$el).find(".item-focus");
        var top = itemFocus.position().top;
        scrollNode.scrollTop(scrollNode.scrollTop() + top - 80);
      }, 10);
    },
    clickItem() {
      this.regDate();
    },
    regDate() {
      if (this.u_input) {
        var req = this.filteredData.length
          ? u_common.clone(this.filteredData[this.active])
          : null;
        if (req) {
          if (req.DiseaseName == this.value) {
            this.u_input = this.value;
          } else {
            this.$emit("on-select", req);
          }
        } else {
          this.u_input = this.value;
        }
      } else {
        this.$emit("on-select");
      }
      this.show = false;
      this.active = 0;
    }
  }
};
</script>