<template>
  <div class="date-pickers">
    <Select
      @on-open-change="openChange"
      class="year select"
      v-model="currentDate.year"
      @change="judgeDay"
      placeholder="年"
    >
      <Option v-for="item in years" :key="item" :label="item" :value="item"></Option>
    </Select>
    <span class="text">年</span>
    <Select
      class="month select"
      v-model="currentDate.month"
      @change="judgeDay"
      placeholder="月"
      @on-open-change="openChange"
    >
      <Option
        v-for="item in months"
        :key="item"
        :label="String(item).length==1?String('0'+item):String(item)"
        :value="item"
      ></Option>
    </Select>
    <span class="text">月</span>
    <Select
      @on-open-change="openChange"
      class="day select"
      :class="{'error':hasError}"
      v-model="currentDate.day"
      placeholder="日"
    >
      <Option
        v-for="item in days"
        :key="item"
        :label="String(item).length==1?String('0'+item):String(item)"
        :value="item"
      ></Option>
    </Select>
    <span class="text">日</span>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: [String, Number]
    }
  },
  name: "date-pickers",
  data() {
    return {
      currentDate: {
        year: "",
        month: "",
        day: ""
      },
      maxYear: new Date().getFullYear(),
      minYear: 1910,
      years: [],
      months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      normalMaxDays: 31,
      days: [],
      hasError: false
    };
  },
  watch: {
    value() {
      if (this.value) {
        this.currentDate = this.timestampToTime(this.value);
      }
    },
    normalMaxDays() {
      this.getFullDays();
      if (this.currentDate.year && this.currentDate.day > this.normalMaxDays) {
        this.currentDate.day = "";
      }
    },
    currentDate: {
      handler(newValue, oldValue) {
        this.judgeDay();
        if (newValue.year && newValue.month && newValue.day) {
          this.hasError = false;
        } else {
          this.hasError = true;
        }
        this.emitDate();
      },
      deep: true
    }
  },

  methods: {
    openChange(e) {
      if (e) {
        setTimeout(() => {
          let items = $(this.$el).find(".ivu-select-dropdown");
          [...items].forEach(i => {
            let s_item = $(i);
            let el = s_item.find(".ivu-select-item-selected");
            let p = $(el).position();
            var h = s_item.scrollTop() + p.top - 60;
            $(i).scrollTop(h);
          });
        }, 300);
      }
    },
    emitDate() {
      let timestamp; //暂默认传给父组件时间戳形式
      if (
        this.currentDate.year &&
        this.currentDate.month &&
        this.currentDate.day
      ) {
        let month =
          this.currentDate.month < 10
            ? "0" + this.currentDate.month
            : this.currentDate.month;
        let day =
          this.currentDate.day < 10
            ? "0" + this.currentDate.day
            : this.currentDate.day;
        let dateStr = this.currentDate.year + "-" + month + "-" + day;
        timestamp = new Date(dateStr).getTime();
        this.$emit("input", dateStr);
      } else {
        timestamp = "";
      }
      this.$emit("dateSelected", timestamp);
    },
    timestampToTime(timestamp) {
      let dateObject = {},
        d = new Date(timestamp);
      dateObject.year = d.getFullYear();
      dateObject.month = d.getMonth() + 1;
      dateObject.day = d.getDate();
      return dateObject;
    },
    getFullYears() {
      for (let i = this.minYear; i <= this.maxYear; i++) {
        this.years.push(i);
      }
    },
    getFullDays() {
      this.days = [];
      for (let i = 1; i <= this.normalMaxDays; i++) {
        this.days.push(i);
      }
    },
    judgeDay() {
      if ([4, 6, 9, 11].indexOf(this.currentDate.month) !== -1) {
        this.normalMaxDays = 30; //小月30天
        if (this.currentDate.day && this.currentDate.day == 31) {
          this.currentDate.day = "";
        }
      } else if (this.currentDate.month == 2) {
        if (this.currentDate.year) {
          if (
            (this.currentDate.year % 4 == 0 &&
              this.currentDate.year % 100 != 0) ||
            this.currentDate.year % 400 == 0
          ) {
            this.normalMaxDays = 29; //闰年2月29天
          } else {
            this.normalMaxDays = 28; //闰年平年28天
          }
        } else {
          this.normalMaxDays = 28; //闰年平年28天
        }
      } else {
        this.normalMaxDays = 31; //大月31天
      }
    }
  },
  created() {
    this.getFullYears();
    this.getFullDays();
    if (this.value) {
      this.currentDate = this.timestampToTime(this.value);
    }
  }
};
</script>
<style >
.date-pickers {
  display: flex;
}
.date-pickers span.text {
  padding: 0 5px;
}
.date-pickers .select {
  width: 80px;
  text-align: center;
}
.date-pickers .year {
  width: 100px;
}
.date-pickers .error .el-input__inner {
  border: 1px solid #f1403c;
  border-radius: 4px;
}
</style>