
// 将图片转换为Base64
export function image2Base64(img) {
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, img.width, img.height);
    var dataURL = canvas.toDataURL("image/png");
    return dataURL;
}

// 获取图片Base64编码
export function getImgBase64(path) {
    return new Promise((resolve, reject) => {
        var base64 = "";
        var img = new Image();
        img.src = path;
        img.crossOrigin = "Anonymous";
        img.onload = () => {
            base64 = image2Base64(img);
            resolve({
                "imageHeight": img.height,
                "imageWidth": img.width,
                "base64": base64
            });
        }
        img.onerror = (err) => {
            reject(err)
        }
    })
}