<!-- 自定义自动完成功能 -->
<template>
  <div class="u_complete">
    <input @blur="blur" @keyup="select_item" class="u_input" type="text" v-model="u_input">
    <div v-show="show" class="select-dropdown-list">
      <ul class="select_ul">
        <li v-for="(item,index) in filteredData" @mouseover="move($event,index)" @mousedown="clickItem" class="item"
            :class="{'item-focus':index == active}">
          <div>{{item.Drug_Name}}</div>
          <div>{{item.specifications}}</div>
          <div>{{item.UnitName}}</div>
          <div>{{item.drugAlias1}}</div>
          <div>{{item.drugAlias2}}</div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
  import u_common from '../common'

  export default {
    name: 'AutoComplete',
    props: {
      value: {
        type: [String, Number],
        default: ''
      },
      data: {
        type: Array,
        default: () => []
      },
    },
    data() {
      return {
        show: false,
        u_input: '',
        active: 0,
        currentValue: this.value,
        disableEmitChange: false,    // for Form reset
        kg: 31,
      };
    },
    computed: {
      filteredData() {
        this.active = 0;
        $(this.$el).find('.select-dropdown-list').scrollTop(0)
        return this.data.filter(item => {
          var val = this.u_input.trim().toUpperCase(),
            code1 = item.pinyinCode.toUpperCase().indexOf(val) !== -1,
            code2 = item.pinyinCode1 ? item.pinyinCode1.toUpperCase().indexOf(val) !== -1 : false,
            code3 = item.pinyinCode2 ? item.pinyinCode2.toUpperCase().indexOf(val) !== -1 : false;
          return code1 || code2 || code3;
        });
      }
    },
    watch: {},
    methods: {
      move(e, index) {
        this.active = index;
      },
      // 失去焦点
      blur() {
        var self = this;
        this.$emit('on-blur');
        setTimeout(() => {
          self.active = 0;
        }, 200);
      },
      // 选择内容
      select_item(e) {
        if (!e.target.value) {
          this.show = false;
          this.active = 0;
        } else {
          this.show = true;
        }
        switch (e.keyCode) {
          case 38: {
            if (this.active > 0) {
              this.active -= 1;
            } else {
              // this.active = this.filteredData.length - 1;
            }
            //移动光标到行尾
            u_common.moveEnd(e.target);
            this.scrollList('up');
          }
            ;
            break;
          case 40: {
            if (this.active < this.filteredData.length - 1) {
              this.active += 1;
            } else {
              // this.active = 0;
            }
            //移动光标到行尾
            u_common.moveEnd(e.target);
            this.scrollList();
          }
            ;
            break;
          case 13: {
            this.clickItem();
          }
            ;
            break;
        }
      },
      clickItem() {
        var req = this.filteredData.length ? u_common.clone(this.filteredData[this.active]) : null;
        this.$emit('on-select', req);
        this.show = false;
        this.u_input = '';
      },
      // 滚动条 up  down
      scrollList(type) {
        var li = $(this.$el).find('li');
        if (!li.length) return '';
        var scrollNode = $(this.$el).find('.select-dropdown-list');
        var itemFocus = $(this.$el).find('.item-focus');
        if (type == 'up') {
          itemFocus = itemFocus.prev();
        } else {
          itemFocus = itemFocus.next();
        }
        if (itemFocus.length) {
          var top = itemFocus.position().top;
          scrollNode.scrollTop(top - 50);
        }
      },
    },
    mounted() {
    }
  };
</script>
