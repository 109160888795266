import Vue from 'vue'
import axios from 'axios'

Vue.prototype.isDrugTaboo = false; //药品禁忌开关

import 'view-design/dist/styles/iview.css';
import ViewUI, {
    BackTop
} from 'view-design';
Vue.use(ViewUI);

import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
Vue.use(PerfectScrollbar)

// 引导功能组件
import '@/com/intro/introjs.css';

import store from './store/index.js'

import './assets/css/app.css';
import './assets/css/user.css';
// import './assets/css/phone.css';
import './assets/iconfont/iconfont.css'
import 'viewerjs/dist/viewer.css';

import medicalRecord from './com/MedicalRecord.vue'
Vue.component('medicalRecord', medicalRecord);

import prescriptionPrint from './com/prescriptionPrint.vue'
Vue.component('prescriptionPrint', prescriptionPrint);

import medicalRecord2 from './com/MedicalRecord2.vue'
Vue.component('medicalRecord2', medicalRecord2);

import Uheader from './com/Uheader.vue'
Vue.component('Uheader', Uheader);
import udate from './com/udate.vue'
Vue.component('udate', udate);

import layer from './com/layer.vue'
Vue.component('layer', layer);

import patientInfo from './com/patientInfo/patientInfo.vue'
Vue.component('patientInfo', patientInfo);
import dateSelect from './com/dateSelect.vue'
Vue.component('dateSelect', dateSelect);

import newsCenter from './com/newsCenter.vue'
Vue.component('newsCenter', newsCenter);

import prescriptionSelect from './com/prescriptionSelect.vue'
Vue.component('prescriptionSelect', prescriptionSelect);

import Ucomplete from './com/Ucomplete.vue'
import common from './common.js';
Vue.component('Ucomplete', Ucomplete);

// 替换替换成<br>
Vue.prototype.newlineToBr = function (str) {
    if (str) return str.replace(/\n/g, '<br>');
}

import uArchives from './com/uArchives.vue'
Vue.component('uArchives', uArchives);

import camera21 from '@/com/camera/camera21.vue'
Vue.component('camera21', camera21);

import camera3 from '@/com/camera/camera3.vue'
Vue.component('camera3', camera3);

import medicalRecordPrint from '@/com/medicalRecordPrint.vue'
Vue.component('medicalRecordPrint', medicalRecordPrint);

import notice from './com/notice.vue'
Vue.component('notice', notice);
import empty from '@/com/empty.vue'
Vue.component('empty', empty);
import uSpin from './com/uSpin.vue'
Vue.component('uSpin', uSpin);

import symptomDropdown from './com/symptomDropdown.vue'
Vue.component('symptomDropdown', symptomDropdown);

import drugs_format from './com/drugs_format.vue'
Vue.component('drugsFormat', drugs_format);

import xAutoComplete from './com/xAutoComplete.vue'
Vue.component('xAutoComplete', xAutoComplete);

import symptomChanges from './com/symptomChanges.vue'
Vue.component('symptomChanges', symptomChanges);

import Print from './com/print/print.js'
Vue.use(Print)

import westernMedicineDiseaseName from './com/westernMedicineDiseaseName/index.vue'
Vue.component('westernMedicineDiseaseName', westernMedicineDiseaseName);

import { WebCam } from "./com/webcam";
Vue.component("web-cam", WebCam);

import {
    Picker
} from 'vant';
Vue.use(Picker);

import utabs from './com/utabs.vue'
Vue.component('utabs', utabs);

import UserAutoComplete from './com/UserAutoComplete.vue'
Vue.component('UserAutoComplete', UserAutoComplete);

import passwordInput from "@/com/passwordInput.vue";
Vue.component('passwordInput', passwordInput);

import uEdition from "@/utils/uEdition.js";
Vue.directive('uEdition', uEdition);


// 拖拽
import { initVDrag } from "@/directive/vDrag.js"
initVDrag(Vue)
// 缩放
import { initVWheelScale} from "@/directive/vWheelScale.js"
initVWheelScale(Vue)


Vue.prototype.confidenceProportion = config.confidenceProportion; //置信度最低显视比例
Vue.prototype.showCodeHosptialIds = config.showCodeHosptialIds; //显视处方支付码

Vue.prototype.uconfig = config
Vue.prototype.cloudPic = config.cloudPic;
Vue.prototype.picroot = function (url, type) {
    var bask = config.picBaseUrl3;
    let origin = location.origin;
    switch (type) {
        case 'symptom':
            bask = config.picBaseUrl2;
            break; //症状学习
        case 'archives':
            bask = config.picBaseUrl;
            break; //患者档案图片前缀
        case 'detail':
            bask = origin + "/Picture/His/";
            break; //病历详情
        case 'images':
            bask = config.picRoot + "/images/";
            break; //病历详情
        case 'acupoint':
            bask = config.picRootAcupoint;
            break; //上传图片的前缀
    }
    return bask + url;
}

Vue.prototype.isNew = (date_str) => {
    return new Date().getTime() <= new Date(date_str).getTime() + 1000 * 60 * 60 * 24 * 14;
}

// 调试工具
import vconsole from 'vconsole'
if (config.vconsole) var vc = new vconsole()

import api2 from '@/api/api2.js'
Vue.prototype.api2 = api2;


function IsPC() {
    var userAgentInfo = navigator.userAgent;
    var Agents = ["Android", "iPhone",
        "SymbianOS", "Windows Phone",
        "iPad", "iPod"
    ];
    var flag = true;
    for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
            flag = false;
            break;
        }
    }
    return flag;
}

// 替换替换成<br>
Vue.prototype.newlineToBr = function (str) {
    if (str) return str.replace(/\n/g, '<br>');
}

Vue.prototype.isMobile = !IsPC();

// 是否Electron环境
Vue.prototype.isElectron = common.isElectron();

let obj = {
    axios,
    store,
    common
};
for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
        Vue.prototype[key] = obj[key];
    }
}
Vue.config.productionTip = false;

import App from './app.vue'

Vue.prototype.versionType = config.versionType;

import VueAMap from 'vue-amap';
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
    key: 'ba980c385ea208cd88eff8d84c4dc129',
    plugin: ['AMap.Autocomplete', 'AMap.Geocoder', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
    // 默认高德 sdk 版本为 1.4.4
    v: '1.4.4'
});


// 正式环境才启用
/* 
import * as fundebug from "fundebug-javascript";
import FundebugVue from "fundebug-vue";
if (process.env.NODE_ENV != 'development') {
    fundebug.apikey = "744f887389f33f75f37ac39d1e10cab7a95e6e95510996b4a9e56a4a24eebc5e"
    new FundebugVue(fundebug).installVueErrorHandler(Vue); // Vue 2.x 
} */

import * as Sentry from "@sentry/vue";
import {
    BrowserTracing
} from "@sentry/tracing";

import router from './router'

import vuescroll from 'vuescroll';
Vue.use(vuescroll, {
    ops: {
        bar: {
            background: '#ccc'
        }
    }, // 在这里设置全局默认配置
});

// 关掉日志功能
/* if (config.isLog) { //是否启用日志
    Sentry.init({
        Vue,
        dsn: config.logApi,
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracingOrigins: ["localhost", "my-site-url.com", /^\//],
            }),
        ],
        tracesSampleRate: 1.0,
    });
} */

window.uApp = new Vue({
    router,
    store: store,
    render: h => h(App)
}).$mount('#app')