class Timeout {
    ids = {};
    addId(id, fun1, fun2, timeout) {
        if (this.ids.hasOwnProperty(id)) return;
        let djs1 = setTimeout(() => {
            fun1();
        }, timeout);
        let djs2 = setTimeout(() => {
            fun2();
        }, timeout + 5000)
        this.ids[id] = [
            djs1, djs2
        ]
        console.log('addId', arguments);
    };
    delId(id) {
        if (this.ids[id]) {
            console.log('delId', id);
            this.ids[id].forEach(i => {
                clearTimeout(i);
            })
            delete this.ids[id];
            return true;
        } else {
            return false;
        }
    }
}

export default new Timeout;