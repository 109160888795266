<template>
    <!-- <div class="human-body-info" @click="clickOutSite" v-clickOutside="clickOutSite"> -->
    <div class="human-body-info" v-clickOutside="clickOutSite">
        <div class="utip" v-if="!groupHasActive">
            <div class="utip-title">选择病因</div>
            <div class="utip-body">
                点击<span class="text-red">红色</span>异常（白色正常）器官选择病因，并输入底层原因，最后点击【下一步】按钮查看分析结果。
            </div>
        </div>
        <div class="info-item" :class="{'item-active': g_item.active}" v-for="(g_item, g_index) in group" :key="g_item.name">
            <template v-if="hasChildrenValue(g_item)">
                <div class="title" v-if="g_item.list.filter((j) => !j.wxActive || (j.wxActive == g_item.name)).length">
                    <div class="icon-g" v-if="g_item.name == 'isWood'">
                        <div class="icon">
                            <img src="@/assets/images/qg/qg_03.png" alt="" />
                            <div class="name">肝</div>
                        </div>
                        <div class="icon">
                            <img src="@/assets/images/qg/qg_05.png" alt="" />
                            <div class="name">胆</div>
                        </div>
                    </div>
                    <div class="icon-g" v-else-if="g_item.name == 'isFire'">
                        <div class="icon">
                            <img src="@/assets/images/qg/qg_01.png" alt="" />
                            <div class="name">心</div>
                        </div>
                        <div class="icon">
                            <img src="@/assets/images/qg/qg_08.png" alt="" />
                            <div class="name">小肠</div>
                        </div>
                    </div>
                    <div class="icon-g" v-else-if="g_item.name == 'isSoil'">
                        <div class="icon">
                            <img src="@/assets/images/qg/qg_09.png" alt="" />
                            <div class="name">脾</div>
                        </div>
                        <div class="icon">
                            <img src="@/assets/images/qg/qg_11.png" alt="" />
                            <div class="name">胃</div>
                        </div>
                    </div>
                    <div class="icon-g" v-else-if="g_item.name == 'isGold'">
                        <div class="icon">
                            <img src="@/assets/images/qg/qg_02.png" alt="" />
                            <div class="name">肺</div>
                        </div>
                        <div class="icon">
                            <img src="@/assets/images/qg/qg_07.png" alt="" />
                            <div class="name">大肠</div>
                        </div>
                    </div>
                    <div class="icon-g" v-else-if="g_item.name == 'isWater'">
                        <div class="icon">
                            <img src="@/assets/images/qg/qg_10.png" alt="" />
                            <div class="name">肾</div>
                        </div>
                        <div class="icon">
                            <img src="@/assets/images/qg/qg_04.png" alt="" />
                            <div class="name">膀胱</div>
                        </div>
                    </div>
                </div>

                <div class="utree-group">
                    <div class="utree-wrop" :class="'symptomid-'+row.symptomId" v-for="(row, r_index) in g_item.list.filter(i=>(i.wxActive == g_item.name) || !i.wxActive)" :key="r_index" >
                        <div class="row-name" v-html="row.symptomNameStr" v-if="itemList(row.list, g_item.active ).length"></div>
                        <div class="utree" :class="{ onlyone: row.list.length == 1, disabled: !g_item.active }">
                            <div class="utree-item" :class="'mraeid-'+item.mraeId" v-show="!item.isHide" v-for="(item, index) in itemList(row.list, g_item.active )" :key="index +'-'+ item.dialecticsId">
                                <div class="label" :class="{ active: item.active || item.children.length }"
                                    @click.stop="clickItem(item, g_item, $event, row)">
                                    {{ item.etiologyName }}
                                </div>
                                <div class="icon add-icon" v-if="item.children.length > 1 || item.active">
                                    <Icon @click.stop="clickAdd(item, $event)" v-if="item.active && g_item.active"
                                        type="md-add-circle" size="22" color="#f00" />
                                </div>
                                <div class="utree" :class="{ onlyone: item.children.length == 1 }" v-if="item.children.length">
                                    <div class="utree-item" v-for="(c_item, c_index) in item.children" :key="c_index">
                                        <div class="label" :class="{ active: c_item.active }"
                                            @click.stop="clickInput(item, g_item)">
                                            <input maxlength="50" @keyup.enter="onEnter(item, $event)" v-model="c_item.label"
                                                @change="inputChange(item, c_index, row, g_item, $event)" placeholder="输入底层原因"></input>
                                        </div>
                                        <div v-if="item.active && g_item.active" class="icon">
                                            <Icon @click.stop="remImte(item, c_index, row)" type="md-close-circle" size="22"
                                                color="#999" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import clickOutside from "view-design/src/directives/clickoutside";
export default {
    directives: { clickOutside },
    props: {
        list: {
            type: Array,
            default: () => {
                return [];
            },
        },
        group: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },
    computed: {
        groupHasActive() {
            return this.group.some(i => i.active) || this.list.some(i => i.children.length);
        }
    },
    watch: {
    },
    data() {
        return {
            djs: null,
            djs2: null,
        };
    },
    methods: {
        toNode(symptomId){
            this.$nextTick(()=>{
                let node2 = this.$el
                let node = node2.querySelector(".symptomid-"+symptomId);
                if(node){
                    let rect = node.getBoundingClientRect()
                    node2.scrollTop = rect.top + node2.scrollTop - 40 ;
                    // 设置焦点
                    node.querySelector(".utree-item .label").click()
                }
            })
        },
        scrollFun(){
            this.$nextTick(()=>{
                let node2 = this.$el
                let node = node2.querySelector(".item-active")
                if(node){
                    let rect = node.getBoundingClientRect()
                    node2.scrollTop = rect.top + node2.scrollTop - 40 ;
                    // 设置焦点
                    if(!this.stater){
                        node.querySelector(".utree-item .label").click()
                    }
                    this.stater = false;
                }
            })
        },
        clickOutSite() {
            this.list.forEach(i => {
                i.children = i.children.filter((j) => j.label);
                i.active = false
            })
            this.group.forEach(i => i.active = false)
            this.$emit("groupChange", "");
        },
        inputChange(item, index, row, group, e) {
            row.wxActive = group.name;
            let value = e.target.value.trim()
            if (value) {
                this.djs = setTimeout(() => {
                    this.$emit("change", {
                        item,
                        value,
                        row,
                    })
                }, 200);
            } else {
                this.remImte(item, index, row);
                if(!row.list.some(i=>i.children.length)) row.wxActive = "";
            }
        },
        itemList(list, active) {
            return active ? list : list.filter(i => i.children.some(i => i.label))
        },
        clickGroup(g_item) {
            if (g_item.active) return;
            this.list.forEach(i => i.active = false)
            this.group.forEach((i) => {
                i.active = i == g_item ? true : false;
            });
            this.$emit("groupChange", g_item.name);
        },
        hasChildrenValue(item) {
            if (item.active) {
                return true;
            } else {
                return item.list.some(j=>{
                    return j.list.some((i) => {
                        return i.children.some((j) => j.label);
                    });
                })
            }
        },
        remImte(item, index, row) {
            clearTimeout(this.djs)
            if (item.mrepId) {
                let obj = {
                    bottomReason: item.children[index].label,//	病因底层原因
                    id: item.mrepId, //	主键(修改时传入)
                }
                this.$store.commit("openSpin");
                this.api2("deleteMrEtiologyPathogenesisExplain", obj).then(res => {
                    this.$store.commit("closeSpin");
                    item.children.splice(index, 1);
                    if (!item.children.length) {
                        item.active = false;
                    }
                    if(!row.list.some(i=>i.children.length)){
                        row.wxActive = "";
                    }
                })
            } else {
                item.children.splice(index, 1);
                if (!item.children.length) {
                    item.active = false;
                }
                if(!row.list.some(i=>i.children.length)){
                    row.wxActive = "";
                }
            }
        },
        onEnter(item, e) {
            this.addChildren(item);
            let parentNode = this.findParent(e.target, "utree")
            if (parentNode) {
                setTimeout(() => {
                    this.setFocus(parentNode);
                }, 200);
            }
        },
        clickAdd(item, e) {
            this.addChildren(item);
            let parentNode = this.findParent(e.target, "utree-item")
            if (parentNode) {
                setTimeout(() => {
                    this.setFocus(parentNode);
                }, 200);
            }
        },
        addChildren(item) {
            item.children.push({
                label: "",
                active: false,
            });
        },
        clickItem(item, g_item, e, row) {
            this.group.forEach(i=>{
                i.list.forEach(j=>{
                    let isHas = false;
                    j.list.forEach(k=>{
                        k.active = false;
                        k.children = k.children.filter(j => j.label);
                        if(k.children.length) isHas = true;
                    })
                    if(!isHas) j.wxActive = "";
                })
            })
            row.wxActive = g_item.name;

            this.clickGroup(g_item);
            item.active = true;
            if (!item.children.length) {
                item.children = [
                    {
                        label: "",
                        active: false,
                    },
                ];
            }
            setTimeout(() => {
                this.setFocus(e.target.parentNode);
            }, 200);
        },
        setFocus(parentNode) {
            let iList = $(parentNode).find("input");
            if (iList.length) {
                iList[iList.length - 1].focus()
            }
        },
        clickInput(item, g_item, e) {
            let active = g_item.active;
            this.stater = true;
            this.clickGroup(g_item);
            this.list.forEach((i) => {
                i.active = item == i ? true : false;
            });

            // 跨五行时需要设置焦点 
            if(!active){
                this.$nextTick(()=>{
                    let node2 = this.$el
                    let node = node2.querySelector(".mraeid-" + item.mraeId + " .label");
                    if(node) node.click()
                })
            }
        },
        // 查找长辈节点
        findParent(node, className) {
            if (node) {
                if (node.className == className) {
                    return node;
                } else {
                    return this.findParent(node.parentNode, className);;
                }
            }
        },
    },
    created() {
    },
};
</script>

<style lang="scss">
.human-body-info {
    position: relative;
    z-index: 2;

    .utip {
        .utip-title {
            font-weight: bold;
            font-size: 16px;
            margin-bottom: 15px;
        }

        .utip-body {}
    }

    input {
        border: none;
        width: 100px;
    }

    .info-item {
        // padding-bottom: 20px;
    }

    .ivu-input-wrapper {
        margin-bottom: 0px;
    }

    .ivu-input {
        padding: 0;
        height: 20px;
        line-height: 20px;
    }
}
</style>