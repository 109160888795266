<template>
  <div class="u_table2" :style="u_table_heigh">
    <div class="t_head">
      <ul class="u_table_h">
        <li class="t_xh">
          <span>序号</span>
        </li>
        <li class="t_mc">
          <span>名称</span>
        </li>
        <li class="t_gg">
          <span>规格</span>
        </li>
        <li class="t_sl">
          <span>数量</span>
        </li>
        <li class="t_dw">
          <span>单位</span>
        </li>
        <li class="t_ts">
          <span>帖数</span>
        </li>
        <li class="t_fyfs">
          <span>服用方式</span>
        </li>
        <li class="t_jyfs">
          <span>煎药方式</span>
        </li>
        <li v-if="pageType" class="t_kc">
          <span>库存</span>
        </li>
        <li class="t_tz">
          <span>操作</span>
        </li>
      </ul>
    </div>
    <div class="t_body" :style="u_table_scroll">
      <div class="ul_yk">
        <Ucomplete class="ypsrk" :class="{ popup_top: uc_top }" @on-blur="drugSearchblur" @on-select="changeValue3"
          :data="drug"></Ucomplete>
        <input @blur="amount_blur" @keyup.enter="edit_key" v-model="edit_amount" class="edit_amount" type="number" />
        <!-- <ul v-for="(item, index) in drugList" :key="index" class="u_table_h" :class="{ active: item.active, error: item.error }"> -->
        <ul v-for="(item, index) in drugList" :key="index" class="u_table_h " :class="{ active: item.active }">
          <li v-if="!item.flevel" class="t_xh">
            <span>{{ index + 1 }}</span>
          </li>
          <li v-else class="t_xh cursor_p">
            <span>
              {{ index + 1 }}
              <i class="iconfont icon-point" v-for="(icon, index) in item.flevel" :key="index"></i>
            </span>
          </li>
          <li class="t_mc" @click="editDrugName($event, item)">
            <span :class="{ isTaboo: tabooDrugIds.includes(item.Drug_Code) }">
              <div class="jin"><i>禁</i></div>
              {{ item.Drug_Name }}
            </span>
          </li>
          <li class="t_gg">
            <span>{{ item.specifications }}</span>
          </li>
          <li class="t_sl" @click="editAmount($event, item, 'Amount')">
            <span class="u_amount">{{ item.Amount }}</span>
          </li>
          <li class="t_dw">
            <span>{{ item.UnitName }}</span>
          </li>
          <li class="t_ts">
            <span @click="editAmount($event, item, 'Quantity')">{{
              item.Quantity
            }}</span>
          </li>
          <li class="t_fyfs">
            <Select clearable @on-change="saveData" v-model="item.drugTakePattern" placeholder>
              <Option v-for="item in Drug_Take_Pattern" :value="item.label" :key="item.value">{{ item.label }}
              </Option>
            </Select>
          </li>
          <li class="t_jyfs">
            <Select clearable @on-change="saveData" v-model="item.drugDecoctPattern" placeholder>
              <Option v-for="item in Drug_Decoct_Pattern" :value="item.label" :key="item.value">{{ item.label }}</Option>
            </Select>
          </li>
          <li v-if="pageType" class="t_kc" :class="{ error: item.Amount * item.Quantity > item.StockQty }">
            <span>{{ item.StockQty }}</span>
          </li>
          <li class="t_tz">
            <span>
              <Button :disabled="item.delBtn" @click="del(index)">删除</Button>
            </span>
          </li>
        </ul>
        <ul class="u_table_h">
          <li class="t_xh">
            <span>{{ drugList.length + 1 }}</span>
          </li>
          <li class="t_mc">
            <span class="input_new" @click="editDrugName($event, '')">输入拼音首字母</span>
          </li>
          <li class="t_gg">
            <span></span>
          </li>
          <li class="t_sl">
            <span></span>
          </li>
          <li class="t_dw">
            <span></span>
          </li>
          <li class="t_ts">
            <span></span>
          </li>
          <li class="t_fyfs">
            <span></span>
          </li>
          <li class="t_jyfs">
            <span></span>
          </li>
          <li v-if="pageType" class="t_kc">
            <span></span>
          </li>
          <li class="t_tz">
            <span></span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    drugList: {
      type: Array,
      default: () => [],
    },
    // 贴数
    stick: {
      type: Number | String,
      default: 7,
    },
    //页面类型  true:前端医生 false:后端医生
    pageType: {
      type: Boolean,
      default: false,
    },
    tabooDrugIds: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      now_symptom: [], //本次
      btnLoading: false,
      btnDisabled: false, //提交按钮禁用开关
      confirm: false,
      uc_top: false,

      // 临时药品ID 编辑药品清单用
      editDrug: null,

      //编辑药品数量
      edit_amount: "",

      // 表格高度
      u_table_heigh: { height: "" },
      u_table_scroll: { height: "" },

      // 是否新插入
      new_input: false,

      // 倒计时
      djs: null,
    };
  },
  computed: {
    ...mapGetters([
      "tabooDiscriminate",
      "Drug_Decoct_Pattern",
      "Drug_Take_Pattern",
      "drug",
      "bnf", //百年方Drug_Code
    ]),
  },
  watch: {
    stick(value) {
      this.drugList.forEach((i, index) => {
        var u_drug = this.drug.find((u) => u.Drug_Code == i.Drug_Code);
        if (u_drug && (u_drug.Drug_Type == "06" || u_drug.Drug_Type == "01")) {
          i.Quantity = value;
        }
      });
      this.Quantity = value;
      this.saveData();
    },
  },
  methods: {
    saveData() {
      this.$emit("change");
    },
    // 药品搜索值改变时
    changeValue3(value) {
      if (value) {
        var newValue = value;
        var self = this;
        // 更新
        if (this.bnf.indexOf(value.Drug_Code) != -1) {
          value.Amount = 1;
        }
        if (this.editDrug) {
          this.drugList.forEach((i, index) => {
            if (i.Drug_Code == this.editDrug.Drug_Code) {
              for (var Key in i) {
                if (Key != "Quantity") i[Key] = value[Key];
              }
              i.drugTaboo = [];
            }
          });
          newValue = this.editDrug;
        } else {
          // 新增
          if (value.Drug_Type == "06" || value.Drug_Type == "01") {
            value.Quantity = this.stick;
          } else {
            value.Quantity = 1;
          }
          value.active = false;
          value.flevel = 0;
          value.drugTaboo = [];
          value.error = false;

          this.drugList.push(value);

          setTimeout(function () {
            self.tableRow = self.tableRow.prev();
          }, 20);
        }
        setTimeout(function () {
          if (self.bnf.indexOf(value.Drug_Code) != -1) {
            $(".input_new").trigger("click");
          } else {
            self.tableRow
              .find(".u_amount")
              .trigger("click", [newValue, "Amount"]);
          }
        }, 30);
      }
      $(".ypsrk").css({ visibility: "hidden" });
      this.editDrug = null;
      this.saveData();
    },

    // 药品搜索框失去焦点
    drugSearchblur() {
      var self = this;
      this.djs = setTimeout(() => {
        $(".ypsrk").css({ visibility: "hidden" });
      }, 100);
    },

    // 修改药品名称
    editDrugName(e, u_drug) {
      clearTimeout(this.djs);
      var offset = $(e.target).position();
      $(".ypsrk").css({
        left: offset.left,
        top: offset.top,
        width: e.target.clientWidth + "px",
        height: e.target.clientHeight,
        visibility: "visible",
      });

      let xy = $(".ypsrk").offset();
      let ul_yk = $(".ul_yk").offset();
      if (xy.top - $(".t_body").scrollTop() > ul_yk.top + 140) {
        this.uc_top = true;
      } else {
        this.uc_top = false;
      }

      $(".ypsrk").find("input").focus();
      this.editDrug = u_drug;

      if (u_drug) {
        this.new_input = false;
      } else {
        this.new_input = true;
      }

      // 找定位
      this.tableRow = $(e.target).parents(".u_table_h");
    },
    // 修改药品数量
    editAmount(e, u_drug, type) {
      // 不改百年方的用量
      if (this.bnf.indexOf(u_drug.Drug_Code) != -1 && type == "Amount") return;

      var offset = $(e.target).position();
      var edit_amount = $(".edit_amount");
      edit_amount.css({
        left: offset.left,
        top: offset.top,
        width: e.target.clientWidth + "px",
        height: e.target.clientHeight,
        visibility: "visible",
      });
      setTimeout(() => {
        $(edit_amount[0]).focus().select();
      }, 100);

      u_drug.type = type;
      this.edit_amount = u_drug[type];
      this.editDrug = u_drug;

      if (e.isTrusted) {
        this.new_input = false;
      } else {
        this.new_input = true;
      }
    },
    // 删除开方清单
    del(index) {
      this.drugList.splice(index, 1);
      this.saveData();
    },
    // 检查数量是否为空
    cj_drugList() {
      var keepGoing = true;
      var jsq = 0;
      this.drugList.forEach((i, index) => {
        if (keepGoing) {
          if (!/^\d{1,3}$/.test(i.Amount) || /^0/.test(i.Amount)) {
            this.$Message.error("处方药品数量输入错误！");
            jsq++;
            $($(".ul_yk").find(".u_table_h")[index])
              .find(".u_amount")
              .trigger("click", [i, "Amount"]);
            keepGoing = false;
          }
        }
      });
      // 当数量不为空时跳到插入位置
      if (jsq == 0 && this.new_input) {
        $(".input_new").trigger("click");
      }
      return keepGoing;
    },
    // 数量修改
    edit_key(e) {
      this.update();
      e.target.blur();
    },
    // 失去焦点
    amount_blur() {
      this.update();
      this.editDrug = null;
      this.edit_amount = "";
      this.cj_drugList();
    },
    // 当前数量更新
    update() {
      this.drugList.forEach((i, index) => {
        if (i.Drug_Code == this.editDrug.Drug_Code) {
          if (this.edit_amount) i[this.editDrug.type] = this.edit_amount;
        }
      });
      $(".edit_amount").css({ visibility: "hidden" });
      setTimeout(() => {
        this.edit_amount = "";
      }, 100);
      this.saveData();
    },
    init() { },
  },
  created() {
    this.$store.dispatch("Drug_Decoct_Pattern"); //药品煎药方式
    this.$store.dispatch("Drug_Take_Pattern"); //药品服用方式
    this.btnDisabled = false;
  },
  mounted: function () {
    $(".t_body").scroll(function (e) {
      $(".t_head").scrollLeft(e.currentTarget.scrollLeft);
    });
  },
};
</script>
<style lang="scss">
.u_table2 {
  display: flex;
  flex-direction: column;

  .t_body {
    flex: 1;
  }

  .t_mc .jin {
    display: inline-block;
    width: 20px;
    height: 20px;
    color: #fff;
    border-radius: 100%;
    line-height: 20px;
    font-size: 12px;
    text-align: center;
    transform: scale(0.9);

    i {
      font-style: normal;
      visibility: hidden;
    }
  }

  .isTaboo {
    .jin {
      background: #f00;

      i {
        visibility: visible;
      }
    }

    color: #f00;
  }
}
</style>

