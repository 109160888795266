<!-- 症状考试 -->
<template>
  <layer pagename="medicalPractise" pageClass="medicalPractisePage ks_page">
    <div class="medicalPractise condition e_Symptom">
      <div class="z_title">
        <div class="label">{{type=='formal'?'病历考试':'病历模拟练习'}}</div>
        <div class="djs">
          <span class="tjzw">剩余时间:</span>
          <span class="nb">{{timer}}</span>
          <Button v-if="type=='practice'" type="warning" @click="stop(1)">暂 停</Button>
          <Button type="success" @click="stateEx(1)">交 卷</Button>
          <Button :disabled="isEnd" v-show="paper.examResult==0" @click="tjbz" type="primary">提交症状</Button>
        </div>
      </div>

      <Row class="user_info">
        <Col class="info_k" :md="12" :sm="24">
          <div class="cont">
            <div class>
              <div class="name">
                {{paper.patientname}}
                <span>{{paper.patientSex}}</span>
              </div>
              <div class="u_row">
                <div class="item">
                  <span class="label">年龄：</span>
                  <span class="nr">{{paper.patientAge}}</span>
                </div>
                <div class="item">
                  <span class="label">性别：</span>
                  <span class="nr">{{paper.patientSex}}</span>
                </div>
                <div class="item">
                  <span class="label">主诉：</span>
                  <span class="nr">{{paper.patientPurpose}}</span>
                </div>
              </div>
            </div>
            <div class="info_zz">
              <div class="title">病情描述：</div>
              <div class="bod_zz">
                <div v-show="!paper.pictureList.length" class="item dsNames_k">
                  <span
                    class="dsNames"
                    :key="index"
                    v-for="(item,index) in dsNames(paper.symptomDescs)"
                  >{{item}}</span>
                </div>
                <Carousel v-model="c_index">
                  <CarouselItem :key="index" v-for="(item,index) in paper.pictureList">
                    <div class="demo-carousel">
                      <span ref="zoom" class="zoom" id="image3">
                        <img :src="item.pictureUrl" id="img3" />
                      </span>
                    </div>
                  </CarouselItem>
                </Carousel>
              </div>
            </div>
          </div>
        </Col>
        <Col v-show="paper.examResult==0" class="zz_k" :md="12" :sm="24">
          <Row class="bz_sjk">
            <Col class="yxz_zz" :md="24" :sm="24">
              <div class="title">已选症状</div>
              <div class="cont">
                <div class="u_selectList">
                  <span :key="index" v-for="(fxk,index) in selectList">
                    <Checkbox
                      @on-change="delSymptom(fxk)"
                      :name="'id_'+fxk.dsId"
                      v-model="fxk.Other"
                    >{{fxk.dsName}}</Checkbox>
                  </span>
                </div>
              </div>
            </Col>
            <Col :md="24" :sm="24">
              <symptomSelect sort ref="symptomSelect" :sexAndAge="sexAndAge" v-model="selectList"></symptomSelect>
            </Col>
          </Row>
        </Col>

        <Col v-show="!paper.examResult==0" class="zz_k zz_dats" :md="12" :sm="24">
          <div v-if="type=='practice'" class="hb" :class="{bhg:paper.examResult==2}"></div>
          <div class="wxts">此题已答过了哦!</div>
          <template v-if="paper.isanswer && type=='practice'">
            <div class="da">
              <div class="title">正确答案:</div>
              <div class="no">
                <div class="item dsNames_k" v-if="sysList.length">
                  <span
                    class="dsNames"
                    :class="{s_error:item.isError}"
                    v-for="(item,index) in sysList"
                    :key="index"
                  >{{item.label}}</span>
                </div>
                <div class="item dsNames_k" v-else>无</div>
              </div>
            </div>
            <div class="da">
              <div class="title">我的答案:</div>
              <div class="no">
                <div class="item dsNames_k" v-if="selfList.length">
                  <span
                    class="dsNames"
                    :class="{s_error:item.isError}"
                    v-for="(item,index) in selfList"
                    :key="index"
                  >{{item.label}}</span>
                </div>
                <div class="item dsNames_k" v-else>无</div>
              </div>
            </div>
          </template>
        </Col>
        <div @click="leftIndex" class="fy_btn b_left">
          <Icon type="ios-arrow-back" />
        </div>
        <div @click="rightIndex" class="fy_btn b_rigth">
          <Icon type="ios-arrow-forward" />
        </div>
      </Row>
      <Row>
        <Col class="questList_k" :md="24" :sm="24">
          <div class="cont">
            <div class="questList">
              <span
                @click="clickIndex(item,index)"
                :key="index"
                class="item"
                v-for="(item, index) in p_list"
                :class="setClassName(item)"
              >
                <i>{{index+1}}</i>
                <i v-if="item.examResult==2" class="iconfont icon-close"></i>
              </span>
            </div>
          </div>
        </Col>
      </Row>
      <div v-if="!p_list.length" class="mack_ts">当前无试题可答哦！</div>
      <template v-else>
        <div v-if="!paper.startdate" class="mack_ts">
          <Button @click="stateEx(0)" type="primary" size="large">开始{{type=='formal'?'考试':'练习'}}</Button>
        </div>
      </template>
      <div v-if="isStop" class="mack_ts mack_ts_stop">
        <div @click="stop(2)" class="u_play">
          <Icon type="md-play" />继续练习
        </div>
      </div>
    </div>
  </layer>
</template>
<script>
import symptomSelect from "@/com/symptomSelect.vue";
import zoombie from "@/jquery.zoombie.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  props: ["type"],
  components: {
    symptomSelect
  },
  data() {
    return {
      isStop: false,
      examinationPaperList: [],
      interval: null,
      time: {},
      timer: "",
      isEnd: false, //倒计时是否结束
      endTime: "", //应为接口获取到的结束时间
      c_index: 0, //轮播序号
      p_index: 0, // 当前序号
      p_list: [], // 问题列表
      selectList: [],
      sexAndAge: {},
      selfList: [],
      sysList: [],
      selfList_same: [],
      sysList_same: [],
      startdate: ""
    };
  },
  computed: {
    ...mapGetters([]),
    paper() {
      var item = this.examinationPaperList[this.p_index] || { pictureList: [] };
      if (item.paperId) {
        if (item.isSuspend != 1) {
          //是否暂停状态
          this.setTimer(item);
        } else {
          this.isStop = true;
        }
        if (item.patientSex) {
          this.sexAndAge = {
            age: item.patientAge,
            sex: item.patientSex.trim() == "女" ? 2 : 1
          };
        }
      }
      return item;
    }
  },
  watch: {
    type(value) {
      this.getData();
    },
    examinationPaperList(value) {
      if (!value.length) {
        this.p_list = [];
        this.p_index = 0;
        return;
      }
      var newArr = value;
      newArr.forEach(i => {
        i.active = false;
        i.state = 0;
      });
      this.p_list = newArr;
      this.changeIndex();
    }
  },
  methods: {
    stop(isSuspend) {
      let obj = {
        isSuspend,
        paperTypeId: this.examinationPaperList[0].paperId
      };
      this.api2("pauseOrStart", obj)
        .then(res => {
          this.isStop = isSuspend == 1 ? true : false;
          if (isSuspend == 1) {
            this.isStop = true;
            clearInterval(this.interval);
          } else {
            this.isStop = false;
            this.updateTime(
              this.examinationPaperList,
              res.result.lastRemainingTime
            );
          }
        })
        .catch(err => console.log(err));
    },
    updateTime(list, lastRemainingTime) {
      if (!list.length) return;
      let state = lastRemainingTime ? true : false;
      if (!lastRemainingTime) {
        lastRemainingTime = list[0].lastRemainingTime;
      }
      let longTime = lastRemainingTime ? lastRemainingTime / 60 : 0;
      let startdate = new Date().getTime() - longTime;
      list.forEach((i, index) => {
        if (state) i.isSuspend = 2;
        if (i.startdate != null) i.startdate = startdate;
        if (longTime) i.longTime = longTime;
      });
      setTimeout(() => {
        this.updateIndex();
      }, 300);
    },
    updateIndex() {
      // 跳到为未答题项
      this.p_index = this.examinationPaperList.findIndex(i => !i.isanswer);
      this.changeIndex();
    },
    refresh() {
      if (this.type == "practice") {
        this.getData3();
        return;
      }
      this.$Message.success("已经提交! 即将进入下一题");
      setTimeout(() => {
        this.rightIndex();
      }, 1000);
    },
    sameSymptom(arr1, arr2) {
      let names = arr2.map(i => i.label);
      arr1
        .filter(i => !names.includes(i.label))
        .forEach(i => (i.isError = true));
      return arr1;
    },
    delSymptom(item) {
      setTimeout(() => {
        this.$refs.symptomSelect.clickSymptoms();
      }, 200);
    },
    setTimer(item) {
      this.startdate = item.startdate;
      var longTime = item.longTime;
      // var longTime = 1;
      let startdate =
        typeof this.startdate == "number"
          ? this.startdate
          : new Date(this.startdate).getTime();
      this.endTime = startdate + longTime * 60 * 1000;
      this.setEndTime();
    },
    setEndTime() {
      var that = this;
      clearInterval(that.interval);
      that.interval = setInterval(function timestampToTime() {
        var date = new Date(that.endTime) - new Date(); //计算剩余的毫秒数
        if (date <= 0) {
          that.timer = "00:00:00";
          that.isEnd = true;
          clearInterval(that.interval);
          if (that.startdate) {
            that.$Modal.warning({
              title: "提示",
              width: 300,
              content: "考试已经结束,系统将自动交卷!",
              onOk: () => {
                that.submit(1); // 交卷
              }
            });
          }
        } else {
          that.isEnd = false;
          that.time.D = parseInt(date / 1000 / 60 / 60 / 24, 10);
          that.time.h = parseInt((date / 1000 / 60 / 60) % 24, 10);
          if (that.time.h < 10) {
            that.time.h = "0" + that.time.h;
          }
          that.time.m = parseInt((date / 1000 / 60) % 60, 10); //计算剩余的分钟
          if (that.time.m < 10) {
            that.time.m = "0" + that.time.m;
          }
          that.time.s = parseInt((date / 1000) % 60, 10); //计算剩余的秒数
          if (that.time.s < 10) {
            that.time.s = "0" + that.time.s;
          }
          that.timer = that.time.h + ":" + that.time.m + ":" + that.time.s;
        }
      }, 1000);
    },
    stateEx(typeId) {
      // 0 开始考试  1 结束考试
      if (typeId == 1) {
        this.$Modal.confirm({
          content: "您确认提交试卷吗?",
          width: "300px",
          onOk: () => {
            clearInterval(this.interval);
            this.submit(typeId);
          }
        });
      } else {
        this.submit(typeId);
      }
    },
    submit(typeId) {
      // 0 开始考试  1 结束考试
      var obj = {
        paperId: this.paper.paperId, //	考试类型 0 练习 1 正式考试
        typeId //数据类型 0 开始考试 1 结束考试
      };
      if (typeId) {
        obj.fstatus = 1;
      }
      this.$store.commit("openSpin");
      this.api2("updateStartEndExaminationPaper", obj).then(res => {
        this.$store.commit("closeSpin");
        this.examinationPaperList.forEach(i => {
          i.startdate = this.common.parseTime(
            new Date(),
            "{y}-{m}-{d} {h}:{i}:{s}"
          );
          i.enddate = res.result.endDate;
        });
        if (res.result.endDate) {
          this.$Message.success("您已交卷成功!");
          this.$router.back();
        }
      });
    },
    getData3() {
      var obj = {
        symptomExaminationMainDTO: {
          mainId: this.paper.mainId
        }
      };
      this.api2("getSymptomAnswerResultBySubjectId", obj).then(res => {
        let s = res.result.selfSymptom.map(i => {
          return {
            label: i.dsNames,
            isError: false
          };
        });
        let y = res.result.sysSymptom.dsNames.split(/,\s*/).map(i => {
          return {
            label: i,
            isError: false
          };
        });
        this.selfList = this.sameSymptom(s, y);
        this.sysList = this.sameSymptom(y, s);
      });
    },
    dsNames(str) {
      return str ? str.split(",") : [];
    },
    // 提序号点击事件
    clickIndex(item, index) {
      this.p_index = index;
      this.changeIndex();
    },
    changeIndex() {
      this.p_list.forEach(i => {
        i.active = false;
      });
      this.p_list[this.p_index].active = true;
      if (this.p_list[this.p_index].isanswer && this.type == "practice") {
        this.getData3(); //获取答案
      }
      setTimeout(() => {
        this.setZoombie();
      }, 1000);
    },
    // 下一题
    rightIndex() {
      if (this.p_index + 1 < this.p_list.length) {
        this.p_index++;
        this.changeIndex();
      } else {
        this.$Message.warning("已经是最后一题!");
      }
    },
    // 下一题
    leftIndex() {
      if (this.p_index - 1 >= 0) {
        this.p_index--;
        this.changeIndex();
      } else {
        this.$Message.warning("已经是第一题!");
      }
    },
    setClassName(item) {
      var cNameList = [];
      if (item.active) cNameList.push("active");
      if (item.examResult == 1) cNameList.push("isAnswer");
      if (item.examResult == 2 || item.examResult == 3)
        cNameList.push("isError");
      return cNameList;
    },

    // 提交症状
    tjbz() {
      var must = this.$refs.symptomSelect.must(); //验证必选项
      if (!must) return;
      let mainId = this.paper.mainId;

      var detailList = [],
        mySymptoms = [];
      this.selectList.forEach(y => {
        detailList.push({
          dsId: y.dsId, //	症状字典症状集编码
          dsNames: y.dsName, //症状字典症状集名称
          mainId //症状考核主表Id
          // detailId //	详情id,新增时不传
        });
        mySymptoms.push(y.dsName);
      });
      var obj = {
        mainId,
        detailList: detailList

        // dsIds: "", //	参考症状集
        // dsNames: "", //	参考患者症状集
        // examResult: "", //	考试结果
        // paperId: "", //试卷Id
        // patientAge: "", //	患者年龄
        // patientName: "", //	患者名称
        // patientPurpose: "", //	患者就诊目的
        // patientSex: "", //	患者性别
        // remark: "", //	备注
        // symptomDescs: "" //	病历症状描述
      };
      if (detailList.length) {
        this.$store.commit("openSpin");

        this.api2("insertSymptom", { symptomExaminationMainDTO: obj })
          .then(res => {
            this.$store.commit("closeSpin");
            this.paper.examResult = res.result.ExamResult;
            this.paper.isanswer = 1;
            this.paper.symptoms = res.result.Symptoms;
            this.refresh();
          })
          .catch(err => console.log(err));

        this.paper.mySymptoms = mySymptoms.join(",");
      } else {
        this.$Message.error("请选择症状后再提交哦！");
      }
    },
    setZoombie() {
      if (!this.$refs.zoom || !this.$refs.zoom.length) return;
      this.$refs.zoom.forEach(i => {
        if (i.className == "zoom")
          zoombie.zoombie_run({ target: i, on: "grab" });
      });
    },
    getData() {
      var obj = {
        paperTypeId: 1, // 试卷类型 0 百年方 1 症状
        examinationTypeId: this.type == "formal" ? 1 : 0 // 考试类型 0 模似 1 正式考试
      };
      this.$store.commit("openSpin");
      this.api2("getExaminationPaperList", obj).then(res => {
        this.$store.commit("closeSpin");
        this.updateTime(res.result);
        this.examinationPaperList = res.result;
      });
    }
  },
  created() {
    this.getData();
  },
  mounted: function() {}
};
</script>
