<template>
  <span class="ivu-badge">
    <span class="ivu-badge-body" v-if="changeText || isAddText">
      <sup v-if="isAddText" class="ivu-badge-count">{{ isAddText }}</sup>
      <sup
        v-if="changeText"
        class="ivu-badge-count"
        :class="
          'ivu-badge-count-' +
          (symptomInfo.symptomChanges == 2 ? 'success' : 'error')
        "
        >{{ changeText }}</sup
      >
    </span>
    <slot></slot>
  </span>
</template>

<script>
export default {
  props: {
    symptomInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isInputPage: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    changeText() {
      if (this.isInputPage && !this.symptomInfo.Other) return "";
      switch (this.symptomInfo.symptomChanges) {
        case 1:
          return "加重";
        case 2:
          return "减轻";
        default:
          return "";
      }
    },
    isAddText() {
      return !this.isInputPage && this.symptomInfo.isAddDs ? "增" : "";
    },
  },
};
</script>

<style lang="scss" scoped>
.ivu-badge {
  .ivu-badge-count {
    height: 16px;
    line-height: 14px;
    padding: 0 4px;
    position: relative;
    font-size: 75% !important;
    transform: none;
    margin-right: 2px;
    top: 0;
  }
  .ivu-badge-body {
    position: absolute;
    top: -10px;
    right: -10px;
    display: flex;
  }
}
</style>