<template>
  <div class="text-more taboo">
    <div class="title">
      {{ title }}
      <Button v-if="html" type="text" size="small" @click="onshow">
        {{ show? "收起": "展开" }}更多
        <Icon v-if="show" type="ios-arrow-up" />
        <Icon v-else type="ios-arrow-down" />
      </Button>
    </div>
    <div class="taboo-text" :class="{ 'taboo-text-more': show }" v-html="html" v-if="html"></div>
    <div v-else>
      <empty text="暂无内容" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "标题",
    },
    html: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    onshow() {
      this.show = !this.show;
    },
  },
};
</script>

<style lang="scss" scoped>
.text-more {
  margin-bottom: 10px;

  .taboo-text {
    line-height: 26px;
    height: 50px;
    overflow: hidden;
  }

  .taboo-text-more {
    height: auto;
  }
}
</style>