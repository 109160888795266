<template>
  <div class="checkVersion"></div>
</template>

<script>
import axios from "axios";
export default {
  name:'checkVersion',
  props: {
    // 不删除的localStroe内容
    exclude: {
      type: Array,
      default: function() {
        return [];
      }
    },
    storageKey:{
      type: String,
      default: '_version_'
    }
  },
  methods: {
    clearStore() {
      for (const key in localStorage) {
        if (localStorage.hasOwnProperty(key)) {
          if (!this.exclude.includes(key)) {
            localStorage.removeItem(key);
          }
        }
      }

      for (const key in sessionStorage) {
        if (sessionStorage.hasOwnProperty(key)) {
          if (!this.exclude.includes(key)) {
            sessionStorage.removeItem(key);
          }
        }
      }

    },
    checkVersion() {
      axios
        .get(`/static/version.json?r=` + Math.random(), {
          headers: { "Cache-Control": "no-cache" }
        }) // 反正就是要请求到json文件的内容, 并且禁止缓存
        .then(res => {
          let date = new Date(res.headers.date);
          let dateOffset = date.getTime() - new Date().getTime();
          localStorage.setItem("dateOffset", dateOffset);

          const version = res.data.version;
          this.$store.commit("setVersion", version);
          const clientVersion = localStorage.getItem(this.storageKey);
          // console.log("最新版本>>" + version, "当前版本>>" + clientVersion);
          // 和最新版本不同，刷新页面
          if (version !== clientVersion) {
            this.clearStore();
            localStorage.setItem(this.storageKey, version);
            window.location.reload();
          }
        });
    }
  },
  created() {
    this.checkVersion();
    setInterval(() => {
      this.checkVersion();
    }, 60000); // 我这儿1分钟一次
  }
};
</script>

<style > 
  .checkVersion{
    height: auto !important;
  }
</style>