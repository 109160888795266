<template>
  <div class="u_content">
    <slot></slot>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    pagename: "",
    pageClass: ""
  },
  data() {
    return {
      title: ""
    };
  },
  methods: {
    init() {
      this.$store.commit("setPagename", this.pagename);
      this.$store.commit("setPageClass", this.pageClass);
    }
  },
  created() {
    this.init();
  }
};
</script>

<style lang="scss" scoped>
</style>