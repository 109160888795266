
<template>
  <div id="app" :class="pageClass">
    <audio muted class="audio" id="audio1" :loop="expert" controls="controls" :src="audioUrl[expert ? 1 : 0]"></audio>
    <div class="u_body" :class="{ expert: expert }">
      <template v-if="onLogin">
        <camera21 />
        <camera3 />
      </template>

      <div class="u_body" :class="{ expert: expert }">
        <div class="index">
          <div class="layout" :class="'page_' + pagename">
            <Layout>
              <newsCenter v-if="['condition', 'prescription', 'prescription_zj'].includes(
                pagename
              )
                "></newsCenter>
              <Uheader v-if="showApp && showPad" />
              <Content v-if="showApp">
                <router-view />
                <Spin class="u_spin" v-if="indexSpin.show" fix>
                  <Icon type="ios-loading" size="30" class="demo-spin-icon-load"></Icon>
                  <div>{{ indexSpin.text || "加载中..." }}</div>
                </Spin>
              </Content>
              <Spin class="openApp_loader" v-else fix>
                <div class="u_loader_k">
                  <div class="u_loader"></div>
                  <img src="@/assets/images/logo.png" alt />
                </div>
              </Spin>
              <Footer v-if="!isElectron" class="layout-footer-center">
                <div class="yw_bq">
                  Copyright © 2017 慈脉 版本号V{{ version }}&nbsp;&nbsp;
                  <a href="https://beian.miit.gov.cn/" target="_blank">赣ICP备2023012367号-3</a>&nbsp;&nbsp;
                  <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=36110202000541" target="_blank"><span style="margin-left:5px;">赣公网安备36110202000541号</span></a>&nbsp;&nbsp;
                  <a class="ssl-logo" target="_blank"
                    href="https://xyt.xcc.cn/getpcInfo?sn=1589954693172277248&certType=8&url=*.cimaizhongyi.com&language=CN"><img
                      src="https://program.xinchacha.com/xyt/xcc_small_ssl.png" style="width: 18px; highet: 18px" /></a>
                </div>
              </Footer>
              <div v-if="u_notice.content" @click="$store.commit('openMsage')" class="gongao_m">
                <i class="iconfont icon-tongzhi1"></i>
              </div>
            </Layout>
          </div>
        </div>
      </div>
    </div>
    <checkVersion :exclude="exclude" storageKey="_zhihu_version_" />
  </div>
</template>
<script>
import Cookies from "js-cookie";
import setKeyjs from "@/com/setKey.js";
import checkVersion from "@/com/checkVersion.vue";
import { mapGetters } from "vuex";
import * as Sentry from "@sentry/vue";

export default {
  name: "App",
  components: {
    checkVersion,
  },
  data() {
    return {
      showPad:  true,
      exclude: ["userInfo", "userSystemConfig"],
    };
  },
  computed: {
    ...mapGetters([
      "userInfo",
      "onLogin",
      "expert",
      "audioUrl",
      "isNoviceGuide",
      "showApp",
      "u_notice",
      "pagename",
      "pageClass",
      "indexSpin",
      "version",
      "showPadPage"
    ]),
  },
  watch: {
    $route(value) {
      this.changePath();
      this.setTitle();
    },
    expert(value) {
      // if (value) $("body").addClass("expert");
    },
    showPadPage(val1,val2){
      this.showPad = false
      setTimeout(() => {
        this.showPad = true
      }, 100);
    }
  },
  methods: {
    changePath() {
      this.u_router();
      this.inOut();
      // this.pageClass = [];
      // if (this.$route.name) this.pageClass.push(this.$route.name);
    },
    u_router() {
      let mode_name = location.hash.split("/");
      this.$store.commit("setNavActive", mode_name[1]);
    },
    // 是否登录
    inOut() {
      var urlStr = this.$route;
      if (urlStr.query.expert) {
        //徐氏专家登录
        this.$store.commit("setUserType", 1);
      }
      if (window.location.hash != "#/" && !this.userInfo.husDoctorId) {
        this.$router.push("/");
      } else if (window.location.hash == "#/" && this.userInfo.husDoctorId) {
        this.$router.push("/index");
      }

      // 如果当前处于登录页 那么打开页面显视 否则会在登录状态下加载所必须的接口后才会显视页面
      if (window.location.hash == "#/") {
        this.$store.commit("setShowApp", true);
      }
    },
    clearStorage() {
      let exclude = ["userInfo"]; //排除工删除的缓存
      for (const key in localStorage) {
        if (localStorage.hasOwnProperty(key)) {
          if (!exclude.includes(key)) {
            localStorage.removeItem(key);
          }
        }
      }
    },
    setTitle() {
      if (/ks_page/.test(this.pageClass)) {
        this.title = "慈脉医生病案练习考试系统";
      } else {
        this.title = "慈脉云诊中医辅助诊疗系统";
      }
      let label = "";
      if (config.versionType == "test") {
        label = "【 测试版 】";
      } else if (config.versionType == "trial") {
        label = "【 试用版 】";
      }
      document.title = label + this.title;
    },

    init() {
      // let userSystemConfig = localStorage.getItem("userSystemConfig");
      // if (userSystemConfig) this.$store.commit("saveuserSystemConfig", JSON.parse(userSystemConfig));


      let handPicList = localStorage.getItem("handPicList");
      if (handPicList)
        this.$store.commit("setHandPicData", JSON.parse(handPicList));

      let saveCondition = localStorage.getItem("saveCondition");
      if (saveCondition)
        this.$store.commit("setSaveCondition", JSON.parse(saveCondition));

      let savePrescription = localStorage.getItem("savePrescription");
      if (savePrescription)
        this.$store.commit("setSavePrescription", JSON.parse(savePrescription));

      let tabooDiscriminate = localStorage.getItem("tabooDiscriminate");
      if (tabooDiscriminate)
        this.$store.commit("setTabooDiscriminate", {
          data: JSON.parse(tabooDiscriminate),
          getLocal: true,
        });

      if (config.isEnableDogLock) {
        let ukey = setKeyjs.getInstance();
        ukey.setLeader(this);
        ukey.myonLoad();
      }

      // 开始引导
      // var introStart = JSON.parse(localStorage.getItem("introStart"));
      // if (introStart && introStart == "1") {
      //   this.$store.commit("setIntroStart", true);
      // }

      let cookies_userInfo = Cookies.get("userInfo");
      if (cookies_userInfo) {
        var storeUserInfo = JSON.parse(localStorage.getItem("userInfo"));
        if (storeUserInfo) this.$store.commit("setUserInfo", storeUserInfo);
        this.u_router();
      }
      this.setTitle();
    },
  },

  created() {
    this.init();
  },

  mounted() {
    // 监听网格是否断开
    if (navigator && navigator.connection) {
      navigator.connection.addEventListener("change", (e) => {
        clearTimeout(this.djs);
        this.djs = setTimeout(() => {
          if (!navigator.onLine) {
            this.$Message.warning("无法连接网络,请检查网络设置后重试!");
          }
        }, 100);
      });
    }

    let _this = this;
    // 设置音频
    this.$store.commit("setAudio", $("#audio1")[0]);

    // 全局提示配置
    this.$Message.config({
      top: 80,
      duration: 3,
    });
    this.$Notice.config({
      top: 120,
      duration: 10,
    });

    // 禁右键菜单
    $(document).bind("contextmenu", function (e) {
      return false;
    });

    this.changePath();

    document.body.addEventListener(
      "mousedown",
      function () {
        _this.$store.commit("setaudioOn");
      },
      false
    );
  },
};
</script>

