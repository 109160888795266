<!-- 症状考试 -->
<template>
  <layer pagename="prescription" pageClass="prescriptionPage ks_page">
    <div class="prescription medicalPractise e_Symptom e_Prescription">
      <div class="z_title">
        <div class="label">{{type=='formal'?'百年方考试':'百年方模拟练习'}}</div>
        <div class="djs">
          <span class="tjzw">剩余时间:</span>
          <span class="nb">{{timer}}</span>
          <Button v-if="type=='practice'" type="warning" @click="stop(1)">暂 停</Button>
          <Button type="primary" @click="stateEx(1)">交 卷</Button>
        </div>
      </div>
      <Row class="info_k">
        <Col :md="24">
          <div class="cont">
            <Row class="bod">
              <Col class="user_k" :md="5" :sm="24">
                <div class="user_info">
                  <div class="name">
                    {{paper.patientname}}
                    <span>{{paper.patientSex}}</span>
                  </div>
                  <div class="item">
                    <span class="label">年龄：</span>
                    <span class="nr">{{paper.patientAge}}</span>
                  </div>
                  <div class="item">
                    <span class="label">主诉：</span>
                    <span class="nr">{{paper.patientPurpose}}</span>
                  </div>
                  <div class="item" :class="{'new':isNew('2020-04-09')}">
                    <span class="label">中医诊断：</span>
                    <span class="nr">{{paper.diseaseName}}</span>
                    <img class="newPic" src="@/assets/images/new.gif" alt />
                  </div>
                  <div class="item">
                    <span class="label">就诊时间：</span>
                    <span class="nr">{{paper.visitDatetime}}</span>
                  </div>
                  <div class="item dsNames_t">
                    <span class="label">症状：</span>
                  </div>
                  <div class="item dsNames_k">
                    <span class="dsNames" v-for="(item,index) in dsNames" :key="index">{{item}}</span>
                  </div>
                </div>
              </Col>
              <Col v-show="paper.isanswer==0 && !isEnd" class="info_cf" :md="19" :sm="24">
                <div class="title">
                  拟定处方：
                  <span class="xh">
                    <i class="active">{{p_index+1}}</i>
                    <i class>/{{examinationPaperList.length}}</i>
                  </span>
                </div>
                <div class="bz_yk">
                  <div class="u_head">
                    <div class="u_zd">
                      <div class="selectGroup">
                        <Input @on-keyup.enter="enterDz" class="stickInput" v-model="stick">
                          <span slot="prepend">帖数</span>
                        </Input>
                      </div>
                    </div>
                  </div>
                  <div class="u_table2">
                    <div class="t_head">
                      <ul class="u_table_h">
                        <li class="t_xh">
                          <span>序号</span>
                        </li>
                        <li class="t_mc">
                          <span>名称</span>
                        </li>
                        <li class="t_gg">
                          <span>规格</span>
                        </li>
                        <li class="t_sl">
                          <span>数量</span>
                        </li>
                        <li class="t_dw">
                          <span>单位</span>
                        </li>
                        <li class="t_ts">
                          <span>帖数</span>
                        </li>
                        <li class="t_tz">
                          <span>操作</span>
                        </li>
                      </ul>
                    </div>
                    <div class="t_body">
                      <div class="ul_yk">
                        <Ucomplete
                          class="ypsrk"
                          :class="{popup_top:uc_top}"
                          @on-blur="drugSearchblur"
                          @on-select="changeValue3"
                          :data="drug"
                        ></Ucomplete>
                        <input
                          @blur="amount_blur"
                          @keyup.enter="edit_key"
                          v-model="edit_amount"
                          class="edit_amount"
                          type="number"
                        />
                        <ul
                          v-for="(item,index) in prescription.drugList"
                          :key="index"
                          class="u_table_h"
                        >
                          <li class="t_xh">
                            <span>{{index+1}}</span>
                          </li>
                          <li class="t_mc" @click="editDrugName($event,item)">
                            <span>{{item.Drug_Name}}</span>
                          </li>
                          <li class="t_gg">
                            <span>{{item.specifications}}</span>
                          </li>
                          <li class="t_sl" @click="editAmount($event,item,'Amount')">
                            <span class="u_amount">{{item.Amount}}</span>
                          </li>
                          <li class="t_dw">
                            <span>{{item.UnitName}}</span>
                          </li>
                          <li class="t_ts">
                            <span @click="editAmount($event,item,'Quantity')">{{item.Quantity}}</span>
                          </li>
                          <li class="t_tz">
                            <span>
                              <Button :disabled="item.delBtn" @click="del(index)">删除</Button>
                            </span>
                          </li>
                        </ul>
                        <ul class="u_table_h">
                          <li class="t_xh">
                            <span>{{prescription.drugList.length+1}}</span>
                          </li>
                          <li class="t_mc">
                            <span class="input_new" @click="editDrugName($event,'')">输入拼音首字母</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="btn">
                  <Button :disabled="isEnd" type="primary" @click="submitPrescription">提 交</Button>
                </div>
              </Col>
              <Col v-if="!paper.isanswer && isEnd" class="info_cf" :md="19" :sm="24">
                <div class="wxts">考试已经结束! 提交试卷吧</div>
              </Col>
              <Col v-if="paper.isanswer" class="info_cf" :md="19" :sm="24">
                <div class="wxts" v-if="paper.examResult==0">此题已答过了哦!</div>
                <div class="wxts" v-if="paper.examResult==1">合格</div>
                <div class="wxts" v-if="paper.examResult==2">不合格</div>
                <div class="wxts" v-if="paper.examResult==3">百年方待审核</div>

                <div class="y_cf cfdb">
                  <div class="m_cf_title">
                    <span>
                      <i class="iconfont icon-jiageprice1"></i> 专家拟定处方
                    </span>
                  </div>
                  <div class="nr">
                    <div>
                      <span class="bt">中医诊断：</span>
                      <span class="da">{{sys_d}}</span>
                    </div>
                    <div class="cf_bnf" :class="{cf_error:selfBnf!=yBnf}">{{yBnf}}</div>
                    <div class="cf_item_m">
                      <div class="t_bq">同：</div>
                      <div class="y_item">{{sysList_same || '无'}}</div>
                    </div>
                    <div class="cf_item_m yc_cf">
                      <div class="t_bq">异：</div>
                      <div class="y_item">{{sysList || '无'}}</div>
                    </div>
                    <!-- <div class="ties"><span>x</span><span class="sl">7</span></div> -->
                  </div>
                </div>
                <div class="m_cf cfdb">
                  <div class="m_cf_title">
                    <span>
                      <i class="iconfont icon-jiageprice1"></i> 我的处方
                    </span>
                  </div>
                  <div class="nr">
                    <div>
                      <span class="bt">中医诊断：</span>
                      <span class="da">{{self_d}}</span>
                    </div>
                    <div class="cf_bnf" :class="{cf_error:selfBnf!=yBnf}">{{selfBnf}}</div>
                    <div class="cf_item_m">
                      <div class="t_bq">同：</div>
                      <div class="y_item">{{selfList_same || '无'}}</div>
                    </div>
                    <div class="cf_item_m yc_cf">
                      <div class="t_bq">异：</div>
                      <div class="y_item">{{selfList || '无'}}</div>
                    </div>
                    <!-- <div class="ties"><span>x</span><span class="sl">{{self_Nb}}</span></div> -->
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <div @click="leftIndex" class="fy_btn b_left">
          <Icon type="ios-arrow-back" />
        </div>
        <div @click="rightIndex" class="fy_btn b_rigth">
          <Icon type="ios-arrow-forward" />
        </div>
      </Row>
      <Row>
        <Col class="questList_k" :md="24" :sm="24">
          <div class="cont">
            <div class="questList">
              <span
                @click="clickIndex(item,index)"
                class="item"
                v-for="(item, index) in p_list"
                :key="index"
                :class="setClassName(item)"
              >
                <i>{{index+1}}</i>
                <i v-if="item.examResult==2 || item.examResult==3" class="iconfont icon-close"></i>
              </span>
            </div>
          </div>
        </Col>
      </Row>
      <div v-if="!p_list.length" class="mack_ts">当前无试题可答哦！</div>
      <template v-else>
        <div v-if="!paper.startdate" class="mack_ts">
          <Button @click="stateEx(0)" type="primary" size="large">开始{{type=='formal'?'考试':'练习'}}</Button>
        </div>
      </template>
      <div v-if="isStop" class="mack_ts mack_ts_stop">
        <div @click="stop(2)" class="u_play">
          <Icon type="md-play" />继续练习
        </div>
      </div>
    </div>
  </layer>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  props: ["type"],
  // props: {
  //   type: {
  //     type: String,
  //     default:''
  //   },
  // },
  data() {
    return {
      // type: '',
      isStop: false,
      // examinationPaperDetail: [], //测试答案
      examinationPaperList: [], //试卷题列表
      selfBnf: "",
      yBnf: "",
      selfList: "",
      selfList_same: "",
      sysList: "",
      sysList_same: "",
      sys_d: "",
      self_d: "",
      self_Nb: "",

      uc_top: false,
      startdate: "",
      interval: null,
      time: {},
      timer: "",
      isEnd: false, //倒计时是否结束
      endTime: "", //应为接口获取到的结束时间

      ties: 7,
      c_index: 0, //轮播序号
      // 帖数
      stick: 7,

      // 开方信息
      prescription: {
        DrugTaboos: [],
        u_jj: "",
        u_dz: "",
        Quantity: 1,
        diagnosis1: { DiseaseName: "" },
        diagnosis2: { DiseaseName: "" },
        drugList: []
      },

      // 临时药品ID 编辑药品清单用
      editDrug: null,

      // 临时病症列表
      diseaseList: [],

      //编辑药品数量
      edit_amount: "",

      // 设置药品搜索列表
      searchDdrugList: [],

      // 行定位
      tableRow: "",

      // 表格高度
      u_table_heigh: { height: "" },
      u_table_scroll: { height: "" },

      // 是否新插入
      new_input: false,

      searchList: [],
      selectList: [],

      // 历史处方
      oldPrescription: {
        oldDrug: [], // 历史用药
        fNumber: "", //帖数
        diagnosis: "", //诊断
        diagnosisArr: "", //诊断
        oldSymptom: [] // 历史用药
      },
      // 倒计时
      djs: null,
      bnf: [
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31"
      ], //百年方Drug_Code

      jzmd: { DiseaseName: "", DiseaseId: "" }, //主诉
      p_index: 0, // 当前序号
      p_list: [], // 问题列表
      activeItem: {}
    };
  },
  computed: {
    ...mapGetters([
      "diseaseInfos",
      "drug",
      "hundredDrugs",
      "drugTaboos",
      "diagnosticList",
      "userInfo",
      "patientList",

      "patientSeeDoctorInfo", //病人看医生信息
      "spinShow", //处方加载动画开关
      "spinShow_hz", //处方提交中动画开关

      "medicalRecordDetailByBillNo"
    ]),
    paper() {
      var item = this.examinationPaperList[this.p_index] || {};
      if (item.paperId) {
        if (item.isSuspend != 1) {
          //是否暂停状态
          this.setTimer(item);
        } else {
          this.isStop = true;
        }
      }
      return item;
    },

    dsNames() {
      return this.paper.dsNames
        ? this.paper.dsNames.replace(/,\s*$/, "").split(",")
        : [];
    }
  },
  watch: {
    type(value) {
      this.getData();
    },
    stick(value) {
      this.prescription.drugList.forEach((i, index) => {
        var u_drug = this.drug.find(u => u.Drug_Code == i.Drug_Code);
        if (u_drug && (u_drug.Drug_Type == "06" || u_drug.Drug_Type == "01")) {
          i.Quantity = value;
        }
      });
      this.prescription.Quantity = value;
    },
    examinationPaperList(value) {
      if (!value.length) {
        this.p_list = [];
        this.p_index = 0;
        return;
      }
      var newArr = value;
      newArr.forEach(i => {
        i.active = false;
        i.state = 0;
      });
      this.p_list = newArr;
      this.changeIndex();
    }
  },
  methods: {
    stop(isSuspend) {
      let obj = {
        isSuspend,
        paperTypeId: this.examinationPaperList[0].paperId
      };
      this.api2("pauseOrStart", obj)
        .then(res => {
          this.isStop = isSuspend == 1 ? true : false;
          if (isSuspend == 1) {
            this.isStop = true;
            clearInterval(this.interval);
          } else {
            this.isStop = false;
            this.updateTime(
              this.examinationPaperList,
              res.result.lastRemainingTime
            );
          }
        })
        .catch(err => console.log(err));
    },
    updateTime(list, lastRemainingTime) {
      if (!list.length) return;
      let state = lastRemainingTime ? true : false;
      if (!lastRemainingTime) {
        lastRemainingTime = list[0].lastRemainingTime;
      }
      let longTime = lastRemainingTime ? lastRemainingTime / 60 : 0;
      let startdate = new Date().getTime() - longTime;
      list.forEach((i, index) => {
        if (state) i.isSuspend = 2;
        if (i.startdate != null) i.startdate = startdate;
        if (longTime) i.longTime = longTime;
      });

      setTimeout(() => {
        this.updateIndex();
      }, 300);
    },
    updateIndex() {
      // 跳到为未答题项
      this.p_index = this.examinationPaperList.findIndex(i => !i.isanswer);
      this.changeIndex();
    },
    init() {
      this.selfBnf = "";
      this.yBnf = "";
      this.selfList = "";
      this.selfList_same = "";
      this.sysList = "";
      this.sysList_same = "";
      this.sys_d = "";
      this.self_d = "";
      this.self_Nb = "";
    },
    setTimer(item) {
      this.startdate = item.startdate;
      var longTime = item.longTime;
      let startdate =
        typeof this.startdate == "number"
          ? this.startdate
          : new Date(this.startdate).getTime();
      this.endTime = startdate + longTime * 60 * 1000;
      this.setEndTime();
    },
    setEndTime() {
      var that = this;
      clearInterval(that.interval);
      that.interval = setInterval(function timestampToTime() {
        var date = new Date(that.endTime) - new Date(); //计算剩余的毫秒数
        if (date <= 0) {
          that.timer = "00:00:00";
          that.isEnd = true;
          clearInterval(that.interval);
          if (that.startdate) {
            that.$Modal.warning({
              title: "提示",
              width: 300,
              content: "考试已经结束,系统将自动交卷!",
              onOk: () => {
                that.submit(1); // 交卷
              }
            });
          }
        } else {
          that.isEnd = false;
          that.time.D = parseInt(date / 1000 / 60 / 60 / 24, 10);
          that.time.h = parseInt((date / 1000 / 60 / 60) % 24, 10);
          if (that.time.h < 10) {
            that.time.h = "0" + that.time.h;
          }
          that.time.m = parseInt((date / 1000 / 60) % 60, 10); //计算剩余的分钟
          if (that.time.m < 10) {
            that.time.m = "0" + that.time.m;
          }
          that.time.s = parseInt((date / 1000) % 60, 10); //计算剩余的秒数
          if (that.time.s < 10) {
            that.time.s = "0" + that.time.s;
          }
          that.timer = that.time.h + ":" + that.time.m + ":" + that.time.s;
          // return that.time.D+that.time.h+that.time.m+that.time.s;
        }
      }, 1000);
    },
    stateEx(typeId) {
      // 0 开始考试  1 结束考试
      if (typeId) {
        this.$Modal.confirm({
          content: "您确定要交卷吗?",
          width: "300px",
          onOk: () => {
            clearInterval(this.interval);
            this.submit(typeId);
          }
        });
      } else {
        this.submit(typeId);
      }
    },
    submit(typeId) {
      // 0 开始考试  1 结束考试
      var obj = {
        paperId: this.paper.paperId, //	考试类型 0 练习 1 正式考试
        typeId //数据类型 0 开始考试 1 结束考试
      };
      if (typeId) {
        obj.fstatus = 1;
      }
      this.$store.commit("openSpin");
      this.api2("updateStartEndExaminationPaper", obj).then(res => {
        this.$store.commit("closeSpin");
        this.examinationPaperList.forEach(i => {
          i.startdate = this.common.parseTime(
            new Date(),
            "{y}-{m}-{d} {h}:{i}:{s}"
          );
          i.enddate = res.result.endDate;
        });
        if (res.result.endDate) {
          this.$Message.success("您已交卷成功!");
          this.$router.back();
        }
      });
    },
    getData3() {
      var obj = {
        bankId: this.activeItem.bankId,
        paperTypeid: this.activeItem.paperTypeId,
        symptomExaminationMainDTO: {
          mainId: this.activeItem.mainId
        }
      };
      this.$store.commit("openSpin");
      this.api2("getAnswerResultBySubjectId", obj).then(res => {
        this.$store.commit("closeSpin");
        res = res.result;
        var se = res.selfList,
          sy = res.sysList;
        var e = [];
        if (se.length) {
          e = se
            .filter(i => !/百年/.test(i.drugName))
            .map(i => {
              return i.drugName + " x " + i.quantity;
            });
        }
        var y = [];
        if (se.length) {
          y = sy
            .filter(i => !/百年/.test(i.drugName))
            .map(i => {
              return i.drugName + " x " + i.quantity;
            });
        }

        this.selfBnf = se
          .filter(i => /百年/.test(i.drugName))
          .map(i => {
            return i.drugName + " x " + i.quantity;
          })
          .join("，");
        this.yBnf = sy
          .filter(i => /百年/.test(i.drugName))
          .map(i => {
            return i.drugName + " x " + i.quantity;
          })
          .join("，");

        this.selfList = this.sameList(e, y, false).join("，");
        this.selfList_same = this.sameList(e, y, true).join("，");

        this.sysList = this.sameList(y, e, false).join("，");
        this.sysList_same = this.sameList(y, e, true).join("，");

        this.sys_d = res.diseaseName;
        this.self_d = se[0] ? se[0].diseaseType : "";
        this.self_Nb = se[0] ? se[0].number : "";
      });
    },
    sameList(arr1, arr2, same) {
      if (same) {
        return arr1.filter(i => arr2.includes(i));
      } else {
        return arr1.filter(i => !arr2.includes(i));
      }
    },
    enterDz() {
      $(".input_new").trigger("click");
    },
    // 提序号点击事件
    clickIndex(item, index) {
      this.init();
      this.p_index = index;
      this.changeIndex();
    },
    changeIndex() {
      // 清数据
      this.prescription.diagnosis2 = { DiseaseName: "" };
      this.prescription.drugList = [];

      this.p_list.forEach(i => {
        i.active = false;
      });
      this.p_list[this.p_index].active = true;
      this.activeItem = this.p_list[this.p_index];
      if (this.activeItem.isanswer && this.type == "practice") {
        this.getData3();
      }
    },
    // 下一题
    rightIndex() {
      if (this.p_index + 1 < this.p_list.length) {
        this.p_index++;
        this.changeIndex();
      } else {
        this.$Message.info("已经是最后一题!");
      }
    },
    // 上一题
    leftIndex() {
      if (this.p_index - 1 >= 0) {
        this.p_index--;
        this.changeIndex();
      } else {
        this.$Message.info("已经是第一题!");
      }
    },
    setClassName(item) {
      var cNameList = [];
      if (item.active) cNameList.push("active");
      if (item.isanswer) cNameList.push("isAnswer");
      return cNameList;
    },

    // 提交处方
    submitPrescription() {
      if (this.cj_drugList("sub")) {
        var DiseaseName = this.paper.diseaseName;
        if (this.prescription.drugList.length) {
          var drugsList = [],
            cf = [];
          this.prescription.drugList.forEach(i => {
            drugsList.push({
              drugCode: i.Drug_Code,
              drugName: i.Drug_Name,
              quantity: i.Amount,
              unit: i.UnitName
            });
            cf.push(i.Drug_Name + " x " + i.Amount + i.UnitName);
          });

          let obj = {
            medicalRecordBankPracticeDTO: {
              bpId: this.paper.mainId,
              diseaseType: DiseaseName,
              quantity: this.stick,
              drugsList: drugsList,
              practicedoctorName: this.userInfo.doctorName
            }
          };
          this.$store.commit("openSpin", "保存中");
          this.api2("addPractice", obj).then(res => {
            this.$store.commit("closeSpin");
            this.paper.isanswer = 1;
            this.refresh();
          });

          this.paper.dialectical_my = DiseaseName;
          this.paper.prescription_my = cf.join("，");
        } else {
          this.$Message.error("请选择本次患者病情的诊断病名!");
          $(".select_zd2")
            .find("input")
            .focus();
        }
      }
    },
    // 刷新数据
    refresh() {
      if (this.type == "practice") {
        this.getData3();
        return;
      }
      this.$Message.success("已经提交! 即将进入下一题");
      setTimeout(() => {
        this.rightIndex();
      }, 1000);
    },

    // 药品搜索值改变时
    changeValue3(value) {
      if (value) {
        var newValue = value;
        var self = this;
        // 更新
        if (this.bnf.indexOf(value.Drug_Code) != -1) {
          value.Amount = 1;
        }
        if (this.editDrug) {
          this.prescription.drugList.forEach((i, index) => {
            if (i.Drug_Code == this.editDrug.Drug_Code) {
              for (var Key in i) {
                if (Key != "Quantity") i[Key] = value[Key];
              }
            }
          });
          newValue = this.editDrug;
        } else {
          // 新增
          if (value.Drug_Type == "06" || value.Drug_Type == "01") {
            value.Quantity = this.stick;
          } else {
            value.Quantity = 1;
          }
          this.prescription.drugList.push(value);
          this.setTaboos();
          setTimeout(function() {
            self.tableRow = self.tableRow.prev();
          }, 20);
        }
        setTimeout(function() {
          if (self.bnf.indexOf(value.Drug_Code) != -1) {
            $(".input_new").trigger("click");
          } else {
            self.tableRow
              .find(".u_amount")
              .trigger("click", [newValue, "Amount"]);
          }
        }, 30);
      }
      $(".ypsrk").css({ visibility: "hidden" });
      this.editDrug = null;
    },

    // 更新禁忌
    setTaboos() {
      this.prescription.u_jj = "";
      this.prescription.drugList.forEach((i, index) => {
        let item = this.hundredDrugs.find(j => j.Drug_Code == i.Drug_Code);
        if (item && item.drugtaboo) {
          this.prescription.u_jj += " " + item.drugtaboo;
        }
      });
      setTimeout(() => {
        this.setTableHeight();
      }, 100);
    },

    // 药品搜索框失去焦点
    drugSearchblur() {
      var self = this;
      this.djs = setTimeout(() => {
        $(".ypsrk").css({ visibility: "hidden" });
      }, 100);
      // self.editDrug = null;
    },

    // 修改药品名称
    editDrugName(e, u_drug) {
      clearTimeout(this.djs);
      var offset = $(e.target).position();
      $(".ypsrk").css({
        left: offset.left,
        top: offset.top,
        width: e.target.clientWidth + "px",
        height: e.target.clientHeight,
        visibility: "visible"
      });

      let xy = $(".ypsrk").offset();
      let wh = $(window).height();
      if (wh - (xy.top + 280) > 0) {
        this.uc_top = false;
      } else {
        this.uc_top = true;
      }

      $(".ypsrk")
        .find("input")
        .focus();
      this.editDrug = u_drug;

      if (u_drug) {
        this.new_input = false;
      } else {
        this.new_input = true;
        var h = $(".t_body").scrollTop() + 35;
        // $(".t_body").animate({ scrollTop: h }, 500);
      }

      // 找定位
      this.tableRow = $(e.target).parents(".u_table_h");
    },
    // 修改药品数量
    editAmount(e, u_drug, type) {
      // 不改百年方的用量
      if (this.bnf.indexOf(u_drug.Drug_Code) != -1 && type == "Amount") return;

      var offset = $(e.target).position();
      var edit_amount = $(".edit_amount");
      edit_amount.css({
        left: offset.left,
        top: offset.top,
        width: e.target.clientWidth + "px",
        height: e.target.clientHeight,
        visibility: "visible"
      });
      setTimeout(() => {
        $(edit_amount[0])
          .focus()
          .select();
      }, 100);

      u_drug.type = type;
      this.edit_amount = u_drug[type];
      this.editDrug = u_drug;

      if (e.isTrusted) {
        this.new_input = false;
      } else {
        this.new_input = true;
      }
    },
    // 删除开方清单
    del(index) {
      this.prescription.drugList.splice(index, 1);
      this.setTaboos();
    },
    // 检查数量是否为空
    cj_drugList(type) {
      var keepGoing = true;
      var jsq = 0;
      this.prescription.drugList.forEach((i, index) => {
        if (keepGoing) {
          if (!/^\d{1,3}$/.test(i.Amount) || /^0/.test(i.Amount)) {
            this.$Message.error("数量输入有误");
            jsq++;
            $($(".ul_yk").find(".u_table_h")[index])
              .find(".u_amount")
              .trigger("click", [i, "Amount"]);
            keepGoing = false;
          }
        }
      });
      // 当数量不为空时跳到插入位置
      if (jsq == 0 && this.new_input) {
        if (!type) $(".input_new").trigger("click");
      }
      return keepGoing;
    },
    // 数量修改
    edit_key() {
      this.update();
    },
    // 失去焦点
    amount_blur() {
      this.update();
      this.editDrug = null;
      this.edit_amount = "";
      this.cj_drugList();
    },
    // 当前数量更新
    update() {
      this.prescription.drugList.forEach((i, index) => {
        if (i.Drug_Code == this.editDrug.Drug_Code) {
          i[this.editDrug.type] = this.edit_amount;
        }
      });
      $(".edit_amount").css({ visibility: "hidden" });
      setTimeout(() => {
        self.edit_amount = "";
      }, 100);
    },
    // 设置表格高度
    setTableHeight() {
      if ($(this.$el).find(".bz_yk")[0].clientHeight) {
        this.u_table_heigh.height =
          $(this.$el).find(".bz_yk")[0].clientHeight -
          $(".u_head")[0].clientHeight -
          20 +
          "px";
        this.u_table_scroll.height =
          $(this.$el).find(".bz_yk")[0].clientHeight -
          $(".u_head")[0].clientHeight -
          64 +
          "px";
      }
    },

    getData() {
      this.examinationPaperList = [];
      var obj = {
        paperTypeId: 0, // 试卷类型 0 百年方 1 症状
        examinationTypeId: this.type == "formal" ? 1 : 0 // 考试类型 0 模似 1 正式考试
      };
      this.$store.commit("openSpin");
      this.api2("getExaminationPaperList", obj).then(res => {
        this.$store.commit("closeSpin");
        this.examinationPaperList = res.result;
      });
    }
  },
  created() {
    this.getData();
  },
  mounted: function() {
    var self = this;

    // 窗口大小调整
    $(window).resize(function() {
      self.setTableHeight();
    });

    $(".info_cf")
      .find("input")
      .bind("keyup", function(e) {
        if (e.keyCode == 13) {
          self.common.nextInput(e);
        }
      });

    $(".t_body").scroll(function(e) {
      $(".t_head").scrollLeft(e.currentTarget.scrollLeft);
    });

    $(".u_zz").delegate(".title", "click", function(e) {
      var node = $(e.currentTarget).next();
      node.slideToggle(200);
    });

    $(".prescription").delegate("input", "blur focus", function(e) {
      if (e.type == "focusin") {
        //focusout
        $(".u_menu").addClass("u_menu_hide");
      } else {
        $(".u_menu").removeClass("u_menu_hide");
      }
    });
  }
};
</script>
