<template>
  <div class="tab-body">
    <div class="title">
      <div class="label">脏腑病变规律</div>
    </div>

    <!-- {{ birthday }} -->
    <!-- 上半年 -->
    <template v-if="birthday.lMonth <= 6">
      <div class="card">
        <div class="card-header">
          <div class="card-h-title">病位</div>
          <div class="card-h-other"></div>
        </div>
        <div class="card-body">
          {{ info.upperYearDLName }}
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <div class="card-h-title">病性</div>
          <div class="card-h-other"></div>
        </div>
        <div class="card-body">
          {{ info.upperYearDNName }}
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <div class="card-h-title">病机</div>
          <div class="card-h-other"></div>
        </div>
        <div class="card-body">
          {{ info.upperYearPathogenesis }}
        </div>
      </div>
    </template>
    <!-- 下半年 -->
    <template v-else>
      <div class="card">
        <div class="card-header">
          <div class="card-h-title">病位</div>
          <div class="card-h-other"></div>
        </div>
        <div class="card-body">
          {{ info.lowerYearDLName }}
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <div class="card-h-title">病性</div>
          <div class="card-h-other"></div>
        </div>
        <div class="card-body">
          {{ info.lowerYearDNName }}
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <div class="card-h-title">病机</div>
          <div class="card-h-other"></div>
        </div>
        <div class="card-body">
          {{ info.lowerYearPathogenesis }}
        </div>
      </div>
    </template>
    <!-- 全年 -->
    <!-- <template>
      <div class="card">
        <div class="card-header">
          <div class="card-h-title">病位</div>
          <div class="card-h-other"></div>
        </div>
        <div class="card-body">
          {{ info.allYearDLName }}
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <div class="card-h-title">病性</div>
          <div class="card-h-other"></div>
        </div>
        <div class="card-body">
          {{ info.allYearDNName }}
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <div class="card-h-title">病机</div>
          <div class="card-h-other"></div>
        </div>
        <div class="card-body">
          {{ info.allyearPathogenesis }}
        </div>
      </div>
    </template> -->

    <div class="title">
      <div class="label">病症倾向</div>
    </div>
    <div class="card">
      <div class="card-header">
        <div class="card-h-title">病症</div>
        <div class="card-h-other">
          展示全部 <i-switch size="small" true-color="#853a2f" v-model="show_more" />
        </div>
      </div>
      <div class="card-body">
        <span v-for="(item, index) in diseaseNameByMonth" :key="index" class="v_item" :class="{ red: item.value }">
          {{ item.label }}
          <i>{{ item.value }}</i>
        </span>
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <div class="card-h-title">疾病趋势</div>
        <div class="card-h-other">
          危重度
          <Rate allow-half :value="info.severityStar" disabled />
        </div>
      </div>
      <div class="card-body">
        {{ info.diseaseTrends }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      default: () => {
        return {}
      }
    },
    birthday: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  computed: {
    diseaseNameByMonth() {
      if (!this.info.diseaseNameByMonth) return [];
      let list = this.info.diseaseNameByMonth.split("|");

      if (!this.show_more) {
        if (list.some(i => /X/.test(i))) {
          list = list.filter(i => /X/.test(i));
        } else {
          list = list.slice(0, 5);
        }
      }

      return list.map(i => {
        let arr = i.split("X");
        let obj = { label: arr[0] };
        if (arr[1]) obj.value = arr[1];
        return obj;
      });
    },
    diseaseNameByYear() {
      if (!this.info.diseaseNameByYear) return [];
      let list = this.info.diseaseNameByYear.split("|");

      if (!this.show_more) {
        if (list.some(i => /X/.test(i))) {
          list = list.filter(i => /X/.test(i));
        } else {
          list = list.slice(0, 5);
        }
      }

      return list.map(i => {
        let arr = i.split("X");
        let obj = { label: arr[0] };
        if (arr[1]) obj.value = arr[1];
        return obj;
      });
    },
  },
  data() {
    return {
      show_more: false
    }
  },
}
</script>

<style lang="scss" >
.tab-body .v_item {
  padding-right: 10px;
}

.tab-body .v_item.red {
  color: #f00;
}

.tab-body .v_item i {
  background: #f00;
  color: #fff;
  height: 14px;
  width: 14px;
  line-height: 14px;
  text-align: center;
  border-radius: 100%;
  font-size: 10px;
  font-style: normal;
  display: none;
}

.tab-body .v_item.red i {
  display: inline-block;
}
</style>