<template>
	<div class="tab-body">
		<div class="title">
			<strong class="label">{{ info.constitutionCategory }}</strong>
			<div class="other">（总体体质）</div>
		</div>
		<div class="card">
			<div class="card-header">
				<div class="card-h-title">形体特征</div>
				<div class="card-h-other"></div>
			</div>
			<div class="card-body">
				{{ info.formFeature }}
			</div>
		</div>
		<div class="card">
			<div class="card-header">
				<div class="card-h-title">发病趋势</div>
				<div class="card-h-other"></div>
			</div>
			<div class="card-body">
				{{ info.incidenceTendency }}
			</div>
		</div>
		<div class="card">
			<div class="card-header">
				<div class="card-h-title">对外界环境适应能力</div>
				<div class="card-h-other"></div>
			</div>
			<div class="card-body">
				{{ info.externalAdaptability }}
			</div>
		</div>
		<div class="card">
			<div class="card-header">
				<div class="card-h-title">身体常见表现（主项）</div>
				<div class="card-h-other"></div>
			</div>
			<div class="card-body">
				{{ info.constitutionMainDisease }}
			</div>
		</div>
		<div class="card">
			<div class="card-header">
				<div class="card-h-title">身体常见表现（副项）</div>
				<div class="card-h-other"></div>
			</div>
			<div class="card-body">
				{{ info.constitutionSubDisease }}
			</div>
		</div>

		<div class="title">
			<strong class="label">{{ info.diseaseNature }}</strong>
			<div class="other">（邪气性质）</div>
		</div>
		<div class="card">
			<div class="card-header">
				<div class="card-h-title">共同症状（主症）</div>
				<div class="card-h-other"></div>
			</div>
			<div class="card-body">
				{{ info.natureMainDisease }}
			</div>
		</div>
		<div class="card">
			<div class="card-header">
				<div class="card-h-title">临床症状（兼症）</div>
				<div class="card-h-other"></div>
			</div>
			<div class="card-body">
				{{ info.natureSubDisease }}
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		info: {
			type: Object,
			default: () => {
				return {}
			}
		},
	},
}
</script>

<style lang="scss" scoped></style>