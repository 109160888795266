import ViewUI from 'view-design'
import store from '@/store/index.js'
let djs = null;
let childrenWinApp = {};
export default {
    dateStrSplice(str) {
        return str ? str.substr(0, str.indexOf(' ')) : ''
    },
    clone: function (D) {
        var E = JSON.stringify(D);
        return E = eval("(" + E + ")")
    },
    // id值转label
    idToLabel(list, value) {
        let item = list.find(i => i.value == value);
        return item ? item.label : '';
    },
    //减去指定天数后的日期 no指定天数可负  iDate在哪个时间开始指定
    getDate: function (no, iDate) {
        var udate = iDate ? new Date(iDate) : new Date();
        return this.timestampToTime(Date.parse(udate) + (60 * 60 * 24 * 1000) * no);
    },
    //获取当前年月日
    timestampToTime: function (timestamp) {
        if (timestamp) {
            var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        } else {
            var date = new Date(); //获取当前时间
        }

        var seperator1 = "-";
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var strDate = date.getDate();
        if (month >= 1 && month <= 9) {
            month = "0" + month;
        }
        if (strDate >= 0 && strDate <= 9) {
            strDate = "0" + strDate;
        }
        var currentdate = year + seperator1 + month + seperator1 + strDate;
        return currentdate;
    },
    //移动光标到行尾
    moveEnd: function (obj) {
        obj.focus();
        var len = obj.value.length;
        if (document.selection) {
            var sel = obj.createTextRange();
            sel.moveStart('character', len); //设置开头的位置
            sel.collapse();
            sel.select();
        } else if (typeof obj.selectionStart == 'number' && typeof obj.selectionEnd == 'number') {
            obj.selectionStart = obj.selectionEnd = len;
        }
    },
    // 按回车后跳到下一个输入框
    nextInput: function (e) {
        var inputs = $("input");
        for (var i = 0; i < inputs.length; i++) {
            // 如果是最后一个，则焦点回到第一个   
            if (i == (inputs.length - 1)) {
                inputs[0].focus();
                break;
            } else if (e.target == inputs[i]) {
                inputs[i + 1].focus();
                break;
            }
        }
    },
    // 日期格式化
    formatDate: function (date) {
        var seperator1 = "-";
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var strDate = date.getDate();
        if (month >= 1 && month <= 9) {
            month = "0" + month;
        }
        if (strDate >= 0 && strDate <= 9) {
            strDate = "0" + strDate;
        }
        var currentdate = year + seperator1 + month + seperator1 + strDate;
        return currentdate;
    },
    //获取当前年月日 格式YYYY-MM-DD
    formatDate: function (timestamp) {
        if (timestamp) {
            var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        } else {
            var date = new Date(); //获取当前时间
        }
        var seperator1 = "-";
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var strDate = date.getDate();
        if (month >= 1 && month <= 9) {
            month = "0" + month;
        }
        if (strDate >= 0 && strDate <= 9) {
            strDate = "0" + strDate;
        }
        var currentdate = year + seperator1 + month + seperator1 + strDate;
        return currentdate;
    },
    Stopwatch: {
        oStart: function () {
        }
    },
    returnFormattedToMilliseconds: function (time) {
        // var milliseconds = Math.floor((time % 1000) / 100),
        // hours = Math.floor((time/(1000*60*60)) % 24);
        var seconds = Math.floor((time / 1000) % 60),
            minutes = Math.floor((time / (1000 * 60)) % 60),
            hours = Math.floor(time / (1000 * 60 * 60));
        seconds = seconds < 10 ? '0' + seconds : seconds;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        hours = hours ? hours + ":" : '';
        return hours + minutes + ":" + seconds;
    },
    isError(req, hide) { //请求出错处理 req:ajax返回的数据，hide:是否隐藏错误提示
        if (req.success) {
            return false;
        }
        if (req.error) {
            if (!hide)
                ViewUI.Message.error(req.error.messages || '数据请求出错了!');
            return true;
        }
        if (req.meta.code != 200) {
            if (!hide) {
                if (req.meta.msg)
                    ViewUI.Message.error(req.meta.msg);
                else
                    ViewUI.Message.error('“服务器出现了一点小状况，请您耐心等一下……”');
            }
            return true;
        }
        return false;
    },

    /**
     * Parse the time to string
     * @param {(Object|string|number)} time
     * @param {string} cFormat
     * @returns {string}
     */
    parseTime(time, cFormat) {
        if (arguments.length === 0) {
            return null
        }
        const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
        let date
        if (typeof time === 'object') {
            date = time
        } else {
            if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
                time = parseInt(time)
            }
            if ((typeof time === 'number') && (time.toString().length === 10)) {
                time = time * 1000
            }
            date = new Date(time)
        }
        const formatObj = {
            y: date.getFullYear(),
            m: date.getMonth() + 1,
            d: date.getDate(),
            h: date.getHours(),
            i: date.getMinutes(),
            s: date.getSeconds(),
            a: date.getDay()
        }
        const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
            let value = formatObj[key]
            // Note: getDay() returns 0 on Sunday
            if (key === 'a') {
                return ['日', '一', '二', '三', '四', '五', '六'][value]
            }
            if (result.length > 0 && value < 10) {
                value = '0' + value
            }
            return value || 0
        })
        return time_str
    },

    back() {
        window.history.back();
    },
    // 症状列表按患者信息过滤
    diagnosticListFilter(list, age, sex) {
        return list.filter(i => {
            if (this.validata(i, age, sex)) {
                i.symptomSubTypeList = i.symptomSubTypeList.filter(j => {
                    if (this.validata(j, age, sex)) {
                        j.symptomList = j.symptomList.filter(k => {
                            if (this.validata(k, age, sex)) {
                                return true;
                            }
                        });
                        return true;
                    }
                });
                return true;
            }
        });
    },
    // 症状列表按患者信息过滤-处理函数
    validata(item, age, sex) {
        if (item.ageE == 0) item.ageE = 150;
        if (item.sex == 0 || item.sex == sex) {
            if (item.ageS <= age && item.ageE > age) {
                return true;
            }
        }
        return false;
    },

    symptomConcat(obj) {
        obj.symptomIds = obj.symptomId ? [obj.symptomId] : [];
        return [obj]
    },

    /* symptomConcat2(list) {
        if (!list || !list.length) return [];
        if (list.length > 1) {
            let obj = list[0];
            obj.symptomIds = list.map(i => i.symptomId);
            return [obj]
        } else {
            list[0].symptomIds = [list[0].symptomId];
            return list;
        }
    }, */

    // 格式化禁忌列表
    formatTaboo(tList, symptomList) {
        let newTlist = [];

        tList.forEach(element => {
            let obj = newTlist.find(i => element.drugCode == i.drugCode);
            if (obj) {
                let list = obj.tabooDetailList;
                let list2 = this.symptomConcat(element);
                if (list2.length) {
                    // tabooType 禁忌类型(0绝对禁忌  1慎用禁忌 2长期服用禁忌 3用量过多禁用) 
                    let item = list.find(j => j.tabooType == list2[0].tabooType);
                    if (item) {
                        item.symptomIds = item.symptomIds.concat(list2[0].symptomIds)
                    } else {
                        if (obj.tabooDetailList.find(i => i.tabooType != 0)) { //是否有绝对禁忌数据
                            if (list2[0].tabooType != 0) { //当前要追回的数据是否绝对禁忌数据
                                obj.tabooDetailList = list.concat(list2);
                            } else {
                                obj.tabooDetailList = list2;
                            }
                        }
                    }
                }
            } else {
                obj = {
                    drugCode: element.drugCode,
                    tabooDetailList: this.symptomConcat(element)
                };
                newTlist.push(obj);
            }
        })


        // 加入药品列表里的禁忌信息
        let drugTabooList = this.clone(store.getters.drugTabooList)
        drugTabooList.forEach(i => {
            let item = newTlist.find(j => j.drugCode == i.drugCode);
            if (item) {
                let f = item.tabooDetailList.find(i => i.tabooType == 0);
                if (!f) {
                    item.tabooDetailList = item.tabooDetailList.concat(i.tabooDetailList)
                }
            } else {
                newTlist.push(i)
            }
        })

        // 加入症状信息
        newTlist.forEach(i => {
            i.tabooDetailList.forEach(j => {
                j.tabooTypeName = this.idToLabel(
                    store.getters.tabooType,
                    j.tabooType
                );
                if ([0, 1].includes(j.tabooType)) {
                    j.tabooReminder = symptomList
                        .filter(i => j.symptomIds && j.symptomIds.includes(i.dsId + ""))
                        .map(i => i.dsName)
                        .join(",");
                } else if (j.tabooType == 3) {
                    j.tabooReminder = `不超过${j.dosageTaboo}克`;
                }
            });
        });

        return newTlist;
    },

    // 将图片转换为Base64
    image2Base64(img) {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, img.width, img.height);
        var dataURL = canvas.toDataURL("image/png");
        return dataURL;
    },

    // 获取图片Base64编码
    getImgBase64(path) {
        return new Promise((resolve, reject) => {
            var base64 = "";
            var img = new Image();
            img.src = path;
            img.crossOrigin = "Anonymous";
            img.onload = () => {
                base64 = this.image2Base64(img);
                resolve({
                    "imageHeight": img.height,
                    "imageWidth": img.width,
                    "base64": base64
                });
            }
            img.onerror = (err) => {
                reject(err)
            }
        })
    },



    dataURLtoFile(dataurl, filename) { //将base64转换为文件，dataurl为base64字符串，filename为文件名（必须带后缀名，如.jpg,.png）
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {
            type: mime
        });
    },

    // 判断图片链接是否有效
    checkImgExists(imgurl) {
        return new Promise(function (resolve, reject) {
            var ImgObj = new Image()
            ImgObj.src = imgurl
            ImgObj.onload = function (res) {
                resolve(res)
            }
            ImgObj.onerror = function (err) {
                reject(err)
            }
        })
    },


    // 值转名
    valToLabel(list, val) {
        let item = list.find(i => i.value == val)
        if (item) {
            return item.label
        } else {
            return ''
        }
    },

    // 删除右边的逗号
    trim(str) {
        return str && /,$/.test(str) ? str.substr(0, str.length - 1) : '';
    },

    // 指定的registerCode 是否在数据里
    inArray(arr, id) {
        return arr.every(j => {
            return j.find(i => i.registerCode == id)
        });
    },

    //base64图片转成文件流格式
    base64ToFile(data, fileName) {
        const dataArr = data.split(",");
        const byteString = atob(dataArr[1]);
        const options = {
            type: "image/jpeg",
            endings: "native"
        };
        const u8Arr = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
            u8Arr[i] = byteString.charCodeAt(i);
        }
        return new File([u8Arr], fileName, options); //返回文件流
    },

    // 运行于electron中
    isElectron() {
        return window && window.process && window.process.versions && window.process.versions['electron'];
    },
    // 获取当月1号到当前时间
    getNowMonth() {
        let d = new Date();
        let year = d.getFullYear();
        let month = d.getMonth() + 1;
        let day = d.getDate();

        return [
            `${year}-${month}-01`,
            `${year}-${month}-${day}`,
        ]
    },
    // 密码强度验证
    checkStrong(sValue) {
        var modes = 0;
        //正则表达式验证符合要求的
        if (sValue.length < 1) return modes;
        if (/\d/.test(sValue)) modes++; //数字
        if (/[a-z]/.test(sValue)) modes++; //小写
        if (/[A-Z]/.test(sValue)) modes++; //大写
        if (/\W/.test(sValue)) modes++; //特殊字符

        //逻辑处理
        switch (modes) {
            case 1:
                return 1;
                break;
            case 2:
                return 2;
                break;
            case 3:
                return 3;
                break;
            case 4:
                return sValue.length < 8 ? 3 : 4;
                break;
        }
        return modes;
    },
    // 禁忌格式化
    tabooFormat(taboo) {
        if (taboo) {
            let arr = taboo.replace(/\s*\r\n\s*/g, "").split(/\d[.、]/);
            let s = [];
            arr
                .filter((i) => i)
                .forEach((i, index) => {
                    s.push(`${index + 1}、${i}`);
                });
            return s.join("<br/>");
        } else {
            return "";
        }
    },

    //定义函数 把用户输入的 年，月，日 作为实参传递给函数
    getDays(year, month, day) {
        year *= 1;
        month *= 1;
        day *= 1;

        var num = day;
        //如果用户输入1月 则直接返回天数
        if (month == 1) {
            return day;
        }
        var monthDay = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        monthDay[1] = new Date(year, 2, 0).getDate();

        for (var i = 0; i < month - 1; i++) {
            num = num + monthDay[i]
        }
        return num;
    },

    // 症状差异获取
    dsNameDiff(dsNames1, dsNames2) {
        return [
            dsNames1.filter(i => dsNames2.includes(i)), //交集
            dsNames1.filter(i => !dsNames2.includes(i)), //差集1
            dsNames2.filter(i => !dsNames1.includes(i)), //差集2
        ]
    },

    // 症状差异对比
    diff(item, list) {
        let diseaseCode = item.diseaseCode.split("|").filter(i => i);
        let lastDsId = item.lastDsId == "‘’" ? [] : item.lastDsId.split("|").filter(i => i);
        if (lastDsId.length) {
            item.same = this.valuesToLabels(diseaseCode.filter(i => lastDsId.includes(i)), list);
            item.newAdd = this.valuesToLabels(diseaseCode.filter(i => !lastDsId.includes(i)), list);
            item.remove = this.valuesToLabels(lastDsId.filter(i => !diseaseCode.includes(i)), list);
        } else {
            item.same = this.valuesToLabels(diseaseCode, list);
            item.newAdd = [];
            item.remove = [];
        }

        // console.log("same", item.same);
        // console.log("newAdd", item.newAdd);
        // console.log("remove", item.remove);
        // console.log( "diseaseCode", this.valuesToLabels(diseaseCode, list));
        // console.log( "diseaseCode", diseaseCode);
        // console.log( "lastDsId", this.valuesToLabels(lastDsId, list));
        // console.log( "lastDsId", lastDsId);
    },

    // 系统检测
    detectOS() {
        var sUserAgent = navigator.userAgent;
        var isWin = (navigator.platform == "Win32") || (navigator.platform == "Windows");
        var isMac = (navigator.platform == "Mac68K") || (navigator.platform == "MacPPC") || (navigator.platform == "Macintosh") || (navigator.platform == "MacIntel");
        if (isMac) return "Mac";
        var isUnix = (navigator.platform == "X11") && !isWin && !isMac;
        if (isUnix) return "Unix";
        var isLinux = (String(navigator.platform).indexOf("Linux") > -1);
        var bIsAndroid = sUserAgent.toLowerCase().match(/android/i) == "android";
        if (isLinux) {
            if (bIsAndroid) return "Android";
            else return "Linux";
        }
        if (isWin) {
            var isWin2K = sUserAgent.indexOf("Windows NT 5.0") > -1 || sUserAgent.indexOf("Windows 2000") > -1;
            if (isWin2K) return "Win2000";
            var isWinXP = sUserAgent.indexOf("Windows NT 5.1") > -1 ||
                sUserAgent.indexOf("Windows XP") > -1;
            if (isWinXP) return "WinXP";
            var isWin2003 = sUserAgent.indexOf("Windows NT 5.2") > -1 || sUserAgent.indexOf("Windows 2003") > -1;
            if (isWin2003) return "Win2003";
            var isWinVista = sUserAgent.indexOf("Windows NT 6.0") > -1 || sUserAgent.indexOf("Windows Vista") > -1;
            if (isWinVista) return "WinVista";
            var isWin7 = sUserAgent.indexOf("Windows NT 6.1") > -1 || sUserAgent.indexOf("Windows 7") > -1;
            if (isWin7) return "Win7";
        }
        if (sUserAgent.indexOf("Windows NT 8") != -1) {
            return "win8";
        }
        if (sUserAgent.indexOf("Windows NT 10") != -1) {
            return "win10";
        }
        return "other";
    },
    // 按一定长度切数组成多个数组
    chunkArray(array, chunkSize) {
        if (array.length === 0) {
            return [];
        }
        const chunk = array.splice(0, chunkSize);
        return [chunk].concat(this.chunkArray(array, chunkSize));
    },
    // 手机号脱系敏
    phone(phone) {
        if (!phone) return "";
        let str = phone.toString();
        return str.substr(0, 3) + "****" + str.substr(7);
    }
}