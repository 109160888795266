<template>
	<!-- 备注输入 -->
	<Poptip @on-popper-show="onPopperShow" ref="bzInfo" class="remarks_input" placement="bottom-start">
		<template v-if="mode=='input'">
			<div class="text" v-if="value">{{ value }}</div>
			<div v-else class="text placeholder">{{ placeholder }}</div>
		</template>
		<span class="open-btn" v-else>
			<Icon type="md-create" />备注
		</span>
		<div slot="title">
			<span class="title1">备注</span>
			<span class="title2">（记录以后有助于和患者亲密交流的信息，例如家庭、工作情况等）</span>
		</div>
		<div slot="content">
			<Input v-model="remarks_input" type="textarea" :autosize="{ minRows: 4, maxRows: 4 }"
				placeholder="请输入备注信息" :maxlength="2000" show-word-limit></Input>
			<div class="bz_foot">
				<Button @click="submitRemarks" type="primary">保存</Button>
				<Button @click="closeRemarks">取消</Button>
			</div>
		</div>
	</Poptip>
</template>

<script>
export default {
	props: {
		value: {
			type: String,
			default: ''
		},
		patientCode: {
			type: String,
			default: ''
		},
		placeholder: {
			type: String,
			default: ''
		},
		mode: {
			type: String,
			default: 'input'
		}
	},
	data() {
		return {
			remarks_input: ''
		}
	},
	methods: {
		onPopperShow() {
			this.remarks_input = this.value;
		},
		submitRemarks() {
			if(/^\s+$/.test(this.remarks_input)){
				this.$Message.error('请输入备注信息');
				return
			}
			let obj = {
				patientCode: this.patientCode, // 患者编码
				otherInfo: this.remarks_input // 其他信息
			};
			this.api2("updatePatientTag", obj).then(res => {
				this.$Message.success(res.result.msg);
				this.$emit('input', this.remarks_input)
			});
			this.closeRemarks();
		},
		closeRemarks() {
			this.$refs.bzInfo.cancel();
		},
	},
}
</script>

<style lang="scss" >
.remarks_input {
	.open-btn,
	&>.ivu-poptip-rel .text{
		cursor: pointer;
	}
	display: block;
	.ivu-poptip-rel{
		display: block;
	}
	.bz_foot {
		text-align: right;
		margin-top: 10px;
		.ivu-btn{
			margin-left: 15px;
		}
	}
}
</style>