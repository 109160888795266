<!-- 运动状态和心率统计 -->
<template>
    <div :class="className" :style="{ height: height, width: width }" />
</template>
  
<script>
import * as echarts from "echarts";
import resize from './mixins/resize.js'

export default {
    mixins: [resize],
    props: {
        className: {
            type: String,
            default: 'chart'
        },
        width: {
            type: String,
            default: '100%'
        },
        height: {
            type: String,
            default: '100%'
        },
        autoResize: {
            type: Boolean,
            default: true
        },
        sportData: {
            type: Object,
            default: {}
        },
        heartData: {
            type: Object,
            default: {}
        },
        addTime: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            chart: null,
        }
    },
    created() {
    },
    mounted() {
        this.$nextTick(() => {
            this.initChart()
        })
    },
    beforeDestroy() {
        if (!this.chart) {
            return
        }
        this.chart.dispose()
        this.chart = null
    },
    methods: {
        initChart() {
            this.chart = echarts.init(this.$el)
            this.setOptions()
        },
        setOptions() {
            let sportNorm = {
                totalNumber: "",
                validDuration: "",
                validNumber: ""
            }
            let e = this.sportData;

            e.totalNumber ? sportNorm.totalNumber = e.totalNumber : sportNorm.totalNumber = "---"
            e.validDuration ? sportNorm.validDuration = e.validDuration : sportNorm.validDuration = "---"
            e.validNumber ? sportNorm.validNumber = e.validNumber : sportNorm.validNumber = "---"


            let sportDataPro = e.sportDataPro ? JSON.parse(e.sportDataPro) : []  //有效步数
            let s = this.addTime.replace(/[-]/g, "/")
            let r = new Date(s + " 23:59:59").getTime()  //max
            let n = new Date(s + " 00:00:00").getTime()  //min
            let stepData = []  //步数数据
            let heartData = []   //心率数据
            sportDataPro.forEach(function (e) {
                stepData.push([s + " " + e[0], e[1]])
            })
            if (this.heartData.today && this.heartData.today.length > 0) {
                heartData = this.heartData.today.map(function (e) {
                    return [e.uploadDataDate + " " + e.slot, e.heartRate]
                });
            }
            let stepMax = null
            if(stepData && stepData.length === 0){
                stepMax = '2000'
            }
            this.chart.setOption({
                grid: {
                    top: 30,
                    right: 15,
                    bottom: 0,
                    left: 15,
                    containLabel: true
                },
                tooltip: {
                    trigger: "axis",
                    formatter: function (e) {
                        var t = e[0].data[0].split(" ")[1]
                            , a = ['<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:#fd7b52;"></span>', "---"]
                            , i = ['<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:#86d9e0;"></span>', "---"];
                        return e.forEach(function (e) {
                            e.marker;
                            var t = e.seriesName
                                , s = e.data;
                            "心率" === t && (a[1] = s[1]),
                                "步数" === t && (i[1] = s[1])
                        }),
                            "<div>\n              <p>时间：" + t + "</p>\n              <p>" + a[0] + " 心率: " + a[1] + "</p>\n              <p>" + i[0] + " 步数: " + i[1] + "</p>\n              </div>"
                    }
                },
                xAxis: {
                    type: "time",
                    max: r,
                    min: n,
                    axisLabel: {
                        color: "#999",
                        fontSize: "12",
                        formatter: "{HH}:{mm}"
                    },
                    axisLine: {
                        lineStyle: {
                            color: "#e7e5e4"
                        }
                    },
                    axisTick: {
                        show: false
                    }
                },
                yAxis: [{
                    scale: true,
                    max: stepMax,
                    min: 0,
                    splitNumber: 5,
                    type: "value",
                    axisLabel: {
                        show: true,
                        color: "#999"
                    },
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    }
                }, {
                    scale: true,
                    max: 160,
                    min: 0,
                    interval: 40,
                    type: "value",
                    axisLabel: {
                        show: true,
                        color: "#999"
                    },
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    }
                }],
                color: ["#fd7b52", "#86d9e0"],
                legend: {
                    selectedMode: false,
                    x: "left",
                    top: 3,
                    padding: [0, 0, 0, 35],
                    itemWidth: 15,
                    itemHeight: 6,
                    itemGap: 27,
                    icon: "rect",
                    data: ["心率", "步数"]
                },
                series: [{
                    name: "步数",
                    type: "bar",
                    barWidth: 1,
                    data: stepData,
                    itemStyle: {
                        color: "#86d9e0"
                    }
                }, {
                    yAxisIndex: 1,
                    name: "心率",
                    type: "line",
                    smooth: true,
                    showSymbol: false,
                    data: heartData,
                    lineStyle: {
                        color: "#fd7b52"
                    }
                }]
            })
        },
    }
}
</script>
  