<template>
  <div class="u_spin">
    <div class="u_spin_i"></div>
    <div class="u_spin_i"></div>
    <div class="u_spin_i"></div>
    <div class="u_spin_i"></div>
    <div class="u_spin_i"></div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.u_spin {
  display: flex;
  justify-content: center;
  align-items: center;
}
.u_spin .u_spin_i {
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background: #d4c0a2;
  margin: 0 3px;
  animation: u_spin linear 1s infinite;
}
.u_spin .u_spin_i:nth-child(1) {
  animation-delay: 0s;
}
.u_spin .u_spin_i:nth-child(2) {
  animation-delay: 0.15s;
}
.u_spin .u_spin_i:nth-child(3) {
  animation-delay: 0.3s;
}
.u_spin .u_spin_i:nth-child(4) {
  animation-delay: 0.45s;
}
.u_spin .u_spin_i:nth-child(5) {
  animation-delay: 0.6s;
}

@keyframes u_spin {
  0%,
  60%,
  100% {
    transform: scale(1);
  }
  30% {
    transform: scale(2.5);
  }
}
</style>