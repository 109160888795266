<!-- 病人信息 -->
<template>
  <div class="patientInfo">
    <div v-if="pageType" class="assistant_menu">
      <div class="as_left">
        <div class="as_menu_item" :class="{active:menuName == '2'}" @click="dropMenu('2')">
          <Badge dot :count="hotMenuList[1].value">五运六气</Badge>
        </div>
        <div class="as_menu_item" :class="{active:menuName == '3'}" @click="dropMenu('3')">
          <Badge dot :count="hotMenuList[2].value">穴位按摩</Badge>
        </div>
        <div class="as_menu_item" :class="{active:menuName == '4'}" @click="dropMenu('4')">西医报告</div>
        <div class="as_menu_item" :class="{active:menuName == '1'}" @click="dropMenu('1')">
          <Badge dot :count="hotMenuList[0].value">话术方向</Badge>
        </div>
      </div>
      <div class="as_right">
        <i class="iconfont icon-double-left"></i> 智能辅助
      </div>
    </div>
    <div class="p_mk b_1">
      <div class="info">
        <span class="name">{{info.patientName}}</span>
        <span>
          <Icon v-if="info.sex=='男'" type="md-male" />
          <Icon v-else type="md-female" />
        </span>
        <span class="gender">{{info.sex}}</span>
        <span class="age">
          <template v-if="info.patientAge > 1">{{info.patientAge}}岁</template>
          <template v-else>
            <template v-if="info.birthMonth">{{info.birthMonth + "个月"}}</template>
          </template>
        </span>
        <span class="age">{{info.fMaritalStatus}}</span>
        <span class="phone">{{info.cellphoneNo}}</span>
      </div>
      <div class="grda">
        <uArchives :id="info.patientCode"></uArchives>
      </div>
    </div>
    <div class="p_mk b_2">
      <div class="xh">
        <div class="label">
          <div class="label">喜好:</div>
          <div v-if="pageType" class="edit_btn" @click="openHobbyWin">
            <Icon type="md-create" />
          </div>
        </div>
        <div class="value">{{hobbyString}}</div>
      </div>
    </div>

    <div class="p_mk b_2">
      <div class="xh">
        <div class="label">
          <div class="label">备注:</div>

          <Poptip
            @on-popper-show="onPopperShow"
            ref="bzInfo"
            class="bzInfo"
            placement="bottom-start"
          >
            <Icon class="edit_btn" type="md-create" />
            <div slot="title">
              <span class="title1">备注</span>
              <span class="title2">（记录以后有助于和患者亲密交流的信息，例如家庭、工作情况等）</span>
            </div>
            <div slot="content">
              <Input
                v-model="remarks_input"
                type="textarea"
                :autosize="{minRows: 4,maxRows: 4}"
                placeholder="请输入备注信息"
              ></Input>
              <div class="bz_foot">
                <Button @click="submitRemarks" type="primary">保存</Button>
                <Button @click="closeRemarks">取消</Button>
              </div>
            </div>
          </Poptip>
        </div>
        <div class="value">{{info.otherInfo}}</div>
      </div>
    </div>

    <template v-if="pageType">
      <div class="p_mk b_3">
        <div class="cameraBtns">
         
          <Button
            @click="showCameraFun(0)"
            class="ubtn_icon"
            shape="circle"
            size="small"
            icon="md-camera"
          >病历扫描</Button>
          <span :class="{handPic_btng:info.picUrl}">
            <Button
              @click="showCameraFun(1)"
              class="btn1 ubtn_icon"
              shape="circle"
              size="small"
              icon="md-hand"
            >手相拍照</Button>
            <Button
              v-if="info.picUrl"
              @click="showCameraFun(1)"
              class="btn2 ubtn_icon havePic"
              shape="circle"
              size="small"
              icon="md-checkmark"
              type="primary"
            >已拍手相</Button>
          </span>
          <Button
            v-if="showFeedbackSheet"
            @click="medicalRecordOpenFun"
            class="ubtn_icon"
            shape="circle"
            size="small"
            icon="md-print"
          >用户反馈单</Button>
        </div>
        <medicalRecord2 v-model="medicalRecordOpen" :info="info" />
      </div>

      <Modal footer-hide class="patientInfo patientInfo_xh" v-model="showAddTag" title="常见喜好">
        <div class="p_title">常见喜好</div>
        <div class="p_con">
          <CheckboxGroup v-model="selectLike">
            <Checkbox v-for="(item,index) in Hobby" :key="index" :label="item.label"></Checkbox>
          </CheckboxGroup>
        </div>
        <div class="p_title">其他喜好</div>
        <div class="p_con p_con_tag">
          <Tag
            color="primary"
            v-for="(item,index) in addTagList"
            :key="index"
            :name="item"
            closable
            @on-close="handleClose2"
          >{{item}}</Tag>
          <Input
            autofocus
            :maxlength="10"
            @on-enter="addTag"
            v-if="showInput"
            placeholder="输入喜好"
            v-model="tagName"
            size="small"
          ></Input>
          <span class="addTagBtn" @click="showInput=true">
            <Icon type="md-add" />
          </span>
        </div>
        <div class="p_tip">（请用不超过10个字符描述其他喜好，按回车键添加）</div>
        <div class="p_btn_g">
          <Button type="primary" @click="addPatientHobby">保存</Button>
          <Button @click="showAddTag=false">取消</Button>
        </div>
      </Modal>
    </template>

    <div
      v-show="menuName"
      class="pI_popup"
      :class="[isRightPopup?'right':'left']"
      :style="popupSize"
    >
      <div class="pi_title">
        <span>智能辅助</span>
        <i class="iconfont icon-doubleright"></i>
        <span v-if="menuName==1">话术方向</span>
        <span v-if="menuName==2">五运六气</span>
        <span v-if="menuName==3">穴位按摩</span>
        <span v-if="menuName==4">西医报告</span>
        <Icon @click="menuName=''; useClock=true" type="md-close-circle" />
      </div>
      <div class="pi_body">
        <speechTechnique v-if="menuName==1" :info="info" />
        <luck v-if="menuName==2" :info="info" />
        <acupoint v-if="menuName==3" />
        <report :info="info" v-if="menuName==4" @close="menuName=''" />
      </div>
    </div>
  </div>
</template>

<script>
import acupoint from "./com/acupoint";
import report from "./com/report";
import luck from "./com/luck";
import speechTechnique from "./com/speechTechnique";
import { mapGetters } from "vuex";
export default {
  props: {
    info: {
      type: Object,
      default: () => {
        return {};
      }
    },
    hideMenu1: {
      type: Boolean,
      default: false
    },
    pageType: {
      //页面类型  true:前端医生 false:后端医生
      type: Boolean,
      default: false
    },
    isRightPopup: {
      // 智能辅助弹框在右边
      type: Boolean,
      default: false
    },
    isAutoPopup: {
      // 是否自动弹出五运六气
      type: Boolean,
      default: false
    }
  },
  watch: {
    info: {
      handler: function(value, oldValue) {
        this.menuName = "";
        this.showAddTag = false;
        this.useClock = false;
        this.formatData();
        if (value.registerCode != oldValue.registerCode) {
          this.getAcupointData();
        }
      },
      deep: true
    },
    diseaseEmotion(value) {
      this.setHotMenuList();
    },
    acupoint(newValue, oldValue) {
      let kg = true;
      if (!newValue.diagnosticId) kg = false;
      if (!newValue.symptomsIds && !oldValue.symptomsIds) kg = false;

      if (kg &&  this.$route.name == "condition") this.getAuxiliaryToolData2();
    }
  },
  components: {
    acupoint,
    report,
    luck,
    speechTechnique
  },
  computed: {
    ...mapGetters([
      "Hobby",
      "diseaseEmotion",
      "acupoint",
      "introStart",
      "userSystemConfig"
    ]),
    showFeedbackSheet() {
      return (
        this.userSystemConfig.userFeedback &&
        location.pathname == "/pages/prescription/index.html"
      );
    }
  },
  data() {
    return {
      medicalRecordOpen: false,
      menuName: "",
      remarks_input: "", //备注信息
      showPopup: false,
      useClock: false, //用户是否有关过弹框

      popupSize: {},
      hotMenuList: [
        { label: "话术方向", value: 0 },
        { label: "五运六气", value: 0 },
        { label: "穴位按摩", value: 0 }
      ],
      hobbyString: "",
      reportShow: false,
      acupointShow: false,
      speechTechniqueShow: false,
      luckShow: false,
      showInput: false,
      selectLike: [],
      addTagList: [],
      tagName: "",
      showAddTag: false,
      djs: null
    };
  },
  methods: {
    medicalRecordOpenFun(index) {
      //弹窗打开
      this.medicalRecordOpen = true;
    },
    onPopperShow() {
      this.remarks_input = this.info.otherInfo;
    },
    submitRemarks() {
      let obj = {
        patientCode: this.info.patientCode, // 患者编码
        otherInfo: this.remarks_input // 其他信息
      };
      this.api2("updatePatientTag", obj).then(res => {
        this.$Message.success(res.result.msg);
        this.info.otherInfo = this.remarks_input;
      });
      this.closeRemarks();
    },
    closeRemarks() {
      this.$refs.bzInfo.cancel();
    },
    getAuxiliaryToolData2() {
      clearTimeout(this.djs);
      this.djs = setTimeout(() => {
        this.getAuxiliaryToolData(1);
      }, 500);
    },
    setHotMenuList() {
      if (this.diseaseEmotion.list.length) {
        this.hotMenuList[0].value = 1;
      }
    },
    getAcupointData() {
      this.resetAcupoint();
      this.getAuxiliaryToolData(0);
    },
    formatData() {
      if (this.info.otherHobbyNames) {
        this.addTagList = this.info.otherHobbyNames
          .substr(0, this.info.otherHobbyNames.length - 1)
          .split(",");
      } else {
        this.addTagList = [];
      }
      if (this.info.commonHobbyIds) {
        let ids = this.info.commonHobbyIds
          .substr(0, this.info.commonHobbyIds.length - 1)
          .split(",");
        this.selectLike = this.Hobby.filter(i => ids.includes(i.value)).map(
          i => i.label
        );
      } else {
        this.selectLike = [];
      }

      let arr = this.selectLike.concat(this.addTagList);
      this.hobbyString = arr.join(", ");

      this.remarks_input = this.info.otherInfo;
    },
    openHobbyWin() {
      this.formatData();
      this.showAddTag = true;
    },
    dropMenu(name) {
      this.menuName = this.menuName == name ? "" : name;
    },
    addTag() {
      if (this.Hobby.find(i => i.label == this.tagName)) {
        this.selectLike.push(this.tagName);
        this.$Message.info("常见喜好里已有该喜好，已经自动勾选上了哦");
      } else {
        this.addTagList.push(this.tagName);
      }
      this.tagName = "";
    },
    handleClose2(event, name) {
      const index = this.addTagList.indexOf(name);
      this.addTagList.splice(index, 1);
    },
    showCameraFun(typeId) {
      let fun = typeId ? "setCameraInfo3" : "setCameraInfo2";
      this.$store.commit(fun, {
        info: this.info,
        typeId,
        showWin: true
      });
    },
    addPatientHobby() {
      let list = this.Hobby.filter(i => this.selectLike.includes(i.label));
      let list2 = list.map(i => {
        return {
          hobbyId: i.value,
          hobbyName: i.label,
          hobbyType: 0,
          patientCode: this.info.patientCode
        };
      });
      let list3 = this.addTagList.map(i => {
        return {
          hobbyId: "",
          hobbyName: i,
          hobbyType: 1,
          patientCode: this.info.patientCode
        };
      });
      let patientHobbyList = list2.concat(list3);
      this.api2("addPatientHobby", {
        patientHobbyList,
        patientCode: this.info.patientCode
      }).then(res => {
        this.$Message.success(res.result);
        this.showAddTag = false;

        // 更新界面
        this.info.commonHobbyIds = list2.length
          ? list2.map(i => i.hobbyId).join(",") + ","
          : "";
        this.info.otherHobbyNames = list3.length
          ? list3.map(i => i.hobbyName).join(",") + ","
          : "";

        // 更新缓存
        this.$store.commit("updataPrescribeRegisterList", {
          patientCode: this.info.patientCode,
          commonHobbyIds: this.info.commonHobbyIds,
          otherHobbyNames: this.info.otherHobbyNames
        });
      });
    },
    getAuxiliaryToolData(typeId) {
      let obj = {
        diagnosticId: this.acupoint.diagnosticId, //	诊断id
        dsIds: this.acupoint.symptomsIds, //	症状集('30154|30156|30148|30514|30119|30304|30150|30430|30290|30234|'),
        patientCode: this.info.patientCode //	患者编码
        // typeId //	0.五运六气 1.话术、穴位按摩
      };
      return this.api2("getAuxiliaryToolData", obj).then(res => {
        // this.$store.commit("sendLog", {
        //   remarks: "五运六气、话术、穴位按摩是否有数据",
        //   apiName: "getAuxiliaryToolData",
        //   outParams: res,
        //   inParams: obj
        // });

        let resdata = res.result;
        //话术数据
        if (resdata.emotion && resdata.emotion.length) {
          this.hotMenuList[0].value = 1;
          this.$store.commit("diseaseEmotion", {
            list: resdata.emotion,
            diagnosticName: this.acupoint.diagnosticName
          });
        } else {
          this.hotMenuList[0].value = 0;
          this.$store.commit("diseaseEmotion", {
            list: [],
            diagnosticName: ""
          });
        }
        //穴位数据
        if (resdata.acquaint && resdata.acquaint.length) {
          this.hotMenuList[2].value = 1;
          this.$store.commit("acupointList", resdata.acquaint);
        } else {
          this.hotMenuList[2].value = 0;
          this.$store.commit("acupointList", []);
        }

        //五运六气数据
        if (resdata.flsq && resdata.flsq.length) {
          this.hotMenuList[1].value = 0;
          this.$store.commit("flsq", resdata.flsq[0]);
        } else {
          this.hotMenuList[1].value = 1;
          this.$store.commit("flsq", null);
          if (this.isAutoPopup && !this.useClock && !this.introStart) {
            this.menuName = 2; //是否自动弹出五运六气
          }
        }
      });
    },
    // 重置辅助工具状态
    resetAcupoint() {
      this.hotMenuList.forEach(i => (i.value = 0));
      this.$store.commit("flsq", null);
      this.$store.commit("acupointList", []);
      this.$store.commit("diseaseEmotion", {
        list: [],
        diagnosticName: ""
      });
    },
    async init() {
      if (this.pageType) {
        await this.$store.dispatch("Hobby");
        this.getAcupointData();
      }
      this.formatData();
    },
    setSize() {
      let size = this.isRightPopup ? $(".u_right") : $(".bz_yk");
      this.popupSize = {
        height: size.height() + "px",
        width: size.width() + "px"
      };
    }
  },
  created() {
    this.init();
  },
  mounted() {
    this.setSize();
    setTimeout(() => {
      this.setSize();
    }, 1000);
    $(window).resize(() => {
      this.setSize();
    });
  }
};
</script>

<style>
.patientInfo {
  padding-bottom: 2px;
  background: #ffffff;
}
.patientInfo.patientInfo_xh {
  padding: 0;
}
.patientInfo .b_2 .ivu-date-picker .ivu-input,
.patientInfo .b_2 .ivu-input-wrapper,
.patientInfo .b_2 .ivu-date-picker-rel,
.patientInfo .b_2 .ivu-date-picker {
  height: 18px;
}
.patientInfo .b_2 .ivu-date-picker {
  width: 10px;
}
.patientInfo .b_2 .ivu-date-picker.isPeriod {
  width: 80px;
}
.patientInfo .b_2 .ivu-date-picker .ivu-input-suffix {
  display: none;
}
.patientInfo .b_2 .ivu-date-picker .ivu-input {
  border: none;
  padding: 1px 0px;
}
.patientInfo .b_2 .ivu-input-wrapper {
  width: auto;
}
.patientInfo .b_2 .xh {
  display: flex;
}
.patientInfo .b_2 .xh .label {
  display: flex;
}
.patientInfo .ivu-checkbox-group .ivu-checkbox-group-item {
  margin-bottom: 6px;
  color: #333;
}
.patientInfo .p_mk {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 8px;
  padding: 0 16px;
}
.patientInfo .p_mk.b_1 {
  padding-top: 10px;
}
.patientInfo .p_mk.b_2 {
  font-size: 12px;
  display: flex;
  align-items: flex-start;
}
.patientInfo .p_mk.b_2 .label {
  white-space: nowrap;
}
.patientInfo .p_mk.b_2 > div {
  display: flex;
  width: 100%;
}
.patientInfo .p_mk .info {
  display: flex;
  align-items: center;
}
.patientInfo .p_mk .info > span {
  margin-right: 10px;
}
.patientInfo .p_mk .name {
  font-size: 20px;
  font-weight: bold;
}
.patientInfo .p_mk .gender {
  background: #86392f;
  color: #ffffff;
  font-size: 12px;
  border-radius: 100%;
  display: inline-block;
  height: 18px;
  width: 18px;
  line-height: 18px;
  text-align: center;
}
.patientInfo .b_3 {
  background: #f5f5f5;
  padding: 8px 15px;
  border: 1px solid #fff;
  border-width: 0 1px;
}
.patientInfo .edit_btn {
  cursor: pointer;
  padding: 0 5px;
}
.patientInfo .u_popup {
  padding: 25px;
  border: 1px solid #ddd;
  border-radius: 5px;
  position: absolute;
  margin-top: 5px;
  top: 100%;
  right: 0;
  width: 400px;
  min-height: 200px;
  background: #ffffff;
  z-index: 100;
  box-shadow: 1px 1px 30px rgba(0, 0, 0, 0.2);
}
.patientInfo .u_popup::after {
  position: absolute;
  content: " ";
  top: -16px;
  right: 20px;
  border: 8px solid #f00;
  border-color: transparent transparent #ccc transparent;
}
.patientInfo .p_tip {
  color: #999;
  font-size: 12px;
}
.patientInfo .p_title {
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 10px;
}
.patientInfo .p_con {
  margin-bottom: 15px;
}
.patientInfo .cameraBtns .ivu-btn {
  margin-right: 10px;
}
.patientInfo .ivu-tag-primary,
.patientInfo .ivu-tag-primary.ivu-tag-dot .ivu-tag-dot-inner {
  background: #86392f;
}
.patientInfo .addTagBtn {
  display: inline-block;
  border: 2px solid #86392f;
  border-radius: 100%;
  height: 22px;
  width: 22px;
  text-align: center;
  color: #86392f;
  cursor: pointer;
  font-size: 12px;
}
.patientInfo .ivu-input-wrapper {
  width: 80px;
  margin-right: 5px;
}
.patientInfo .more_tag {
  cursor: pointer;
  padding-left: 3px;
}
.patientInfo .p_con_tag {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.patientInfo .p_btn_g {
  margin-top: 15px;
  text-align: right;
}
.patientInfo .p_btn_g .ivu-btn {
  margin-left: 15px;
}

.patientInfoModal .ivu-modal-body {
  padding: 15px;
}
.patientInfo.patientInfo_xh .ivu-modal-header {
  display: none;
}
.patientInfo .yjts {
  margin-left: 20px;
}

.patientInfo .assistant_menu {
  position: relative;
  font-size: 13px;
  display: flex;
  width: 100%;
  white-space: nowrap;
  background: #853a2f;
  color: #fff;
  padding: 2px 15px 2px 2px;
  line-height: 26px;
}
.patientInfo .assistant_menu::after {
  content: " ";
  border: 6px solid transparent;
  border-top-color: #853a2f;
  position: absolute;
  right: 20px;
  bottom: -12px;
}
.patientInfo .assistant_menu .as_left {
  width: 100%;
  display: flex;
}
.patientInfo .assistant_menu .as_left .as_menu_item {
  padding: 0 10px;
  cursor: pointer;
}
.patientInfo .assistant_menu .as_left .as_menu_item.active {
  color: #853a2f;
  background: #fff;
}
.patientInfo .assistant_menu .as_right .icon-double-left {
  font-size: 8px;
}
.patientInfo .pI_popup {
  background: #75645a;
  border: 1px solid #75645a;
  padding: 15px 20px;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 20;
}
.patientInfo .pI_popup::after {
  content: " ";
  position: absolute;
  border: 10px solid transparent;
  top: 40px;
}
.patientInfo .pI_popup.left::after {
  border-left-color: #75645a;
  right: -20px;
}
.patientInfo .pI_popup.left {
  right: 100%;
  margin-right: 20px;
}
.patientInfo .pI_popup.right {
  left: 100%;
  margin-left: 10px;
}
.patientInfo .pI_popup.right::after {
  border-right-color: #75645a;
  left: -20px;
}
.patientInfo .pi_title {
  color: #fff;
  padding-bottom: 10px;
}
.patientInfo .pi_title span {
  font-size: 17px;
  font-weight: bold;
}
.patientInfo .pi_title i.iconfont {
  padding: 0 10px;
}
.patientInfo .pi_title i.ivu-icon {
  font-size: 24px;
  float: right;
  cursor: pointer;
}
.patientInfo .pi_body {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.patientInfo .bzInfo .title2 {
  font-size: 12px;
  opacity: 0.7;
}
.patientInfo .bzInfo .ivu-poptip-body-content {
  height: 150px;
  overflow: hidden;
}
.patientInfo .b_2 .bzInfo .ivu-input-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
}
.patientInfo .b_2 .bzInfo .bz_foot {
  text-align: right;
  margin-top: 10px;
}
.patientInfo .b_2 .bzInfo .bz_foot .ivu-btn {
  margin-left: 15px;
}
</style>