<template>
    <div class="disease-enter-container">
        <div v-if="showStart" class="start-page">
            <div class="disease-nav">
                <div class="menu-icon" @click="showMenu">
                    <img class="menu-bar" src="@/assets/images/menuBar.png" alt="">
                </div>
            </div>
            <div class="content">
                <div class="tip-content">请等待医生录入病历</div>
                <div class="tip-img">
                    <div class="mask"></div>
                    <img src="@/assets/images/logo.png" alt="">
                </div>
            </div>
        </div>
        <div v-if="!showStart && !showEnd" class="main-page">
            <div class="disease-main">
                <div class="disease-nav">
                    <div class="patient-info">
                        <span>
                            <i v-if="yzToPadData.patientSex === '女'" class="ivu-icon ivu-icon-md-female"></i>
                            <i v-if="yzToPadData.patientSex === '男'" class="ivu-icon ivu-icon-md-male"></i>
                        </span>
                        <span class="name">{{ yzToPadData.patientName }}</span>
                        <span class="age">{{ yzToPadData.patientAge }}岁</span>
                        <span class="phone">{{ yzToPadData.cellphoneNo }}</span>
                    </div>
                    <div class="menu-icon" @click="showMenu">
                        <img class="menu-bar" src="@/assets/images/menuBar.png" alt="">
                    </div>
                </div>
                <div class="disease-content" style="padding: 15px; font-size: 18px;">
                    <div claas="disease-title"
                        style="position: relative;margin-bottom: 15px; padding-left: 36px; line-height: 30px;">
                        <span class="disease-title-icon">
                            <img src="@/assets/images/calendar.png" alt="">
                        </span>
                        <span>您的身体情况是如下所述吗？</span>
                    </div>
                    <div claas="disease-list" style="margin-bottom: 15px;">
                        <div v-if="value.length">
                            <span class="symptom_name" v-for="item in value" :key="'dsId_' + item.dsId">
                                <symptomChanges :symptomInfo="item">{{ item.dsName }}</symptomChanges>
                            </span>
                        </div>
                    </div>
                    <div class="patient-complaint" style="">自述：{{ yzData.diseaseTypeName }}</div>
                    <div class="tips">( <span>{{ yzToPadData.hospitalName }}</span> {{ currentDate }})</div>
                </div>
            </div>
            <div class="disease-footer">
                <Button class="know-btn" @click="knowHandle" type="primary">是的</Button>
            </div>
        </div>

        <div v-if="showEnd" class="end-page">
            <div class="disease-nav">
                <div class="menu-icon" @click="showMenu">
                    <img class="menu-bar" src="@/assets/images/menuBar.png" alt="">
                </div>
            </div>
            <div class="content">
                <div class="tip-content">
                    <div>感谢您的配合确认</div>
                    <div>让我们能更好为您服务</div>
                </div>
                <div class="tip-img">
                    <div class="mask"></div>
                    <img src="@/assets/images/logo.png" alt="">
                </div>
            </div>
        </div>
        <Drawer :closable="false" class-name="menu-drawer" v-model="showRight" width="300">
            <div class="menu-container">
                <div class="switch-mode">
                    <div>医生看诊模式</div>
                    <i-switch v-model="checked" true-color="#af312d" size="large" false-color="#D8D8D8"
                        @on-change="switchHandle" style="margin-top: 5px;;" />
                </div>
                <div class="logout" @click="logoutHandle">
                    <img src="@/assets/images/power.png" class="power" />
                    <span class="text">退出登录</span>
                </div>
            </div>
        </Drawer>
    </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
    computed: {
        ...mapGetters([
            "yzData", // 用户信息
        ])
    },
    watch: {
        yzData(newValue, oldValue) {
            this.showEnd = false
            this.showStart = false
            this.yzToPadData = newValue
            this.getYzMessage()
            this.value = []
            let idsArr = []
            let symArr = []
            if (this.yzToPadData.dsIds && this.yzToPadData.dsIds.length > 0) {
                idsArr = this.yzToPadData.dsIds.split('|')
            }
            if (this.yzToPadData.symptoms && this.yzToPadData.symptoms.length > 0) {
                // symArr = this.yzToPadData.symptoms.split('|')
                this.value = JSON.parse(this.yzToPadData.symptoms) 
            }
            // idsArr.forEach((i, iIndex) => {
            //     symArr.forEach((j, jIndex) => {
            //         if (iIndex === jIndex) {
            //             this.value.push({
            //                 dsId: i,
            //                 dsName: j
            //             })
            //         }
            //     })
            // })
        },
    },
    data() {
        return {
            showStart: true,
            showEnd: false,
            yzToPadData: {},
            activeKey: 0,
            showRight: false,
            checked: false,
            value: [],
            currentDate: ''
        };
    },
    methods: {
        getYzMessage() {
            this.api2("padConfirm", this.yzToPadData).then((res) => {
                console.log(res)
            })
        },
        // 获取当前日期
        getCurrentDate() {
            const nowDate = new Date();
            const [year, month, day] =
                [
                    nowDate.getFullYear(),
                    nowDate.getMonth() + 1,
                    nowDate.getDate()
                ];
            this.currentDate = year + "-" + month + "-" + day
        },
        showMenu() {
            this.showRight = true
        },
        knowHandle() {
            let obj = {
                patientCode: this.yzToPadData.patientCode,
                patientName: this.yzToPadData.patientName,
                hospitalCode: this.yzToPadData.hospitalCode
            }
            this.api2("confirmSymptoms", obj).then((res) => {
                this.showEnd = true
            })
        },
        logoutHandle() {
            this.$store.commit("logOut");
        },
        switchHandle(status) {
            if (status) {
                localStorage.setItem('notPadModeStatus', true);
                this.$store.commit('setShowPadPage', true)
                setTimeout(() => {
                    this.$router.push("/index");
                }, 200);
            }
        }
    },
    created() {
        if (window.location.href.match(/\/(diseaseEnter)/)) {
            this.modeChecked = false
        }
        this.getCurrentDate()
    },
};
</script>

<style lang="scss" scoped>
::v-deep .menu-drawer {
    .ivu-drawer-content {
        background-color: #FEFBFC;
    }
}

.menu-container {
    width: 100%;
    font-size: 20px;

    .switch-mode {
        background-color: #fff;
        box-shadow: 0px 2px 4px 0px #EDEDF0;
        border-radius: 4px;
        padding: 15px;
        display: flex;
        margin-bottom: 15px;

        div {
            flex: 1;
        }
    }

    .logout {
        position: relative;
        background-color: #fff;
        box-shadow: 0px 2px 10px 0px #EDEDF0;
        border-radius: 4px;
        padding: 10px;
        text-align: center;
        color: #af312d;

        .power {
            position: absolute;
            top: 50%;
            height: 23px;
            margin-top: -12px;
        }

        .text {
            padding-left: 28px;
        }
    }
}

.disease-enter-container {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 999;

    :deep{
        .ivu-badge .ivu-badge-count{
            font-size: 14px !important;
            height: 20px;
            line-height: 18px;
        }
    }

    .start-page,
    .end-page {
        width: 100%;
        height: 100%;

        .content {
            position: relative;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            font-size: 30px;

            .tip-img {
                position: relative;
                width: 480px;
                margin: 0 auto;
                margin-top: 30px;

                .mask {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(255, 255, 255, 0.86);
                }

                img {
                    width: 100%;
                }
            }
        }
    }

    .main-page {
        .disease-main {
            position: absolute;
            left: 0;
            bottom: 75px;
            width: 100%;
            height: calc(100% - 75px);


        }

        .disease-footer {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            min-height: 75px;
            border-top: 1px solid #ddd;

            .know-btn {
                display: block;
                width: 160px;
                height: 42px;
                margin: 16px auto;
                font-size: 20px;
            }
        }
    }
}

.disease-nav {
    position: relative;
    width: 100%;
    height: 70px;
    font-size: 22px;
    padding: 10px 20px;
    line-height: 50px;
    border-bottom: 1px solid #ddd;

    span {
        padding-right: 12px;

        &.name {
            color: #000;
            font-size: 24px;
            font-weight: bold;
        }
    }

    .menu-icon {
        position: absolute;
        right: 20px;
        top: 10px;
        cursor: pointer;

        .menu-bar {
            width: auto;
            height: 50px;
        }
    }
}

.disease-title {
    position: relative;
    padding: 10px 20px;
}

.disease-title-icon {
    position: absolute;
    left: 0;
    top: 0;
    width: 30px;

    img {
        width: 100%;
        height: auto;
    }
}

.symptom_name {
    display: inline-block;
    line-height: 50px;
    padding: 0 10px;
    border-radius: 5px;
    font-weight: 400;
    font-size: 28px;
    background: #f2e6d4;
    margin: 10px 10px 10px 0;
}

.patient-complaint {
    font-size: 28px;
    margin-bottom: 20px;
}
</style>