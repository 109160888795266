<!-- 症状学习 -->
<template>
  <layer pagename="condition" pageClass="condition2Page ks_page">
    <div class="condition medicalPractise">
      <div class="bz_list_k">
        <div class="st_title">症状学习</div>
        <Row class="bz_sjk">
          <Col class="con_dh" :md="4" :sm="24">
            <div class="bz_xl">
              <AutoComplete
                class="bz_value"
                icon="ios-search"
                v-model="bz_value"
                @on-select="changeValue"
                @on-search="setSearchList"
                placeholder="输入症状拼音首字母"
              >
                <Option
                  v-for="(item,index) in searchList"
                  :value="item.PY+'_'+item.dsId"
                  :key="index"
                >{{ item.dsName }}</Option>
              </AutoComplete>
            </div>
            <div class="myScrollspy">
              <ul class="nav">
                <li v-for="(bz,index) in u_bz_list" :key="index">
                  <a
                    @click.prevent="click_scrool(bz.dtId)"
                    :href="'#id_'+bz.dtId"
                  >{{bz.diagnosticName}}</a>
                </li>
              </ul>
            </div>
          </Col>
          <Col class="bz_list" :md="20" :sm="24">
            <div
              class="scroll_list"
              data-spy="scroll"
              data-target=".myScrollspy"
              data-offset="0"
              style="overflow:auto; position: relative;"
            >
              <div class="section" v-for="(bz,bzindex) in u_bz_list" :key="bzindex">
                <div :id="'id_'+bz.dtId" class="scroll_dw"></div>
                <div
                  v-for="(bz_item,bz_i_index) in bz.symptomSubTypeList"
                  class="item_bz"
                  :key="bz_i_index"
                >
                  <div class="bz_title">{{bz_item.diagnosticName}}</div>
                  <div class="bz_xx">
                    <span
                      v-for="(fxk,index) in bz_item.symptomList.filter(i=>i.isFrequently)"
                      @click="newWin(fxk.dsId)"
                      :key="index"
                    >
                      <Checkbox
                        :class="'id_'+fxk.dsId"
                        :name="'id_'+fxk.dsId"
                        v-model="fxk.Other"
                      >{{fxk.dsName}}</Checkbox>
                    </span>
                    <span v-if="bz_item.symptomList.filter(i=>!i.isFrequently).length">
                      <label @click="clickMear" class="mear">更多...</label>
                    </span>
                    <p style="display: none;">
                      <span
                        v-for="(fxk,index) in bz_item.symptomList.filter(i=>!i.isFrequently)"
                        @click="newWin(fxk.dsId)"
                        :key="index"
                      >
                        <Checkbox
                          :class="'id_'+fxk.dsId"
                          :name="'id_'+fxk.dsId"
                          v-model="fxk.Other"
                        >{{fxk.dsName}}</Checkbox>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <span id="activeitem" style="color:red;"></span>
          </Col>
        </Row>
      </div>
      <!-- 症状学习弹窗  -->
      <Modal
        footer-hide
        class-name="studywin zzxxWin vertical-center-modal"
        v-model="wwin"
        width="800"
        title="症状学习"
      >
        <div class="re-wrap">
          <a class="button back" @click="setPage(-1)"><</a>
          <div class="re-wrapper">
            <div class="item dbt">
              <span class="point">{{diagnosticSymptomDetail.dsName}}</span>
            </div>
            <div class="item zzsm">
              <div class="point">症状说明：</div>
              <span class v-html="diagnosticSymptomDetail.descs"></span>
            </div>
            <div id="images" v-if="diagnosticSymptomDetail.picUrl" class="item xj">
              <div class="point">图例：</div>
              <img
                @click="clickPic('images')"
                :src="picroot(diagnosticSymptomDetail.picUrl, 'symptom')"
              />
            </div>
          </div>
          <a class="button next" @click="setPage(1)">></a>
        </div>
      </Modal>
    </div>
  </layer>
</template>
<script>
import viewer from "viewerjs";
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  props: ["id"],
  data() {
    return {
      diagnosticSymptomDetail: {},
      picView: null,
      searchList: [],
      dsList: [],
      bz_value: "",
      u_bz_list: [],
      wwin: false,
      zzList: [],
      dqId: "",
      udjs: null
    };
  },
  computed: {
    ...mapGetters(["diagnosticList"])
  },
  watch: {
    diagnosticList(value) {
      this.showZz();
    },
    u_bz_list: {
      handler: function(value) {
        setTimeout(() => {
          this.clearSelectList();
        }, 2000);
      },
      deep: true
    }
  },
  methods: {
    showZz() {
      this.zzList = [];
      this.diagnosticList.forEach(i => {
        i.symptomSubTypeList.forEach(j => {
          j.symptomList.forEach(x => {
            this.zzList.push(x.dsId);
            this.dsList.push(x);
          });
        });
      });
      this.u_bz_list = this.common.clone(this.diagnosticList);
    },
    setPage(nb) {
      var index = this.zzList.indexOf(this.dqId);
      if (this.zzList[index + nb]) {
        this.getData(this.zzList[index + nb]);
      } else {
        this.$Message.error("已到达最后一页");
      }
    },
    // 设置搜索列表
    setSearchList(value) {
      // 触发键盘事件 下方向键
      setTimeout(function() {
        let mockKeyboardEvent = new KeyboardEvent("keydown", { keyCode: 40 });
        document.dispatchEvent(mockKeyboardEvent);
      }, 200);

      var newArr = [];
      this.u_bz_list.forEach(i => {
        i.symptomSubTypeList.forEach(x => {
          x.symptomList.forEach(y => {
            if (
              !y.Other &&
              y.py.toUpperCase().indexOf(value.toUpperCase()) !== -1
            ) {
              newArr.push(y);
            }
          });
        });
      });
      this.searchList = newArr;
    },
    // 搜索值改变时
    changeValue(value) {
      var _this = this;
      _this.bz_value = "";
      $(".bz_value")
        .find("input")
        .focus();
      var bz_id = value.match(/\d+$/)[0];
      this.u_bz_list.forEach(i => {
        i.symptomSubTypeList.forEach(x => {
          x.symptomList.forEach(y => {
            if (y.dsId == bz_id) y.Other = true;
          });
        });
      });
      var offset = $(".id_" + bz_id).position();

      var h = $(".scroll_list").scrollTop() + offset.top - 30;
      $(".scroll_list").animate({ scrollTop: h }, 500);
    },
    clickMear(e) {
      var node = $(e.target)
        .parent()
        .next();
      node.slideToggle(200);
    },
    // 点击滚动
    click_scrool(id) {
      var offset = $("#id_" + id).position();
      var h = $(".scroll_list").scrollTop() + offset.top;
      $(".scroll_list").animate({ scrollTop: h }, 500);
      $(".con_dh").removeClass("con_dh_a");
    },
    getData(id) {
      this.dqId = id;
      let item = this.dsList.find(i => i.dsId == id);
      this.diagnosticSymptomDetail = item;
    },
    newWin(id) {
      clearTimeout(this.udjs);
      this.udjs = setTimeout(() => {
        this.getData(id);
        this.wwin = true;
      }, 300);
    },
    // 清空以选择病症
    clearSelectList() {
      this.u_bz_list.forEach(i => {
        i.symptomSubTypeList.forEach(x => {
          x.symptomList.forEach(y => {
            if (y.Other) y.Other = false;
          });
        });
      });
    },
    onkey() {
      let _this = this;
      //  键盘事件
      document.onkeydown = function(event) {
        if (!_this.wwin) return;
        if (_this.picView && _this.picView.isShown) return;
        var e = event || window.event || arguments.callee.caller.arguments[0];
        if (e && e.keyCode == 37) {
          // 按 左
          _this.setPage(-1);
        }
        if (e && e.keyCode == 39) {
          // 按  右
          _this.setPage(1);
        }
      };
    },
    offKey() {
      document.onkeydown = null;
    },
    clickPic(ref) {
      if (this.picView) this.picView.destroy();
      this.picView = new viewer($("#" + ref)[0]);
    }
  },
  created() {
    if (this.diagnosticList.length) {
      this.showZz();
    } else {
      this.$store.dispatch("getDiagnosticList");
    }
  },
  mounted: function() {
    //滚动高亮显视
    setTimeout(() => {
      var liArr = $(".nav li");
      $(liArr[0]).addClass("active");
      var secArr = $(".scroll_list .section");
      $(".scroll_list").bind("scroll", function(e) {
        for (var i = 0; i < secArr.length; i++) {
          if ($(secArr[i]).position().top >= 0) {
            liArr.removeClass("active");
            $(liArr[i]).addClass("active");
            break;
          }
        }
      });
    }, 300);
    this.onkey();
  },
  beforeDestroy() {
    this.offKey();
  }
};
</script>
