<!-- 症状考试 -->
<template>
  <layer pagename="medicalPractise" pageClass="medicalPractisePage ks_page">
    <div class="medicalPractise condition e_Symptom">
      <div class="z_title">
        <div class="label">
          病历考试
          <span>错题查看</span>
        </div>
      </div>
      <Row class="user_info">
        <Col class="info_k" :md="12" :sm="24">
          <div class="cont">
            <div class>
              <div class="name">
                {{paper.patientname}}
                <span>{{paper.patientSex}}</span>
              </div>
              <div class="u_row">
                <div class="item">
                  <span class="label">年龄：</span>
                  <span class="nr">{{paper.patientAge}}</span>
                </div>
                <div class="item">
                  <span class="label">性别：</span>
                  <span class="nr">{{paper.patientSex}}</span>
                </div>
                <div class="item">
                  <span class="label">主诉：</span>
                  <span class="nr">{{paper.patientPurpose}}</span>
                </div>
              </div>
            </div>
            <div class="info_zz">
              <div class="title">病情描述：</div>
              <div class="bod_zz">
                <div v-show="!paper.pictureList.length" class="item dsNames_k">
                  <span
                    class="dsNames"
                    :key="index"
                    v-for="(item,index) in dsNames(paper.symptomDescs)"
                  >{{item}}</span>
                </div>
                <Carousel v-model="c_index">
                  <CarouselItem :key="index" v-for="(item,index) in paper.pictureList">
                    <div class="demo-carousel">
                      <span ref="zoom" class="zoom" id="image3">
                        <img :src="item.pictureUrl" id="img3" alt="Daisy on the Ohoopee" />
                      </span>
                    </div>
                  </CarouselItem>
                </Carousel>
              </div>
            </div>
          </div>
        </Col>

        <Col class="zz_k zz_dats" :md="12" :sm="24">
          <div class="da">
            <div class="title">正确答案:</div>
            <div class="no">
              <div class="item dsNames_k" v-if="sysList.length">
                <span
                  class="dsNames"
                  :class="{s_error:item.isError}"
                  v-for="(item,index) in sysList"
                  :key="index"
                >{{item.label}}</span>
              </div>
              <div class="item dsNames_k" v-else>无</div>
            </div>
          </div>
          <div class="da">
            <div class="title">我的答案:</div>
            <div class="no">
              <div class="item dsNames_k" v-if="selfList.length">
                <span
                  class="dsNames"
                  :class="{s_error:item.isError}"
                  v-for="(item,index) in selfList"
                  :key="index"
                >{{item.label}}</span>
              </div>
              <div class="item dsNames_k" v-else>无</div>
            </div>
          </div>
        </Col>
        <div @click="leftIndex" class="fy_btn b_left">
          <Icon type="ios-arrow-back" />
        </div>
        <div @click="rightIndex" class="fy_btn b_rigth">
          <Icon type="ios-arrow-forward" />
        </div>
      </Row>
      <Row>
        <Col class="questList_k" :md="24" :sm="24">
          <div class="cont">
            <div class="questList">
              <span
                @click="clickIndex(item,index)"
                :key="index"
                class="item"
                v-for="(item, index) in p_list"
                :class="setClassName(item)"
              >
                <i>{{index+1}}</i>
                <i v-if="item.isanswer==2" class="iconfont icon-close"></i>
              </span>
            </div>
          </div>
        </Col>
      </Row>
      <div class="e_bnt_g">
        <Button @click="rightIndex" type="primary">查看下一题</Button>
        <Button @click="del" class="e_del">移除此题</Button>
      </div>
      <div v-show="!p_list.length" class="mack_ts">当前无试题可答哦！</div>
    </div>
  </layer>
</template>
<script>
import zoombie from "@/jquery.zoombie.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  props: ["type"],
  data() {
    return {
      userWrongQuestionsList: [],
      c_index: 0, //轮播序号
      searchList: [],
      bz_value: "",
      u_bz_list: [],
      zzList: [],
      dqId: "",

      p_index: 0, // 当前序号
      p_list: [], // 问题列表

      selfList: [],
      sysList: [],
      selfList_same: [],
      sysList_same: []
    };
  },
  computed: {
    ...mapGetters(["diagnosticList"]),
    paper() {
      let item = this.userWrongQuestionsList[this.p_index] || {
        pictureList: [],
        dsList: []
      };
      return item;

      if (!item.dsNames) return item;

      let s = item.dsList.map(i => {
        return {
          label: i.dsNames,
          isError: false
        };
      });
      let y = item.dsNames.split(/,\s*/).map(i => {
        return {
          label: i,
          isError: false
        };
      });

      this.selfList = this.sameSymptom(s, y);
      this.sysList = this.sameSymptom(y, s);
      return item;
    }
  },
  watch: {
    type(value) {},
    userWrongQuestionsList(value) {
      if (!value.length) {
        this.p_list = [];
        this.p_index = 0;
        return;
      }
      var newArr = value;
      newArr.forEach(i => {
        i.active = false;
        i.state = 0;
      });
      this.p_list = newArr;
      this.changeIndex();
    }
  },
  methods: {
    sameSymptom(arr1, arr2) {
      let names = arr2.map(i => i.label);
      arr1
        .filter(i => !names.includes(i.label))
        .forEach(i => (i.isError = true));
      return arr1;
    },
    del() {
      this.$store.commit("openSpin");
      this.api2("delMyWrongQuestions", {
        wrongid: this.paper.wrongId
      }).then(res => {
        this.$store.commit("closeSpin");
        this.$Message.success(res.result);
        this.userWrongQuestionsList.splice(this.p_index, 1);
      });
    },
    dsNames(str) {
      return str ? str.split(",") : [];
    },
    // 提序号点击事件
    clickIndex(item, index) {
      this.p_index = index;
      this.changeIndex();
    },
    changeIndex() {
      this.p_list.forEach(i => {
        i.active = false;
      });
      this.p_list[this.p_index].active = true;

      setTimeout(() => {
        this.setZoombie();
      }, 500);
    },
    // 下一题
    rightIndex() {
      if (this.p_index + 1 < this.p_list.length) {
        this.p_index++;
        this.changeIndex();
      } else {
        this.$Message.warning("已经是最后一题!");
      }
    },
    // 下一题
    leftIndex() {
      if (this.p_index - 1 >= 0) {
        this.p_index--;
        this.changeIndex();
      } else {
        this.$Message.warning("已经是第一题!");
      }
    },
    setClassName(item) {
      var cNameList = [];
      if (item.active) cNameList.push("active");
      if (item.isanswer == 1) cNameList.push("isAnswer");
      if (item.isanswer == 2) cNameList.push("isError");
      return cNameList;
    },

    setZoombie() {
      if (!this.$refs.zoom || !this.$refs.zoom.length) return;
      this.$refs.zoom.forEach(i => {
        if (i.className == "zoom")
          zoombie.zoombie_run({ target: i, on: "grab" });
      });
    },
    getData() {
      var obj = {
        pageIndex: 1, //	页码
        pageSize: 1000, //	页大小
        paperTypeId: 1 // 	试卷类型 0 百年方 1 症状
      };
      this.$store.commit("openSpin");
      this.api2("getUserWrongQuestionsList", obj)
        .then(res => {
          this.$store.commit("closeSpin");
          this.userWrongQuestionsList = res.result;
        })
        .catch(err => console.log(err));
    }
  },
  created() {
    this.getData();
  },
  mounted: function() {}
};
</script>
