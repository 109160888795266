<!-- 脏腑趋势分析 -->

<template>
    <div>
        <div class="section-time">{{ section.name }}</div>
        <div v-if="section.arr && section.arr.length > 0" class="section-list">
            <div v-for="(item, index) in section.arr" :key="index">
                <span>{{ item.value }}</span>
                <span>{{ item.name }}</span>
            </div>
        </div>
        <div :class="className" :id="visceraId" :style="{ height: height, width: width }" />
    </div>
</template>

<script>
import * as echarts from "echarts";
import resize from './mixins/resize.js'
import { dayList, dataZoom } from '@/utils/tool.js'
import dayjs from 'dayjs'
export default {
    mixins: [resize],
    props: {
        type: {
            type: String,
            default: ''
        },
        className: {
            type: String,
            default: 'chart'
        },
        width: {
            type: String,
            default: '100%'
        },
        height: {
            type: String,
            default: '100%'
        },
        autoResize: {
            type: Boolean,
            default: true
        },
        visceraData: {
            type: Object,
            default: {}
        },
        addTime: {
            type: String,
            default: ''
        },
        visitDateArr: {
            type: Array,
            default: () => []
        },
    },
    data() {
        return {
            chart: null,
            section: [],
            visceraId: ''
        }
    },
    watch: {

    },
    created() {
        if (this.type === 'allDay') {
            this.visceraId = 'allDayViscera'
        } else if (this.type === 'day') {
            this.visceraId = 'dayViscera'
        } else if (this.type === 'night') {
            this.visceraId = 'nightViscera'
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.initChart()
        })
    },
    beforeDestroy() {
        if (!this.chart) {
            return
        }
        this.chart.dispose()
        this.chart = null
    },
    methods: {
        initChart() {
            this.chart = echarts.init(document.getElementById(this.visceraId))
            var e = []
                , t = []
                , a = []
                , i = []
                , s = []
                , r = []
            if (this.type === 'allDay') {
                r = this.visceraData.averageVisceras;
            } else if (this.type === 'day') {
                r = this.visceraData.averageDayTimeVisceras;
            } else if (this.type === 'night') {
                r = this.visceraData.averageNightVisceras;
            }
            r && (dayList(this.addTime).last16.forEach(function (n, o) {
                r.forEach(function (r, l) {
                    r.dataDate && n === r.dataDate.split(" ")[0] && (e[o] = r.intHeart,
                        t[o] = r.intLiver,
                        a[o] = r.intKidney,
                        i[o] = r.intSpleen,
                        s[o] = r.intLungs)
                })
            }),
                e.length < 16 && (e[15] = ""),
                t.length < 16 && (t[15] = ""),
                a.length < 16 && (a[15] = ""),
                i.length < 16 && (i[15] = ""),
                s.length < 16 && (s[15] = "")),
                this.setOptions({
                    id: this.visceraId,
                    section: "nightVisceraThrend",
                    organs1_data1: e,
                    organs1_data2: t,
                    organs1_data3: a,
                    organs1_data4: i,
                    organs1_data5: s
                })
        },
        formateDate(date) {
            let month = dayjs(date).month() + 1
            let day = dayjs(date).date()
            return month + '/' + day
        },
        getDate(date) {
            let month = date.split('/')[0]
            if (Number(month) < 10) {
                month = '0' + month
            }
            let day = date.split('/')[1]
            if (Number(day) < 10) {
                day = '0' + day
            }
            return month + '-' + day
        },
        setOptions(e) {
            let that = this
            var s = e.organs1_data1
                , r = e.organs1_data2
                , n = e.organs1_data3
                , o = e.organs1_data4
                , l = e.organs1_data5
            let uesTemp = dayList(this.addTime).ecUse
            let dataTemp = dayList(this.addTime).ecShow
            let markAreaData = []
            let firstDayMonth = dataTemp[0].split('/')[0]
            let endDayMonth = dataTemp[29].split('/')[0]
            let thirtyFirstDay = ''
            let thirtyEndDay = ''
            let currentYear = this.addTime.split('-')[0]
            // 说明跨年
            if (Number(firstDayMonth) === 12 && Number(endDayMonth) === 1) {
                thirtyFirstDay = (Number(currentYear) - 1) + '-' + this.getDate(dataTemp[0])
            } else {
                thirtyFirstDay = currentYear + '-' + this.getDate(dataTemp[0])
            }
            thirtyEndDay = currentYear + '-' + this.getDate(dataTemp[29])
            this.visitDateArr.map((item, index) => {
                let newArr = []
                //如果数据的结束时间都小于x轴开始时间就不必要处理了
                if (item.visitEndDate < thirtyFirstDay) {
                    return
                }
                if (item.visitEndDate >= thirtyFirstDay && thirtyFirstDay > item.visitStartDate) {
                    //时间段处于一段时间在x轴起点之外，一段在x轴中
                    newArr = [
                        {
                            name: '服药中',
                            xAxis: this.formateDate(item.visitEndDate)
                        },
                        {
                            xAxis: this.formateDate(thirtyFirstDay)
                        }
                    ]
                } else if (item.visitStartDate <= thirtyEndDay && item.visitEndDate > thirtyEndDay) {
                    //时间段处于一段时间在x轴终点之外，一段在x轴中
                    newArr = [
                        {
                            name: '服药中',
                            xAxis: this.formateDate(thirtyEndDay)
                        },
                        {
                            xAxis: this.formateDate(item.visitStartDate)
                        }
                    ]
                } else {
                    newArr = [
                        {
                            name: '服药中',
                            xAxis: this.formateDate(item.visitEndDate)
                        },
                        {
                            xAxis: this.formateDate(item.visitStartDate)
                        }
                    ]
                }
                markAreaData.push(newArr)
            })
            this.chart.setOption(
                {
                    grid: {
                        top: 30,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        containLabel: !0
                    },
                    tooltip: {
                        trigger: "axis",
                        formatter: function (e) {
                            for (var a = dataTemp[e[0].dataIndex].split("/"), i = "<div><p>" + (a[0] + "月" + a[1] + "日") + "</p>", s = 0; s < e.length; s++) {
                                var r = e[s]
                                    , n = r.data
                                    , o = r.marker
                                    , l = r.seriesName;
                                "心脏" == l ? e[s].data ? i += "<p>" + o + " 心脏：" + n + "</p>" : i += "<p>" + o + " 心脏：---</p>" : "肝脏" == l ? e[s].data ? i += "<p>" + o + " 肝脏：" + n + "</p>" : i += "<p>" + o + " 肝脏：---</p>" : "肾脏" == l ? e[s].data ? i += "<p>" + o + " 肾脏：" + n + "</p>" : i += "<p>" + o + " 肾脏：---</p>" : "脾脏" == l ? e[s].data ? i += "<p>" + o + " 脾脏：" + n + "</p>" : i += "<p>" + o + " 脾脏：---</p>" : "肺脏" == l && (e[s].data ? i += "<p>" + o + " 肺脏：" + n + "</p>" : i += "<p>" + o + " 肺脏：---</p>")
                            }
                            return i += "</div>"
                        }
                    },
                    toolbox: {
                        show: !0,
                        feature: {
                            dataZoom: {
                                yAxisIndex: "none",
                                title: {
                                    back: "还原"
                                }
                            }
                        }
                    },
                    xAxis: [
                        {
                            type: "category",
                            boundaryGap: !0,
                            data: uesTemp,
                            axisLabel: {
                                interval: 0,
                                color: "#999"
                            },
                            axisLine: {
                                lineStyle: {
                                    color: "e7e5e4"
                                }
                            },
                            // axisTick: {
                            //     show: !1
                            // },
                            splitArea: {
                                show: !1
                            }
                        },
                        {
                            type: "category",
                            data: dataTemp,
                            show: false,
                            silent: true,
                            axisPointer: {
                                show: false
                            },
                        }
                    ],
                    yAxis: {
                        max: 190,
                        min: 10,
                        interval: 60,
                        type: "value",
                        axisLabel: {
                            show: !1,
                            color: "#999"
                        },
                        splitLine: {
                            show: !0,
                            lineStyle: {
                                type: "solid"
                            }
                        },
                        axisLine: {
                            show: !1
                        },
                        axisTick: {
                            show: !1
                        },
                        splitArea: {
                            show: !0,
                            areaStyle: {
                                color: ["#fff", "#f6f6f6", "#fff"]
                            }
                        }
                    },
                    color: ["#b28850","#66bb6a","#d73835","#fad53e","#78909c"],   //肾、肝、心、脾、肺
                    legend: {
                        x: "center",
                        itemWidth: 15,
                        itemHeight: 6,
                        itemGap: 20,
                        data: [
                            {
                                name: "肝脏",
                                icon: "rect"
                            },
                            {
                                name: "心脏",
                                icon: "rect"
                            },
                            {
                                name: "脾脏",
                                icon: "rect"
                            },
                            {
                                name: "肺脏",
                                icon: "rect"
                            },
                            {
                                name: "肾脏",
                                icon: "rect"
                            }
                        ]
                    },
                    series: [
                        {
                            name: '占位',
                            type: 'bar',
                            xAxisIndex: 1, // 对应第二个x轴
                            silent: true,
                            markArea: {
                                itemStyle: {
                                    color: 'rgba(255, 173, 177, 0.2)'
                                },
                                data: markAreaData
                            }
                        },
                        {
                            name: "肝脏",
                            type: "line",
                            smooth: !0,
                            data: r,
                            itemStyle: {
                                lineStyle: {
                                    color: "#66bb6a"
                                }
                            }
                        },
                        {
                            name: "心脏",
                            type: "line",
                            smooth: !0,
                            data: s,
                            itemStyle: {
                                lineStyle: {
                                    color: "#d73835"
                                }
                            },
                        },
                        {
                            name: "脾脏",
                            type: "line",
                            smooth: !0,
                            data: o,
                            itemStyle: {
                                lineStyle: {
                                    color: "#fad53e"
                                }
                            }
                        },
                        {
                            name: "肺脏",
                            type: "line",
                            smooth: !0,
                            data: l,
                            itemStyle: {
                                lineStyle: {
                                    color: "#78909c"
                                }
                            }
                        },
                        {
                            name: "肾脏",
                            type: "line",
                            smooth: !0,
                            data: n,
                            itemStyle: {
                                lineStyle: {
                                    color: "#b28850"
                                }
                            }
                        },
                    ]
                }
            )

            this.section = []
            dataZoom({
                echart: this.chart,
                series: [{
                    name: "心脏",
                    data: s
                }, {
                    name: "肝脏",
                    data: r
                }, {
                    name: "肾脏",
                    data: n
                }, {
                    name: "脾脏",
                    data: o
                }, {
                    name: "肺脏",
                    data: l
                }],
                addTime: this.addTime,
                sectionArr: function (e) {
                    that.section = e
                }
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.section-time {
    position: absolute;
    top: -60px;
    line-height: 32px;
    right: 0;
    font-size: 16px;
    color: #000;
}

.section-list {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;

    div {
        border-right: 1px solid #e9e9e9;
        padding: 0 40px;

        &:last-child {
            border: none;
        }

        span {
            display: block;
            text-align: center;

            &:first-child {
                font-size: 18px;
                font-weight: 700;
                margin-bottom: 10px;
            }

            &:last-child {
                font-size: 14px;
                color: #7f7f7f;
            }
        }
    }
}
</style>