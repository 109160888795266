<template>
  <div ref="userCenterUi" class="news_center"
    :class="[point ? 'utip_left' : 'utip_right', moveing ? '' : 'c_stop', ex ? '' : 'ex']">
    <img @mousedown.stop="tStart" @touchstart="tStart_touch" @touchmove.stop="tMove_touch" @touchend.stop="tEnd_touch"
      @click.stop="showN_tip" src="@/assets/images/npc.gif" alt />
    <div id="step3" v-show="n_tip.show && n_tip.content" class="utip">
      <div @click="ex = !ex" @touchstart="ex = !ex" class="uzoom">
        <i v-if="ex" class="iconfont icon-zoomin"></i>
        <i v-else class="iconfont icon-zoomout"></i>
      </div>
      <div @click.stop="hideN_tip" @touchstart="hideN_tip" class="c_btn">
        <Icon type="md-remove-circle" />
      </div>
      <div class="utip_title" v-if="n_tip.title">{{ n_tip.title }}</div>
      <div class="utip_cont" v-html="n_tip.content"></div>
      <div v-if="n_tip.onOk" class="n_btn_k">
        <button class="ivu-btn ivu-btn-primary ivu-btn-small" @click.stop="confirmN_tip" @touchstart.stop="confirmN_tip"
          size="small" type="primary">{{ n_tip.okText || '好的，我已确认' }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
function findDimensions() {
  //函数：获取尺寸
  let point = {};
  //获取窗口宽度
  window.innerWidth && (point.width = window.innerWidth);
  window.innerHeight && (point.height = window.innerHeight);
  document.body.clientWidth && (point.width = document.body.clientWidth);
  document.body.clientHeight && (point.height = document.body.clientHeight);
  return point;
}
export default {
  computed: {
    ...mapGetters(["n_tip"])
  },
  data() {
    return {
      point: false, //false:右边  true:左边
      ex: true,
      oldPoint: {},
      newPoint: {},
      oldPosition: {},
      winSize: {},
      userUi: null,
      // 临时数据
      old: null,
      moveing: false
    };
  },
  methods: {
    ...mapMutations(["showN_tip", "hideN_tip", "confirmN_tip"]),
    tStart_touch(e) {
      e.preventDefault();
      e = e.changedTouches[0];
      this.oldPoint = {
        x: e.clientX,
        y: e.clientY
      };
      this.oldPosition = {
        x: this.userUi.offsetLeft,
        y: this.userUi.offsetTop
      };
      this.moveing = true;
    },
    tMove_touch(e) {
      if (!this.moveing) return;
      e = e.changedTouches[0];
      this.newPoint = {
        x: e.clientX,
        y: e.clientY
      };
      if (e.clientX > (this.winSize.width - this.userUi.clientWidth) / 2) {
        this.point = true;
      } else {
        this.point = false;
      }
      let offset = {
        x: this.oldPoint.x - this.newPoint.x,
        y: this.oldPoint.y - this.newPoint.y
      };
      this.setPostiton({
        x: this.oldPosition.x - offset.x,
        y: this.oldPosition.y - offset.y
      });
    },
    tEnd_touch(e) {
      if (!this.moveing) return;
      e = e.changedTouches[0];
      document.onmousemove = null;
      document.onmousup = null;
      this.moveing = false;
      let left = this.userUi.offsetLeft,
        top = this.userUi.offsetTop;

      if (e.clientX > (this.winSize.width - this.userUi.clientWidth) / 2) {
        left = this.winSize.width - this.userUi.clientWidth;
        this.point = true;
      } else {
        left = 0;
        this.point = false;
      }
      if (
        this.userUi.offsetTop >
        this.winSize.height - this.userUi.clientHeight
      ) {
        top = this.winSize.height - this.userUi.clientHeight;
      }
      if (this.userUi.offsetLeft < 0) {
        left = 0;
      }
      if (this.userUi.offsetTop < 0) {
        top = 0;
      }
      this.setPostiton({ x: left, y: top });
    },
    tStart(e) {
      e.preventDefault();
      this.oldPoint = {
        x: e.clientX,
        y: e.clientY
      };
      this.oldPosition = {
        x: this.userUi.offsetLeft,
        y: this.userUi.offsetTop
      };
      this.moveing = true;
      document.onmousemove = this.tMove;
      document.onmouseup = this.tEnd;
    },
    tMove(e) {
      if (!this.moveing) return;
      this.newPoint = {
        x: e.clientX,
        y: e.clientY
      };
      if (e.clientX > (this.winSize.width - this.userUi.clientWidth) / 2) {
        this.point = true;
      } else {
        this.point = false;
      }
      let offset = {
        x: this.oldPoint.x - this.newPoint.x,
        y: this.oldPoint.y - this.newPoint.y
      };
      this.setPostiton({
        x: this.oldPosition.x - offset.x,
        y: this.oldPosition.y - offset.y
      });
      e.preventDefault();
    },
    tEnd(e) {
      if (!this.moveing) return;
      document.onmousemove = null;
      document.onmousup = null;
      this.moveing = false;
      let left = this.userUi.offsetLeft,
        top = this.userUi.offsetTop;
      if (e.clientX > (this.winSize.width - this.userUi.clientWidth) / 2) {
        left = this.winSize.width - this.userUi.clientWidth;
        this.point = true;
      } else {
        left = 0;
        this.point = false;
      }
      if (
        this.userUi.offsetTop >
        this.winSize.height - this.userUi.clientHeight
      ) {
        top = this.winSize.height - this.userUi.clientHeight;
      }
      if (this.userUi.offsetLeft < 0) {
        left = 0;
      }
      if (this.userUi.offsetTop < 0) {
        top = 0;
      }
      this.setPostiton({ x: left, y: top });
    },
    setPostiton(obj) {
      this.userUi.style.top = obj.y + "px";
      this.userUi.style.left = obj.x + "px";
    },
    initPosition() {
      this.setPostiton({
        x: this.point ? this.winSize.width - this.userUi.clientWidth : 0,
        y: this.winSize.height - this.userUi.clientHeight - 200
      });
    },
    setWinSize() {
      this.winSize = findDimensions();
      this.initPosition();
    }
  },
  mounted: function () {
    this.userUi = this.$refs.userCenterUi;
    this.setWinSize();
    $(window).resize(() => {
      this.setWinSize();
    });
  }
};
</script>


<style >
.news_center {
  position: absolute;
  z-index: 10000;
  width: 60px;
  height: 60px;
  left: 2000px;
  top: 2000px;
}

.news_center.c_stop {
  transition: all 0.3s;
}

.news_center img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.news_center .utip {
  background: #333;
  padding: 20px 20px 15px;
  width: 260px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  position: absolute;
  top: 0;
  color: #fff;
}

.news_center.ex .utip {
  width: 500px;
}

.news_center.utip_right .utip {
  left: 75px;
  animation: slideInRight 1.5s ease-out;
}

.news_center.utip_left .utip {
  right: 75px;
  animation: slideInLeft 1.5s;
}

.news_center .utip::after {
  content: " ";
  border: 10px solid transparent;
  position: absolute;
  top: 20px;
}

.news_center.utip_right .utip::after {
  left: -20px;
  border-right-color: #333;
}

.news_center.utip_left .utip::after {
  right: -20px;
  border-left-color: #333;
}

.news_center .utip .utip_title {
  font-weight: bold;
  padding-bottom: 10px;
  font-size: 16px;
}

.news_center .icon-warn,
.news_center .t_read {
  color: #f00;
  font-weight: bold;
  padding: 0 5px;
}

.news_center .uzoom,
.news_center .c_btn {
  position: absolute;
  right: 5px;
  top: 2px;
  width: 20px;
  height: 20px;
  color: #999898;
  font-size: 18px;
  cursor: pointer;
}

.news_center .uzoom {
  right: 30px;
}

.news_center .uzoom i {
  font-size: 19px;
}

.news_center .utip_cont {
  max-height: 150px;
  overflow-y: auto;
}

.news_center .utip_cont .ftitle {
  font-weight: 500;
}

.news_center .utip_cont .fname {
  opacity: 0.8;
}

.news_center.ex .utip_cont {
  max-height: 300px;
  line-height: 1.8em;
}

.news_center .n_btn_k {
  padding-top: 10px;
}

.news_center .n_btn_k button {
  float: right;
}

@keyframes slideInRight {

  0%,
  60%,
  75%,
  90%,
  100% {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0px, 0px);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0px, 0px);
  }

  75% {
    transform: translate3d(-10px, 0px, 0px);
  }

  90% {
    transform: translate3d(5px, 0px, 0px);
  }

  100% {
    transform: none;
  }
}

@keyframes slideInLeft {

  0%,
  60%,
  75%,
  90%,
  100% {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(3000px, 0px, 0px);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0px, 0px);
  }

  75% {
    transform: translate3d(10px, 0px, 0px);
  }

  90% {
    transform: translate3d(-5px, 0px, 0px);
  }

  100% {
    transform: none;
  }
}

@keyframes slideInRight22 {
  0% {
    transform: translate3d(-100px, 0, 0);
  }

  to {
    transform: translateZ(0);
  }
}

@keyframes slideInLeft22 {
  0% {
    transform: translate3d(100px, 0, 0);
  }

  90% {
    transform: translate3d(-10px, 0, 0);
  }

  to {
    transform: translateZ(0);
  }
}

@media screen and (max-width: 900px) {
  .news_center {
    position: fixed;
  }
}
</style>
