<template>
  <div class="xuewei">
    <div v-if="resList.length" class="u_row">
      <div class="u_col u_c_left">
        <div class="u_tab">
          <div
            class="u_tab_item"
            @click="tabFun(item)"
            :class="{active:item.active}"
            v-for="(item,index) in resList"
            :key="index"
          >{{item.diagnosticName}}</div>
        </div>
      </div>
      <div class="u_col u_c_right">
        <div class="x_mk">
          <div class="x_mk_title">按摩主穴</div>
          <div class="x_mk_body">
            <div class="x_mk_item" v-for="(i, index) in acupointTabData.acupoint1" :key="index">
              <div class="x_mk_tip">{{i.acquaintRemark}}</div>
              <span
                v-if="item.acquaintName"
                @click="jump(item.acquaintId)"
                v-for="(item,index1) in i.diseaseAcquaintDetailList"
                :key="index1"
              >{{item.acquaintName}}</span>
            </div>
          </div>
        </div>
        <div class="x_mk">
          <div class="x_mk_title">配合穴位</div>
          <div class="x_mk_body">
            <div class="x_mk_item" v-for="(i, index) in acupointTabData.acupoint2" :key="index">
              <div class="x_mk_tip">{{i.acquaintRemark}}</div>
              <span
                v-if="item.acquaintName"
                @click="jump(item.acquaintId)"
                v-for="(item,index1) in i.diseaseAcquaintDetailList"
                :key="index1"
              >{{item.acquaintName}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="u_col u_c_center">
        <div
          :class="'id_'+item.acquaintId"
          class="u_c_item"
          v-for="(item,index) in acupointTabData.list"
          :key="index"
        >
          <div class="u_c_item_cont">
            <div class="u_c_row">
              <span class="u_c_lab">穴位</span>
              <span class="u_c_val">{{item.acquaintName}}</span>
            </div>
            <div class="u_c_row">
              <span class="u_c_lab">定位</span>
              <span class="u_c_val">{{item.acquaintLocation}}</span>
            </div>
            <div class="u_c_row">
              <div class="u_c_lab">简便取穴</div>
              <div class="u_c_val">{{item.acquaintSelect}}</div>
            </div>
          </div>
          <div :id="'id_'+item.acquaintId" class="u_c_item_pic">
            <img @click="clickPic(item.acquaintId)" :src="cloudPic+'/acupoint/'+item.picUrl" />
          </div>
        </div>
      </div>
    </div>
    <empty v-else />
  </div>
</template>

<script>
import viewer from "viewerjs";
import { mapGetters } from "vuex";
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    show(newValue, oldValue) {
      this.showWin = newValue;
    }
  },
  computed: {
    ...mapGetters(["acupointList"]),
    acupointTabData() {
      return this.resList.find(i => i.active) || {};
    }
  },
  data() {
    return {
      picView: null,
      showWin: false,
      resList: [],
      acupointInfoList: []
    };
  },
  methods: {
    clickPic(id) {
      if (this.picView) this.picView.destroy();
      this.picView = new viewer($("#id_" + id)[0]);
    },
    // 跳转到指定位置
    jump(id) {
      var offset = $(".id_" + id).position();
      var h = $(".u_c_center").scrollTop() + offset.top - 70;
      $(".u_c_center").animate({ scrollTop: h }, 200);
    },
    tabFun(item) {
      this.resList.forEach(i => {
        i.active = false;
      });
      item.active = true;
    },
    close(res) {
      if (!res) this.$emit("close");
    },
    setList(acupointlist) {
      if (!acupointlist.length) {
        this.$Message.info("暂无数据");
        this.resList = [];
        this.close();
        return;
      }
      this.resList = this.common.clone(acupointlist).map((i, index) => {
        let list = [];
        i.diseaseAcquaintList.forEach(j => {
          j.diseaseAcquaintDetailList.forEach(k => {
            if (!list.find(l => l.acquaintId == k.acquaintId)) {
              list.push(k);
            }
          });
        });

        i.list = list;
        i.active = index ? false : true;
        i.acupoint1 = i.diseaseAcquaintList.filter(j => !j.acquaintType);
        i.acupoint2 = i.diseaseAcquaintList.filter(j => j.acquaintType);
        return i;
      });
      this.showWin = this.show;
    }
  },
  created() {
    this.setList(this.acupointList);
  }
};
</script>

<style lang="scss">
.xuewei {
  height: 500px;
  height: 100%;
  padding: 15px;
  background: #fff; 
}
.xuewei .u_row {
  display: flex;
  height: 100%;
  overflow: hidden;
}
.xuewei .u_row .u_col {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.xuewei .u_row .u_col.u_c_right {
  width: 220px;
  flex-shrink: 0;
  margin: 0 10px;
}
.xuewei .u_row .u_col.u_c_center {
  background: rgba(255, 255, 255, 0.2);
  padding: 15px;
}
.xuewei .u_row .u_col.u_c_left {
  width: 150px;
  flex-shrink: 0;
  background: rgba(255, 255, 255, 0.2);
}
.xuewei .x_mk {
  line-height: 30px;
  background: rgba(255, 255, 255, 0.2);
  margin-bottom: 15px;
}
.xuewei .x_mk_title {
  padding: 4px 15px;
  border-bottom: 1px dotted #cccccc;
  font-weight: bold;
}
.xuewei .x_mk_body {
  padding: 8px 15px;
}
.xuewei .x_mk_body span {
  margin: 0 10px 10px 0;
  cursor: pointer;
}
.xuewei .x_mk_tip {
  color: #999;
  font-size: 12px;
  line-height: 20px;
}
.xuewei .x_mk_item {
  border-bottom: 1px dotted #cccccc;
  margin-bottom: 10px;
}

.xuewei .u_tab .u_tab_item {
  line-height: 40px;
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 15px;
  cursor: pointer;
}
.xuewei .u_tab .u_tab_item.active {
  background: rgba(0, 0, 0, 0.06);
}
.xuewei .u_c_item_cont .u_c_lab {
  color: #999;
  padding-right: 15px;
}
.xuewei .u_c_item_cont .u_c_row {
  padding: 3px 0;
}
.xuewei .u_c_center .u_c_item {
  display: flex;
  line-height: 26px;
  border-bottom: 1px dotted #ccc;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.xuewei .u_c_center .u_c_item_cont {
  width: 100%;
}
.xuewei .u_c_center .u_c_item_pic {
  width: 130px;
  height: 130px;
  border: 4px solid rgba(255, 255, 255, 0.8);
  border-radius: 3px;
  flex-shrink: 0;
  margin-left: 10px;
  cursor: pointer;
}
.xuewei .u_c_item_pic img {
  width: 100%;
  height: 100%;
}
</style>