<template>
  <div ref="uconvas" class="uconvas" :class="{ 'uconvas-full': isFullScreen }">
    <div class="tool">
      <div class="tool-btn" @click="fullScreen">
        <Icon v-if="!isFullScreen" type="ios-expand" />
        <Icon v-else type="md-expand" />
      </div>
    </div>
    <div
      ref="uconvasBody"
      v-drag="initWheelScale.isDisabled"
      v-wheelScale:[initWheelScale]="wheelScaleChange"
      class="convas-body"
      :style="convasStyle"
    >
      <div id="convas"></div>
    </div>
  </div>
</template>

<script>
// import data from "@/views/index/data2.json";
import { Scene, Path, Label, Group } from "spritejs";
export default {
  props: {
    info: {
      type: Object,
      default: () => {
        return {};
      },
    },
    severeIllness:{
      type: Array,
      default:()=>[]
    }
  },
  name: "HomeView",
  components: {},
  data() {
    return {
      isFullScreen: false,
      initWheelScale: { maxScale: 5, minScale: 0.5, isDisabled: true },
      imgData: "",
      container: null,
      lineGroup: null,
      scene: null,

      colors: [
        "#8000bf",
        "#3300ff",
        "#009c99",
        "#007d00",
        "#005989",
        "#ff0000",
      ],

      nodeHeight: 15,
      padding: 100,
      vw: 3500,
      vh: 3500,
      layerNode: [],
      layer: null,
      tree: [],
      yList: [180, 600, 900, 1100, 1300],

      cw: 800,
      arr: [],
    };
  },
  computed: {
    convasStyle() {
      return {
        width: this.cw + "px",
        height: this.cw + "px",
        left: this.cl + "px",
        top: this.ct + "px",
      };
    },
    // 主诉
    diagnosticName(){
      return this.info.diagnosticName1 || "";
    }
  },
  methods: {
    fullScreen() {
      this.isFullScreen = !this.isFullScreen;
      this.initWheelScale.isDisabled = !this.isFullScreen;
      if (!this.isFullScreen) {
        let o = this.convasStyle; 
        this.node.style.cssText = `width: ${o.width}; height: ${o.height} `;
      }
    },
    wheelScaleChange(e) {
      // console.log(e);
    },
    formatList(list, index) {
      list.forEach((i) => {
        if (!this.arr.find((j) => j.label == i.label)) {
          let obj = i;
          i.x = 0;
          i.y = 0;
          i.lines = [];
          this.arr.push(obj);
          let arr = this.layerNode[index];
          if (arr) {
            arr.push(obj);
          } else {
            this.layerNode[index] = [obj];
          }
        }
        if (i.children && i.children.length) {
          this.formatList(i.children, index + 1);
        }
      });
    },

    setVw() {
      let d = this.layerNode.reduce((n, item) => {
        return n > item.length ? n : item.length;
      }, 0);
      this.vw = Math.max(d * 45 + this.padding * 2, 2200);
      this.vh = this.vw;
    },

    setPoint() {
      this.setVw();
      this.setYList();

      let h = this.layerNode.length;
      let w = this.layerNode.reduce((n, item) => {
        return n > item.length ? n : item.length;
      }, 0);
      let oy = (this.vh - this.padding * 2) / h;

      this.layerNode.reverse().forEach((j, index) => {
        let ox = (this.vw - this.padding * 2) / j.length;

        j.forEach((i, n_index) => {
          i.x = n_index * ox + this.padding / 2  + ox / 2;
          i.y = this.yList[index];
          if (!i.color) {
            i.color = this.colors[index % 5];
          }
        });
      });

      this.container = document.querySelector("#convas");
      this.scene = new Scene({
        container: this.container,
        width: this.vw,
        height: this.vh,
      });
      this.layer = this.scene.layer();

      // 背景
      let bg = new Group({
        bgcolor: "#fff",
        width: this.vw,
        height: this.vh,
        zIndex: -1,
      });
      this.layer.append(bg);

      // 添加节点
      this.arr.forEach((i) => {
        if(i.label){
          let isZoom = false;
          // 突显比较重的辨证
          if(this.severeIllness.includes(i.label)){
            i.bgcolor = "#ff0000"
            i.color = "#006664"
            isZoom = true;
          }
          // 突显主诉
          if(i.label == this.diagnosticName ){
            i.color = "#ff0000"
            isZoom = true;
          }

          this.createLabel(this.layer, i, isZoom);
        }
      });

      this.lineGroup = new Group();
      this.layer.append(this.lineGroup);

      this.arrowGroup = new Group();
      this.layer.append(this.arrowGroup);

      this.addLine(null, this.tree, this.lineGroup);

      // 画面转成bast64
      setTimeout(() => {
        this.imgData = this.scene.snapshot().toDataURL();
        this.submitFun();
      }, 3000);
    },

    // 拍照结果
    submitFun() {
      let fileName =
        this.info.symptomNo +
        "-" +
        this.info.patientCode +
        "-" +
        Math.floor(Math.random() * 10000) +
        ".jpg";
      var fileObj = this.common.base64ToFile(this.imgData, fileName); //base64数据转为文
      this.upLoadImage([
        {
          key: "multipartFiles",
          file: fileObj,
        },
      ]);
    },

    // 上传图片
    upLoadImage(obj) {
      // typeId值，0病历图片 1手相图片 2面相图片 3穴位图片 4伙伴图片 5药品图片 6其他图片 8疾病发展现况图
      obj.push({
        key: "typeId",
        file: 8,
      });
      this.api2("fileUploadNew", obj).then((res) => {
        if (res.result) this.saveMedicalRecordDiseaseMap(res.result);
      });
    },

    saveMedicalRecordDiseaseMap(url) {
      let obj = {
        diseaseMapUrl: url, //	疾病发展现况图url		false
        symptomNo: this.info.symptomNo, //	症状号
      };
      this.api2("saveMedicalRecordDiseaseMap", obj).then((res) => {});
    },

    addLine(parentNode, list, layer) {
      list.forEach((i) => {
        if (parentNode) {
          this.createLine(parentNode, i, layer);
        }
        if (i.children && i.children.length) {
          this.addLine(i, i.children, layer);
        }
      });
    },

    createLine(start, end, layer) {
      let sNode = this.arr.find((i) => i.label == start.label);
      let eNode = this.arr.find((i) => i.label == end.label);
      let offset = (sNode.y - eNode.y) / 2;
      if (offset < 100) offset = 100;
      let d = `M${sNode.x} ${sNode.y - this.nodeHeight} C 
      ${sNode.x} ${sNode.y - offset} 
      ${eNode.x} ${eNode.y + offset}  
      ${eNode.x} ${eNode.y + this.nodeHeight}`;

      const p1 = new Path();
      if (start.lines) start.lines.push(p1);
      p1.attr({
        d,
        lineDash: [2, 3],
        strokeColor: "#666",
        lineWidth: 2,
      });
      layer.append(p1);
      this.createArrow([eNode.x, eNode.y + this.nodeHeight]);
    },
    createArrow(pos) {
      const p1 = new Path();
      p1.attr({
        pos,
        d: "M-5 12 L 0 0 5 12 0 8 Z",
        strokeColor: "#04773B",
        fillColor: "#04773B",
        lineWidth: 1,
        lineWidth: 1,
        zIndex: 3,
      });
      this.arrowGroup.append(p1);
    },
    // 字符串取指定长度
    getStr(str, length) {
      return str.length > length ? str.substring(0, length) + "..." : str;
    },
    // 创建多行文本
    createMultiLineLabel(layer, item, length) {
      let text = item.label;
      let n = text.length / length;
      let tArr = [];
      for (let i = 0; i < n; i++) {
        tArr.push(text.substring(i * length, (i + 1) * length));
      }
      const group = new Group();
      group.attr({
        size: [300, 30 * tArr.length + 15],
        pos: [0, 35],
        anchor: [0.5, 0],
        borderRadius: 10,
        bgcolor: item.color,
        opacity: 0,
        pointerEvents: "none",
        rotate: 55,
      });
      layer.append(group);

      tArr.forEach((i, index) => {
        const buttonNormal = new Label(i);
        buttonNormal.attr({
          pos: [0, index * 26 + 10],
          // anchor: 0.5,
          anchor: [0.5, 0],
          font: '20px "黑体"',
          fillColor: "#fff",
          lineHeight: 26,
          textAlign: "left",
          height: 30,
        });
        group.append(buttonNormal);
      });

      return group;
    },
    createLabel(layer, item, isZoom) {
      if (item.isKeyPoint) {
        item.y += this.rowsize;
      }
      let g = new Group();
      g.attr({
        pos: [item.x, item.y],
        anchor: 0.5,
        rotate: -55,
        zIndex: 1,
      });
      layer.append(g);

      let length = 16;
      const buttonNormal = new Label(this.getStr(item.label, length));
      buttonNormal.attr({
        pos: [-15, 0],
        anchor: 0.5,
        anchor: [0, 0.5],
        font: '20px "黑体"',
        fontWeight: "bold",
        fillColor: "#fff",
        lineHeight: isZoom ? 36 : 26,
        textAlign: "center",
        height: isZoom ? 40 : 30,
        padding: [0, 20],
        border: [2, item.bgcolor || item.color],
        borderRadius: isZoom ? 20 : 15,
        bgcolor: item.color,
      });
      g.append(buttonNormal);

      let hisPropo = item.label.length > length;
      let propo = hisPropo ? this.createMultiLineLabel(g, item, 12) : "";

      buttonNormal.addEventListener("mouseenter", (evt) => {
        this.setLineColor(item, true);
        if (hisPropo) {
          propo.attr({
            opacity: 1,
          });
        }
      });

      buttonNormal.addEventListener("mouseleave", (evt) => {
        this.setLineColor(item, false);
        if (hisPropo) {
          propo.attr({
            opacity: 0,
          });
        }
      });

      g.addEventListener("mouseenter", (evt) => {
        g.attr({
          zIndex: 10,
        });
      });
      g.addEventListener("mouseleave", (evt) => {
        g.attr({
          zIndex: 1,
        });
      });
    },

    createLabel3(layer, item) {
      let length = 5;
      const buttonNormal = new Label(this.getStr(item.label, length));
      buttonNormal.attr({
        pos: [item.x, item.y],
        anchor: 0.5,
        font: '20px "黑体"',
        fillColor: "#fff",
        lineHeight: 26,
        textAlign: "center",
        height: 30,
        padding: [0, 20],
        border: [2, item.bgcolor || item.color],
        borderRadius: 15,
        bgcolor: item.color,
      });
      layer.append(buttonNormal);

      // this.createMultiLineLabel(buttonNormal, item);

      buttonNormal.addEventListener("mouseenter", (evt) => {
        this.setLineColor(item, true);
      });

      buttonNormal.addEventListener("mouseleave", (evt) => {
        this.setLineColor(item, false);
      });
    },

    setLineColor(item, isEnter) {
      if (isEnter) {
        item.lines.forEach((i) => {
          i.attr({
            strokeColor: "#f00",
            zIndex: 2,
            lineWidth: 3,
          });
        });
      } else {
        item.lines.forEach((i) => {
          i.attr({
            strokeColor: "#666",
            zIndex: 1,
            lineWidth: 2,
          });
        });
      }
    },
    // 去重
    removeRepeat(list) {
      let arr = [];
      list.forEach((i) => {
        if (!arr.find((j) => j.label == i.label && j.value == i.value)) {
          arr.push(i);
        }
      });
      return arr;
    },
    formatData(list) {
      let a3 = {};
      let obj = list.dialecticalSymptomMap;
      for (const key in obj) {
        if (Object.hasOwnProperty.call(obj, key)) {
          const element = obj[key];
          a3[key] = element.map((i) => {
            return {
              label: i.symptomName,
              value: i.symptomId,
              children: [],
            };
          });
        }
      }

      let a2 = [];
      list.dialecticalSubList.forEach((i) => {
        let children = i.dialecticalVOList.map((j) => {
          return {
            label: j.bianzName,
            value: j.bianzCode,
            children: a3[j.bianzCode + "|" + j.bianzName] || [],
          };
        });
        var item = a2.find(
          (j) => j.label == i.bianzName && j.value == i.bianzCode
        );
        if (item) {
          item.children = this.removeRepeat(item.children.concat(children));
        } else {
          let obj = {
            label: i.bianzName,
            value: i.bianzCode,
            children: this.removeRepeat(children),
          };
          a2.push(obj);
        }
      });

      let a1 = [];
      list.voList.forEach((i) => {
        JSON.parse(i.bottomReason).forEach((j) => {
          let children = [
            {
              label: i.symptomName,
              value: i.symptomId,
              color: "#bf6600",
              bgcolor: "#f00",
              isKeyPoint: true,
              children: [],
            },
          ];
          let dialectics = a2.find((y) => y.value == i.dialecticsId);
          if (dialectics) {
            children = children.concat(dialectics.children);
          }

          let obj = {
            label: j,
            value: j,
            children: [
              {
                label: i.etiologyName,
                value: i.etiologyId,
                children: [
                  {
                    label: i.dialecticsName,
                    value: i.dialecticsId,
                    children,
                  },
                ],
              },
            ],
          };
          a1.push(obj);
        });
      });
      this.tree = a1;
      this.formatList(this.tree, 0);
      this.setPoint();
      return a1;
    },
    getData() {
      this.$store.commit("openSpin");
      this.api2("listEtiologyPathogenesisPicData", this.info).then((res) => {
        this.$store.commit("closeSpin");
        this.formatData(res.result);
      });
    },
    init() {
      this.node = this.$refs.uconvasBody;
      this.cw = this.$refs.uconvas.offsetWidth;
    },
    setYList() {
      this.yList = [];
      this.rowsize = Math.min((this.vh - this.padding * 2 - 350) / 5, 400);
      for (let index = 0; index <= 5; index++) {
        if (index != 2) {
          this.yList.push(this.rowsize * index + 350);
        }
      }
    },
  },
  mounted() {
    this.init();
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.uconvas {
  height: 100vw;
  width: 100%;
  overflow: hidden;
  position: relative;
  &.uconvas-full {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1000;
    background: #fff;
  }
  .tool {
    line-height: 24px;
    position: absolute;
    z-index: 101;
    right: 15px;
    top: 15px;
    height: 24px;
    .tool-btn {
      font-size: 24px;
      cursor: pointer;
    }
  }
}

.convas-body {
  position: absolute;
  z-index: 100;
  ::v-deep {
    canvas,
    & > div {
      width: 100% !important;
      height: 100% !important;
    }
  }
  #convas {
    // transform: rotate(90deg)
  }
}
</style>
