<template>
  <div class="empty">
    <i></i>
    <span>{{text}}</span>
    <i></i>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "暂无数据"
    }
  }
};
</script>

<style >
.empty {
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: #999999;
}
.empty i{
    width: 30px;
    border-top: 1px solid #dddddd;
}
.empty span{
    padding: 0 10px;
}
</style>