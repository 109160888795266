<template>
  <div class="success-case-detail" v-if="value">
    <div class="utabs">
      <Tabs v-model="tabname">
        <TabPane label="治愈详情" name="tab1"> </TabPane>
        <TabPane v-uEdition="'YZ-09'" label="疗程就诊记录" name="tab2"> </TabPane>
      </Tabs>
      <Icon type="md-close" @click="close" />
    </div>

    <div class="utabs-body">
      <tab1 v-if="tabname == 'tab1'" :info="info" />
      <tab2 v-else isPrescriptionPage :info="info" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import tab1 from "./tab1.vue";
import tab2 from "./tab2.vue";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: { tab1, tab2 },
  computed: {
    ...mapGetters([])
  },
  data() {
    return {
      tabname: "tab1",
      activeName: "second",
      tabsValue: "",
      panes: [
        {
          label: "治愈详情",
          value: "1",
        },
        {
          label: "疗程就诊记录",
          value: "2",
        },
      ],
    };
  },
  methods: {
    close() {
      this.$emit("input", false);
    },
  },
};
</script>

<style lang="scss">
.success-case-detail {
  position: absolute;
  z-index: 100;
  right: 0;
  top: 0;
  height: 100%;
  min-width: 500px;
  width: 33%;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-left: 1px solid #ddd;
  display: flex;
  flex-direction: column;

  .ivu-icon {
    position: absolute;
    right: 5px;
    top: 2px;
    padding: 5px;
    font-size: 20px;
    cursor: pointer;
  }

  .utabs {
    height: 36px;
  }

  .utabs-body {
    flex: 1;
    overflow: auto;
    background: #f5f5f5;
    padding: 10px 0;
  }

  .ivu-tabs {
    .ivu-tabs-bar {
      margin-bottom: 0;
    }
  }

  .ivu-tabs-nav .ivu-tabs-tab-active {
    color: #853a2f;
  }

  .ivu-tabs-ink-bar {
    background: #853a2f;
  }
}
</style>