<template>
  <div class="luck-pic">
    <div id="stage" :style="{ width: width + 'px', height: width + 'px' }"></div>
  </div>
</template>
  
<script>
import { Scene, Ring, Group, Path, Label, Ellipse } from "spritejs";
export default {
  props: {
    //1 运图 2 气图
    picType: {
      type: Number,
      default: 2
    },
    list: {
      type: Array,
      default: () => {
        return []
      }
    },
    birthday: {
      type: String,
      default: ""
    }
  },
  watch: {
    list: {
      handler() {
        this.init();
      },
      deep: true,
    },
    birthday() {
      // this.init();
    }
  },
  computed: {
    days() {
      return this.common.getDays(...this.birthday.split('-'))
    }
  },
  data() {
    return {
      scene: null,
      zoom: 1.4,
      width: 400,
      fontSize: 26,
      solarTerm: [
        "立春",
        "雨水",
        "惊蛰",
        "春分",
        "清明",
        "谷雨",
        "立夏",
        "小满",
        "芒种",
        "夏至",
        "小暑",
        "大暑",
        "立秋",
        "处暑",
        "白露",
        "秋分",
        "寒露",
        "霜降",
        "立冬",
        "小雪",
        "大雪",
        "冬至",
        "小寒",
        "大寒",
      ],

      birthdayGrout: null, //生日点 组
      arcGroup: null, //饼组
      solarTermGroup: null, //节气 组

    }
  },
  methods: {
    init() {
      if (!this.scene) {
        const container = this.$el.querySelector('#stage');
        this.scene = new Scene({ container, width: this.width * 2, height: this.width * 2 });
      }
      const layer = this.scene.layer();
      layer.removeAllChildren();

      let index = this.list.findIndex(i => i.active)
      let item = 360 / this.list.length
      let r = item * index + item / 2;
      // r = -(item / 2)  + 15 + 90
      r = -(item / 2) + 15 + 90 - item * index;
      let group = new Group({
        pos: [this.width, this.width],
        rotate: r
      })
      layer.append(group);

      const ring = new Ring({
        innerRadius: 0,
        outerRadius: this.width * .82,
        fillColor: '#f6ffe9',
        strokeColor: '#b20',
        lineWidth: 1,
      });
      group.append(ring);
      const ring2 = new Ring({
        innerRadius: 0,
        outerRadius: this.width * .7,
        fillColor: '#fff6e6',
      });
      group.append(ring2);
      const ring3 = new Ring({
        innerRadius: 0,
        outerRadius: this.width * .35,
        fillColor: '#ffb9b9',
      });
      group.append(ring3);

      this.addBirthday(group);

      this.solarTermGroup = new Group();
      group.append(this.solarTermGroup);
      this.createLineAndLabel(this.solarTermGroup);

      this.createArc(group);


      let jd = (this.days - 20) * 360 / 365 + 180 - 15
      this.birthdayGrout.animate([
        { rotate: 0 },
        { rotate: jd },
      ], {
        fill: 'forwards',
        duration: 1000,
        delay: 900,
        easing: 'ease-out'
      });

      this.solarTermGroup.animate([
        { rotate: 100 },
        { rotate: 0 },
      ], {
        duration: 1000,
        easing: 'ease-out'
      });

      this.arcGroup.animate([
        { rotate: -100 },
        { rotate: 0 },
      ], {
        duration: 1000,
        easing: 'ease-out'
      });

    },

    addBirthday(layer) {
      this.birthdayGrout = new Group({
        // rotate: -50
      });
      layer.append(this.birthdayGrout);

      const ring3 = new Ring({
        pos: [this.width * .82, 0],
        innerRadius: 0,
        outerRadius: 12,
        fillColor: '#f00',
      });
      this.birthdayGrout.append(ring3);
    },

    createArc(layer) {
      this.arcGroup = new Group();
      layer.append(this.arcGroup)
      let angle = 360 / this.list.length; // 72;
      this.list.forEach((i, index) => {
        let radius = this.width * .7;
        let g = new Group({
          rotate: index * angle + 180 - 15,
        });
        this.arcGroup.append(g);

        i.node = g;

        const ellipse = new Ellipse({
          radiusX: radius,
          radiusY: radius,
          startAngle: 0,
          endAngle: angle,
          lineWidth: 1,
          strokeColor: '#000',
          closeType: 'sector',
          fillColor: i.active ? "#f5d7a099" : "",
        });
        g.append(ellipse);
        this.addLabel(g, angle / 2, this.width * .24, i.label)
        if (this.picType == 1) {
          i.zhuke.forEach((j, j_index) => {
            let rotate = 35 * j_index + 17.5;
            this.addLabel(g, rotate, this.width * .6, j.label)
            this.addLabel(g, rotate, this.width * .48, j.value, j_index % 2 == 0 ? "#ab442b" : "#e94825")
          })
        } else {
          i.zhuke.forEach((j, j_index) => {
            let x = j_index % 2 == 0 ? this.width * .58 : this.width * .45;
            this.addLabel(g, 13, x, j.label)
            this.addLabel(g, 40, x, j.value, j_index % 2 == 0 ? "#ab442b" : "#e94825")
          })
        }

      })
    },

    addLabel(layer, rotate, x, label, bgcolor) {
      let l = new Group({
        rotate,
      })
      layer.append(l);
      const text1 = new Label({
        anchor: [0.5, 0.5],
        text: label,
        pos: [x, 0],
        strokeWidth: 0,
        fontSize: this.fontSize,
        rotate: 90,
        padding: [2, 5, 1, 5],
        borderRadius: [5, 5, 5, 5],
        fontWeight: 300,
        strokeColor: bgcolor ? "#fff" : "",
        fillColor: bgcolor ? "#fff" : "#111",
        bgcolor,
      });
      l.append(text1);
    },

    // 添加节气
    createLineAndLabel(layer) {

      this.solarTerm.forEach((i, index) => {
        const p1 = new Path();
        p1.attr({
          d: `M-${this.width * .82} 0 l25 0`,
          strokeColor: '#b20',
          lineWidth: 1,
          rotate: index * 15,
        });
        layer.append(p1);

        let tg = new Group({
          rotate: index * 15,
        });
        const text1 = new Label(i);
        text1.attr({
          pos: [-this.width, -14],
          fillColor: '#b20',
          fontSize: this.fontSize,
          fontWeight: 300,
        });
        tg.append(text1);
        layer.append(tg);
      })
    }
  },
  mounted() {
    this.init();
  },
}
</script>
<style lang="scss"></style>
  