<template>
    <div class="western-medicine-disease-name" v-if="diseaseName">
      <Tooltip :content="diseaseName" placement="bottom-start">
          <span class="text">(<span class="text-body">{{ diseaseName }}</span>)</span>
      </Tooltip>
    </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  export default {
    props: {
      diagnosis: {
        type: Number | String,
        default: "",
      },
    },
    computed: {
      ...mapGetters(["dicts"]),
      diseaseName() {
        let node = this.dicts["Disease_Type"].find(
          (i) => i.value == this.diagnosis
        );
        return node && node.string3 ? ("西：" + node.string3) : "";
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .western-medicine-disease-name {
    display: inline-block;
    line-height: 24px;
    height: 24px;
    cursor: pointer;
    margin: 0 5px;
    :deep{
      .ivu-tooltip,
      .ivu-tooltip-rel{
          height: 24px;
      }
      .ivu-tooltip-inner{
          white-space: wrap;
      }
    }
    .text{
      display: inline-block;
      line-height: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      .text-body{
          display: inline-block;
          max-width: 120px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
      }
    }
  }
  </style>