export function dayList (e){
    for (var t = e.split("-"), a = Math.floor(t[0]), i = Math.floor(t[1]), s = Math.floor(t[2]), r = function (e, t) {
        0 === t && (t = 12);
        var a = "";
        a = 2 === t ? e % 4 == 0 && e % 100 != 0 || e % 400 == 0 ? 29 : 28 : t < 7 ? t % 2 == 1 ? 31 : 30 : 7 === t ? 31 : t % 2 == 0 ? 31 : 30;
        return a
    }(a, i - 1), n = [], o = [], l = [], c = 0; c < 30; c++) {
        var d;
        c > 0 && --s <= 0 && (s = r,
            --i <= 0 && (i = 12,
                a--)),
            o[c] = Math.floor(i) + "/" + Math.floor(s),
            s < 10 && !(s.length > 1) && (s = "0" + s),
            i < 10 && !(i.length > 1) && (i = "0" + i),
            d = a + "-" + i + "-" + s,
            n[c] = d
    }
    for (var u = 0; u < 30; u++)
        l[u] = u % 3 == 0 ? o[u] : "";
    return {
        last16: n.reverse(),
        ecShow: o.reverse(),
        ecUse: l.reverse()
    }
}


export function getDayList (e) {
    for (var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 30, a = [], i = 0; i < t; i++) {
        var s = e ? new Date(e) : new Date;
        s.setHours(s.getHours() + 8);
        var r = new Date(s.setDate(s.getDate() - i));
        a.push(r.toISOString().split("T")[0])
    }
    return a.reverse()
}

export function addCount(e, t) {
    var a = 0;
    return e.forEach(function (e) {
        a = (a * t + e * t) / t
    }),
        a
}
export function sumArr(e) {
    var a = 0
        , i = 0
        , s = e.type
        , r = e.item
        , n = e.start
        , o = e.end
        , l = e.length;
    if ("text" == s)
        for (var c = n; c <= o; c++)
            r[c] && (a = addCount([a, Number(r[c])], l),
                i++);
    else
        "time" == s && r.forEach(function (e) {
            var s = new Date(e[0]).getTime();
            s >= n && s <= o && e[1] && (a = addCount([a, Number(e[1])], l),
                i++)
        });
    return Math.round(a / i * 10) / 10
}
export function getTimeInfo(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "-"
        , a = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : ":"
        , i = e || new Date
        , s = i.getFullYear()
        , r = i.getMonth() + 1 <= 9 ? "0" + (i.getMonth() + 1) : i.getMonth() + 1
        , n = i.getDate() <= 9 ? "0" + i.getDate() : i.getDate()
        , o = i.getHours() <= 9 ? "0" + i.getHours() : i.getHours()
        , l = i.getMinutes() <= 9 ? "0" + i.getMinutes() : i.getMinutes()
        , c = i.getSeconds() <= 9 ? "0" + i.getSeconds() : i.getSeconds();
    return [s, r, n].join(t) + " " + [o, l, c].join(a)
}

export function echartSection (obj) {
    var a = obj.series
        , i = obj.echart
        , s = obj.start
        , r = obj.end
        , n = obj.dateList
        , o = obj.length
        , l = void 0 === o ? 10 : o
        , c = obj.type
        , d = a
        , u= []
        , m = "";
    "text" == c ? m = n[s] + " 至 " + n[r] : "time" == c && (m = getTimeInfo(new Date(s)) + " 至 " + getTimeInfo(new Date(r)));
    var p = i.getOption();
    if (p.legend && 0 != p.legend)
        for (var g = p.legend[0].selected, h = 0; h < d.length; h++) {
            var v = d[h];
            void 0 == g[v.name] || g[v.name] || (d.splice(h, 1),
                h--)
        }
    return d.forEach(function (e) {
        var a = "";
        if (e.section) {
            var i = new Array(e.data.length);
            e.data.forEach(function (e, a) {
                i[a] = sumArr({
                    type: c,
                    item: e,
                    start: s,
                    end: r,
                    length: l
                })
            }),
                a = i.join(" - ")
        } else
            a = sumArr({
                type: c,
                item: e.data,
                start: s,
                end: r,
                length: l
            }) + "";
        -1 == a.indexOf("NaN") && u.push({
            name: e.name + "均值",
            value: a
        })
    }),
    {
        name: m,
        arr: u
    }
}
export function dataZoom (obj) {
    let t = obj.echart
        , a = obj.series
        , i = obj.type
        , s = void 0 === i ? "text" : i
        , r = obj.addTime
        , n = obj.sectionArr
        , l = getDayList(r);
        t.off("dataZoom"),
        t.on("dataZoom", function (e) {
            var i = e.batch[0].startValue
                , r = e.batch[0].endValue;
            if (void 0 != i) {
                i < 0 && (i = 0);
                var c = echartSection({
                    echart: t,
                    series: a,
                    start: i,
                    end: r,
                    dateList: l,
                    type: s
                });
                n(c)
            } else{
                n([])
            }        
        })
}


export function ymd(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "-"
      , a = e || new Date;
    return [a.getFullYear(), a.getMonth() + 1 <= 9 ? "0" + (a.getMonth() + 1) : a.getMonth() + 1, a.getDate() <= 9 ? "0" + a.getDate() : a.getDate()].join(t)
}

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
    let timeout, args, context, timestamp, result;
    const later = function () {
      // 据上一次触发时间间隔
      const last = +new Date() - timestamp;
  
      // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
      if (last < wait && last > 0) {
        timeout = setTimeout(later, wait - last);
      } else {
        timeout = null;
        // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
        if (!immediate) {
          result = func.apply(context, args);
          if (!timeout) context = args = null;
        }
      }
    };
  
    return function (...args) {
      context = this;
      timestamp = +new Date();
      const callNow = immediate && !timeout;
      // 如果延时不存在，重新设定延时
      if (!timeout) timeout = setTimeout(later, wait);
      if (callNow) {
        result = func.apply(context, args);
        context = args = null;
      }
  
      return result;
    };
  }

export function  getNullValue (e){
    return void 0 != e && "" != e ? e : "-"
}