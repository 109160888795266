<template>
  <span class="symptom-dropdown" :class="'tippoint-' + tippoint" @mouseenter="mouseenter">
    <symptomChanges :symptomInfo="symptomInfo" isInputPage>
      <slot></slot>
    </symptomChanges>
    <div class="menu" v-if="isShowDropdown" >
      <div class="menu-item"></div>
      <div class="menu-item" v-if="isShowSymptomChanges" @click="clickMenu(2)">
        <i-switch size="small" :value="symptomInfo.symptomChanges == 2" />
        症状减轻
      </div>
      <div class="menu-item" v-if="isShowSymptomChanges" @click="clickMenu(1)">
        <i-switch size="small" :value="symptomInfo.symptomChanges == 1" />
        症状加重
      </div>
      <div
        class="menu-item"
        v-if="selectEtiology"
        @click="clickMenu(3)"
        :divided="isShowSymptomChanges ? true : false"
      >
        选择病因(单击鼠标)
      </div>
      <div
        class="menu-item"
        @click="clickMenu(4)"
        :divided="isShowSymptomChanges ? true : false"
      >
        删除症状(双击鼠标) 
      </div>
    </div>
  </span>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      // 1左  2右
      tippoint: 1 
    }
  },
  computed: {
    ...mapGetters(["patientLastSymptomIds", "selectEtiology"]),
    isShowSymptomChanges() {
      return this.patientLastSymptomIds.includes(this.symptomInfo.dsId + "");
    },
    isShowDropdown() {
      // console.log(
      //   this.symptomInfo.Other,
      //   this.selectEtiology, 
      //   this.isShowSymptomChanges
      // );
      return this.symptomInfo.Other;
      // return (
      //   this.symptomInfo.Other &&
      //   (this.selectEtiology || this.isShowSymptomChanges)
      // );
    },
  },
  props: {
    symptomInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    parentClass: {
      type: String,
      default: "" 
    }
  },
  methods: {
    mouseenter(e){
      if(this.symptomInfo.Other){
        let node = document.querySelector(this.parentClass);
        let pRect = node.getBoundingClientRect();
        let nRect = e.target.getBoundingClientRect();
        this.tippoint =  pRect.left + pRect.width < nRect.left + 150 ? 2 : 1;
      }
    },
    clickMenu(value) {
      switch (value) {
        case 4:
        case 3:
          this.$emit("clickMenu", this.symptomInfo, value);
          break;
        case 1:
          this.symptomInfo.symptomChanges =
            this.symptomInfo.symptomChanges == 1 ? 0 : value;
          break;
        case 2:
          this.symptomInfo.symptomChanges =
            this.symptomInfo.symptomChanges == 2 ? 0 : value;
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.symptom-dropdown {
  position: relative;
  display: inline-block;
  &:hover {
    .menu {
      display: inline-block;
    }
  }
  &.tippoint-1{
    .menu{
      &::after{
        left: 20px;
      }
      left: 0 
    }
  }
  &.tippoint-2{
    .menu{
      &::after{
        right: 20px;
      }
      right: 15px
    }
  }
  .menu {
    display: none;
    position: absolute;
    top: 35px;
    width: 150px;
    z-index: 300;
    background: #96635e;
    color: #fff;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
    padding: 10px 0;
    border-radius: 5px;
    &::after {
      content: "";
      position: absolute;
      top: -6px;
      width: 0;
      height: 0;
      border: 6px solid transparent; 
      border-top-width: 0 ;
      border-bottom-color: #96635e;
    }
    .menu-item {
      line-height: 36px;
      padding: 0 10px;
      border-bottom: 1px solid #00000011;
      cursor: pointer;
      &:hover {
        background: #7a4d49;
      }
    }
  }
}
</style>