import { Scene, Path, Gradient, Group, Ring, Label } from "spritejs";
import transitionList from './transitionList'

function createCircle(obj) {
    let r = obj.radius || 100;
    return new Ring({
        pos: [obj.x || 0, obj.y || 0],
        innerRadius: r - (obj.width || 1),
        outerRadius: r,
        fillColor: obj.color || '#fff',
        opacity: obj.opacity,
        startAngle: obj.startAngle || 0,
        endAngle: obj.endAngle || 360,
        rotate: obj.rotate || 0
    });
};

function sleep(ms) {
    return new Promise(resolve => {
        setTimeout(resolve, ms * 1000);
    });
};
const Focus = function(layer, pos) {
    this.layer = layer;
    this.pos = pos;
    this.color = "#008aff";
    this.color = "#2cffff";
    this.init();
};
Focus.prototype = {
    init() {
        let x = 0,
            y = 0,
            r1 = 60;

        let g1 = new Group();
        g1.attr({
            pos: this.pos || [0, 0],
        });
        this.g1 = g1;
        this.layer.append(g1);

        let circleList = [{
                radius: r1,
                width: 2,
                opacity: 1,
                color: this.color,
            },
            {
                radius: r1 + 15,
                width: 1,
                opacity: .3,
                color: this.color,
            }
        ];
        circleList.forEach(i => {
            g1.append(createCircle(i))
        })

        for (let i = 0; i < 180; i += 90) {
            const p2 = new Path();
            p2.attr({
                d: `M-${r1 + 30},0 L${r1 + 30},0`,
                rotate: i,
                pos: [0, 0],
                strokeColor: this.color,
                lineWidth: 1,
                opacity: 0.5,
                lineDash: [6, 2]
            });
            g1.appendChild(p2);
        }
        for (let i = 0; i < 4; i++) {
            const p2 = new Path();
            p2.attr({
                d: `M${r1 + 8},6 L${r1}, 0, ${r1 + 8}, -6`,
                rotate: i * 90,
                pos: [0, 0],
                strokeColor: this.color,
                lineWidth: 2,
                opacity: 0.6,
            });
            g1.appendChild(p2);
        }
        for (let i = 0; i < 360; i += 20) {
            const p2 = new Path();
            p2.attr({
                d: `M${r1},0 L${r1}, 0, ${r1 + 5}, 0`,
                rotate: i,
                pos: [0, 0],
                strokeColor: this.color,
                lineWidth: 1,
                opacity: 0.3,
            });
            g1.appendChild(p2);
        }


        let g2 = new Group();
        let g2_r = 35;
        g2.attr({
            pos: this.pos || [0, 0],
        });
        this.g2 = g2;
        this.layer.append(g2);
        let circleList2 = [{
            radius: g2_r,
            width: 2,
            opacity: .2,
            color: this.color
        }, ];
        circleList2.forEach(i => {
            g2.append(createCircle(i))
        })

        for (let i = 0; i < 2; i++) {
            g2.append(createCircle({
                radius: g2_r,
                width: 6,
                opacity: .4,
                startAngle: 0,
                endAngle: 60,
                rotate: i * 180,
                color: this.color
            }));
        }

        let g3 = new Group();
        let g3_r = 15;
        g3.attr({
            pos: this.pos || [0, 0],
        });
        this.g3 = g3;
        this.layer.append(g3);
        g3.append(createCircle({
            radius: g3_r,
            width: 2,
            opacity: .3,
            color: this.color
        }));

        for (let i = 0; i < 360; i += 120) {
            const p2 = new Path();
            p2.attr({
                d: `M${g3_r - 5},0 L${g3_r + 5},0`,
                rotate: i,
                pos: [0, 0],
                strokeColor: this.color,
                lineWidth: 2,
                opacity: 0.3,
            });
            g3.append(p2);
        }

        let g1_2 = g1.cloneNode(true);
        this.g1_2 = g1_2;
        g1_2.attr({
            opacity: .2,
        })
        this.layer.append(g1_2);
    },
    async run() {
        transitionList(this.g1, [{
                duration: .3,
                arrt: {
                    rotate: -20,
                    scale: [.9, .9]
                },
                easing: 'ease-out',
            },
            {
                duration: .7,
                arrt: {
                    rotate: 35,
                    scale: [.1, .1]
                },
                easing: 'ease-out',
            },
            {
                duration: .5,
                arrt: {
                    rotate: -35,
                    scale: [1, 1]
                },
                easing: 'ease-out',
            },
            {
                duration: .5,
                arrt: {
                    rotate: 35,
                    scale: [1, 1]
                },
                easing: 'ease-out',
            },
        ])

        transitionList(this.g1_2, [{
                duration: 1.3,
                arrt: {},
            },
            {
                duration: 0,
                arrt: {
                    opacity: 0.5
                },
                easing: 'ease-out',
            },
            {
                duration: .5,
                arrt: {
                    scale: [4, 4],
                    opacity: 0
                },
                easing: 'ease-out',
            },
            {
                duration: .1,
                arrt: {
                    scale: [1, 1],
                    opacity: 0
                },
                easing: 'ease-out',
            }
        ])

        transitionList(this.g2, [{
                duration: 1,
                arrt: {
                    rotate: -35,
                    scale: [.6, .6]
                },
                easing: 'ease-out',
            },
            {
                duration: .5,
                arrt: {
                    rotate: 35,
                    scale: [1, 1]
                },
                easing: 'ease-out',
            }
        ])

        transitionList(this.g3, [{
                duration: 0,
                arrt: {
                    rotate: 0,
                    scale: [1, 1]
                },
                easing: 'ease-out',
            },
            {
                duration: 1,
                arrt: {
                    rotate: 35,
                    scale: [.3, .3]
                },
                easing: 'ease-out',
            },
            {
                duration: .5,
                arrt: {
                    rotate: -35,
                    scale: [1, 1]
                },
                easing: 'ease-out',
            }
        ])

    }
}

const Focus2 = function(layer, pos, color) {
    this.layer = new Group({
        pos: pos || [0, 0],
    });
    layer.append(this.layer)
    this.color = color || "#008aff";
    this.init();
};
Focus2.prototype = {
    init() {
        let x = 0,
            y = 0,
            r1 = 120;

        let g1 = new Group();
        g1.attr({
            pos: this.pos || [0, 0],
        });
        this.g1 = g1;
        this.layer.append(g1);

        let circleList = [{
                radius: r1,
                width: 1,
                opacity: .3,
                color: this.color,
            },
            {
                radius: r1 - 20,
                width: 1,
                opacity: .3,
                color: this.color,
            },
            {
                radius: r1 - 25,
                width: 1,
                opacity: .5,
                color: this.color,
            }
        ];
        circleList.forEach(i => {
            g1.append(createCircle(i))
        })

        for (let i = 0; i < 180; i += 5) {
            const p2 = new Path();
            p2.attr({
                d: `M${r1 -21},0 L${r1 - 24},0`,
                rotate: i,
                pos: [0, 0],
                strokeColor: this.color,
                lineWidth: 1,
                opacity: 0.5,
                lineDash: [6, 2]
            });
            g1.appendChild(p2);
        }
        for (let i = 0; i < 45; i += 5) {
            const p2 = new Path();
            p2.attr({
                d: `M${r1 - 1},0 L${r1 + 1}, 0`,
                rotate: i + 90,
                pos: [0, 0],
                strokeColor: this.color,
                lineWidth: 2,
                opacity: 0.6,
            });
            g1.appendChild(p2);
        }

        let g2 = new Group();
        let g2_r = r1 - 50;
        g2.attr({
            pos: this.pos || [0, 0],
        });
        this.g2 = g2;
        this.layer.append(g2);
        let circleList2 = [{
                radius: g2_r,
                width: 1,
                opacity: .7,
                color: this.color,
                rotate: 0,
                startAngle: 0,
                endAngle: 60,

            },
            {
                radius: g2_r,
                width: 2,
                opacity: .3,
                color: this.color,
                rotate: 180,
                startAngle: 0,
                endAngle: 270,

            },
            {
                radius: g2_r + 2,
                width: 1,
                opacity: .4,
                color: this.color,
                rotate: 130,
                startAngle: 0,
                endAngle: 120,

            },
        ];

        circleList2.forEach(i => {
            g2.append(createCircle(i))
        })

        let g3 = new Group();
        let g3_r = 45;
        g3.attr({
            pos: this.pos || [0, 0],
        });
        this.g3 = g3;
        this.layer.append(g3);

        let circleList3 = [{
                radius: g3_r,
                width: 5,
                opacity: .7,
                color: this.color,
                rotate: 0,
                startAngle: 0,
                endAngle: 60,

            },
            {
                radius: g3_r,
                width: 2,
                opacity: .3,
                color: this.color,
                rotate: 180,
                startAngle: 0,
                endAngle: 270,

            },
            {
                radius: g3_r,
                width: 5,
                opacity: .4,
                color: this.color,
                rotate: 200,
                startAngle: 0,
                endAngle: 120,

            },
        ];

        circleList3.forEach(i => {
            g3.append(createCircle(i))
        })

    },
    // remove true 删除
    async run(remove) {
        let active1 = [{
                duration: 0,
                arrt: {
                    rotate: 0,
                    scale: [1, 1],
                    opacity: 1
                },
                easing: 'ease-out',
            },
            {
                duration: .8,
                arrt: {
                    rotate: 200,
                },
                easing: 'ease-out',
            },
            {
                duration: .7,
                arrt: {
                    rotate: 360,
                    scale: [3, 3],
                    opacity: .2
                },
                easing: 'ease-out',
            },
            {
                duration: 1.5,
                arrt: {
                    rotate: 380,
                },
                easing: 'ease-out',
            },
        ];
        transitionList(this.g1, active1)

        let active2 = [{
                duration: 0,
                arrt: {
                    rotate: 0,
                    opacity: 1,
                    scale: [1, 1]
                },
            },
            {
                duration: .8,
                arrt: {
                    rotate: -200
                },
                easing: 'ease-out',
            },
            {
                duration: .7,
                arrt: {
                    rotate: -360,
                    scale: [3, 3],
                    opacity: .3
                },
                easing: 'ease-out',
            },
            {
                duration: 1.5,
                arrt: {
                    rotate: -380
                },
                easing: 'ease-out',
            }
        ];
        transitionList(this.g2, active2)

        let active3 = [{
                duration: 0,
                arrt: {
                    rotate: 0,
                    scale: [1, 1]
                },
                easing: 'ease-out',
            },
            {
                duration: 1,
                arrt: {},
                easing: 'ease-out',
            },
            {
                duration: 1,
                arrt: {
                    rotate: 100,
                    scale: [.6, .6]
                },
                easing: 'ease-out',
            }
        ];
        transitionList(this.g3, active3);
        let active4 = [{
                duration: 2,
                arrt: {},
                easing: 'ease-out',
                opacity: 1,
            },
            {
                duration: .3,
                arrt: {
                    scale: [2, 2],
                    opacity: 0,
                },
                easing: 'ease-out',
            }

        ];
        transitionList(this.layer, active4)

        // 动画完后移除组件
        if (remove) {
            let activeList = [active1, active2, active3, active4];
            let numList = activeList.map(i => {
                let num = i.reduce((sun, item) => {
                    return sun += item.duration;
                }, 0)
                return num;
            })
            let maxDuration = Math.max(...numList)
            setTimeout(() => {
                this.remove();
            }, maxDuration * 1000);
        }
    },
    remove() {
        this.layer.remove();
    }
}

// layer 添加的层 pos位置 size比例 color颜色 hideCenter隐藏中心点
const Focus3 = function(layer, pos, size, color, hideCenter) {
    this.layer = layer;
    this.hideCenter = hideCenter;
    this.size = size;
    this.pos = pos;
    this.color = color || "#0087fc";
    this.node = null;
    this.init();
}

Focus3.prototype = {
    async init() {
        let g = new Group({
            pos: this.pos,
            scale: this.size || 1
        });
        this.node = g;

        this.layer.append(g);
        let rings = [{
                innerRadius: 0,
                outerRadius: this.hideCenter ? 30 : 6,
                opacity: this.hideCenter ? 0 : .6,
                fillColor: this.color
            },
            {
                innerRadius: 10,
                outerRadius: 11,
                opacity: .5,
                fillColor: this.color
            },
            {
                innerRadius: 10,
                outerRadius: 11,
                opacity: .5,
                fillColor: this.color
            },
            {
                innerRadius: 10,
                outerRadius: 11,
                opacity: .5,
                fillColor: this.color
            }
        ];
        for (let index = 0; index < rings.length; index++) {
            const i = rings[index];
            let ring = new Ring(i);
            g.append(ring);
            if (index) {
                ring.animate([{
                        innerRadius: 10,
                        outerRadius: 11,
                        opacity: 1
                    },
                    {
                        innerRadius: 29,
                        outerRadius: 30,
                        opacity: 0
                    }
                ], {
                    duration: 2000,
                    iterations: Infinity
                });
            }
            await sleep(0.5);
        }
    }
}

const Loading = function(layer, pos, color) {
    this.layer = layer;
    this.pos = pos;
    this.g = null;
    this.radius = 50;
    this.color = color || '#fff',
        this.dsq = null;

    this.init();
}
Loading.prototype = {
    init() {
        this.g = new Group({
            pos: this.pos || [0, 0],
            zIndex: 12,
        });
        this.layer.append(this.g)

        this.g1 = new Group({
            rotate: 90,
        });
        this.g.append(this.g1)

        const text1 = new Label('手相识别中');
        text1.attr({
            anchor: [0.5, 0.5],
            pos: [0, 0],
            fillColor: this.color,
            fontSize: 12,
        });
        this.g.append(text1);

        for (let i = 0; i < 360; i += 360 / 12) {
            let g2 = new Group({
                pos: [0, 0],
            })
            this.g1.append(g2);
            let ring = new Ring({
                pos: [this.radius, 0],
                innerRadius: 0,
                outerRadius: 5,
                fillColor: this.color,
            });
            g2.append(ring);
        }
        clearInterval(this.dsq);

        // 动画
        this.run();
        this.dsq = setInterval(() => {
            this.run();
        }, 3000);
    },
    run() {
        this.g1.childNodes.forEach((i, index) => {
            i.attr({
                rotate: 0,
            })
            i.transition(2).attr({
                rotate: index * 30
            });
        })
        this.g1.attr({
            rotate: 90,
        })
        this.g1.transition(2).attr({
            rotate: 450,
        })
    },
    remove() {
        clearInterval(this.dsq);
        this.g.remove();
    }
}

export default {
    Focus,
    Focus2,
    Focus3,
    Loading,
}