<template>
	<!-- 待就诊和就诊中列表 -->
	<div class="patient_list">
		<div class="p_search">
			<Input size="small" prefix="ios-search" v-model="keyword" placeholder="搜索患者姓名/拼音字母"></Input>
		</div>
		<div class="p_content">
			<div class="u_tabs">
				<div class="u_tabs_item" @click="clickTabs(item)" :class="{ active: item.active }"
					v-for="(item, index) in tabs" :key="index">
					<Badge :count="item.list.length">
						<span class="label">{{ item.label }}</span>
					</Badge>
				</div>
			</div>
			<div class="u_tabs_content">
				<div class="u_tabs_content_item">
					<template v-if="list.length">
						<div class="patient_item" :class="{ active: id == patient.registerCode }" v-for="patient in list"
							:key="patient.registerCode" @click="jump(patient)">
							<i v-if="patient.sex == '女'" class="iconfont icon-morennvtouxiang"></i>
							<i v-else class="iconfont icon-morennantouxiang"></i>
							<span class="name">{{ patient.patientName }}</span>
							<span v-if="patient.visitNumber" class="registerCode">
								挂号{{ patient.visitNumber }}
							</span>
							<div class="btn_g">
								<div v-uEdition="'YZ-08'" class="btn" @click.stop="showCameraFun(patient, 0)">
									<Icon type="md-camera" />
									<div class="label">病历扫描</div>
								</div>
								<div v-uEdition="'YZ-01'" class="btn" @click.stop="showCameraFun(patient, 1)">
									<Icon type="md-hand" />
									<div class="label">手相拍照</div>
								</div>
							</div>
						</div>
					</template>
					<empty v-else />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	props: {
		id: {
			type: String,
			default: ''
		},
		// 是否就诊中页面
		isPrescription: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		...mapGetters([
			"prescribeRegisterList", // 就诊中及未就诊挂号(处方单)
		]),
		list() {
			let item = this.tabs.find(i => i.active);
			let list = item ? item.list : [];
			return this.keyword ? list.filter(i =>
				i.pinyinCode.toUpperCase().indexOf(this.keyword.toUpperCase()) != -1 ||
				i.patientName.indexOf(this.keyword) != -1
			) : list;
		}
	},
	watch: {
		prescribeRegisterList(newValue, oldValue) {
			this.setTabsList();
		}
	},
	data() {
		return {
			keyword: '',
			tabs: [
				{
					label: '待就诊',
					active: true,
					list: []
				},
				{
					label: '就诊中',
					active: false,
					list: []
				}
			]
		}
	},
	methods: {
		showCameraFun(item, typeId) {
			let fun = typeId ? "setCameraInfo3" : "setCameraInfo2";
			this.$store.commit(fun, {
				info: item,
				typeId,
				showWin: true
			});
		},
		jump(item) {
			let index = this.tabs.findIndex(i => i.active);
			let url = (index ? "/prescription" : "/condition") + "/" + item.registerCode;
			this.$router.push(url);
		},
		setTabsList() {
			let noVisit = this.tabs[0];
			let Visit = this.tabs[1];

			if (this.isPrescription) {
				noVisit.active = false;
				Visit.active = true;
			}

			noVisit.list = this.prescribeRegisterList.NoVisit.details;
			Visit.list = this.prescribeRegisterList.Visit.details;
		},
		clickTabs(item) {
			this.keyword = '';
			this.tabs.forEach(i => i.active = false)
			item.active = true;
		},
	},
	created() {
		this.setTabsList();
	},
}
</script>

<style lang="scss" >
.patient_list {
	border-radius: 5px 5px 0 0;
	overflow: hidden;
	background: #fff;
	height: 100%;
	display: flex;
	flex-direction: column;

	.p_search {
		padding: 5px 10px;
		background: #853a2f;
		height: 36px;
	}

	.p_content {
		padding: 15px;
		height: 100%;
		display: flex;
		overflow: hidden;
		flex-direction: column;
	}

	.u_tabs {
		display: flex;
		padding-bottom: 15px;

		&_item {
			width: 100%;
			cursor: pointer;

			&.active .label {
				border-bottom: 2px solid #853a2f;
			}

			.label {
				display: inline-block;
				font-size: 15px;
				font-weight: bold;
			}

			.ivu-badge-count {
				right: -10px;
				line-height: 14px;
				height: 16px;
				padding: 0 4px;
				min-width: 10px;
			}
		}

	}

	.u_tabs_content {
		height: 100%;
		overflow: hidden;
	}

	.u_tabs_content_item {
		height: 100%;
		overflow-y: auto;

		.patient_item {
			cursor: pointer;
			display: flex;
			align-items: center;
			padding: 0 8px;
			white-space: nowrap;
			border-bottom: 1px solid #eee;
			min-height: 50px;

			&:hover {
				.registerCode {
					display: none;
				}

				.btn_g {
					display: flex;
				}
			}

			&.active {
				background: #f5f5f5;
				border-radius: 5px;
			}

			.iconfont {
				padding-right: 8px;

				&.icon-morennvtouxiang {
					color: #ff00b3;
				}

				&.icon-morennantouxiang {
					color: blue;
				}
			}


			.name {
				width: 100px;
			}

			.btn_g {
				display: none;
				text-align: center;

				.btn {
					padding: 0 2px;
					line-height: 1em;
					cursor: pointer;

					.label {
						font-size: 10px;
						transform: scale(.9);
					}
				}
			}
		}
	}
}
</style>