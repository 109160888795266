<template>
    <div class="chart-container">
        <Modal class-name="chart-modal" v-model="showWatch" :closable="false" :mask-closable="true" width="80%" footer-hide
            title="手环数据" closable @on-cancel="cancel">
            <chart :sunGoUserInfo="sunGoUserInfo"></chart>
        </Modal>
    </div>
</template>
  
<script>
import chart from './chart.vue'
import { mapGetters } from "vuex";
export default {
    props: {
        sunGoUserInfo: {
            type: Object,
            default: () => {
                return {};
            }
        },
    },
    components: {
        chart,
    },
    data() {
        return {
            showWatch: true,
        };
    },
    methods: {
        cancel() {
            this.$emit("closeSungo");
        },
    },
    created() {
    },
    mounted() {

    },
};
</script>
<style lang="scss" scoped>
::v-deep .chart-modal {
    height: 100%;

    .ivu-modal {
        min-width: 900px;
        height: 90%;
        top: 50%;
        transform: translate(0, -50%);
        margin-bottom: 0;
    }

    .ivu-modal-header {
        background-color: #fff;
    }

    .ivu-modal-content {
        height: 100%;
        background-color: #fff;

        .ivu-modal-body {
            height: calc(100% - 50px);
            padding: 0 16px 16px 16px;
        }
    }
}
</style>
