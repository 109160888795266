<template>
  <FormItem :class="{error:value != 0}" :label="label">
    <span class="icon_k">
      <Icon v-if="value == 2" type="md-arrow-up" />
      <Icon v-else-if="value == 1" type="md-arrow-down" />
    </span>
    <span class="value2" v-if="disabled">{{value2}}</span>
    <Select v-else @on-change="dataChange" v-model="input">
      <Option v-for="(item,index) in option" :key="index" :value="item.value">{{item.label}}</Option>
    </Select>
  </FormItem>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0
    },
    label:{
      type: String,
      default: ''
    },
    disabled:{
      type: Boolean,
      default: false
    }
  },
  watch: {
    value(newValue, oldValue) {
      this.init(); 
    }
  },
  computed: {
    value2() {
      let item =  this.option.find(i=>i.value == this.input) 
      return item ? item.label : ''
    }
  },
  data() {
    return {
      input: 0,
      option: [
        { label: "正常", value: 0 },
        { label: "降低", value: 1 },
        { label: "增高", value: 2 }
      ],
    }
  },
  methods: {
    init() {
      this.input = this.value; 
    },
    dataChange(){
      this.$emit('input', this.input);
      this.$emit('change')
    }
  },
  created () {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
</style>