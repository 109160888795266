<template>
  <div class="home">
    <div class="convas" id="convas"></div>
  </div>
</template>

<script>
import { hierarchy, tree } from "../tree/index.js";
import data from "@/views/index/data.json";
import {
  Scene,
  Path,
  Gradient,
  Sprite,
  Polyline,
  Label,
  Group,
} from "spritejs";
export default {
  props: {
    info: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  name: "HomeView",
  components: {},
  data() {
    return {
      imgData: "",
      container: null,
      lineGroup: null,
      scene: null,

      colors: [
        "#8000bf",
        "#3300ff",
        "#009c99",
        "#007d00",
        "#005989",
        "#ff0000",
      ],

      nodeHeight: 0,
      padding: 120,
      vw: 2500,
      vh: 2500,
      arr: [],
      layerNode: [],
      layer: null,
      tree: [],
      yList: [180, 600, 900, 1100, 1300],
    };
  },
  methods: {
    formatList(list, index) {
      list.forEach((i) => {
        let arr = this.layerNode[index];
        if (arr) {
          let item = arr.find((j) => j.label == i.label);
          arr.push(i);
        } else {
          this.layerNode[index] = [i];
        }

        if (i.children && i.children.length) {
          this.formatList(i.children, index + 1);
        }
      });
    },

    formatList2(list, index) {
      list.forEach((i) => {
        if (!this.arr.find((j) => j.label == i.label)) {
          let obj = i;
          i.x = 0;
          i.y = 0;
          i.lines = [];
          this.arr.push(obj);
          let arr = this.layerNode[index];
          if (arr) {
            arr.push(obj);
          } else {
            this.layerNode[index] = [obj];
          }
        }
        if (i.children && i.children.length) {
          this.formatList2(i.children, index + 1);
        }
      });
    },

    defaultValue(d) {
      return d.value;
    },

    ascendingValue(a, b) {
      return 0;
      // return a.value - b.value;
    },

    descendingValue(a, b) {
      return b.value - a.value;
    },

    calculateNodePosition(simple, x, y) {
      let s = this.vw;
      const t = tree(simple).size([s, s]);
      const root = t(
        hierarchy(simple).sum(this.defaultValue).sort(this.descendingValue)
      );
      const nodes = root.descendants();
      return nodes;
    },

    setPoint() {
      this.container = document.querySelector("#convas");
      this.scene = new Scene({
        container: this.container,
        width: this.vw,
        height: this.vh,
      });
      this.layer = this.scene.layer();

      // 背景
      let bg = new Group({
        bgcolor: "#fff",
        width: this.vw,
        height: this.vh,
        zIndex: -1,
      });
      this.layer.append(bg);

      let list = {
        children: this.formatData2(this.tree),
      };
      let nodes = this.calculateNodePosition(list);

      console.log("-------------->", nodes);

      nodes.forEach((i) => {
        i.y = this.vh - i.y + 400;
        i.label = i.data.label || "";
        i.bgcolor = "#f00";
        i.color = "#000";
        this.createLabel(this.layer, i);
        if (i.parent && i.parent.parent)
          this.createLine2(i.parent, i, this.layer);
      });
    },

    createLine2(sNode, eNode, layer) {
      let offset = (sNode.y - eNode.y) / 2;
      if (offset < 100) offset = 100;
      let d = `M${sNode.x} ${sNode.y - this.nodeHeight} C 
      ${sNode.x} ${sNode.y - offset} 
      ${eNode.x} ${eNode.y + offset}  
      ${eNode.x} ${eNode.y + this.nodeHeight}`;

      const p1 = new Path();
      p1.attr({
        d,
        lineDash: [2, 3],
        strokeColor: "#666",
        lineWidth: 2,
      });
      layer.append(p1);
    },

    formatData2(list) {
      let arr = [];
      list.forEach((i) => {
        let item = arr.find((j) => j.value == i.value);
        if (item) {
          item.children.push(...i.children);
        } else {
          arr.push(i);
        }
      });
      return arr;
    },

    setPoint2() {
      let h = this.layerNode.length;
      let w = this.layerNode.reduce((n, item) => {
        return n > item.length ? n : item.length;
      }, 0);
      let oy = (this.vh - this.padding * 2) / h;

      this.layerNode.reverse().forEach((j, index) => {
        let ox = (this.vw - this.padding * 2) / j.length;
        j.forEach((i, n_index) => {
          let offset = 0;
          i.x = n_index * ox + this.padding + ox / 2;
          i.y = this.yList[index] + offset;
          if (!i.color) {
            i.color = this.colors[index % 5];
          }
        });
      });

      this.container = document.querySelector("#convas");
      this.scene = new Scene({
        container: this.container,
        width: this.vw,
        height: this.vh,
      });
      this.layer = this.scene.layer();

      // 背景
      let bg = new Group({
        bgcolor: "#fff",
        width: this.vw,
        height: this.vh,
        zIndex: -1,
      });
      this.layer.append(bg);

      // 添加节点
      this.arr.forEach((i) => {
        this.createLabel(this.layer, i);
      });

      this.lineGroup = new Group();
      this.layer.append(this.lineGroup);

      this.arrowGroup = new Group();
      this.layer.append(this.arrowGroup);

      this.addLine(null, this.tree, this.lineGroup);

      // 画面转成bast64
      setTimeout(() => {
        this.imgData = this.scene.snapshot().toDataURL();
        this.submitFun();
      }, 3000);
    },

    // 拍照结果
    submitFun() {
      let fileName =
        this.info.symptomNo +
        "-" +
        this.info.patientCode +
        "-" +
        Math.floor(Math.random() * 10000) +
        ".jpg";
      var fileObj = this.common.base64ToFile(this.imgData, fileName); //base64数据转为文
      this.upLoadImage([
        {
          key: "multipartFiles",
          file: fileObj,
        },
      ]);
    },

    // 上传图片
    upLoadImage(obj) {
      // typeId值，0病历图片 1手相图片 2面相图片 3穴位图片 4伙伴图片 5药品图片 6其他图片 8疾病发展现况图
      obj.push({
        key: "typeId",
        file: 8,
      });
      this.api2("fileUploadNew", obj).then((res) => {
        if (res.result) this.saveMedicalRecordDiseaseMap(res.result);
      });
    },

    saveMedicalRecordDiseaseMap(url) {
      let obj = {
        diseaseMapUrl: url, //	疾病发展现况图url		false
        symptomNo: this.info.symptomNo, //	症状号
      };
      this.api2("saveMedicalRecordDiseaseMap", obj).then((res) => {});
    },

    addLine(parentNode, list, layer) {
      list.forEach((i) => {
        if (parentNode) {
          this.createLine(parentNode, i, layer);
        }
        if (i.children && i.children.length) {
          this.addLine(i, i.children, layer);
        }
      });
    },

    createLine(start, end, layer) {
      let sNode = this.arr.find((i) => i.label == start.label);
      let eNode = this.arr.find((i) => i.label == end.label);
      let offset = (sNode.y - eNode.y) / 2;
      if (offset < 100) offset = 100;
      let d = `M${sNode.x} ${sNode.y - this.nodeHeight} C 
      ${sNode.x} ${sNode.y - offset} 
      ${eNode.x} ${eNode.y + offset}  
      ${eNode.x} ${eNode.y + this.nodeHeight}`;

      const p1 = new Path();
      if (start.lines) start.lines.push(p1);
      p1.attr({
        d,
        lineDash: [2, 3],
        strokeColor: "#666",
        lineWidth: 2,
      });
      layer.append(p1);
      this.createArrow([eNode.x, eNode.y + this.nodeHeight]);
    },
    createArrow(pos) {
      const p1 = new Path();
      p1.attr({
        pos,
        d: "M-5 12 L 0 0 5 12 0 8 Z",
        strokeColor: "#04773B",
        fillColor: "#04773B",
        lineWidth: 1,
        lineWidth: 1,
        zIndex: 3,
      });
      this.arrowGroup.append(p1);
    },
    // 字符串取指定长度
    getStr(str, length) {
      return str.length > length ? str.substring(0, length) + "..." : str;
    },
    // 创建多行文本
    createMultiLineLabel(layer, item, length) {
      let text = item.label;
      let n = text.length / length;
      let tArr = [];
      for (let i = 0; i < n; i++) {
        tArr.push(text.substring(i * length, (i + 1) * length));
      }
      const group = new Group();
      group.attr({
        size: [300, 30 * tArr.length + 15],
        pos: [0, 25],
        anchor: [0.5, 0],
        borderRadius: 10,
        bgcolor: item.color,
        opacity: 0,
        pointerEvents: "none",
      });
      layer.append(group);

      tArr.forEach((i, index) => {
        const buttonNormal = new Label(i);
        buttonNormal.attr({
          pos: [0, index * 26 + 10],
          // anchor: 0.5,
          anchor: [0.5, 0],
          font: '20px "宋体"',
          fillColor: "#fff",
          lineHeight: 26,
          textAlign: "left",
          height: 30,
        });
        group.append(buttonNormal);
      });

      return group;
    },
    createLabel(layer, item) {
      // if (item.bgcolor) {
      //   item.y += 260;
      // }
      let g = new Group();
      g.attr({
        pos: [item.x, item.y],
        anchor: 0.5,
        rotate: -90,
        zIndex: 1,
      });
      layer.append(g);

      let length = 10;
      const buttonNormal = new Label(this.getStr(item.label, length));
      buttonNormal.attr({
        pos: [0, 0],
        anchor: 0.5,
        anchor: [0, 0.5],
        font: '20px "宋体"',
        fillColor: "#fff",
        lineHeight: 26,
        textAlign: "center",
        height: 30,
        padding: [0, 20],
        border: [2, item.bgcolor || item.color],
        borderRadius: 15,
        bgcolor: item.color,
      });
      g.append(buttonNormal);

      let hisPropo = item.label.length > length;
      let propo = hisPropo ? this.createMultiLineLabel(g, item, length) : "";

      buttonNormal.addEventListener("mouseenter", (evt) => {
        this.setLineColor(item, true);
        if (hisPropo) {
          propo.attr({
            opacity: 1,
          });
        }
      });

      buttonNormal.addEventListener("mouseleave", (evt) => {
        this.setLineColor(item, false);
        if (hisPropo) {
          propo.attr({
            opacity: 0,
          });
        }
      });

      g.addEventListener("mouseenter", (evt) => {
        g.attr({
          zIndex: 10,
        });
      });
      g.addEventListener("mouseleave", (evt) => {
        g.attr({
          zIndex: 1,
        });
      });
    },

    createLabel3(layer, item) {
      let length = 5;
      const buttonNormal = new Label(this.getStr(item.label, length));
      buttonNormal.attr({
        pos: [item.x, item.y],
        anchor: 0.5,
        font: '20px "宋体"',
        fillColor: "#fff",
        lineHeight: 26,
        textAlign: "center",
        height: 30,
        padding: [0, 20],
        border: [2, item.bgcolor || item.color],
        borderRadius: 15,
        bgcolor: item.color,
      });
      layer.append(buttonNormal);

      this.createMultiLineLabel(buttonNormal, item);

      buttonNormal.addEventListener("mouseenter", (evt) => {
        this.setLineColor(item, true);
      });

      buttonNormal.addEventListener("mouseleave", (evt) => {
        this.setLineColor(item, false);
      });
    },

    setLineColor(item, isEnter) {
      this.lineGroup.children.forEach((i) => {
        i.attr({
          strokeColor: isEnter ? "#ccc" : "#666",
          zIndex: 1,
          lineWidth: 2,
        });
      });
      if (isEnter) {
        // this.container.canvas.style.cursor = "pointer";
        item.lines.forEach((i) => {
          i.attr({
            strokeColor: "#f00",
            zIndex: 2,
            lineWidth: 3,
          });
        });
      } else {
        // this.container.canvas.style.cursor = 'default';
      }
    },
    // 去重
    removeRepeat(list) {
      let arr = [];
      list.forEach((i) => {
        if (!arr.find((j) => j.label == i.label && j.value == i.value)) {
          arr.push(i);
        }
      });
      return arr;
    },
    formatData(list) {
      let a3 = {};
      let obj = list.dialecticalSymptomMap;
      for (const key in obj) {
        if (Object.hasOwnProperty.call(obj, key)) {
          const element = obj[key];
          a3[key] = element.map((i) => {
            return {
              label: i.symptomName,
              value: i.symptomId,
              children: [],
            };
          });
        }
      }

      let a2 = [];
      list.dialecticalSubList.forEach((i) => {
        let children = i.dialecticalVOList.map((j) => {
          let children = a3[j.bianzCode + "|" + j.bianzName] || [];
          children.forEach((x) => (x.parent = j.bianzCode));
          return {
            label: j.bianzName,
            value: j.bianzCode,
            children,
          };
        });
        var item = a2.find(
          (j) => j.label == i.bianzName && j.value == i.bianzCode
        );
        if (item) {
          item.children = this.removeRepeat(item.children.concat(children));
        } else {
          let obj = {
            label: i.bianzName,
            value: i.bianzCode,
            children: this.removeRepeat(children),
          };
          a2.push(obj);
        }
      });

      let a1 = [];
      list.voList.forEach((i) => {
        JSON.parse(i.bottomReason).forEach((j) => {
          let children = [
            {
              label: i.symptomName,
              value: i.symptomId,
              parent: i.dialecticsId,
              color: "#bf6600",
              bgcolor: "#f00",
              children: [],
            },
          ];
          let dialectics = a2.find((y) => y.value == i.dialecticsId);
          if (dialectics) {
            dialectics.children.forEach(x=>x.parent = i.dialecticsId)
            children = children.concat(dialectics.children);
          }

          let obj = {
            label: j,
            value: j,
            parent: "",
            children: [
              {
                label: i.etiologyName,
                value: i.etiologyId,
                parent: j,
                children: [
                  {
                    label: i.dialecticsName,
                    value: i.dialecticsId,
                    parent: i.etiologyId,
                    children,
                  },
                ],
              },
            ],
          };
          a1.push(obj);
        });
      });
      this.tree = a1;
      this.formatList(this.tree, 0);
      this.setPoint();
      return a1;
    },
    getData() {
      this.$store.commit("openSpin");
      this.api2("listEtiologyPathogenesisPicData", this.info).then((res) => {
        this.$store.commit("closeSpin");
        this.formatData(res.result);
      });
    },
  },
  mounted() {
    // this.yList= [180, 600, 900, 1100, 1300];
    this.yList = [];
    let o = this.vh / 5;
    for (let index = 0; index < 5; index++) {
      this.yList.push(o * index + 300);
    }

    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.home {
  text-align: center;
}

.convas {
  width: 1000px;
  height: 1000px;
  display: inline-block;
  position: relative;
  z-index: 10000;
}
</style>
