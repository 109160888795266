<template>
  <div class="etiology-select" :class="{ animate__flash: animateRun }">
    <div class="e-hender">
      <div class="title">
        <i class="iconfont icon-tianjiawenjianjia1"></i>
        <span>选病因</span>
      </div>
      <!-- <Icon class="close" type="md-close" @click="close" /> -->
    </div>
    <div class="e-content" v-if="show">
      <div class="e-c-hender">
        <div class="e-c-hender-title">
          症状: <span>{{ symptomInfo.dsName }}</span>
        </div>
        <!-- <Button @click="submit" type="primary" v-if="etiologyList.length">确定</Button> -->
      </div>
      <div class="e-list" v-if="etiologyList.length">
        <div
          class="e-list-item"
          v-for="(item, index) in etiologyList"
          :key="index"
          :ref="'etiology_item_' + index"
        >
          <Checkbox
            label="twitter"
            v-model="item.value"
            @on-change="change(item, index)"
          >
            <span class="label">{{ item.etiologyName }}</span>
          </Checkbox>
          <div
            v-show="item.value"
            class="input-wrap"
            v-for="(i_item, i_index) in item.children"
            :key="i_index"
          >
            <div class="icon">
              <Icon
                @click="add(item.children)"
                type="md-add"
                v-if="i_index == item.children.length - 1"
              />
            </div>
            <!-- <Input
              v-model="i_item.label"
              @on-change="changeInput(item)"
              @on-enter="
                enter(
                  item.children,
                  i_index,
                  'etiology_input_' + index + '_' + (i_index + 1)
                )
              "
              :ref="'etiology_input_' + index + '_' + i_index"
              maxlength="50"
            ></Input> -->
            <xAutoComplete
              v-model="i_item.label"
              @on-change="changeInput(item)"
              @on-enter="
                enter(
                  item.children,
                  i_index,
                  'etiology_input_' + index + '_' + (i_index + 1)
                )
              "
              @on-select="
                enter(
                  item.children,
                  i_index,
                  'etiology_input_' + index + '_' + (i_index + 1)
                )
              "
              :ref="'etiology_input_' + index + '_' + i_index"
              maxlength="50"
              :data="getList(item.etiologyId)"
              :filter-method="handleSearch1"
              placeholder="请输入底层原因"
            ></xAutoComplete>
            <div class="icon remove-btn">
              <Icon type="ios-close" @click="removeItem(item, i_index)" />
            </div>
          </div>
        </div>
      </div>
      <empty v-else>暂无数据</empty>
    </div>
    <div class="e-content" v-else>
      <div class="e-c-hender">
        <div class="e-c-hender-title">请选择症状</div>
      </div>
      <empty>暂无数据</empty>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    info: {
      type: Object,
      default: () => {
        return {};
      },
    },
    symptomInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    etiologyIds: {
      type: Object,
      default: () => {
        return {};
      },
    },
    selectList: {
      ttype: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters(["reasonList"]),
  },
  data() {
    return {
      etiologyList: [],
      show: false,
      animateRun: false,
      autoList: [],
    };
  },
  watch: {
    symptomInfo: {
      handler(newValue, oldValue) {
        if (this.symptomInfo.Other) {
          this.getData();
          this.show = true;
        } else {
          this.show = false;
        }
      },
      deep: true,
    },
  },
  methods: {
    getList(etiologyId) {
      return this.reasonList[etiologyId] || [];
    },
    handleSearch1(value, option) {
      return option.toUpperCase().indexOf(value.toUpperCase()) !== -1;
    },
    tip() {
      this.animateRun = true;
      setTimeout(() => {
        this.animateRun = false;
      }, 1000);
    },
    close() {
      this.show = false;
    },
    submit(notTip) {
      if (
        this.etiologyList
          .filter((i) => i.value)
          .every((i) => i.children.some((j) => j.label.trim()))
      ) {
        this.symptomInfo.etiologyList = this.etiologyList;
        this.$emit("change", this.etiologyList);
        this.checkEtiologyType();
      } else if (this.etiologyList.some((i) => i.value)) {
        if (!notTip) this.$Message.warning("请输入底层原因");
      } else {
        if (!notTip) this.$Message.warning("请选择病因");
      }
    },

    getExplainDTOS(){
      let arr = [];
      this.selectList.forEach(j=>{
        j.etiologyList.forEach(item=>{
          if(item.value && item.children.length) {
            arr.push({
              symptomNo: this.info.symptomNo, // 患者症状号
              patientCode: this.info.patientCode, // 患者编码
              bottomReason: item.children[0].label, // 病因底层原因
              bottomReasonJson: JSON.stringify(
                item.children.filter((i) => i.label).map((i) => i.label)
              ), // 病因底层原因JSON
              id: item.mrepId || null, // 主键(修改时传入)
              dialecticsId: item.dialecticsId, // 辨证Id
              etiologyId: item.etiologyId, // 病因Id
              pathogenesisId: item.pathogenesisId, // 病机Id
              symptomId: item.symptomId, // 症状Id
              noRelationSymptomName: item.noRelationSymptomName,
              symptomName: item.symptomName,
              isTypical: item.isTypical,
            }) 
          }
        })
      })
      return arr;
    },
    
    getData2() {
      if (
        this.symptomInfo.etiologyList &&
        this.symptomInfo.etiologyList.length
      ) {
        this.etiologyList = this.common.clone(this.symptomInfo.etiologyList);
        this.etiologyList.forEach((item) => {
          if (this.etiologyIds[item.etiologyId]) {
            item.children = this.etiologyIds[item.etiologyId].children;
            item.value = true;
          } else {
            item.children = [];
            item.value = false;
          }
        });
        this.checkEtiologyType();
      } else {
        let obj = {
          dsIds: this.symptomInfo.dsId, // 症状集
          sysType: 0, // 系统类型  0云诊 1病案分析
          prescriptionNo: "", // 处方ID
          explainDTOS: this.getExplainDTOS()
        };
        this.api2("listSymptomEtiologyDialectical", obj).then((res) => {
          this.etiologyList = this.formatData(res.result);
          this.checkEtiologyType();
          this.$emit("change", this.common.clone(this.etiologyList));
        });
      }
    },

    getData() {
      if (
        this.symptomInfo.etiologyList &&
        this.symptomInfo.etiologyList.length && 
        this.symptomInfo.etiologyList.filter(i=>i.value).some(i=>i.children.length)
      ) {
        this.etiologyList = this.common.clone(this.symptomInfo.etiologyList);
        this.etiologyList.forEach((item) => {
          if (this.etiologyIds[item.etiologyId]) {
            item.children = this.etiologyIds[item.etiologyId].children;
            item.value = true;
          } else {
            item.children = [];
            item.value = false;
          }
        });
        this.checkEtiologyType();
      } else {
        let obj = {
          dsIds: this.symptomInfo.dsId, // 症状集
          sysType: 0, // 系统类型  0云诊 1病案分析
          prescriptionNo: "", // 处方ID
          explainDTOS: this.getExplainDTOS()
        };
        this.api2("listSymptomEtiologyDialectical", obj).then((res) => {
          this.etiologyList = this.formatData(res.result);
          this.checkEtiologyType();
          this.$emit("change", this.common.clone(this.etiologyList));
        });
      }
    },

    formatData(obj) {
      let list = [];
      for (const key in obj) {
        if (Object.hasOwnProperty.call(obj, key)) {
          const arr = obj[key];
          arr.forEach((i) => {
            if (!list.find((j) => j.etiologyId == i.etiologyId)) {
              if (this.etiologyIds[i.etiologyId]) {
                i.children = this.etiologyIds[i.etiologyId].children;
                i.value = true;
              } else {
                i.children = [];
                i.value = false;
              }
              list.push(i);
            }
          });
        }
      }
      return list;
    },

    checkEtiologyType() {
      this.etiologyList.forEach((i) => {
        if (!i.value) {
          for (const k in this.etiologyIds) {
            let e = this.etiologyIds[k];
            if (e.etiologyType == i.etiologyType) {
              e.children.forEach((j) => {
                if (!i.children.find((x) => x.label == j.label)) {
                  i.children.push(j);
                }
              });
            }
          }
        }
      });
    },

    removeItem(item, index) {
      let list = item.children;
      if (list.length == 1) {
        list[index].label = "";
        item.value = false;
      } else {
        list.splice(index, 1);
      }
      this.submit();
    },
    changeInput() {
      this.submit();
    },
    enter(list, index, refStr) {
      if (list.length - 1 == index) {
        if (list.every((i) => i.label)) {
          this.add(list);
          this.$nextTick(() => {
            if(this.$refs[refStr][0]){
              this.$refs[refStr][0].focus();
            }
          });
        }
      }
    },
    add(list) {
      list.push({
        label: "",
      });
    },
    change(item, index) {
      if (item.children.length == 0) {
        this.add(item.children);
      }
      this.$nextTick(() => {
        let node = this.$refs["etiology_item_" + index];
        if (node && node.length) {
          node[0].querySelector(".ivu-input").focus();
        }
        this.submit(true);
      });
    },
    init() {},
  },
  created() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
.etiology-select {
  width: 280px;
  height: 100%;
  border-right: 1px solid #eee;
  padding: 15px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  label.ivu-checkbox-wrapper {
    font-weight: initial;
  }
}
.e-hender {
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  span {
    font-weight: bold;
  }
  .iconfont {
    color: #734137;
    margin-right: 5px;
  }
  .close {
    font-size: 20px;
    cursor: pointer;
  }
  .icon-close {
    float: right;
    font-size: 20px;
    cursor: pointer;
  }
}
.e-content {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .e-c-hender {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    .e-c-hender-title {
      background: #96635e;
      color: #fff;
      padding: 5px 10px;
      border-radius: 5px;
      width: 100%;
      font-weight: bold;
      .ivu-btn-primary {
        background: transparent;
      }
    }
  }
  .e-list {
    height: 100%;
    overflow-y: auto;
    :deep {
      .ivu-checkbox-wrapper {
        display: flex;
        margin-bottom: 10px;
        .ivu-checkbox {
          margin: 2px 7px 0 0;
        }
      }
    }
    .e-list-item {
      padding: 8px 0;
    }
    .input-wrap {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      position: relative;
      .icon {
        width: 20px;
        flex-shrink: 0;
      }
      .remove-btn {
        position: absolute;
        right: 0px;
        top: -10px;
        .ivu-icon {
          border: 1px solid #ddd;
        }
        &:hover {
          .ivu-icon {
            color: #fff;
            background: #f00;
          }
        }
      }
      .ivu-icon {
        font-size: 18px;
        cursor: pointer;
        border-radius: 100%;
        color: #734137;
        background: #fff4f0;
      }
      .ivu-input-wrapper {
        margin-bottom: 0;
        margin: 0 10px;
      }
    }
  }
  .ivu-checkbox-wrapper-checked {
    .label {
      color: #853a2f;
    }
  }
}
.animate__flash {
  animation: flash2 0.8s linear;
}
</style>
