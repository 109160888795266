<!-- 查看成绩 -->
<template>
  <layer pagename="schoolReport" pageClass="schoolReportPage ks_page">
    <div class="schoolReport">
      <div class="z_title">
        <div class="label">{{type=='prescription'?'百年方考核成绩':'病案历考核成绩'}}</div>
      </div>
      <Table stripe :columns="columns1" :data="list"></Table>
    </div>
  </layer>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  props: ["type"],
  data() {
    return {
      columns1: [
        {
          title: "序号",
          type: "index",
          width: 80,
          align: "center"
        },
        {
          title: "试卷应考时长",
          align: "center",
          key: "longTime"
        },
        {
          title: "用过时长",
          align: "center",
          key: "useTime"
        },
        {
          title: "开始时间",
          align: "center",
          key: "startDate"
        },
        {
          title: "结束时间",
          align: "center",
          key: "endDate"
        },
        {
          title: "考试结果",
          key: "examResult",
          align: "center",
          width: 200,
          render: (h, params) => {
            var item = params.row;
            var str = "";
            switch (
              item.examResult //0 未考 1 合格  2 不合格
            ) {
              case 0:
                str = "未考";
                break;
              case 1:
                str = "合格";
                break;
              case 2:
                str = "不合格";
                break;
            }
            return h("div", str);
          }
        },
        {
          title: "考试题数",
          key: "paperNumber",
          align: "center",
          width: 100
        }
      ],
      data1: [],
      endExaminationPaperList: {},
      endExaminationPaperList: []
    };
  },
  watch: {
    type() {
      this.getData();
    }
  },
  computed: {
    ...mapGetters([]),
    list() {
      let type = this.type == "prescription" ? 0 : 1; // "0.百年方  1.症状"
      return this.endExaminationPaperList.filter(i => (i.type == type));
    }
  },
  methods: {
    getData() {
      this.$store.commit("openSpin");
      this.api2("getEndExaminationPaperList", {}).then(res => {
        this.$store.commit("closeSpin");
        this.endExaminationPaperList = res.result;
      });
    }
  },
  mounted() {},
  created() {
    this.getData();
  }
};
</script>
