import { Rect, Scene, Path, Gradient, Group, Ring, Sprite, Polyline, Label } from "spritejs";
import Animation from "@/assets/js/animation2.js";
import transitionList from '@/assets/js/transitionList'

const Hander = function (layer) {
    this.speedRatio = .5; //动画速度比例
    this.layer = layer;
    this.scaleSize = [layer.width, layer.height];
    this.sceneCenter = [this.scaleSize[0] / 2, this.scaleSize[1] / 2];
    this.nodes = {};
    this.tip = null;
    this.nineTip = null;
    this.guang = require("@/assets/images/hand/guang-line1.png");
    this.bg = [
        require("@/assets/images/hand/white1.png"),
        require("@/assets/images/hand/white2.png"),
        require("@/assets/images/hand/white1_1.png"),
        require("@/assets/images/hand/white2_1.png"),
    ]
    this.white1 = "", 
    this.white2 = "", 
    // this.white1 = require("@/assets/images/hand/white1.png");
    // this.white2 = require("@/assets/images/hand/white2.png");
    this.handerData = {};

    this.sceneWidth = this.layer.width; //画布宽
    this.sceneHeight = this.layer.height; //画布高

    this.minScale = 0; //手相最下缩放比例

    this.fingerColor = '#ff0'; //  //指节纹颜色
    this.qingjinColor = '#f00'; //  //青筋颜色
    this.threeLineColor = '#00d100'; //三大主线颜色
    this.polyLineColor = '#0051d6'; // //九丘一平原颜色
    this.patternColor = '#009ccf'; // 纹路颜色
    this.patternColor = '#cc30ff'; // 纹路颜色
    this.speckleColor = '#f00'; //  斑点颜色
    this.handerGroupScale = 1; // 手相初始缩放比例
    this.init();
};
Hander.prototype = {
    init() {
        // 加载动画
        this.loading = new Animation.Loading(this.layer, this.sceneCenter, '#86392f');
    },
    setData(handerData) {
        this.handerData = handerData;
        this.imageWidth = this.handerData.imageWidth;
        this.imageHeight = this.handerData.imageHeight;
        this.minScale = this.sceneHeight / this.imageHeight;

        // 设置补白背景图片
        if(handerData.verson == 1){
            this.white1 = this.bg[0];
            this.white2 = this.bg[1];
        }else{
            this.white1 = this.bg[2];
            this.white2 = this.bg[3];
        }

        // 斑点列表数据
        this.speckleList = handerData.speckle;

        // 三大主线数据
        this.threeLine = handerData.threeMainLine;

        // 纹路数据
        this.pattern = handerData.texture;

        // 提取坐标点
        this.plan = handerData.graphical;
        this.point = this.plan.reduce((arr, item) => {
            return arr.concat(item.points);
        }, []);
        this.point = this.deteleObject(this.point);

        // 手相中心点
        this.center = this.getCenter(this.point);

        this.run();
    },
    run() {
        // 移除加载动画
        this.loading.remove()
        let handerPicBjWidth = 2143;
        let x = handerPicBjWidth - 600;
        let x1 = this.imageWidth - 600;

        let group2 = [{
            type: 'group',
            attr: {
                name: 'handerGroup',
                width: this.imageWidth,
                height: this.imageHeight,
                pos: this.getPoint(1),
                scale: 1,
            },
            children: [{
                type: 'group',
                attr: {
                    name: 'handerPicGroup',
                    width: this.imageWidth,
                    height: this.imageHeight,
                    opacity: 1,
                },
                children: [{
                    type: 'img',
                    attr: {
                        name: 'handerPic',
                        pos: [0, 0],
                        texture: this.handerData.base64,
                        opacity: 1,
                    },
                },
                {
                    type: 'img',
                    attr: {
                        name: 'handerPicBj',
                        pos: [-x, 0],
                        size: [handerPicBjWidth, this.imageHeight],
                        texture: this.white1,
                        opacity: 1,
                    },
                },
                {
                    type: 'img',
                    attr: {
                        name: 'handerPicBj',
                        pos: [x1, 0],
                        size: [handerPicBjWidth, this.imageHeight],
                        texture: this.white2,
                        opacity: 1,
                    },
                },
                {
                    type: 'group',
                    attr: {
                        name: 'whitePic',
                        pos: [-(this.imageHeight - this.imageWidth / 2), 0],
                        size: [this.imageHeight * 2, this.imageHeight],
                        bgcolor: '#fff',
                        opacity: .9,
                        zIndex: 100
                    },
                },
                ]
            }],
        },];


        this.addNode(group2, this.layer)

        // 添加九区名称
        this.addNiceTip();

        // 移动缩放相册
        this.addEventHanderGroup();

        // 添加九丘一平原
        this.addPolyline();

        // 添加指节纹
        this.addFinger();

        // 添加青筋
        this.addQingjin();

        // 添加三大主线
        this.addThreeLine();

        // 添加纹路
        this.addPattern();

        // 添加动画
        let handerGroup = this.nodes['handerGroup'];
        let handerPic = this.nodes.whitePic;
        setTimeout(() => {
            transitionList(handerGroup, [{
                duration: 1 * this.speedRatio,
                arrt: {
                    pos: this.getPoint(
                        // .4
                        this.handerGroupScale,
                    ),
                    // scale: [.4, .4]
                    scale: [this.handerGroupScale, this.handerGroupScale]
                },
                easing: 'ease-out',
            }]);
            transitionList(handerPic, [{
                duration: 1 * this.speedRatio,
                arrt: {},
            },
            {
                duration: 2 * this.speedRatio,
                arrt: {
                    opacity: 0
                },
                easing: 'ease-out',
            }
            ]);
        }, 600 * this.speedRatio);

        // 添加斑点层
        this.addSpeckle();

        // 添加按钮
        // setTimeout(() => {
        //     this.addButton();
        // }, 3000 * this.speedRatio)
    },
    // 获取手相与画布中对齐的坐标
    getPoint(scale) {
        scale = scale || 1;
        return [
            this.sceneWidth / 2 - this.center[0] * scale,
            this.sceneHeight / 2 - this.center[1] * scale
        ]
    },
    // 显视或隐藏区块名称
    setNiceTipVisibility(nb) {
        let niceTip = this.nodes['niceTip'];
        if (niceTip) {
            niceTip.attr({
                opacity: nb
            })
        }
    },
    // 添加九区名称
    addNiceTip() {
        let list = this.handerData.ninePoint;
        let handerGroup = this.nodes['handerGroup'];
        let poingGroup = new Group({
            opacity: 0,
        });
        handerGroup.append(poingGroup)
        this.nodes['niceTip'] = poingGroup;

        list.forEach(i => {
            let g = new Group({
                pos: i.point,
            });
            poingGroup.append(g);

            const p1 = new Path();
            p1.attr({
                d: 'M510.9 841.1c6 0 11.7-2.7 15.5-7.2 11.2-13.3 273.4-328.1 273.4-482.1 0-159.3-129.6-288.9-288.9-288.9C351.6 62.9 222 192.5 222 351.8c0 153.9 262.2 468.8 273.3 482.1 3.9 4.5 9.6 7.2 15.6 7.2z m0-737.7c137 0 248.4 111.4 248.4 248.4 0 119.3-191.8 366.6-248.4 437-56.6-70.4-248.4-317.7-248.4-437 0.1-137 111.5-248.4 248.4-248.4z m123.7 225.8c0-66.1-53.8-119.9-119.9-119.9-66.1 0-119.9 53.8-119.9 119.9S448.7 449 514.8 449s119.8-53.7 119.8-119.8z m-199.2 0c0-43.8 35.6-79.3 79.4-79.3 43.7 0 79.3 35.6 79.3 79.3s-35.6 79.3-79.3 79.3c-43.8 0-79.4-35.6-79.4-79.3z m212 410.8c-10.9-2.3-21.7 4.9-23.9 15.8-2.2 11 4.9 21.6 15.8 23.9 78.7 15.9 119.9 44 119.9 63.5 0 30.8-96.8 75.5-248.4 75.5-151.6 0-248.4-44.7-248.4-75.5 0-19.8 42-48.1 122.3-64 11-2.2 18.1-12.8 15.9-23.8-2.2-11-12.8-18.1-23.8-16-98.5 19.5-155 57.3-155 103.7 0 76.2 145.3 116 288.9 116 143.6 0 288.9-39.9 288.9-116 0.2-45.9-55.3-83.5-152.2-103.1z',
                scale: 0.04,
                anchor: [.5, .5],
                strokeColor: '#839',
                fillColor: '#839',
                lineWidth: 12,
                pos: [-25, -40],
            });
            g.append(p1)

            const text3 = new Label(i.label);
            text3.attr({
                pos: [0, 30],
                fillColor: '#86392f',
                fontSize: 26,
                textAlign: 'center',
                anchor: [.5, .5]
            });
            g.append(text3);
        })
    },
    // 添加纹路
    addPattern() {
        let handerGroup = this.nodes['handerGroup'];
        // 点和多边形层
        let poingGroup = new Group();
        handerGroup.append(poingGroup)
        this.nodes['patternGroup'] = poingGroup;

        this.pattern.forEach(j => {
            let p1 = j.points[0],
                p2 = j.points[1],
                size = [
                    p2[0] - p1[0],
                    p2[1] - p1[1],
                ];

            let polygon = new Rect({
                pos: p1,
                size,
                lineWidth: 2,
                opacity: 0,
                strokeColor: this.patternColor,
            });

            poingGroup.append(polygon);

            // 多边形过渡效果
            polygon.transition(3).attr({
                opacity: .9
            })

            // 对多边形添加事件
            setTimeout(() => {
                this.polygonAddEvent(polygon, j);
            }, 5000);

            // 对多边框添加游走的高光
            /*
            const bird = new Sprite(this.guang);
            bird.attr({
                anchor: [0.5, 0.5],
                offsetPath: polygon.d,
                zIndex: 200,
                scale: 1.5
            });
            poingGroup.append(bird);
            bird.animate([
                { offsetDistance: 0 },
                { offsetDistance: 1 },
            ], {
                duration: 6000,
                iterations: Infinity,
            });
            */
        })

    },
    // 添加三大主线
    addThreeLine() {
        let handerGroup = this.nodes['handerGroup'];
        // 点和多边形层
        let poingGroup = new Group();
        handerGroup.append(poingGroup)
        this.nodes['threeLingPpoingGroup'] = poingGroup;
        let point = this.threeLine.reduce((arr, i) => {
            return arr.concat(i.points);
        }, []);

        this.threeLine.forEach(j => {
            let points = j.points.reduce((arr, i) => {
                return arr.concat(i);
            }, []);
            let polygon = new Polyline({
                points,
                strokeColor: this.threeLineColor,
                lineWidth: 2,
                opacity: 0,
                zIndex: 201,
                smooth: true,
            });
            poingGroup.append(polygon);

            // 多边形过渡效果
            polygon.transition(3).attr({
                opacity: 1
            })

            // 对多边形添加事件
            setTimeout(() => {
                this.threeLineAddEvent(polygon, j);
            }, 3000);
        })
    },
    // 添加青筋
    addQingjin(){
        if(!this.handerData.qingjin) return;
        let handerGroup = this.nodes['handerGroup'];
        // 点和多边形层
        let poingGroup = new Group();
        handerGroup.append(poingGroup)
        this.nodes['qingjin'] = poingGroup;
        this.handerData.qingjin.forEach(i=>{
            let points = i.points.reduce((arr, i) => {
                return arr.concat(i);
            }, []);
            let polygon = new Polyline({
                points,
                lineWidth: 3,
                close: true,
                strokeColor: this.qingjinColor,
                lineWidth: 1,
                opacity: 1,
                zIndex: 200,
            });
            poingGroup.append(polygon);

            // 对多边形添加事件
            this.polygonAddEvent(polygon, i);
        })
    },

    // 添加指节纹
    addFinger(){
        if(!this.handerData.finger) return;
        let handerGroup = this.nodes['handerGroup'];
        // 点和多边形层
        let poingGroup = new Group();
        handerGroup.append(poingGroup)
        this.nodes['finger'] = poingGroup;
        this.handerData.finger.forEach(i=>{
            let points = i.points.reduce((arr, i) => {
                return arr.concat(i);
            }, []);
            let polygon = new Polyline({
                points,
                lineWidth: 3,
                close: true,
                strokeColor: this.fingerColor,
                lineWidth: 1,
                opacity: 1,
                zIndex: 200,
            });
            poingGroup.append(polygon);
        })
    },
    
    // 添加九丘一平原
    addPolyline() {
        let handerGroup = this.nodes['handerGroup'];
        // 点和多边形层
        let poingGroup = new Group();
        handerGroup.append(poingGroup)
        this.nodes['poingGroup'] = poingGroup;

        // 添加动态点
        this.point.forEach(i => {
            new Animation.Focus3(poingGroup, i, Math.random() + .5, this.polyLineColor);
        })

        // 添加指节纹数据
        // if (this.handerData.finger) this.plan = this.plan.concat(this.handerData.finger);

        // 添加区块线以及上的游走动画
        this.plan.forEach((i, index) => {
            let points = i.points.reduce((arr, i) => {
                return arr.concat(i);
            }, []);
            let polygon = new Polyline({
                points,
                lineWidth: 3,
                close: true,
                strokeColor: this.polyLineColor,
                lineWidth: 1,
                opacity: 0,
                zIndex: 200,
            });
            poingGroup.append(polygon);

            // 多边形过渡效果
            polygon.transition(3).attr({
                opacity: .5
            })

            if (i.isFinger) return;

            // 对多边形添加事件
            setTimeout(() => {
                this.polygonAddEvent(polygon, i);
            }, 3000);

            // 对多边框添加游走的高光
            if(index % 3 == 0){
                const bird = new Sprite(this.guang);
                bird.attr({
                    anchor: [0.5, 0.5],
                    offsetPath: polygon.d,
                    zIndex: 200,
                    scale: 1.5
                });
                poingGroup.append(bird);
                bird.animate([
                    { offsetDistance: 0 },
                    { offsetDistance: 1 },
                ], {
                    duration: 6000,
                    iterations: Infinity,
                });
            }
        })
    },
    // 添加斑点层
    addSpeckle() {
        let handerGroup = this.nodes['handerGroup'];
        let speckleGroup = new Group();
        handerGroup.append(speckleGroup)
        this.nodes['speckleGroup'] = speckleGroup;
        this.speckleList.forEach(i => {
            let pNode = new Animation.Focus3(speckleGroup, i.points, 2, this.speckleColor, true);
            this.speckleAddEvent(pNode.node, i);
        })
    },
    // 添加节点
    addNode(item, layer) {
        let node = null;
        item.forEach(i => {
            if (i.type == 'group') {
                node = new Group(i.attr)
            } else if (i.type == 'img') {
                node = new Sprite(i.attr)
            }
            this.nodes[i.attr.name] = node;
            layer.append(node);
            if (i.children) {
                this.addNode(i.children, node);
            }
        })
    },
    // 手相中心点
    getCenter(points) {
        let mun_x = 0,
            mun_y = 0,
            xList = [],
            yList = [];
        points.forEach(i => {
            mun_x += i[0];
            mun_y += i[1];
            xList.push(i[0]);
            yList.push(i[1]);
        });
        let height = Math.max(...yList) - Math.min(...yList);
        let width = Math.max(...xList) - Math.min(...xList);
        this.handerGroupScale = this.sceneHeight / (height + 300);
        return [
            Math.min(...xList) + width / 2,
            Math.min(...yList) + height / 2
        ];
        // return [mun_x / points.length, mun_y / points.length];
    },
    // 父节点是否隐藏
    parentIsVisible(e) {
        return e.target.parent.opacity;
    },
    formatText(text, length) {
        length = length || 14;
        let list = [];
        let nb = Math.ceil(text.length / length);
        for (let i = 0; i < nb; i++) {
            const element = text.substring(i * length, (i + 1) * length);
            list.push(element);
        }
        return list;
    },
    // 斑点添加事件
    speckleAddEvent(node, item) {
        node.addEventListener('click', (e) => {
            if (!this.parentIsVisible(e)) return;
            console.log('斑点点击', item);
        })
        node.addEventListener('mouseenter', (e) => {
            if (!this.parentIsVisible(e)) return;
            setTimeout(() => {
                if (item.text) this.tipFun(this.formatText(item.text), '', '#f00');
                this.layer.canvas.style.cursor = 'pointer';
            }, 50)
            node.attr({
                fillColor: "rgba(255,255,255, .2)",
            })
        })
        node.addEventListener('mouseleave', (e) => {
            if (!this.parentIsVisible(e)) return;
            if (this.tip) this.tip.remove();
            this.layer.canvas.style.cursor = 'default';
            node.attr({
                filter: '',
                fillColor: '',
            })
        })
        node.addEventListener('mousemove', (e) => {
            if (!this.parentIsVisible(e)) return;
            if (this.tip) {
                this.tip.attr({
                    x: e.layerX + 20,
                    y: e.layerY - 20,
                    opacity: 1
                })
            }
        })
    },
    // 三大主线添加事件
    threeLineAddEvent(node, item) {
        node.addEventListener('click', (e) => {
            if (!this.parentIsVisible(e)) return;
            // console.log('多边开区域点击', item);
        })
        node.addEventListener('mouseenter', (e) => {
            if (!this.parentIsVisible(e)) return;
            setTimeout(() => {
                if (item.text) this.tipFun(this.formatText(item.text));
                this.layer.canvas.style.cursor = 'pointer';
            }, 50)
            node.attr({
                lineWidth: 4,
            })
        })
        node.addEventListener('mouseleave', (e) => {
            if (!this.parentIsVisible(e)) return;
            if (this.tip) this.tip.remove();
            this.layer.canvas.style.cursor = 'default';
            node.attr({
                lineWidth: 1,
            })
        })
        node.addEventListener('mousemove', (e) => {
            if (!this.parentIsVisible(e)) return;
            if (this.tip) {
                this.tip.attr({
                    x: e.layerX + 20,
                    y: e.layerY - 20,
                    opacity: 1
                })
            }
        })
    },
    // 多边形区块里添加事件
    polygonAddEvent(node, item) {
        node.addEventListener('click', (e) => {
            if (!this.parentIsVisible(e)) return;
        })
        node.addEventListener('mouseenter', (e) => {
            if (!this.parentIsVisible(e)) return;
            setTimeout(() => {
                if (item.text) this.tipFun(this.formatText(item.text));
                this.layer.canvas.style.cursor = 'pointer';
            }, 50)
            node.attr({
                fillColor: "rgba(255,255,255, .9)",
            })
        })
        node.addEventListener('mouseleave', (e) => {
            if (!this.parentIsVisible(e)) return;
            if (this.tip) this.tip.remove();
            this.layer.canvas.style.cursor = 'default';
            node.attr({
                filter: '',
                fillColor: '',
            })
        })
        node.addEventListener('mousemove', (e) => {
            if (!this.parentIsVisible(e)) return;
            // if (item.text) this.tipFun(this.formatText(item.text));
            if (this.tip) {
                this.tip.attr({
                    x: e.layerX + 20,
                    y: e.layerY - 20,
                    opacity: 1
                })
            }
        })
    },

    // 按钮方法
    clickBtn(ids) {
        let list = [
            {
                value: 1,
                label: "九丘一平原",
                node: this.nodes['poingGroup'],
            },
            {
                value: 2,
                label: "三大主线",
                node: this.nodes['threeLingPpoingGroup'],
            },
            {
                value: 3,
                label: "纹路",
                node: this.nodes['patternGroup'],
            },
            {
                value: 4,
                label: "斑点",
                node: this.nodes['speckleGroup'],
            },
            {
                value: 5,
                label: "青筋",
                node: this.nodes['qingjin'],
            },
            {
                value: 6,
                label: "指节纹",
                node: this.nodes['finger'],
            },
        ]
        list.forEach(i => {
            if(i.node){
                let state = !ids.includes(i.value);
                i.node.attr({
                    opacity: state ? 1 : 0,
                    scale: state ? [1, 1] : [0, 0]
                })
            }
        })
    },

    // 添加按钮
    async addButton() {
        let b_width = 150; //按钮长度
        let x = this.sceneWidth - b_width;
        var btnList = [{
            text: '九丘一平原',
            index: '01',
            pos: this.sceneCenter,
            pos1: [x, 70],
            btnfun: (state) => {
                let group = this.nodes['poingGroup']
                group.attr({
                    opacity: state ? 1 : 0,
                    scale: state ? [1, 1] : [0, 0]
                })
            }
        },

        {
            text: '三大主线',
            index: '02',
            pos: this.sceneCenter,
            pos1: [x, 120],
            btnfun: (state) => {
                let group = this.nodes['threeLingPpoingGroup']
                group.attr({
                    opacity: state ? 1 : 0,
                    scale: state ? [1, 1] : [0, 0]
                })
            }
        },

        {
            text: '纹路',
            index: '03',
            pos: this.sceneCenter,
            pos1: [x, 170],
            btnfun: (state) => {
                let group = this.nodes['patternGroup']
                group.attr({
                    opacity: state ? 1 : 0,
                    scale: state ? [1, 1] : [0, 0]
                })
                console.log('按钮3');
            }
        },
        {
            text: '斑点',
            index: '04',
            pos: this.sceneCenter,
            pos1: [x, 220],
            btnfun: (state) => {
                let group = this.nodes['speckleGroup']
                group.attr({
                    opacity: state ? 1 : 0,
                    scale: state ? [1, 1] : [0, 0]
                })
            }
        },
        ]
        let g = new Group({
            zIndex: 11
        });
        this.layer.append(g)
        for (let index = 0; index < btnList.length; index++) {
            const i = btnList[index];
            let btn = this.createButton(i)
            g.append(btn)
            // btn.transition(.5).attr({ //动画关闭
            btn.transition(0).attr({
                opacity: 1,
                pos: i.pos1
            })
            await this.sleep(.001)
        }
    },
    sleep(ms) {
        return new Promise(resolve => {
            setTimeout(resolve, ms * 1000);
        });
    },
    // 对手相组添加移动与缩放方法
    addEventHanderGroup() {
        let old = {};
        let handerGroup = this.nodes['handerGroup'];
        let isDown = false;
        let nodePos = [];
        // 移动
        handerGroup.addEventListener('mousedown', (e) => {
            nodePos = handerGroup.worldPosition;
            old = { x: e.layerX, y: e.layerY }
            isDown = true;
        });
        handerGroup.addEventListener('mousemove', (e) => {
            let scale = handerGroup.worldScaling[0];
            let nowImgHeight = this.imageHeight * scale;
            let nowImgWidth = this.imageWidth * scale;

            let s = this.sceneHeight / this.imageHeight
            let w = this.imageWidth * s;
            let offsetWidth = ((this.sceneWidth - w) / 2) / s

            if (isDown) {
                let x = nodePos[0] + e.layerX - old.x,
                    y = nodePos[1] + e.layerY - old.y;
                if (y > 0) {
                    y = 0;
                } else if (Math.abs(y) > (nowImgHeight - this.sceneHeight)) {
                    y = -(nowImgHeight - this.sceneHeight);
                }
                if (x > offsetWidth * scale) {
                    x = offsetWidth * scale
                } else if (x < (this.sceneWidth - nowImgWidth - offsetWidth * scale)) {
                    x = this.sceneWidth - nowImgWidth - offsetWidth * scale
                }
                handerGroup.attr({ x, y })
            }
        });
        handerGroup.addEventListener('mouseup', (e) => {
            isDown = false;
        });
        // 滚动缩放
        handerGroup.addEventListener('mousewheel', (e) => {
            let isUp = e.originalEvent.wheelDelta > 0;
            let wPos = { x: e.layerX, y: e.layerY };
            if (!isUp && this.isScaleLoke) return;
            this.zoom(wPos, isUp);
        });
    },
    zoom(wPos, isEnlarge) {
        let js = isEnlarge ? 0.2 : -0.2;
        this.isScaleLoke = false;
        let handerGroup = this.nodes['handerGroup'];
        let node = handerGroup.worldPosition;
        let scale = handerGroup.worldScaling[0] + handerGroup.worldScaling[0] * js;

        let nowImgHeight = this.imageHeight * scale;
        let nowImgWidth = this.imageWidth * scale;

        let width = wPos.x - node[0],
            height = wPos.y - node[1];

        if (scale < this.minScale) {
            scale = this.minScale;
        };

        let x = node[0] + width - width / handerGroup.worldScaling[0] * scale,
            y = node[1] + height - height / handerGroup.worldScaling[1] * scale;

        if (Math.abs(y) > (nowImgHeight - this.sceneHeight)) {
            y = -(nowImgHeight - this.sceneHeight);
        }
        if (y > 0) y = 0;

        handerGroup.transition(.3).attr({
            scale: [scale, scale],
            x,
            y
        })
        if (scale <= this.minScale) this.isScaleLoke = true;
    },
    tipFun(text, pos, color) {
        if (this.tip) this.tip.remove();
        this.tip = new Group({
            pos: pos || [100, 100],
            width: 260,
            height: 26 * text.length + 22,
            bgcolor: "rgba(0, 3, 11, .8)",
            bgcolor: color || '#008aff',
            opacity: 0
        })
        const line = new Polyline({
            pos: [-10, 23],
            points: [0, 0, 10, -10, 10, 10],
            fillColor: color || '#008aff',
        });
        this.tip.append(line);
        this.layer.append(this.tip);
        text.forEach((i, index) => {
            const text3 = new Label(i);
            text3.attr({
                pos: [15, 26 * index + 15],
                fillColor: '#fff',
                fontSize: 16,
            });
            this.tip.append(text3);
        })
    },
    createButton(obj) {
        let state = true;
        let g = new Group({
            width: 150,
            height: 35,
            pos: obj.pos || [0, 0],
            opacity: 0,
        })
        let img = new Sprite({
            pos: [0, 0],
            texture: require("@/assets/images/hand/an1.png"),
        })
        g.append(img)

        let eyeGroup = new Group({
            width: 20,
            height: 20,
            pos: [33, 10]
        })
        g.append(eyeGroup);

        let eye1 = new Sprite({
            pos: [0, 0],
            texture: require("@/assets/images/hand/eye3.png"),
            opacity: 1,
            pointerEvents: 'none'
        })
        eyeGroup.append(eye1)

        let eye2 = new Sprite({
            pos: [0, 0],
            texture: require("@/assets/images/hand/eye4.png"),
            opacity: 0,
            pointerEvents: 'none'
        })
        eyeGroup.append(eye2)

        img.addEventListener('mouseenter', (e) => {
            this.layer.canvas.style.cursor = 'pointer';
            img.attr({
                opacity: .6
            })
        });
        img.addEventListener('mouseleave', (e) => {
            this.layer.canvas.style.cursor = 'default';
            img.attr({
                opacity: 1
            })
        })

        let text1 = new Label({
            text: obj.text || '按钮',
            pos: [55, 9],
            fillColor: '#86392f',
            fontSize: 15,
            pointerEvents: 'none'
        });
        g.append(text1);

        if (obj.btnfun) {
            g.addEventListener('click', () => {
                state = !state;
                eye1.attr({
                    opacity: state ? 1 : 0,
                })
                eye2.attr({
                    opacity: state ? 0 : 1,
                })
                obj.btnfun(state);
            })
        }
        return g
    },
    // 删除数组对象的重复值
    deteleObject(obj) {
        var uniques = [];
        var stringify = {};
        for (var i = 0; i < obj.length; i++) {
            var keys = Object.keys(obj[i]);
            keys.sort(function (a, b) {
                return (Number(a) - Number(b));
            });
            var str = '';
            for (var j = 0; j < keys.length; j++) {
                str += JSON.stringify(keys[j]);
                str += JSON.stringify(obj[i][keys[j]]);
            }
            if (!stringify.hasOwnProperty(str)) {
                uniques.push(obj[i]);
                stringify[str] = true;
            }
        }
        uniques = uniques;
        return uniques;
    },
}

export default Hander;