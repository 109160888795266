import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import hosipital from './modules/hosipital'
import socket from './modules/socket'
import system from './modules/system'

Vue.use(Vuex)
export default new Vuex.Store({
  modules: {
    user,
    hosipital,
    socket,
    system,
  },
})
