<!-- 患者查询 -->

<template>
  <layer pagename="patientSearch" pageClass="patientSearchPage">
    <div class="patientSearch">
      <div class="search_input pc">
        <div class="from_b">
          <Input search @on-search="search" v-model="keyWork" placeholder="姓名、拼音码、手机号" />
        </div>
      </div>

      <div class="select_table">
        <Table stripe border @on-row-click="rowClick" :columns="columns1" :data="patientHistoryList.result"
          :height="u_table_heigh">
          <template slot-scope="{ row,index }" slot="patientName">
            <!-- <Button @click.stop="searchData(index)" type="text"> -->
            <Button type="text">
              <Icon v-if="row.patientSex == '男'" type="md-male" />
              <Icon v-else type="md-female" />
              {{ row.patientName }}
            </Button>
          </template>
        </Table>
        <Page show-total show-sizer @on-change="getPatientHistoryList" :total="totalCount" :page-size="pageSize"
          @on-page-size-change="setPageSize" :current="page"></Page>
      </div>

      <uArchives ref="archives" :info="activeItem" type="btn"></uArchives>

    </div>
  </layer>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  props: ["id"],
  data() {
    return {
      activeItem: {},
      totalCount: 0,
      page: 1,
      patientHistoryList: {},
      // 表格高度
      u_table_heigh: 100,
      pageSize: 10, //一页10条
      keyWork: "",
      columns1: [
        {
          title: "序号",
          type: "index",
          width: 68,
          align: "center"
        },
        {
          title: "姓名",
          key: "patientName",
          slot: "patientName"
        },
        {
          title: "年龄",
          key: "patientAge"
        },
        {
          title: "手机",
          key: "cellPhone"
        },
        {
          title: "婚否",
          key: "maritalStatusDesc"
        },
        {
          title: "来源",
          key: "patientSource"
        },
        {
          title: "就诊次数",
          key: "visitNumber"
        },
        {
          title: "累计付费",
          key: "totalMoney"
        },
        {
          title: "最近就诊",
          key: "recentlyVisitDateTime"
        },
        {
          title: "首次就诊",
          key: "firstVisitDateTime"
        },
      ]
    };
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  watch: {},
  methods: {
    ...mapActions([""]),
    setPageSize(size) {
      this.pageSize = size;
      this.getPatientHistoryList(1);
    },
    rowClick(data, index) {
      this.searchData(index);
    },
    searchData(index) {
      let item = this.patientHistoryList.result[index];
      this.activeItem = item;
      this.$refs.archives.show();
    },
    getPatientHistoryList(page) {
      this.page = page ? page : 1;
      var data = {
        pageSize: this.pageSize, //每页条数
        pageIndex: this.page, //当前页
        keyWork: this.keyWork, //搜索关键字(姓名、拼音码、手机号)
      };
      this.getData(data);
    },
    getData(data) {
      data["hospitalCode"] = this.userInfo.hosptialCode; //医馆编码
      this.patientHistoryList = {};
      this.$store.commit("openSpin");
      this.api2("pagePatientHistory", data).then(res => {
        this.$store.commit("closeSpin");
        res.result.forEach(i => {
          i.patientSource = i.patientSource || '/'
          i.recentlyVisitDateTime = i.recentlyVisitDateTime || '/'
          i.firstVisitDateTime = i.firstVisitDateTime || '/'
        })
        this.patientHistoryList = res;
        this.totalCount = res.total;
      });
    },
    search() {
      this.getPatientHistoryList(1);
    },
    // 设置表格高度
    setTableHeight() {
      if ($(".patientSearch").length)
        this.u_table_heigh =
          $(".patientSearch")[0].clientHeight -
          $(".search_input")[0].clientHeight -
          60;
    }
  },
  created() {
    this.getData({
      pageIndex: 1,
      pageSize: 10
    });
  },
  mounted: function () {
    this.setTableHeight();

    var self = this;
    // 窗口大小调整
    $(window).resize(function () {
      self.setTableHeight();
    });
  }
};
</script>
