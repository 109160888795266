<template>
    <div class="utabs">
        <div class="utabs-item" @click="clickItem(item)" :class="{ active: item.value == value }"
            v-for="(item, index) in list" :key="index">
            {{ item.label }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: String | Number,
            default: ""
        },
        list: {
            type: Array,
            default: () => {
                return [];
            }
        }
    },
    methods: {
        clickItem(item) {
            this.$emit("input", item.value)
        }
    },

}
</script>

<style lang="scss" scoped>
.utabs {
    display: flex;
    margin-bottom: 15px;

    .utabs-item {
        padding: 2px 0;
        margin-right: 20px;
        cursor: pointer;
        font-size: 15px;

        &.active {
            border-bottom: 2px solid #f00;
            font-weight: bold;
        }
    }
}
</style>