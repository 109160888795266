<template>
  <div class="report">
    <div class="btn2_g">
      <Button
        size="small"
        @click="pageTypeFun"
        class="look_old_btn"
        type="text"
      >{{pageType?'录入新报告':'查看历史'}}</Button>
      <!-- <Button class="submitBtn" @click="close(false)" size="small">退出</Button> -->
      <Button v-if="!pageType" class="closeBtn" @click="submit" size="small" type="primary">保存</Button>
    </div>
    <div class="report_body">
      <!-- 	报告类型 0.肝功能报告 1.肾功能报告 -->
      <div class="r_left">
        <div class="m_item" :class="{active:reportType==1}" @click="reportTypeFun(1)">肾功能</div>
        <div class="m_item" :class="{active:reportType==0}" @click="reportTypeFun(0)">肝功能</div>
      </div>
      <div class="r_right">
        <div class="r_title">
          <i></i>
          <span>{{reportType ? '肾': '肝'}}功能报告{{reportDt}}</span>
        </div>
        <template v-if="(pageType && this.reportList.length) || !pageType">
          <Form :class="[reportType?'form1':'form2']" :label-width="180">
            <Row :gutter="20">
              <Col
                :md="24"
                :lg="reportType?24:12"
                v-for="(item,index) in formItemList.filter(i=>i.reportType == reportType)"
                :key="index"
              >
                <uFormItem
                  @change="cacheData"
                  :disabled="pageType"
                  v-model="item.value"
                  :label="item.label"
                ></uFormItem>
              </Col>
            </Row>
          </Form>
          <Page
            size="small"
            v-if="pageType"
            @on-change="pageIndexChange"
            :total="total"
            :page-size="pageSize"
          />
        </template>
        <empty v-else />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import uFormItem from "./u_formItem";
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    info: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  components: {
    uFormItem
  },
  computed: {
    ...mapGetters(["diseaseEmotion"])
  },
  watch: {
    show(newValue, oldValue) {
      this.showWin = newValue;
    }
  },
  data() {
    return {
      reportDt: "",
      total: 0,
      pageType: false, // false编辑状态 true查看状态
      activeId: "", //当前编辑的Id
      reportType: 1,
      pageSize: 1,
      pageIndex: 1,
      reportList: [],
      showWin: true,
      option: [
        //0.正常 1.降低 2.增高 (默认为0)
        { label: "正常", value: 0 },
        { label: "降低", value: 1 },
        { label: "增高", value: 2 }
      ],

      formItemSave: {},

      formItemList: [
        {
          value: 0,
          key: "cr",
          label: "肌酐（Cr）",
          reportType: 1
        },
        {
          value: 0,
          key: "ccr",
          label: "生肌酐清除率（Ccr）",
          reportType: 1
        },
        {
          value: 0,
          key: "bun",
          label: "血尿素氮（BUN）",
          reportType: 1
        },
        {
          value: 0,
          key: "gfr",
          label: "肾小球滤过率（GFR）",
          reportType: 1
        },
        {
          value: 0,
          key: "b2mg",
          label: "血β2-微球蛋白（β2-MG）",
          reportType: 1
        },
        {
          value: 0,
          key: "ua",
          label: "血尿酸（UA）",
          reportType: 1
        },
        {
          value: 0,
          key: "cysc",
          label: "血清胱抑素C（CysC）",
          reportType: 1
        },

        {
          value: 0,
          key: "alt",
          label: "谷丙转氨酶（ALT）",
          reportType: 0
        },
        {
          value: 0,
          key: "cb",
          label: "直接胆红素（CB）",
          reportType: 0
        },
        {
          value: 0,
          key: "ast",
          label: "谷草转氨酶（AST）",
          reportType: 0
        },
        {
          value: 0,
          key: "ucb",
          label: "间接胆红素（UCB）",
          reportType: 0
        },
        {
          value: 0,
          key: "altAst",
          label: "谷草/谷丙（ALT/AST）",
          reportType: 0
        },
        {
          value: 0,
          key: "stp",
          label: "血清总蛋白（STP）",
          reportType: 0
        },
        {
          value: 0,
          key: "ggt",
          label: "谷氨酰转移酶（GGT）",
          reportType: 0
        },
        {
          value: 0,
          key: "albumin",
          label: "白蛋白（A）",
          reportType: 0
        },
        {
          value: 0,
          key: "alp",
          label: "碱性磷酸酶（ALP）",
          reportType: 0
        },
        {
          value: 0,
          key: "globulin",
          label: "球蛋白（G）",
          reportType: 0
        },
        {
          value: 0,
          key: "gdh",
          label: "谷氨酸脱氢酶（GDH）",
          reportType: 0
        },
        {
          value: 0,
          key: "whiteBallRatio",
          label: "白球比（A/G）",
          reportType: 0
        },
        {
          value: 0,
          key: "stb",
          label: "总胆红素（STB）",
          reportType: 0
        }
      ],

      cacheFormData1: "", //暂时保存编辑状态的数据
      cacheId1: "",
      cacheFormData2: "", //暂时保存编辑状态的数据
      cacheId2: ""
    };
  },
  methods: {
    cacheData() {
      if (this.reportType) {
        this.cacheFormData1 = this.common.clone(this.formItemList);
        this.cacheId1 = this.activeId;
      } else {
        this.cacheFormData2 = this.common.clone(this.formItemList);
        this.cacheId2 = this.activeId;
      }
    },
    pageTypeFun() {
      this.pageType = !this.pageType;
      this.pageIndex = 1;
      this.activeId = "";
      this.getData();
    },
    pageIndexChange(index) {
      this.pageIndex = index;
      this.getData();
    },
    reportTypeFun(index) {
      this.activeId = "";
      this.pageIndex = 1;
      this.reportType = index;
      this.getData();
    },
    close(res) {
      if (!res) this.$emit("close");
    },
    submit() {
      if (!this.pageType) {
        this.dataChange();
      }
      this.close();
    },

    dataChange() {
      let obj = {};
      this.formItemList.forEach(i => {
        obj[i.key] = i.value;
      });
      obj.patientCode = this.info.patientCode;
      obj.reportType = this.reportType;
      if (!this.activeId) {
        obj.reportDt = this.common.parseTime(new Date(), "{y}-{m}-{d}");
      } else {
        obj.id = this.activeId;
      }

      this.api2("saveLiverRenalFunctionReport", {
        liverRenalFunctionReportDTO: obj
      }).then(res => {
        // 清除缓存数据
        if (this.reportType) {
          this.cacheFormData1 = "";
        } else {
          this.cacheFormData2 = "";
        }

        this.$Message.success("保存成功");
        this.activeId = res.result;
      });
    },
    getData() {
      // 如果有编辑过的数据就不请求接口
      if (
        (!this.pageType && this.reportType && this.cacheFormData1) ||
        (!this.pageType && !this.reportType && this.cacheFormData2)
      ) {
        if (this.reportType && this.cacheFormData1) {
          this.formItemList = this.common.clone(this.cacheFormData1);
          this.activeId = this.cacheId1;
        } else {
          this.formItemList = this.common.clone(this.cacheFormData2);
          this.activeId = this.cacheId2;
        }
        return;
      }

      this.api2("getLiverRenalFunctionReportList", {
        liverRenalFunctionReportDTO: {
          patientCode: this.info.patientCode, //患者编码
          reportType: this.reportType, //报告类型 0.肝功能报告 1.肾功能报告
          isHistory: this.pageType ? 0 : 1 //	0.查看历史 1.查看今天
        },
        pageSize: this.pageSize,
        pageIndex: this.pageIndex
      }).then(res => {
        this.total = res.total;
        this.reportList = res.result;
        if (this.pageType) {
          this.setFormItemList(res.result[0] || "");
        } else {
          let item = res.result.find(
            i => i.reportDt == this.common.parseTime(new Date(), "{y}-{m}-{d}")
          );
          if (item) {
            this.setFormItemList(item);
          } else {
            this.formItemList = this.common.clone(this.formItemSave);
          }
        }
      });
    },
    setFormItemList(item) {
      this.formItemList = this.common.clone(this.formItemSave);
      if (!item) return;
      this.reportDt = this.pageType ? `(${item.reportDt})` : "";
      this.activeId = item.id;
      this.formItemList.forEach(i => {
        if (item.hasOwnProperty(i.key)) {
          i.value = item[i.key];
        }
      });
    },
    init() {
      this.formItemSave = this.common.clone(this.formItemList);
      this.showWin = this.show;
      this.getData();
    }
  },
  created() {
    this.init();
  }
};
</script>

<style>
.report{
  height: 100%;
  padding: 15px;
  background: #fff; 
} 
.report .report_body {
  display: flex;
  height: 100%;
}
.report .report_body .r_left {
  border-right: 1px dotted #ddd;
  width: 120px;
  flex-shrink: 0;
}
.report .report_body .m_item {
  text-align: center;
  line-height: 36px;
  border-bottom: 1px dotted #ddd;
  cursor: pointer;
}
.report .report_body .m_item.active {
  background: rgba(0, 0, 0, 0.05);
  font-weight: bold;
}
.report .report_body .r_right {
  width: 100%;
  padding: 15px;
  text-align: center;
}
.report .ivu-form-item-label {
  text-align: left;
}
.report .ivu-form-item {
  margin-bottom: 10px;
}
.report .ivu-select {
  width: 100px;
}
.report .ivu-form.form1 {
  width: 400px;
  margin: 0 auto;
}
.report .r_title {
  width: 300px;
  margin: 0 auto 20px;
  height: 34px;
  position: relative;
}
.report .r_title span,
.report .r_title i {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  line-height: 34px;
}
.report .r_title i {
  background: rgba(0, 0, 0, 0.05);
  transform: skewX(-35deg);
}
.report .ivu-select-selection {
  background: none;
}
.report .icon_k {
  display: inline-block;
  width: 30px;
  text-align: center;
}
.report .error .value2,
.report .error .icon_k,
.report .error .ivu-select {
  color: #f00;
}
.report .btn2_g {
  position: absolute;
  top: 55px;
  right: 60px;
}
.report .btn2_g .ivu-btn {
  margin-left: 10px;
}
.report .look_old_btn {
  font-size: 12px;
}
.report .look_old_btn span {
  text-decoration: underline;
  color: #57a3f3;
}
.report .submitBtn {
  font-size: 12px;
}
</style>