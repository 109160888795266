<template>
    <div class="commentary ">
        <div class="block">
            <div class="block-header">【{{ info.diagnosticName }}】病因病机通解</div>
            <div class="black-body">
                <div class="b-title">病因</div>
                {{ explainInfo.etiologyExplain || '暂无数据' }}
                <div class="b-title">病机</div>
                {{ explainInfo.pathogenesisExplain || '暂无数据' }}
            </div>
        </div>
        <div class="block">

            <div class="block-header">当前病情分析</div>
            <div class="black-body">
                <div>
                    <span class="b-title">未关联辨证的症状：</span>
                    <span>{{ noRelationSymptomName || "无" }}</span>
                </div>

                <div class="jl">
                    <span class="b-title">结论：</span>
                    <span class="value red">{{ epd.conclusion || "无" }}</span>
                </div>

                <div class="b-title">推理逻辑：</div>

                <div class="group">
                    <span>主诉：</span>
                    <span v-for="(item, index) in etiologyDialectical2" :key="index"><span v-html="item"></span></span>
                    <span v-if="!etiologyDialectical2.length">无</span>
                </div>

                <div class="group" v-if="etiologyDialectical.length">
                    <div class="content">
                        <p v-for="(item, index) in etiologyDialectical" :key="index"><span v-html="item"></span></p>
                    </div>
                </div>


                <div class="group">
                    <div>病情五行图：<span v-if="!tableData.length">无</span></div>
                    <Table :columns="columns1" :data="tableData" border>
                        <template slot-scope="{ row }" slot="木">
                            <Icon v-if="row.木" type="md-arrow-down" />
                            {{ row.木 }}
                        </template>
                        <template slot-scope="{ row }" slot="火">
                            <Icon v-if="row.火" type="md-arrow-down" />
                            {{ row.火 }}
                        </template>
                        <template slot-scope="{ row }" slot="土">
                            <Icon v-if="row.土" type="md-arrow-down" />
                            {{ row.土 }}
                        </template>
                        <template slot-scope="{ row }" slot="金">
                            <Icon v-if="row.金" type="md-arrow-down" />
                            {{ row.金 }}
                        </template>
                        <template slot-scope="{ row }" slot="水">
                            <Icon v-if="row.水" type="md-arrow-down" />
                            {{ row.水 }}
                        </template>
                    </Table>
                </div>

                <div class="group">
                    <div>辨证组合分析：<span v-if="!wxGroup.length">无</span></div>
                    <div class="flex_between" v-if="wxGroup.length">
                        <div class="table-black">
                            <Table :columns="columns2" :data="wxGroup" border>
                                <template slot-scope="{ row }" slot="木">
                                    <div v-html="row.木"></div>
                                </template>
                                <template slot-scope="{ row }" slot="火">
                                    <div v-html="row.火"></div>
                                </template>
                                <template slot-scope="{ row }" slot="土">
                                    <div v-html="row.土"></div>
                                </template>
                                <template slot-scope="{ row }" slot="金">
                                    <div v-html="row.金"></div>
                                </template>
                                <template slot-scope="{ row }" slot="水">
                                    <div v-html="row.水"></div>
                                </template>
                            </Table>
                        </div>
                        <div class="tip-info">
                            <div class="tip-info-title">严重程度</div>
                            <div class="tip-info-body">
                                <div class="tib-left"></div>
                                <div class="tib-right">
                                    <span>高</span>
                                    <span>低</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div class="group">
                    <!-- 好转的症状表现：【舌苔薄白(正常苔)】【咽痛】趋向减轻；【咳嗽】【盗汗】消失 -->
                    好转的症状表现：
                    <template v-if="epd.betterSymptom && epd.betterSymptom.length">
                        <span v-for="(item, index) in epd.betterSymptom" :key="index">【{{ item }}】</span>
                        趋向减轻；
                    </template>
                    <template v-if="epd.disappearSymptom && epd.disappearSymptom.length">
                        <span v-for="(item, index) in epd.disappearSymptom" :key="index">【{{ item }}】</span>
                        消失
                    </template>
                    <span v-if="!(epd.betterSymptom && epd.betterSymptom.length) && !(epd.disappearSymptom && epd.disappearSymptom.length)">无</span>
                </div>

                <div class="b-title">
                    总结：
                </div>

                <div class="group">
                    病因可能性：{{ epd.etiologyPossible || "无" }}
                </div>
                <div class="group">
                    <div>辨证疗程进度：</div>
                    <treatmentProgress v-if="epd.progress && epd.progress.length" :list="epd.progress" />
                    <span v-else>无</span>
                </div>
                <div class="group" >
                    好转脏腑：
                    <span v-if="epd.betterViscera && epd.betterViscera.length">{{ epd.betterViscera.join("、") }}</span>
                    <span v-else>无</span>
                </div>

                <div class="label">疾病发展推演图</div>
                <uconvas v-if="showUConvas" :info="info" :severeIllness="severeIllness" />
            </div>
        </div>
    </div>
</template>

<script>
import treatmentProgress from '@/com/treatmentProgress.vue';
import uconvas from "./uconvas.vue"
export default {
    components: {
        uconvas,
        treatmentProgress,
    },
    props: {
        ids: {
            type: Array,
            default: () => {
                return []
            }
        },
        info: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            epd: {
                betterSymptom:[], 
                disappearSymptom:[], 
                progress:[], 
            },
            tabsValue: 1,
            tabsList: [],
            columns1: [
                {
                    title: "  ",
                    key: "label",
                    width: 60,
                    align: "center",
                },
                {
                    title: "木",
                    key: "木",
                    minWidth: 100,
                    slot: "木",
                    align: "center",
                },
                {
                    title: "火",
                    key: "火",
                    minWidth: 100,
                    slot: "火",
                    align: "center",
                },
                {
                    title: "土",
                    key: "土",
                    minWidth: 100,
                    slot: "土",
                    align: "center",
                },
                {
                    title: "金",
                    key: "金",
                    minWidth: 100,
                    slot: "金",
                    align: "center",
                },
                {
                    title: "水",
                    key: "水",
                    minWidth: 100,
                    slot: "水",
                    align: "center",
                },
            ],
            columns2: [
                {
                    title: "木",
                    key: "木",
                    minWidth: 100,
                    slot: "木",
                    align: "center",
                },
                {
                    title: "火",
                    key: "火",
                    minWidth: 100,
                    slot: "火",
                    align: "center",
                },
                {
                    title: "土",
                    key: "土",
                    minWidth: 100,
                    slot: "土",
                    align: "center",
                },
                {
                    title: "金",
                    key: "金",
                    minWidth: 100,
                    slot: "金",
                    align: "center",
                },
                {
                    title: "水",
                    key: "水",
                    minWidth: 100,
                    slot: "水",
                    align: "center",
                },
            ],
            wxGroup: [],
            tableData: [],
            etiologyDialectical: [],
            etiologyDialectical2: [], //主诉信息
            noRelationSymptomName: "",
            severeIllness: [], //重症
            showUConvas: false,
        }
    },
    computed: {
        explainInfo() {
            return this.tabsList.length ? this.tabsList.find(i => i.value == this.tabsValue) : {}
        }
    },
    methods: {
        getData() {
            let obj = {
                "diseaseIdList": this.ids,
            }
            this.api2("listDiseaseEpPopularExplain", obj).then(res => {
                this.tabsList = []
                let o = res.result;
                for (const key in o) {
                    if (Object.hasOwnProperty.call(o, key)) {
                        const i = o[key][0];
                        if (i) {
                            i.label = i.diseaseName
                            i.value = i.diseaseId
                            this.tabsList.push(i)
                        }
                    }
                }
                if (this.tabsList.length) {
                    this.tabsValue = this.tabsList[0].value;
                }
            })
        },
        getEtiologyPathogenesisData() {
            this.api2("getEtiologyPathogenesisData", {
                "symptomNo": this.info.symptomNo,
                "patientCode": this.info.patientCode
            }).then(res => {
                this.epd = res.result;
                let { etiologyDialecticalVOS, wx, wxGroup } = res.result;
                this.wxGroup = wxGroup ? this.formatWxGroup(wxGroup) : [];
                this.setEtiologyDialectical(etiologyDialecticalVOS)
                this.setTableData(wx)
                this.showUConvas = true;    
            })
        },
        formatWxGroup(list) {
            let obj = {}
            this.severeIllness = [];
            list.forEach(i => {
                obj[i.name] = i.bzName.map(j => `<div>${j}</div>`).join("")
                if(i.bzName.length ) this.severeIllness.push(i.bzName[0] + "证");
            })
            // 去重
            this.severeIllness = [...new Set(this.severeIllness)];
            return [obj];
        },
        setEtiologyDialectical(list) {
            this.noRelationSymptomName = list.length ? list[0].noRelationSymptomName : "";
            let symptomId = list.map(i => i.symptomId)
            let ids = new Set(symptomId);
            let arr = [];
            ids.forEach(i => {
                let arr2 = list.filter(j => j.symptomId == i);
                let ids2 = new Set(arr2.map(o => o.dialecticsId))
                let l = []
                ids2.forEach(i => {
                    l.push(arr2.filter(j => j.dialecticsId == i))
                })
                arr.push(l);
            });

            let strArr = [];
            let strArr2 = []; //保存主诉信息
            arr.forEach(j => {
                let symptomName = j[0][0].symptomName;
                let isZs = this.info.diagnosticName1 == symptomName; //是否主诉
                let str = isZs ? `【<span class="red">${symptomName}</span>】` : `【${symptomName}】`
                j.forEach(i => {
                    let s = isZs ? "这是由于" : "是由于";
                    let sArr = [];
                    i.forEach(j => {
                        let bottomReason = JSON.parse(j.bottomReason).join(",");
                        // let str = !isZs ? `（${bottomReason})` : "";
                        let str = `（${bottomReason})`;
                        sArr.push(`【${j.etiologyName}】${str}导致【${i[0].pathogenesisName}之${i[0].isTypical ? '典型' : '非典型'}】`)
                    })
                    s += `${sArr.join("、")}，中医叫做【${i[0].dialecticsName}】`
                    if (isZs) { //保存主诉信息
                        strArr2.push(str + s + "。");
                    } else {
                        strArr.push(str + s + "。");
                    }
                })
            })
            this.etiologyDialectical = strArr;
            this.etiologyDialectical2 = strArr2;
        },
        
        setTableData(list) {
            let yang = { label: "阳" };
            let yin = { label: "阴" };
            list.forEach(i => {
                yang[i.name] = i.yang;
                yin[i.name] = i.yin;
            })
            this.tableData = [yang, yin];
        }
    },
    created() {
        this.getData();
        this.getEtiologyPathogenesisData()
    },
}
</script>

<style lang="scss">
.commentary {
    // padding-top: 30px;
    text-align: left;
    height: 100%;
    overflow-y: auto;
    line-height: 2.5em;

    .block {
        margin-bottom: 15px;

        .block-header {
            padding-bottom: 5px;
            font-weight: bold;
        }

        .black-body {
            padding: 15px;
            border: 1px solid #ddd;
        }
    }

    .ivu-card {
        margin-bottom: 15px;

        .ivu-card-head {
            padding: 8px 16px;
            background: #f5f5f5;

            p {
                font-size: 14px;
            }
        }
    }

    .b-title {
        font-weight: bold;
    }

    .group {
        padding-left: 20px;
        position: relative;
        margin-bottom: 10px;

        &::after {
            content: " ";
            display: block;
            clear: both;
            width: 8px;
            height: 8px;
            border-radius: 100%;
            background: rgba(0, 0, 0, .7);
            position: absolute;
            left: 0;
            top: 14px;
        }
    }

    .red {
        color: #f00;
        font-style: normal;
    }

    .ivu-table-cell {
        height: auto !important;
    }

    .treatment-progress {
        background: none;

        .ivu-progress-inner {
            background: #f5f5f5;
        }
    }

    .tip-info {
        width: 120px;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        .tip-info-body {
            height: 100%;
            display: flex;

            .tib-left {
                border-right: 1px solid #ccc;
                width: 10px;
                margin-right: 15px;
                position: relative;

                &::after {
                    content: '';
                    display: block;
                    width: 9px;
                    height: 9px;
                    position: absolute;
                    border: 1px solid #ccc;
                    border-width: 1px 0 0 1px;
                    right: -5px;
                    transform: rotate(45deg);
                }
            }

            .tib-right {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
        }
    }

    .table-black {
        width: 100%;
    }
}
</style>