<template>
  <div class="tab-body">
    <div class="card">
      <div class="card-header">
        <div class="card-h-title">时节气候特点</div>
      </div>
      <div class="card-body">
        初之气：主气厥阴风木，客气少阴君火。
        <br />
        壬寅岁初之气，自辛丑年大寒日申初，至是岁春分日午初，凡六十日八十七刻半。主位太角木，客气少阴火，中见木运，木火相得，运当其位，以奉少阳之政，而行春令，地气迁，风胜乃摇，寒乃去，候乃大温，草木早荣，寒来不杀，温病乃起。
      </div>
    </div>
</div>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped></style>