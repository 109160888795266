import store from '@/store';
export default {
    inserted(el, binding, vnode) {
        if (!store.getters.getUserCloudSystem.includes(binding.value)) {
            el.remove()
        }
    },
    update(el, binding) {
    },
    unbind(el, binding) {
    }
};