<template>
  <span>
    <Dropdown class="print_menu" placement="bottom-end" @on-click="clickMenu">
      <Button size="small">
        <Icon type="ios-print" />打印
      </Button>
      <DropdownMenu slot="list">
        <DropdownItem :name="1">病历打印</DropdownItem>
        <DropdownItem :name="2">处方打印</DropdownItem>
      </DropdownMenu>
    </Dropdown>
    <medicalRecordPrint v-model="showWin1" :info="info" :taboo="taboo" />
    <prescriptionPrint
      v-model="showWin2"
      :drugList="drugListFormat"
      ref="prescriptionPrint"
      :info="info"
      :quantity="quantity"
      hide
    />
  </span>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    info: {
      type: Object,
      default: function() {
        return {};
      }
    },
    taboo: {
      type: String,
      default: ""
    },
    drugList: {
      type: Array,
      default: function() {
        return [];
      }
    },
    quantity: {
      type: Number | String,
      default: 0
    }
  },
  computed: {
    ...mapGetters(["bnf"]),
    drugListFormat() {
      return this.drugList.map(i => {
        return {
          amount: i.Amount,
          discountRate: i.discountRate,
          drugCode: i.Drug_Code,
          drugDecoctPattern: i.drugDecoctPattern,
          drugName: i.Drug_Name,
          drugTakePattern: i.drugTakePattern,
          drugType: this.bnf.includes(i.Drug_Code) ? "06" : "01",
          quantity: i.Quantity,
          salesPrice: 0,
          specifications: i.specifications,
          unit: i.unit
        };
      });
    }
  },
  data() {
    return {
      showWin1: false,
      showWin2: false
    };
  },
  methods: {
    clickMenu(name) {
      switch (name) {
        case 1:
          this.showWin1 = true;
          break;
        case 2:
          this.showWin2 = true;
          break;
      }
    }
  },
  created() {
  }
};
</script>

<style lang="scss" scoped>
.print_menu {
  .ivu-icon {
    font-size: 16px;
    margin-right: 5px;
  }
}
</style>