//AES加密
import CryptoJS from "crypto-js";

var aesKey = 'L/MNDZpEZIV95VDD'

export default {
    //加密
    encrypt(data,resKey) {
        var key = CryptoJS.enc.Utf8.parse(resKey || aesKey);
        var options = {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        };
        var encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), key, options);
        var encryptResult = encryptedData.toString();
        return encryptResult;
    },
    //解密
    decrypt(data,resKey) {
        var key = CryptoJS.enc.Utf8.parse(resKey || aesKey);
        var options = {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        };
        var decryptedData = CryptoJS.AES.decrypt(data, key, options);
        var decryptResult = JSON.parse(decryptedData.toString(CryptoJS.enc.Utf8));
        return decryptResult;
    }
}