<!-- 精神状态分析 -->
<template>
    <div>
        <div class="section-time">{{ section.name }}</div>
        <div v-if="section.arr && section.arr.length > 0" class="section-list">
            <div v-for="(item, index) in section.arr" :key="index">
                <span>{{ item.value }}</span>
                <span>{{ item.name }}</span>
            </div>
        </div>
        <div id="spiritTrend" :style="{ height: height, width: width }" />
    </div>
</template>
  
<script>
import * as echarts from "echarts";
import resize from './mixins/resize.js'
import { dayList, dataZoom } from '@/utils/tool.js'
export default {
    mixins: [resize],
    props: {
        className: {
            type: String,
            default: 'chart'
        },
        width: {
            type: String,
            default: '100%'
        },
        height: {
            type: String,
            default: '100%'
        },
        autoResize: {
            type: Boolean,
            default: true
        },
        ecgData: {
            type: Object,
            default: {}
        },
        addTime: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            chart: null,
            section: []
        }
    },
    watch: {

    },
    created() {

    },
    mounted() {
        this.$nextTick(() => {
            this.initChart()
        })
    },
    beforeDestroy() {
        if (!this.chart) {
            return
        }
        this.chart.dispose()
        this.chart = null
    },
    methods: {
        initChart() {
            this.chart = echarts.init(document.getElementById('spiritTrend'))
            this.setOptions()
        },
        setOptions() {
            let that = this
            var t = this.ecgData
                , a = new Array(30)
                , i = new Array(30)
                , s = new Array(30)
                , r = new Array(30)
                , n = new Array(30)
                , o = new Array(30)
                , l = t.averageEcgs;

            dayList(this.addTime).last16.forEach(function (e, t) {
                l && l.forEach(function (l, c) {
                    if (e == l.data_date) {
                        var d = l.day_hr_pressure
                            , u = l.day_tension_degree;
                        0 == d && 0 == u ? (i[t] = 0,
                            n[t] = 0) : (a[t] = d,
                                r[t] = u),
                            s[t] = d,
                            o[t] = u
                    }
                })
            });
            let uesTemp = dayList(this.addTime).ecUse
            let dataTemp = dayList(this.addTime).ecShow
            this.chart.setOption({
                grid: {
                    top: 30,
                    right: 0,
                    bottom: 0,
                    left: 40,
                    containLabel: !0
                },
                tooltip: {
                    trigger: "axis",
                    formatter: function (t) {
                        var a = t[0].dataIndex
                            , i = t.filter(function (e) {
                                return "应变能力" === e.seriesName
                            })[0].marker
                            , r = t.filter(function (e) {
                                return "放松程度" === e.seriesName
                            })[0].marker
                            , n = dataTemp[a].split("/");
                        return "<div>\n              <p>" + (n[0] + "月" + n[1] + "日") + '</p>\n              <p">' + i + "应变能力：" + (s[a] ? s[a] : "---") + '</p>\n              <p">' + r + "放松程度：" + (o[a] ? o[a] : "---") + "</p>\n            </div>"
                    }
                },
                toolbox: {
                    show: !0,
                    feature: {
                        dataZoom: {
                            yAxisIndex: "none",
                            title: {
                                back: "还原"
                            }
                        }
                    }
                },
                color: ["#0091ea", "#fd7b52", "#86d9e0"],
                legend: {
                    x: "left",
                    padding: [0, 0, 0, 35],
                    itemWidth: 15,
                    itemHeight: 6,
                    itemGap: 27,
                    top: 5,
                    data: [{
                        name: "应变能力",
                        icon: "rect"
                    }, {
                        name: "放松程度",
                        icon: "rect"
                    }]
                },
                xAxis: {
                    type: "category",
                    boundaryGap: !0,
                    data: uesTemp,
                    axisLabel: {
                        interval: 0,
                        color: "#999"
                    },
                    axisLine: {
                        lineStyle: {
                            color: "#e7e5e4"
                        }
                    },
                    axisTick: {
                        show: !1
                    },
                    splitArea: {
                        show: !1
                    }
                },
                yAxis: {
                    scale: !0,
                    max: 100,
                    min: 0,
                    splitNumber: 5,
                    type: "value",
                    axisLabel: {
                        show: !1,
                        color: "#999"
                    },
                    splitLine: {
                        show: !1,
                        lineStyle: {
                            type: "dotted"
                        }
                    },
                    axisLine: {
                        show: !1
                    },
                    axisTick: {
                        show: !1
                    }
                },
                series: [{
                    name: "应变能力",
                    type: "line",
                    zlevel: 2,
                    smooth: !0,
                    data: a,
                    itemStyle: {
                        lineStyle: {
                            color: "#0091ea"
                        }
                    }
                }, {
                    name: "应变能力",
                    zlevel: 1,
                    type: "line",
                    smooth: !0,
                    data: i,
                    lineStyle: {
                        width: 0,
                        color: "#0091ea"
                    }
                }, {
                    name: "放松程度",
                    zlevel: 1,
                    type: "line",
                    smooth: !0,
                    data: r,
                    itemStyle: {
                        lineStyle: {
                            color: "#fd7b52"
                        }
                    }
                }, {
                    name: "放松程度",
                    zlevel: 1,
                    type: "line",
                    smooth: !0,
                    data: n,
                    lineStyle: {
                        width: 0,
                        color: "#fd7b52"
                    }
                }, {
                    name: "背景板",
                    type: "line",
                    smooth: !0,
                    markLine: {
                        silent: !0,
                        symbol: "none",
                        label: {
                            formatter: "{b}",
                            position: "start",
                            grid: {
                                left: 100
                            }
                        },
                        data: [{
                            yAxis: 30,
                            name: "",
                            lineStyle: {
                                width: 1,
                                type: "solid",
                                color: "#e7e5e4"
                            }
                        }, {
                            yAxis: 70,
                            name: "",
                            lineStyle: {
                                width: 1,
                                type: "solid",
                                color: "#e7e5e4"
                            }
                        }, {
                            yAxis: 100,
                            name: "",
                            lineStyle: {
                                width: 1,
                                type: "solid",
                                color: "#e7e5e4"
                            }
                        }, {
                            yAxis: 15,
                            name: "偏低",
                            lineStyle: {
                                width: 0
                            },
                            label: {
                                color: "rgb(255,59,48)"
                            }
                        }, {
                            yAxis: 50,
                            name: "正常",
                            lineStyle: {
                                width: 0
                            },
                            label: {
                                color: "#717171"
                            }
                        }, {
                            yAxis: 85,
                            name: "优秀",
                            lineStyle: {
                                width: 0
                            },
                            label: {
                                color: "rgb(76,217,100)"
                            }
                        }]
                    },
                    markArea: {
                        silent: !1,
                        data: [[{
                            name: "",
                            yAxis: 30,
                            itemStyle: {
                                color: "rgba(128,128,128,0.2)"
                            }
                        }, {
                            yAxis: 70
                        }]]
                    }
                }]
            })

            dataZoom({
                echart: this.chart,
                series: [{
                    name: "应变能力",
                    data: s
                }, {
                    name: "放松程度",
                    data: o
                }],
                addTime: this.addTime,
                sectionArr: function (t) {
                    that.section = t
                }
            })

        }
    }
}
</script>
<style lang="scss" scoped>
.section-time {
    position: absolute;
    top: -60px;
    line-height: 32px;
    right: 0;
    font-size: 16px;
    color: #000;
}

.section-list {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;

    div {
        border-right: 1px solid #e9e9e9;
        padding: 0 40px;

        &:last-child {
            border: none;
        }

        span {
            display: block;
            text-align: center;

            &:first-child {
                font-size: 18px;
                font-weight: 700;
                margin-bottom: 10px;
            }

            &:last-child {
                font-size: 14px;
                color: #7f7f7f;
            }
        }
    }
}
</style>