<!--处方选择-->
<template>
  <div class="prescriptionSelect" :class="{show:show}">
    <div class="head">
      <Icon @click="close" type="md-arrow-forward" />
      <span>处方</span>
    </div>
    <div
      class="p_item"
      v-for="(item,index) in list"
      :key="index"
      :class="{active:item.active}"
      @click="onItem(item,index)"
    >
      <div class="title">
        {{item.remark}}
        <Icon type="md-checkmark-circle" />
      </div>
      <!-- <div class="title2">{{item.remark}}</div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  watch: {
    list(newValue, oldValue) {
      this.init();
    }
  },
  data() {
    return {
      show: false,
      arr: []
    };
  },
  computed: {},
  methods: {
    onItem(item, index) {
      this.list.forEach(i => (i.active = false));
      item.active = true;
      this.$emit("change", this.list[index].drugsList);
    },
    onShow() {
      this.show = true;
    },
    init() {
      this.list.forEach((i, index) => index ? i.active = false : i.active = true);
      if (this.list.length > 1) {
        /*let pss = localStorage.getItem("prescriptionSelectShow");
        if (!pss) {
          this.onShow();
        } else {
          this.show = false;
        }*/
        this.show = true;
      } else {
        this.show = false;
      }
    },
    close() {
      this.show = false;
      // localStorage.setItem("prescriptionSelectShow", 1);
    }
  },
  created() {
    this.init();
  }
};
</script>

<style>
</style>