<template>
  <div class="drug_input">
    <div class="ucard_body">
      <div class="u_head">
        <div class="u_bz">
          <div class="ts_dz">
            <div class="selectGroup1">
              <div class="label">中药处方</div>
              <Input type="number" class="stickInput" v-model="prescriptionInfo.Quantity" @on-change="quantityChange">
              <span slot="append">帖</span>
              </Input>
            </div>
            <div class="right">
              <div class="follow-up-time">
                <span>应复诊时间</span>
                <DatePicker :options="options3" v-model="prescriptionInfo.returnVisitDate" type="date" placeholder="请选择" style="width: 120px" placement="bottom-end" :clearable="false"></DatePicker>
              </div>
              <!-- 测试 -->
              <!-- <Button class="more_cf" @click="onMore" type="text" >更多处方</Button> -->
              <Button class="more_cf" @click="onMore" type="text" v-if="recordSymptom && recordSymptom.length > 1">更多处方</Button>
            </div> 
          </div>
        </div>
      </div>
      <drug-table :pageType="pageType" :tabooDrugIds="tabooDrugIds" ref="drugTable" @change="change"
        :stick="prescriptionInfo.Quantity" :drugList="prescriptionInfo.drugList" />
      <!-- 暂时关闭 -->
      <div class="taboo add-drug-taboo">
        <div class="title">
          <Tooltip content="处方中可能与患者的辨证产生禁忌的加药，在此处提醒说明，需点击确认使用，方可提交处方。" placement="right-start">
            <span>
              加药禁忌
              <Icon type="ios-information-circle-outline" />
            </span>
          </Tooltip>
          <Button v-if="tabooDiscriminateList.length" :type="tabooSwitch ? 'error' : 'default'" size="small"
            @click="tabooBtnChange">{{ tabooSwitch ? "确认使用" : "取消确认" }}</Button>
        </div>
        <div class="taboo-body">
          <Tabs v-if="tabooDiscriminateList.length">
            <TabPane v-for="(item, index) in tabooDiscriminateList" :key="index" :label="labelFormat(item)">
              <div>
                <span class="text-red">{{ item.drugName }}</span>
                <span>与患者可能的辨证</span>
                <span class="text-red">{{ item.discriminatesStr }}</span>
                <span>,发生禁忌</span>
              </div>
              <div>
                典籍记载：<span class="text-heavy">{{ item.drugTaboo }}</span>
              </div>
            </TabPane>
          </Tabs>
          <empty v-else text="暂无内容" />
        </div>
      </div>

      <textMore title="医嘱" :html="tabooMerge" />
      <textMore title="饮食宜进" :html="yinShiList" />
      <textMore title="饮食禁忌" :html="jiKouList" />

      <!-- 
        <textMore title="今日宜吃" :html="yinShiList" />
      <textMore title="饮食忌口" :html="jiKouList" />
       -->

      <!-- <div class="u_medical_advice" v-if="spinShow && pageType">
        <div class="cf_content">
          <div class="load_tip">
            <img src="@/assets/images/wz.png" alt />
            <uSpin />
          </div>
        </div>
      </div> -->
    </div>
    <prescriptionSelect v-if="pageType" @change="insertDrugFun" :list="recordSymptom" ref="pSelect" />
    <tabooPrint v-model="tabooPrintShow" :info="info" :taboo="{ yinShiList, jiKouList }" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import drugTable from "./drugTable.vue";
import textMore from "./textMore.vue";
import tabooPrint from "./tabooPrint.vue";
import dayjs from 'dayjs';
export default {
  components: { drugTable, textMore, tabooPrint },
  computed: {
    ...mapGetters([
      "tabooDiscriminate",
      "addMedicalRecordSymptom",
      "spinShow", //处方加载动画开关
      "hundredDrugs",
      "userSystemConfig",
    ]),
    recordSymptom() {
      return this.addMedicalRecordSymptom[this.info.registerCode] || [];
    },
    tabooDiscriminateList22() {
      let list = this.tabooDiscriminate[this.info.registerCode] || [];
      this.tabooDrugIds = list.map((i) => i.drugCode);
      if (
        list.length &&
        this.prescriptionInfo.drugList &&
        this.prescriptionInfo.drugList.length
      ) {
        let ids = this.prescriptionInfo.drugList.map((i) => i.Drug_Code);
        return list.filter((i) => ids.includes(i.drugCode));
      } else {
        return [];
      }
    },
  },
  watch: {
    userSystemConfig: {
      handler: function () {
        this.photoReminder = this.userSystemConfig.mrScanRemindVisited;
      },
      deep: true,
    },
    info() {
      this.init();
      this.setList();
    },
    tabooDiscriminate: {
      handler: function () {
        this.setList();
      },
      deep: true,
    },
  },
  props: {
    prescriptionInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    //页面类型  true:前端医生 false:后端医生
    pageType: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
      default: () => {
        return {};
      },
    },
    // 导入处方
    insertDrugFun: {
      type: Function,
      default: () => { },
    },
    //ai返回的禁忌信息
    drugtaboo: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      tabooPrintShow: false,
      tabooDiscriminateList: [],
      tabooSwitch: true, //禁忌开头
      tabooDrugIds: [],
      tabooMerge: "",
      doctorsAdvice: "",
      medicalAdviceSwitch: false,
      resDrugTaboo: [],
      tabooStr: "",
      btnDisabled: false, //提交按钮禁用开关
      confirm: false,
      photoReminder: false, //病历拍照提醒

      jiKouList: "",
      yinShiList: "",

      options3: {
        disabledDate (date) {
          return date && date.valueOf() < Date.now();
        }
      },
    };
  },
  methods: {
    tabooPrint() {
      this.tabooPrintShow = true;
    },
    tabooBtnChange() {
      this.tabooSwitch = !this.tabooSwitch;
      if (!this.tabooSwitch) {
        let obj = {
          prescriptionNo: this.info.prescriptionNo, //处方号
          patientCode: this.info.patientCode, //病人编码
          drugDialecticsInfo: JSON.stringify(this.tabooDiscriminateList), //药物禁忌辨证json串
          symptomNo: this.info.symptomNo, //病历号
        };
        this.api2("saveDrugTabooByDiscriminateLog", obj).then((res) => { });
      }
    },
    setList() {
      // 暂时关闭
      // return;
      let list = this.tabooDiscriminate[this.info.registerCode] || [];
      this.tabooDrugIds = list.map((i) => i.drugCode);
      if (
        list.length &&
        this.prescriptionInfo.drugList &&
        this.prescriptionInfo.drugList.length
      ) {
        let ids = this.prescriptionInfo.drugList.map((i) => i.Drug_Code);
        this.tabooDiscriminateList = list.filter((i) =>
          ids.includes(i.drugCode)
        );
      } else {
        this.tabooDiscriminateList = [];
      }
    },
    labelFormat(item) {
      return (h) => {
        return h("div", [
          h(
            "i",
            {
              class: "",
            },
            "禁"
          ),
          h("span", item.drugName),
        ]);
      };
    },
    onMedicalAdviceSwitch() {
      this.medicalAdviceSwitch = !this.medicalAdviceSwitch;
    },
    onMore() {
      this.$refs.pSelect.onShow();
    },
    // 验证
    async validate() {
      if (!this.prescriptionInfo.drugList.length) {
        this.$Message.error("请先开好处方再提交!");
        return;
      }
      if (!this.$refs.drugTable.cj_drugList()) {
        return;
      }


      //暂时关闭
      if (this.tabooDiscriminateList.length) {
        if (this.tabooSwitch) {
          this.$store.commit("setN_tip", {
            title: "提示",
            okText: "确认使用",
            onOk: () => {
              this.tabooBtnChange();
            },
            content: "有未被确认的加药禁忌",
          });
          return;
        }
      }

      /* if (
        this.prescriptionInfo.drugList.some((i) => i.error) &&
        !this.confirm
      ) {
        setTimeout(() => {
          this.btnDisabled = true;
        }, 300);
        this.$store.commit("setN_tip", {
          title: "绝对禁忌提醒",
          onOk: () => {
            this.confirm = true;
          },
          onCancel: () => {
            this.btnDisabled = false;
          },
          content: "有绝对禁忌药物, 是否确认提交?",
        });
        return;
      } */

      // 是否病历拍照
      if (this.photoReminder && this.pageType) {
        let obj = {
          patientCode: this.info.patientCode,
          registerCode: this.info.registerCode,
          picType: 0,
        };

        this.photoReminder = false;
        let res = await this.api2("queryMedicalRecordScan", obj);
        if (!res.result.length) {
          this.$store.commit("setN_tip", {
            title: "提示",
            content: "病历还没有扫描哦",
          });
          return;
        }
      }

      return true;
    },
    getRecallAndDrugTaboo() {
      let obj = {
        patientCode: this.info.patientCode,
        symptomNo: this.info.symptomNo,
        dsIds: this.info.symptom.map((i) => i.dsId).join("|"),
      };
      this.$store.commit("openSpin");
      this.api2("getRecallAndDrugTaboo", obj).then((res) => {
        this.$store.commit("closeSpin");
        // this.$store.commit("sendLog", {
        //   remarks: "获取召回状态和症状冲突",
        //   apiName: "getRecallAndDrugTaboo",
        //   inParams: obj,
        //   outParams: res,
        // });
        this.resDrugTaboo = res.result.drugTaboo;
        this.doctorsAdvice = res.result.doctorsAdvice;
        this.$store.commit("setIsRecall", res.result.isRecall);
        this.setTaboos();
      });
    },
    change() {
      this.$emit("change");
      this.setList();
      this.setTaboos();
    },
    // 更新禁忌
    setTaboos() {
      this.tabooStr = "";
      this.prescriptionInfo.drugList.forEach((i, index) => {
        let item = this.hundredDrugs.find((j) => j.Drug_Code == i.Drug_Code);
        if (item && item.drugtaboo) {
          this.tabooStr += " " + item.drugtaboo;
        }
      });

      if (!this.tabooStr) {
        this.tabooStr = this.drugtaboo;
      }

      this.tabooMerge = this.common.tabooFormat(
        this.tabooStr + (this.doctorsAdvice || "")
      );

      this.$emit("tabooChange", this.tabooMerge);

      if (!(this.resDrugTaboo && this.resDrugTaboo.length)) return;

      if (this.info.symptom.length) {
        // 禁忌药品数据格式化
        let list = this.common.formatTaboo(
          this.common.clone(this.resDrugTaboo),
          this.info.symptom
        );
        this.prescriptionInfo.drugList.forEach((i) => {
          let item = list.find((j) => j.drugCode == i.Drug_Code);
          if (item) {
            let tabooDetailList = item.tabooDetailList;
            let type3 = tabooDetailList.find((k) => k.tabooType == 3);
            if (type3 && type3.dosageTaboo >= i.Amount) {
              tabooDetailList = tabooDetailList.filter((k) => k.tabooType != 3);
            }
            i.drugTaboo = tabooDetailList;
            i.error = tabooDetailList.find((k) => k.tabooType == 0)
              ? true
              : false;
          } else {
            i.drugTaboo = [];
          }
        });
      }
    },

    /* 
    getMedicalDietPlanByBillNo() {
      let obj = {
        symptomNo: this.info.symptomNo,
      };
      this.$store.commit("openSpin");
      this.api2("getMedicalDietPlanByBillNo", obj).then((res) => {
        this.$store.commit("closeSpin");
        this.jiKouList = res.result.jiKouList;
        this.yinShiList = res.result.yinShiList;
      });
    },
    */
    listDiseaseDietPlan() {
      let obj = {
        dsIds: this.info.diagnosticId2,
      };
      this.$store.commit("openSpin");
      this.api2("listDiseaseDietPlan", obj).then((res) => {
        this.$store.commit("closeSpin");
        this.jiKouList = res.result.jiKouList ? this.common.tabooFormat(res.result.jiKouList.map(i => i.jiKouName).join(" ")) : "";
        this.yinShiList = res.result.yinShiList ? this.common.tabooFormat(res.result.yinShiList.map(i => i.yinShiName).join(" ")) : "";
      });
    },

    quantityChange(){
      if(!this.prescriptionInfo.Quantity) return;
      this.prescriptionInfo.returnVisitDate = dayjs().add(this.prescriptionInfo.Quantity * 1 - 1, 'day').format('YYYY-MM-DD');
      let d = dayjs().add(this.prescriptionInfo.Quantity * 1 + 29, 'day').toDate();
      this.options3 = {
          disabledDate(date){
            return !(date.valueOf() > Date.now() && date.valueOf() < d.valueOf());
          }
      }
    },
   

    getDietaryPlan() {
      let meal = ["", "早餐", "午餐", "晚餐"]
      let obj = {
        diseaseType: this.info.diagnosticId2,
        userSex: this.info.sex == "男" ? 1 : 2,
      };
      this.$store.commit("openSpin");
      this.api2("getDietaryPlan", obj).then((res) => {
        this.$store.commit("closeSpin");

        let str = "";
        let taboo = res.result.taboo
        for (const key in taboo) {
          if (Object.hasOwnProperty.call(taboo, key)) {
            const item = taboo[key];
            str += `<div class="taboo-item"><span>${key}：</span> ${item.join(", ")}</div>`
          }
        }
        this.jiKouList = str;
        this.yinShiList = res.result.suitable.map(i => {
          return `<div class="taboo-item"><span>${meal[i.planThreeMeal]}：</span> ${i.foodsName}</div>`
        }).join(" ");
      });
    },
    init() {
      this.listDiseaseDietPlan();
      this.getRecallAndDrugTaboo();
      this.photoReminder = this.userSystemConfig.mrScanRemindVisited;
      this.btnDisabled = false;
      this.confirm = false;
      this.tabooSwitch = true;
      this.$store.commit("initN_tip");
      setTimeout(() => {
        this.quantityChange();
      }, 500)
    },
  },
  mounted () {
    this.init();
  },
};
</script>
<style lang="scss" scoped>
.selectGroup1 {
  display: flex;
  line-height: 32px;

  .label {
    padding-right: 30px;
    font-weight: bold;
  }

  .stickInput {
    width: 120px;
    height: 32px;
  }
}

.drug_input {
  height: 100%;
  overflow: hidden;
  position: relative;

}

::v-deep .taboo-item {
  span {
    font-weight: bold;
  }
}

.taboo {
  border: 1px solid #eee;
  padding: 10px;
  border-radius: 5px;

  .title {
    font-weight: bold;
    padding-bottom: 0;
  }

  .taboo-text {
    line-height: 26px;
    height: 50px;
    overflow: hidden;

    &-more {
      height: auto;
    }
  }
}

.add-drug-taboo {
  margin-bottom: 10px;

  .ivu-tabs-tabpane {
    line-height: 2em;
    background: #fffce3;
    padding: 5px 10px;
  }
}

.u_table2 {
  margin-bottom: 10px;
}
.ts_dz{
  display: flex;
  justify-content: space-between;
  align-items: center;
  .follow-up-time,
  .right{
    display: flex;
    align-items: start;
  }
  .follow-up-time{
    &>span{
      padding-right: 10px;
    }
  }
}
</style>
