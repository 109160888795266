<!-- 睡眠状态 -->
<template>
    <div :class="className" :style="{ height: height, width: width }" />
</template>
  
<script>
import * as echarts from "echarts";
import resize from './mixins/resize.js'
import { getTimeInfo } from '@/utils/tool.js'
export default {
    mixins: [resize],
    props: {
        className: {
            type: String,
            default: 'chart'
        },
        width: {
            type: String,
            default: '100%'
        },
        height: {
            type: String,
            default: '100%'
        },
        autoResize: {
            type: Boolean,
            default: true
        },
        sleepData: {
            type: Object,
            default: {}
        },
        heartData: {
            type: Object,
            default: {}
        },
    },
    data() {
        return {
            chart: null,
        }
    },
    created() {

    },
    mounted() {
        this.$nextTick(() => {
            this.initChart()
        })
    },
    beforeDestroy() {
        if (!this.chart) {
            return
        }
        this.chart.dispose()
        this.chart = null
    },
    methods: {
        initChart() {
            this.chart = echarts.init(this.$el)
            this.setOptions()
        },
        setOptions() {
            let sleepNorm = {
                deepSleep: "",
                lightSleep: "",
                exception: ""
            }
            let a = this.sleepData;
            a.deepSleep ? sleepNorm.deepSleep = a.deepSleep : sleepNorm.deepSleep = "---"
            a.lightSleep ? sleepNorm.lightSleep = a.lightSleep : sleepNorm.lightSleep = "---"
            a.exception ? sleepNorm.exception = a.exception : sleepNorm.exception = "---"
            a.sleepData && (a.sleepData = a.sleepData.replace(/[-]/g, "/"));
            let i = JSON.parse(a.sleepData) || []   //睡眠数据
            let s = this.heartData.today     //夜间心率数据
            let r = ["#cfa972", "#86d9e0", "#0091ea"]
            let n = [80, 60, 40]
                , o = ["异常", "浅睡", "深睡"]
                , l = ""
                , c = ""
                , d = []
                , u = []
                , m = []
                , p = [];
            if (s) {
                s.forEach(function (t) {
                    let a
                    for (let key in t) {
                        a = key
                    }
                    if (Number(t.timeType) === 2) {
                        d.push([new Date(t.uploadDataDate + " " + t.slot).getTime(), t.heartRate])
                    }
                })
            }
            if (i && i.length > 0) {
                let g = new Date(i[0][0])
                let h = new Date(i[i.length - 1][1]);
                l = h
                c = g
                i.forEach(function (e) {
                    var t = e
                        , a = t[0]
                        , i = t[1]
                        , s = t[2];
                    u.push({
                        value: [a, i, n[s], s],
                        itemStyle: {
                            color: r[s]
                        }
                    })
                })
            }
            this.chart.setOption({
                grid: {
                    top: 20,
                    right: 15,
                    bottom: 20,
                    left: 15,
                    containLabel: !0
                },
                tooltip: {
                    trigger: "axis",
                    formatter: function (e) {
                        var a = e[0].dataIndex
                            , i = d[a]
                            , s = i[0]
                            , r = i[1];
                        s = getTimeInfo(new Date(s), "/");
                        var n = ""
                            , l = new Date(s).getTime();
                        if (u.forEach(function (e) {
                            var t = e.value
                                , a = t[0]
                                , i = t[1]
                                , c = (t[2],
                                    t[3]);
                            l >= new Date(a).getTime() && l <= new Date(i).getTime() && (n += "<div>\n                  <p>时间：" + s.split(" ")[1] + "</p>\n                  <p>心率：" + r + "</p>\n                  <p>" + o[c] + "：" + a.split(" ")[1] + " - " + i.split(" ")[1] + "</p>\n                  </div>")
                        }),
                            r)
                            return n
                    }
                },
                xAxis: {
                    type: "time",
                    max: l,
                    min: c,
                    axisLabel: {
                        color: "#999",
                        fontSize: "8",
                        showMaxLabel: !0,
                        showMinLabel: !0,
                        formatter: "{HH}:{mm}"
                    },
                    axisLine: {
                        lineStyle: {
                            color: "#e7e5e4"
                        }
                    },
                    axisTick: {
                        show: !1
                    }
                },
                yAxis: {
                    scale: !0,
                    max: 140,
                    min: 0,
                    splitNumber: 6,
                    type: "value",
                    axisLabel: {
                        color: "#999"
                    },
                    axisLine: {
                        show: !1
                    },
                    axisTick: {
                        show: !1
                    }
                },
                series: [{
                    name: "心率",
                    type: "line",
                    smooth: !0,
                    showSymbol: d.length <= 1,
                    itemStyle: {
                        color: "#F2A791"
                    },
                    data: d
                }, {
                    type: "custom",
                    dimensions: ["from", "to", "profit"],
                    silent: !0,
                    tooltip: {
                        show: !1
                    },
                    label: {
                        show: !1
                    },
                    renderItem: function (e, t) {
                        var a = t.value(2)
                            , i = t.coord([t.value(0), a])
                            , s = t.size([t.value(1) - t.value(0), a]);
                        return {
                            type: "rect",
                            shape: {
                                x: i[0],
                                y: i[1],
                                width: s[0],
                                height: s[1]
                            },
                            style: {
                                fill: t.visual("color")
                            }
                        }
                    },
                    encode: {
                        x: [0, 1],
                        y: 2,
                        tooltip: [0, 1, 2],
                        itemName: 3
                    },
                    data: u
                }, {
                    type: "scatter",
                    symbolSize: 15,
                    itemStyle: {
                        color: "rgba(207,170,115,1)",
                        borderWidth: 2,
                        borderColor: "rgba(255, 255, 255, 1)",
                        opacity: 1
                    },
                    tooltip: {
                        show: !1
                    },
                    label: {
                        show: !0,
                        color: "#fff",
                        formatter: function (e) {
                            return e.dataIndex + 1
                        }
                    },
                    data: m
                }]
            })
        },
    }
}
</script>
  