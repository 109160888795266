<template>
  <div class="patientInfoModal ai_win luck">
    <Spin v-if="loading" fix>
      <div class="u_loader_k">
        <div class="u_loader"></div>
        <img src="@/assets/images/logo.png" alt />
      </div>
      <div>推演中...</div>
    </Spin>
    <Form v-show="!pageType" ref="formValidate" :model="formValidate" label-position="top" :rules="ruleValidate">
      <FormItem label="请确认出生日期" prop="value2">
        <input class="ivu-input" type="text" id="datepicker" :data-date="formValidate.value2" readonly />
      </FormItem>
      <FormItem label="请选择形体" :rules="value3Rules" prop="value3">
        <div class="ivu-form-item-label" slot="label">
          请选择形体
          <span>（非必选，若选，则色泽必选）</span>
        </div>
        <RadioGroup class="body_select" v-model="formValidate.value3">
          <Radio v-for="(item, index) in BODY_SHAPE" :key="index" :label="item.value">
            <div class="r_label">{{ item.label }}</div>
          </Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="请选择脸上色泽" :rules="value4Rules" prop="value4">
        <div class="ivu-form-item-label" slot="label">
          请选择脸上色泽
          <span>（非必选，若选，则形体必选）</span>
        </div>
        <RadioGroup v-model="formValidate.value4">
          <Radio v-for="(item, index) in FACE_COLOR" :key="index" :label="item.value">{{ item.label }}</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem class="btn_b">
        <Tooltip always placement="right">
          <template slot="content">
            <Icon type="md-information-circle" />一键知晓用户的五运六气，推荐使用
          </template>
          <Button size="large" type="primary" @click="handleSubmit('formValidate')">推演五运六气</Button>
        </Tooltip>
      </FormItem>
    </Form>
    <div v-if="pageType" class="show_info1">
      <Button class="back" @click="pageType = false" type="text">
        <Icon type="ios-arrow-back" />
        返回
      </Button>
      <div class="detail">
        <div class="detail-info">
          <div class="hander2">
            <div class="rq-info" @click="pageType = false">
              <!-- {{ birthday }} -->
              <div class="yl">
                <Icon type="md-calendar" />
                <!-- <span>{{ birthday.date }}</span> -->
                <span>{{ day }}</span>
              </div>
              <div class="nl lr">
                <div>{{ birthday.gzYear }}年</div>
                <div>{{ birthday.IMonthCn + birthday.IDayCn }}</div>
              </div>
            </div>

            <div class="list-item" v-for="(item, index) in list2" :key="index">
              <div class="label lr">{{ item.label }}</div>
              <div class="value lr" :class="'wx-color-' + item.type">{{ item.value }}</div>
            </div>

            <div class="label lr">客主加临</div>
            <div class="value">
              <Tag type="border" :color="item.type" v-for="(item, index) in otherGas" :key="index">{{ item.label }}</Tag>
            </div>
            <div class="label lr">运气相和</div>
            <div class="value">
              <Tag type="border" :color="item.type" v-for="(item, index) in alienation" :key="index">{{ item.label }}
              </Tag>
            </div>
          </div>
          <RadioGroup v-model="tabValue">
            <Radio :label="item.value" border v-for="item in tabs" :key="item.value">{{ item.label }}</Radio>
          </RadioGroup>

          <luck-tab-1 :info="FLSQ" v-if="tabValue == '1'" />
          <luck-tab-2 :info="FLSQ" v-else-if="tabValue == '2'" />
          <luck-tab-3 :info="FLSQ" v-else-if="tabValue == '3'" :birthday="birthday" />
          <luck-tab-4 :info="FLSQ" v-else-if="tabValue == '4'" />
          <luck-tab-5 :info="FLSQ" v-else-if="tabValue == '5'" />
        </div>
        <div class="detail-pic">
          <luck-pic :list="yunList" :birthday="patientBirthday" :picType="1" />
          <luck-pic :list="qiList" :birthday="patientBirthday" :picType="2" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { calendar } from '@/assets/js/calendar.js'

import { mapGetters } from "vuex";
import luckPic from './luckPic.vue';
import luckTab1 from './luckTab1.vue';
import luckTab2 from './luckTab2.vue';
import luckTab3 from './luckTab3.vue';
import luckTab4 from './luckTab4.vue';
import luckTab5 from './luckTab5.vue';
export default {
  components: { luckTab1, luckTab2, luckTab3, luckTab4, luckTab5, luckPic },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    info: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  watch: {
    show(newValue, oldValue) {
      this.showWin = newValue;
      if (newValue) {
        this.setDate();
        this.initDatepicker();
        this.pageType = false;
      }
    },
    info: {
      handler(value) {
        this.setDate();
      },
      deep: true
    },
    flsq(value) {
      this.setFlsq();
    }
  },
  computed: {
    ...mapGetters(["FACE_COLOR", "BODY_SHAPE", "flsq"]),
    birthday() {
      let bd = this.patientBirthday.split('-');
      return calendar.solar2lunar(...bd)
    },
    day() {
      return this.common.parseTime(this.patientBirthday, "{y}年{m}月{d}")
    },
    formadDate() {
      return this.common.parseTime(this.formValidate.value2, "{y}年{m}月{d}日");
    },
    diseaseNameByMonth() {
      if (!this.FLSQ.diseaseNameByMonth) return [];
      let list = this.FLSQ.diseaseNameByMonth.split("|");

      if (!this.show_more) {
        if (list.some(i => /X/.test(i))) {
          list = list.filter(i => /X/.test(i));
        } else {
          list = list.slice(0, 5);
        }
      }

      return list.map(i => {
        let arr = i.split("X");
        let obj = { label: arr[0] };
        if (arr[1]) obj.value = arr[1];
        return obj;
      });
    },
    diseaseNameByYear() {
      if (!this.FLSQ.diseaseNameByYear) return [];
      let list = this.FLSQ.diseaseNameByYear.split("|");

      if (!this.show_more) {
        if (list.some(i => /X/.test(i))) {
          list = list.filter(i => /X/.test(i));
        } else {
          list = list.slice(0, 5);
        }
      }

      return list.map(i => {
        let arr = i.split("X");
        let obj = { label: arr[0] };
        if (arr[1]) obj.value = arr[1];
        return obj;
      });
    },
    value3Rules() {
      if (this.formValidate.value3 || this.formValidate.value4) {
        return [
          {
            required: true,
            message: "请选择形体",
            trigger: "blur"
          }
        ];
      } else {
        return [];
      }
    },
    value4Rules() {
      if (this.formValidate.value3 || this.formValidate.value4) {
        return [
          {
            required: true,
            message: "请选择脸上色泽",
            trigger: "blur"
          }
        ];
      } else {
        return [];
      }
    },
    alienation() {
      let nice = ["顺化"]
      let alienation = this.FLSQ.alienation
      let l1 = alienation ? alienation.split("、").filter(i => i != '/').map(i => {
        return {
          label: i,
          type: nice.includes(i) ? "success" : "error"
        }
      }) : []

      let assimilation = this.FLSQ.assimilation
      let l2 = assimilation ? assimilation.split("、").filter(i => i != '/').map(i => {
        return {
          label: i,
          type: ""
        }
      }) : []

      return l1.concat(l2);
    },
    otherGas() {
      let nice = ["相得", "顺"]
      let otherGas = this.FLSQ.otherGas
      return otherGas ? otherGas.split("、").map(i => {
        return {
          label: i,
          type: nice.includes(i) ? "success" : "error"
        }
      }) : []
    }
  },
  data() {
    return {
      patientBirthday: '',
      show_more: false,
      showWin: false,
      datepicker: null,
      boyStr: "",
      boyShow: false,
      loading: false,
      FLSQ: { diseaseNameByMonth: "", diseaseNameByYear: "" },
      pageType: false,
      tabValue: "1",
      yunList: [
        {
          label: "初之运",
          active: false,
          nodew: null,
          zhuke: [
            {
              label: "主",
              value: "",
              key: "firstMainCarrier",
            },
            {
              label: "客",
              value: "",
              key: "firstNextCarrier",
            },
          ]
        },
        {
          label: "二之运",
          active: false,
          nodew: null,
          zhuke: [
            {
              label: "主",
              value: "",
              key: "twoMainCarrier",
            },
            {
              label: "客",
              value: "",
              key: "twoNextCarrier",
            },
          ]
        },
        {
          label: "三之运",
          active: false,
          nodew: null,
          zhuke: [
            {
              label: "主",
              value: "",
              key: "threeMainCarrier",
            },
            {
              label: "客",
              value: "",
              key: "threeNextCarrier",
            },
          ]
        },
        {
          label: "四之运",
          active: false,
          nodew: null,
          zhuke: [
            {
              label: "主",
              value: "",
              key: "fourMainCarrier",
            },
            {
              label: "客",
              value: "",
              key: "fourNextCarrier",
            },
          ]
        },
        {
          label: "终之运",
          active: false,
          nodew: null,
          zhuke: [
            {
              label: "主",
              value: "",
              key: "lastMainCarrier",
            },
            {
              label: "客",
              value: "",
              key: "lastNextCarrier",
            },
          ]
        },
      ],
      qiList: [
        {
          label: "初之气",
          active: false,
          nodew: null,
          zhuke: [
            {
              label: "客",
              value: "",
              key: "firstNextGas",
            },
            {
              label: "主",
              value: "",
              key: "firstMainGas",
            },
          ]
        },
        {
          label: "二之气",
          active: false,
          nodew: null,
          zhuke: [
            {
              label: "客",
              value: "",
              key: "twoNextGas",
            },
            {
              label: "主",
              value: "",
              key: "twoMainGas",
            },
          ]
        },
        {
          label: "三之气",
          active: false,
          nodew: null,
          zhuke: [
            {
              label: "客(司天)",
              value: "",
              key: "threeNextGas",
            },
            {
              label: "主",
              value: "",
              key: "threeMainGas",
            },
          ]
        },
        {
          label: "四之气",
          active: false,
          nodew: null,
          zhuke: [
            {
              label: "客",
              value: "",
              key: "fourNextGas",
            },
            {
              label: "主",
              value: "",
              key: "fourMainGas",
            },
          ]
        },
        {
          label: "五之气",
          active: false,
          nodew: null,
          zhuke: [
            {
              label: "客",
              value: "",
              key: "fiveNextGas",
            },
            {
              label: "主",
              value: "",
              key: "fiveMainGas",
            },
          ]
        },
        {
          label: "终之气",
          active: false,
          nodew: null,
          zhuke: [
            {
              label: "客(在泉)",
              value: "",
              key: "lastNextGas",
            },
            {
              label: "主",
              value: "",
              key: "lastMainGas",
            },
          ]
        },
      ],
      list2: [
        {
          label: "岁运",
          value: "",
          type: 0,
          key: 'yearLuck',
        },
        {
          label: "主运",
          value: "",
          type: 0,
          key: 'mainCarrier',
        },
        {
          label: "客运",
          value: "",
          type: 0,
          key: 'nextCarrier',
        },
        {
          label: "主气",
          value: "",
          type: 0,
          key: 'mainGas',
        },
        {
          label: "客气",
          value: "",
          type: 0,
          key: 'nextGas',
        },
        {
          label: "在泉",
          value: "",
          type: 0,
          key: 'inSpring',
        },
        {
          label: "司天",
          value: "",
          type: 0,
          key: 'sitian',
        },
      ],
      tabs: [
        {
          label: "体质",
          value: "1"
        },
        {
          label: "性格",
          value: "2"
        },
        {
          label: "疾病倾向",
          value: "3"
        },
        {
          label: "预防注意",
          value: "4"
        },
        /* {
          label: "今年气运",
          value: "5"
        }, */
      ],
      wx: ["木", "火", "土", "金", "水"],
      formValidate: {
        value1: "0",
        value2: "",
        value3: "",
        value4: "",
        value5: "",
        value6: "",
        value7: ""
      },
      ruleValidate: {
        value3: [
          {
            required: true,
            message: "请选择形体",
            trigger: "blur"
          }
        ],
        value4: [
          {
            required: true,
            message: "请选择脸上色泽",
            trigger: "blur"
          }
        ]
      }
    };
  },
  methods: {
    setFlsq() {
      if (this.flsq) {
        this.FLSQ = this.flsq;
        this.pageType = true;
        this.formatData();
      } else {
        this.FLSQ = { diseaseNameByMonth: "", diseaseNameByYear: "" };
        this.pageType = false;
      }
    },
    close(res) {
      if (!res) this.$emit("close");
    },

    isLunarChange(res) {
      this.formValidate.value1 = res ? 1 : 0;
    },
    addZero(number) {
      if (typeof number == "Number") {
        return number > 10 ? number : "0" + number;
      } else {
        return number;
      }
    },
    boyConfirm(data) {
      this.boyStr = data.thisStr;
      let date =
        data.year +
        "-" +
        this.addZero(data.month) +
        "-" +
        this.addZero(data.day);
      this.formValidate.value2 = date;
      this.formValidate.value5 = date;
      this.formValidate.value6 =
        data.lYear +
        "-" +
        this.addZero(data.lMonth) +
        "-" +
        this.addZero(data.lDay);
      this.formValidate.value7 = data.isLeap;
    },
    showBoyPicker() {
      this.boyShow = true;
    },
    setDate() {
      this.pageType = false;
      this.formValidate.value1 = 0;
      this.formValidate.value2 = this.info.patientBirthday;
      this.formValidate.value3 = "";
      this.formValidate.value4 = "";
      this.initDatepicker();
    },
    initDatepicker() {
      setTimeout(() => {
        if ($("#datepicker").length) {
          if (!this.datepicker) {
            this.datepicker = new ruiDatepicker();
            this.datepicker.init("#datepicker");
          } else {
            this.datepicker.initValue();
          }
        }
      }, 300);
    },
    closeWin() {
      this.$emit("input", false);
    },
    formatSyndrome(str) {
      return str ? str.replace(/,$/, "") : "";
    },
    handleSubmit(name) {
      this.$refs[name].validate(valid => {
        if (valid) {
          this.postData();
        } else {
          this.$Message.error("请将信息填写完整!");
        }
      });
    },
    postData() {
      let dEl = document.querySelector("#datepicker"),
        solarCalendar = dEl.getAttribute("data-date"),
        lunarCalendar = dEl.getAttribute("data-sdate"),
        isLeapMonth = 0; //	是否闰月 0否，1是
      this.boyStr = dEl.value;
      if (/--/.test(lunarCalendar)) {
        isLeapMonth = 1;
        lunarCalendar = lunarCalendar.replace("--", "-");
      }
      this.loading = true;
      let obj = {
        bodyCode: this.formValidate.value3, //	形体Id
        facialColorCode: this.formValidate.value4, //	面部色泽Id
        patientCode: this.info.patientCode, //患者编码
        isLeapMonth,
        solarCalendar,
        lunarCalendar
      };
      this.api2("getFLSQ_Calculate_Result_New", obj)
        .then(async res => {
          await this.closeLoading();
          this.pageType = true;
          this.FLSQ = res.result;
          this.patientBirthday = solarCalendar;
          this.formatData();
        })
        .catch(err => {
          this.closeLoading();
        });
    },
    formatData() {
      this.list2.forEach(i => {
        let value = this.FLSQ[i.key]
        if (value) {
          i.value = value;
          i.type = this.wx.findIndex(i => {
            return value.indexOf(i) != -1
          }) + 1;
        }
      })

      this.yunList.forEach((j, index) => {
        if (this.FLSQ.numberCarrier - 1 == index) {
          j.active = true;
        } else {
          j.active = false;
        }
        j.zhuke.forEach(i => {
          let v = this.FLSQ[i.key]
          i.value = v ? v.replace('运', '') : ""
        })
      })

      this.qiList.forEach((j, index) => {
        if (this.FLSQ.numberGas - 1 == index) {
          j.active = true;
        } else {
          j.active = false;
        }
        j.zhuke.forEach(i => {
          i.value = this.FLSQ[i.key]
        })
      })

    },
    closeLoading() {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          this.loading = false;
          resolve();
        }, 1000);
      });
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    async getData() {
      await this.$store.dispatch("FACE_COLOR");
      await this.$store.dispatch("BODY_SHAPE");
    }
  },
  created() {
    this.patientBirthday = this.info.patientBirthday
    this.setDate();
    this.showWin = this.show;
    this.getData();
    this.setFlsq();
  }
};
</script>

<style lang="scss">
.luck {
  .back {
    padding: 0;
  }

  .detail {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;


    .detail-info {
      flex: 1;
    }

    .detail-pic {
      width: 430px;
      padding: 0 10px 0 20px;
      box-sizing: border-box;

      .luck-pic {
        margin-bottom: 30px;
      }
    }

    .hander2 {
      display: flex;
      justify-content: space-between;

      .ivu-tag {
        display: block;
        text-align: center;
        margin-bottom: 10px;

        &.ivu-tag-warning {
          background: #ff990022 !important;
        }

        &.ivu-tag-error {
          background: #ed401422 !important;
        }

        &.ivu-tag-success {
          background: #19be6b22 !important;
        }
      }

      .rq-info {
        margin-right: 15px;
        width: 140px;

        .yl {
          font-size: 16px;
          font-weight: bold;

          i {
            font-size: 22px;
            padding-right: 5px;
          }

          span {
            border-bottom: 1px solid #853a2f;
            cursor: pointer;
          }
        }

        .nl {
          font-weight: bold;
          padding: 20px 35px;
          line-height: 30px;
          font-size: 16px;
        }
      }

      .list-item {

        .label {
          margin-bottom: 15px;
        }
      }

      .lr {
        writing-mode: vertical-lr;
        letter-spacing: 2px;
      }

      .ivu-tag,
      .yl {
        writing-mode: initial;
      }

      .list {
        display: flex;
      }

      .kzjl {
        display: flex;
      }
    }

    .ivu-radio-group {
      margin-bottom: 15px;

      .ivu-radio {
        display: none;
      }

      .ivu-radio-wrapper-checked {
        background: #853a2f;
        border-color: #853a2f;
        color: #fff;
        position: relative;

        &::after {
          content: " ";
          border: 8px solid transparent;
          border-top-color: #853a2f;
          position: absolute;
          bottom: -16px;
          left: 50%;
          margin-left: -8px;
        }
      }
    }

    .tab-body {

      .title {
        padding: 10px 0;
        overflow: hidden;
        display: flex;
        align-items: flex-end;

        .label {
          // padding-right: 3px;
        }

        .other {
          font-size: 12px;
        }
      }

      .card {
        margin-bottom: 10px;

        .card-header {
          background: #f7eddb;
          font-size: 13px;
          padding: 2px 15px;
          border-radius: 5px 5px 0 0;
          display: flex;
          justify-content: space-between;

          .card-h-other {
            font-size: 12px;
          }
        }

        .card-body {
          font-weight: bold;
          background: #fffbee;
          border-radius: 0 0 5px 5px;
          padding: 15px;
          line-height: 1.8em;
        }
      }
    }

  }

}
</style>