var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab-body"},[_c('div',{staticClass:"title"},[_c('strong',{staticClass:"label"},[_vm._v(_vm._s(_vm.info.constitutionCategory))]),_c('div',{staticClass:"other"},[_vm._v("（总体体质）")])]),_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_vm._v(" "+_vm._s(_vm.info.formFeature)+" ")])]),_c('div',{staticClass:"card"},[_vm._m(1),_c('div',{staticClass:"card-body"},[_vm._v(" "+_vm._s(_vm.info.incidenceTendency)+" ")])]),_c('div',{staticClass:"card"},[_vm._m(2),_c('div',{staticClass:"card-body"},[_vm._v(" "+_vm._s(_vm.info.externalAdaptability)+" ")])]),_c('div',{staticClass:"card"},[_vm._m(3),_c('div',{staticClass:"card-body"},[_vm._v(" "+_vm._s(_vm.info.constitutionMainDisease)+" ")])]),_c('div',{staticClass:"card"},[_vm._m(4),_c('div',{staticClass:"card-body"},[_vm._v(" "+_vm._s(_vm.info.constitutionSubDisease)+" ")])]),_c('div',{staticClass:"title"},[_c('strong',{staticClass:"label"},[_vm._v(_vm._s(_vm.info.diseaseNature))]),_c('div',{staticClass:"other"},[_vm._v("（邪气性质）")])]),_c('div',{staticClass:"card"},[_vm._m(5),_c('div',{staticClass:"card-body"},[_vm._v(" "+_vm._s(_vm.info.natureMainDisease)+" ")])]),_c('div',{staticClass:"card"},[_vm._m(6),_c('div',{staticClass:"card-body"},[_vm._v(" "+_vm._s(_vm.info.natureSubDisease)+" ")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('div',{staticClass:"card-h-title"},[_vm._v("形体特征")]),_c('div',{staticClass:"card-h-other"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('div',{staticClass:"card-h-title"},[_vm._v("发病趋势")]),_c('div',{staticClass:"card-h-other"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('div',{staticClass:"card-h-title"},[_vm._v("对外界环境适应能力")]),_c('div',{staticClass:"card-h-other"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('div',{staticClass:"card-h-title"},[_vm._v("身体常见表现（主项）")]),_c('div',{staticClass:"card-h-other"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('div',{staticClass:"card-h-title"},[_vm._v("身体常见表现（副项）")]),_c('div',{staticClass:"card-h-other"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('div',{staticClass:"card-h-title"},[_vm._v("共同症状（主症）")]),_c('div',{staticClass:"card-h-other"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('div',{staticClass:"card-h-title"},[_vm._v("临床症状（兼症）")]),_c('div',{staticClass:"card-h-other"})])
}]

export { render, staticRenderFns }