<template>
  <div class="prescription_input prescription">
    <div class="bz_yk ucard">
      <div class="ucard_hander">
        <span class="ucard_title">拟定处方</span>
        <div class="ucard_title_right">
          <template v-if="pageType">
            <Button size="small" v-if="!isRecall" class="recallBtn" @click="zaohui(1)">召回病历</Button>
            <!-- <Button v-uEdition="'YZ-03'" size="small" @click="etiologicalAnalysisShow = !etiologicalAnalysisShow">
              <Icon type="ios-arrow-back" />病因分析
            </Button> -->
            <span v-if="!spinShow">
              <Dropdown @on-click="clikMenu">
                <Button size="small" icon="md-print">打印</Button>
                <DropdownMenu slot="list">
                  <DropdownItem name="1">病历</DropdownItem>
                  <DropdownItem name="2">饮食宜忌</DropdownItem>
                </DropdownMenu>
              </Dropdown>
              <!-- <Button @click="printWin = true" size="small">
                <Icon type="ios-print" />病历打印
              </Button> -->
            </span>
          </template>

          <template v-if="pageType">
            <Button v-if="!spinShow" size="small" :loading="indexSpin.show" @click="submitPrescription"
              type="primary">提交处方</Button>
          </template>
          <Button v-else size="small" :loading="indexSpin.show" @click="submitPrescription" type="primary">提交处方</Button>
        </div>
      </div>
      <drug-input v-if="showDrugInput" :drugtaboo="defaultDrugtaboo" @change="saveData" @tabooChange="tabooChange"
        :prescriptionInfo="prescription" :insertDrugFun="loadCf" :pageType="pageType" ref="drugInput" :info="info" />

      <div class="u_medical_advice" v-else>
        <div class="cf_content">
          <div class="load_tip auto-prescr-error " v-if="autoPrescriptionError">
            <div class="title">自动开方出错：</div> 
            <div class="content">{{autoPrescriptionError}}</div>
            <div class="content">
              <Button type="primary" size="small" @click="zaohui">召回重新提交试试</Button>
            </div>
          </div>
          <div class="load_tip" v-else>
            <img src="@/assets/images/wz.png" alt />
            <uSpin />
            <!-- <div class="tip">等待socket消息中</div> -->
          </div>
        </div>
      </div>
      
    </div>

    <etiologicalAnalysis :recall="zaohui" :info="info" v-uEdition="'YZ-03'"
      v-if="pageType && etiologicalAnalysisShow && info && info.prescriptionNo"
      @close="etiologicalAnalysisShow = false" />

    <!-- 病历打印 -->
    <medicalRecordPrint v-if="info.prescriptionNo" v-model="printWin" :drugList="drugListFormat" :info="info"
      :taboo="drugtaboo" :quantity="prescription.Quantity" />

  </div>
</template>

<script>
import print from "./print";
import etiologicalAnalysis from "@/com/etiologicalAnalysis";
import { mapGetters } from "vuex";
import DrugTable from "./drugInput/drugTable.vue";
import DrugInput from "./drugInput/drugInput.vue";
export default {
  props: {
    info: {
      type: Object,
      default: () => {
        return {};
      },
    },
    quoteDurgList: {
      type: Array,
      default: () => [],
    },
    //页面类型  true:前端医生 false:后端医生
    pageType: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    etiologicalAnalysis,
    print,
    DrugTable,
    DrugInput,
  },
  data() {
    return {
      showDrugInput: false,
      printWin: false,
      prescribeByBillNoList: [], //合作医生获取徐氏医生开方信息
      sendMessageSecond: 1, //接口请求次数
      etiologicalAnalysisShow: true,
      photoReminder: false, //病历拍照提醒
      confirm: false,
      drugtaboo: "",
      defaultDrugtaboo: "",
      state: 0, // 状态(0开方未结束 1开方已结束)

      // 开方信息
      prescription: {
        Quantity: 1,
        drugList: [],
        returnVisitDate:"", //应复诊时间
      },


      autoPrescriptionError: "", //自动开方错误
    };
  },
  computed: {
    ...mapGetters([
      "indexSpin",
      "prescribeRegisterList", // 就诊中及未就诊挂号(处方单)
      "userSystemConfig",
      "keyInfo",
      "drug",
      "spinShow", //处方加载动画开关
      "isRecall",
      "savePrescription",
      "bnf", //百年方Drug_Code
      "isAnalysisList",
    ]),
    drugListFormat() {
      return this.prescription.drugList.map((i) => {
        return {
          amount: i.Amount,
          discountRate: i.discountRate,
          drugCode: i.Drug_Code,
          drugDecoctPattern: i.drugDecoctPattern,
          drugName: i.Drug_Name,
          drugTakePattern: i.drugTakePattern,
          // drugType: this.bnf.includes(i.Drug_Code) ? "06" : "01",
          drugType: i.Drug_Type,
          quantity: i.Quantity,
          salesPrice: 0,
          specifications: i.specifications,
          unit: i.unit,
        };
      });
    },
  },
  watch: {
    quoteDurgList(value) {
      if (value.length) {
        this.loadCf(value);
      }
    },
    info(value) {
      this.init();
    },
  },
  methods: {
    clikMenu(name) {
      switch (name) {
        case "1":
          this.printWin = true
          break;
        case "2":
          this.$refs.drugInput.tabooPrint();
          break;
      }
    },
    tabooChange(taboo) {
      this.drugtaboo = taboo;
    },
    // 前端医生获取处方
    getPrescribeByBillNoList() {
      let billNo = this.info.prescriptionNo; // 处方ID
      this.state = billNo ? 1 : 0;

      if (!billNo) {
        return;
      }
      let registerCode = this.info.registerCode;
      this.$store.commit("addResultDirectReturn", registerCode); //添加10秒倒计时
      this.api2("getPrescribeByBillNoList", { billNo }).then((res) => {
        this.$store.commit("sendLog", {
          apiName: "getPrescribeByBillNoList",
          remarks: "获取徐氏专家处方",
          inParams: { prescriptionNo: this.info.prescriptionNo },
          outParams: res,
        });
        this.$store.commit("delResultDirectReturn", registerCode); //删除超时后的回退操作
        if (!res.result[0].drugsList.length) {
          //如果没有药品列表
          if (this.sendMessageSecond > 3) {
            ViewUI.Modal.confirm({
              title: "提示",
              content: "获取处方失败,是否再获取一次?",
              onOk: () => {
                this.$store.commit("sendMessage_symptom", {
                  registerCode,
                  success: false,
                });
              },
              onCancel: () => { },
            });
          } else {
            this.$store.commit("sendMessage_symptom", {
              registerCode,
              success: false,
            });
          }
          this.sendMessageSecond++;
        } else {
          this.setPrescribe();
          this.$store.commit("setSpinShow", false);
          this.sendMessageSecond = 1;
          this.prescribeByBillNoList = res.result[0];
          this.drugtaboo = this.prescribeByBillNoList.drugtaboo || "";
          this.defaultDrugtaboo = this.drugtaboo;
        }
      }).finally(()=>{
      })
    },
    // 获取库存
    setPrescribe() {
      this.$store
        .dispatch("getDrugStockByHospitalCode")
        .then((res) => { })
        .catch((err) => {
          this.$Message.warning("药品库存获取失败");
        })
        .finally(() => {
          this.setPrescribe2();
          this.showDrugInput = true;
        });
    },
    async setPrescribe2() {
      this.prescription.drugList = [];
      let obj = this.prescribeByBillNoList;
      let item = this.savePrescription[this.info.registerCode];

      if (item && item.drugList.length) {
        this.prescription.Quantity = item.Quantity;
        this.prescription.drugList = item.drugList.map((i) => {
          let drugItem = this.drug.find((j) => j.Drug_Code == i.Drug_Code);
          if (drugItem) i.StockQty = drugItem.StockQty;
          return i;
        });
      } else {
        this.prescription.Quantity = obj.quantity;
        // 暂时手动改成 7帖
        this.prescription.Quantity = 7;
        obj.drugsList.forEach((i) => {
          let drugItem = this.drug.find((j) => j.Drug_Code == i.drugCode);
          if(drugItem){
            this.prescription.drugList.push({
              drugTaboo: [],
              error: false,
              flevel: 0,
              active: false,
              Drug_Code: i.drugCode,
              Drug_Id: i.drugId,
              Drug_Name: i.drugName,
              Sales_Price: i.salesPrice,
              specifications: drugItem
                ? drugItem.specifications
                : i.specifications,
              drugDecoctPattern: drugItem
                ? drugItem.drugDecoctPattern
                : i.drugDecoctPattern,
              drugTakePattern: drugItem
                ? drugItem.drugTakePattern
                : i.drugTakePattern,
              StockQty: drugItem ? drugItem.StockQty : 0, //库存
              UnitName: drugItem ? drugItem.UnitName : i.unit,
              Amount: i.amount,
              Quantity: ["06", "01"].includes(drugItem.Drug_Type) ? i.quantity : 1, //["06", "01"] 按帖数来 其它默认1帖
              discountRate: i.discountRate,
              Drug_Type: drugItem ? drugItem.Drug_Type : "",
              // delBtn: this.bnf.indexOf(i.drugCode) != -1 ? true : false //删除按钮
              delBtn: false, //删除按钮
            });
          }
        });

        if(this.$refs.drugInput){
          this.$refs.drugInput.setTaboos();
        }

        this.saveData();
      }
    },
    saveData() {
      if (this.prescription.drugList.length) {
        this.$store.commit("savePrescription", {
          key: this.info.registerCode,
          prescription: this.prescription,
        });
      }
    },
    zaohui(fstatus) {
      this.$Modal.confirm({
        title: "确定召回此病历吗？",
        content:
          "<p>每个病历限定召回三次</p><p>召回后可修改症状，需重新提交</p>",
        onOk: () => {
          this.$store.dispatch("updateRecallMedicalRecordSymptom", {
            state: this.state, // 	状态(0开方未结束 1开方已结束)
            returnNum: 1, //0 没有限制次数召回 1 只能召回1次
            registerCode: this.info.registerCode, //挂号ID
            symptomNo: this.info.symptomNo, //症状
          });
          this.$store.commit("delPrescription", this.info.registerCode);
        },
      });
    },
    // 引用自动开方的处方数据
    loadCf(list) {
      if (!list) return;
      this.prescription.drugList = [];
      list.forEach((x) => {
        var u_drug = this.drug.find((i) => i.Drug_Code == x.drugCode);
        if (u_drug) {
          var u_dr = this.common.clone(u_drug);
          u_dr.Amount = x.amount;
          u_dr.Quantity =
            u_drug.Drug_Type == "06" || u_drug.Drug_Type == "01"
              ? this.prescription.Quantity
              : 1;
          u_dr.active = false;
          u_dr.flevel = 0;
          u_dr.drugTaboo = [];
          u_dr.error = false;
          this.prescription.drugList.push(u_dr);
        }
      });
      this.saveData();
    },

    // 提交处方
    submitPrescription(e) {
      // 验证
      this.$refs.drugInput.validate().then((res) => {
        if (res) {
          this.updateMedicalRecord();
        }
      });
    },

    // 前端医生保存处方
    async updateMedicalRecord() {
      var drugList = [];
      this.prescription.drugList.forEach((i) => {
        if (!i.Drug_Type) {
          let item = this.drug.find((j) => j.Drug_Code == i.Drug_Code);
          if (item) i.Drug_Type = item.Drug_Type;
        }
        drugList.push({
          amount: i.Amount, //	数量
          drugCode: i.Drug_Code, //	药品编码
          drugName: i.Drug_Name, //	药品名称
          drugType: i.Drug_Type || "01", //	药品类型
          quantity: i.Quantity, //	贴数
          specifications: i.specifications, //	药品规格
          unit: i.UnitName, //	单位名称
          drugTakePattern: i.drugTakePattern, //
          StockQty: i.StockQty, //库存
          drugDecoctPattern: i.drugDecoctPattern, //
        });
      });

      var newData = {
        drugList,
        returnVisitDate: this.common.parseTime(this.prescription.returnVisitDate, "{y}-{m}-{d}"),
        quantity: this.prescription.Quantity,
        prescriptionNo: this.info.prescriptionNo, //前端医生用
        symptomNo: this.info.symptomNo, //专家医生用
        patientType: this.info.patientType, // 患者类型
      };

      this.pageType && Object.assign(newData, this.keyInfo);

      this.$store.commit("openSpin");
      let fun = this.pageType ? "updateMedicalRecord" : "addMedicalRecord";
      this.api2(fun, newData).then(async (res) => {
        this.$store.commit("removeTabooDiscriminate", this.info.registerCode);

        this.$store.commit("confirmN_tip", false);
        this.$Message.success(res.result);
        await this.$store.dispatch("getReloadOrderList");

        if (this.pageType) {
          //前端医生
          this.$store.commit("delPrescription", this.info.registerCode);
          if (this.userSystemConfig.printPrescription) {
            this.$store.commit("medicalRecordPringWin", {
              info: this.common.clone(this.info),
              quantity: this.prescription.Quantity,
              drugList: this.common.clone(this.drugListFormat),
              show: true,
            });
          } else {
            this.$store.commit("clickVisit");
          }

          this.$store.commit(
            "addMedicalRecordSymptom_remove",
            this.info.registerCode
          );
          this.$store.commit("sendLog", {
            apiName: "updateMedicalRecord",
            remarks: "合作医生保存处方 ",
            inParams: newData,
            outParams: res,
          });
        } else {
          //专家医生
          localStorage.removeItem("addDoctorRushBill");
          this.$router.push("/index");
        }

        // 清缓存数据
        sessionStorage.removeItem("listPatientMainSymptom_submit_" + this.info.registerCode)
        sessionStorage.removeItem("listSymptomEtiologyDialectical_" + this.info.registerCode)
        localStorage.removeItem("selectList_" + this.info.registerCode);
        sessionStorage.removeItem("autoPrescriptionError_" + this.info.registerCode)
        
      }).finally(() => {
        this.$store.commit("closeSpin");
      })
    },

    init() {
      // 是否自动开方报错
      this.autoPrescriptionError = sessionStorage.getItem("autoPrescriptionError_" + this.info.registerCode);

      let selectList = localStorage.getItem("selectList_" + this.info.registerCode)
      // 如果有病因分析过了默认就不打开分析页
      this.etiologicalAnalysisShow = this.isAnalysisList.find(i => i == this.info.registerCode) || !selectList ? false : true;

      this.$store.commit("setIsRecall", 0);
      this.$store.commit("setSpinShow", true);
      // 初始化处方信息
      this.prescription = {
        Quantity: 1,
        drugList: [],
      };
      this.sendMessageSecond = 1;
      this.$store.commit("initN_tip");
      if (this.pageType) {
        this.getPrescribeByBillNoList();
      } else {
        this.loadCf(this.prescribeRegisterList.drugList);
      }


    },
  },
  created() {
    this.init();
  },
};
</script>

<style lang="scss">
.prescription_input {
  height: 100%;

  .ucard {
    margin-bottom: 0;
    height: 100%;
    display: flex;
    flex-direction: column;

    .ucard_hander {
      background: rgba(133, 58, 47, 0.15);
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 5px 5px 0 0;
      padding: 8px 15px;

      .ucard_title {
        color: #853a2f;
        font-weight: bold;
      }

      .ucard_title_right {
        display: flex;

        &>* {
          margin-left: 10px;
        }
      }
    }

    .ucard_body {
      border: 1px solid #ddd;
      padding: 15px;
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      position: relative;

      .u_table2 {
        // flex: 1;
        // flex-shrink: 0;
        // min-height: 100px;
        flex-grow: 1;
        flex-shrink: 0;
        height: 350px;
      }
    }
  }
  .u_medical_advice{
    .tip{
      padding-top: 20px;
      opacity: 0.8;
    }
    .auto-prescr-error{
      .title{
        width: 500px;
        font-weight: bold;
        font-size: 16px;
      }
      .content{
        width: 500px;
        padding-bottom: 20px;
      }
    } 
  }
}
</style>
