<!-- 心率趋势分析 -->
<template>
    <div>
        <div class="section-time">{{ section.name }}</div>
        <div v-if="section.arr && section.arr.length > 0" class="section-list">
            <div v-for="(item, index) in section.arr" :key="index">
                <span>{{ item.value }}</span>
                <span>{{ item.name }}</span>
            </div>
        </div>
        <div class="search-box">
            <Form :model="heartForm" :label-width="100" inline>
                <FormItem label="最高基准心率">
                    <Input v-model="heartForm.maxHeart" type='number'></Input>
                </FormItem>
                <FormItem label="最低基准心率">
                    <Input v-model="heartForm.minHeart" type='number'></Input>
                </FormItem>
                <Button type="primary" @click="heartConfirm">确认</Button>
            </Form>
        </div>
        <div id="heartRateTrends" :class="className" :style="{ height: height, width: width }" />
    </div>
</template>
  
<script>
import * as echarts from "echarts";
import resize from './mixins/resize.js'
import { dayList, getNullValue, dataZoom } from '@/utils/tool.js'
export default {
    mixins: [resize],
    props: {
        className: {
            type: String,
            default: 'chart'
        },
        width: {
            type: String,
            default: '100%'
        },
        height: {
            type: String,
            default: '100%'
        },
        autoResize: {
            type: Boolean,
            default: true
        },
        heartData: {
            type: Object,
            default: {}
        },
        addTime: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            chart: null,
            heartForm: {
                maxHeart: '120',
                minHeart: '50'
            },
            section: []
        }
    },
    watch: {

    },
    created() {

    },
    mounted() {
        this.$nextTick(() => {
            this.initChart()
        })
    },
    beforeDestroy() {
        if (!this.chart) {
            return
        }
        this.chart.dispose()
        this.chart = null
    },
    methods: {
        initChart() {
            this.chart = echarts.init(document.getElementById('heartRateTrends'))
            this.setOptions()
        },
        setOptions() {
            let a = this
            var e = this.heartData.day
                , t = this.heartData.night
                , i = new Array(30).fill("")
                , s = new Array(30).fill("")
                , r = new Array(30).fill("")
                , n = new Array(30).fill("")
                , o = new Array(30).fill("")
                , l = new Array(30).fill("")
                , c = new Array(30).fill("")
                , d = new Array(30).fill("")
                , u = new Array(30).fill("");
            e && dayList(this.addTime).last16.forEach(function (t, a) {
                e.forEach(function (e, o) {
                    t === e.dataDate.split(" ")[0] && (s[a] = e.hrMax - e.hrMin,
                        i[a] = e.hrMax,
                        r[a] = e.hrMin,
                        n[a] = e.hrDay)
                })
            })
            t && (dayList(this.addTime).last16.forEach(function (e, a) {
                t.forEach(function (t, i) {
                    e === t.dataDate.split(" ")[0] && (l[a] = t.hrMax - t.hrMin,
                        o[a] = t.hrMax,
                        c[a] = t.hrMin,
                        d[a] = t.hrDay)
                })
            }));
            for (var p = 0; p < u.length; p++)
                n[p] && d[p] && (u[p] = n[p] - d[p]);
            let uesTemp = dayList(this.addTime).ecUse
            let dataTemp = dayList(this.addTime).ecShow
            this.chart.setOption({
                backgroundColor: "#fff",
                tooltip: {
                    trigger: "axis",
                    formatter: function (e) {
                        var t = e[0].dataIndex
                            , s = dataTemp[e[0].dataIndex].split("/")
                            , n = s[0] + "月" + s[1] + "日" + "<br>";
                        return e.forEach(function (e) {
                            "日间最高心率和最低心率区间" === e.seriesName && (n += e.marker + e.seriesName + "：" + getNullValue(i[t]) + "-" + getNullValue(r[t]) + "<br>"),
                                "夜间最高心率和最低心率区间" === e.seriesName && (n += e.marker + e.seriesName + "：" + getNullValue(o[t]) + "-" + getNullValue(c[t]) + "<br>"),
                                "夜间平均心率" === e.seriesName && (n += e.marker + e.seriesName + "：" + getNullValue(e.value) + "<br>"),
                                "日间平均心率" === e.seriesName && (n += e.marker + e.seriesName + "：" + getNullValue(e.value) + "<br>"),
                                "昼夜节律" === e.seriesName && (n += e.marker + e.seriesName + "：" + getNullValue(e.value))
                        }),
                            n
                    }
                },
                toolbox: {
                    show: !0,
                    top: 15,
                    feature: {
                        dataZoom: {
                            yAxisIndex: "none",
                            title: {
                                back: "还原"
                            }
                        }
                    }
                },
                legend: {
                    data: ["日间最高心率和最低心率区间", "夜间最高心率和最低心率区间", "日间平均心率", "夜间平均心率", "昼夜节律"],
                    selected: {
                        "夜间最高心率和最低心率区间": !1,
                        "日间最高心率和最低心率区间": !1
                    }
                },
                grid: {
                    y: 50,
                    x2: 0,
                    y2: 0,
                    x: 0,
                    containLabel: !0
                },
                xAxis: {
                    type: "category",
                    data: uesTemp,
                    axisLabel: {
                        interval: 0,
                        color: "#999"
                    },
                    axisLine: {
                        lineStyle: {
                            color: "#e7e5e4"
                        }
                    },
                    axisTick: {
                        show: !1
                    },
                    splitArea: {
                        show: !1
                    }
                },
                yAxis: [{
                    type: "value",
                    max: 170,
                    min: 30,
                    interval: 20,
                    axisTick: {
                        show: !1
                    },
                    axisLine: {
                        show: !1
                    },
                    axisLabel: {
                        margin: 10,
                        color: "#999"
                    },
                    splitLine: {
                        lineStyle: {
                            type: "solid"
                        }
                    }
                }, {
                    type: "value",
                    position: "right",
                    max: 50,
                    min: -20,
                    scale: !1,
                    axisTick: {
                        show: !1
                    },
                    axisLine: {
                        show: !1
                    },
                    axisLabel: {
                        margin: 10,
                        color: "#999"
                    },
                    splitLine: {
                        show: !1,
                        lineStyle: {
                            type: "solid"
                        }
                    }
                }],
                series: [{
                    name: "日间最低",
                    type: "bar",
                    stack: "总量",
                    barWidth: 5,
                    color: "transparent",
                    data: r
                }, {
                    name: "日间最高心率和最低心率区间",
                    type: "bar",
                    barWidth: 5,
                    stack: "总量",
                    itemStyle: {
                        color: "#B2A58A"
                    },
                    data: s
                }, {
                    name: "夜间最低",
                    type: "bar",
                    stack: "总量2",
                    barWidth: 5,
                    color: "transparent",
                    data: c
                }, {
                    name: "夜间最高心率和最低心率区间",
                    type: "bar",
                    stack: "总量2",
                    data: l,
                    barWidth: 5,
                    itemStyle: {
                        color: "#6B8DDC"
                    }
                }, {
                    name: "日间平均心率",
                    type: "line",
                    smooth: !0,
                    itemStyle: {
                        color: "#B2A58A"
                    },
                    data: n
                }, {
                    name: "夜间平均心率",
                    type: "line",
                    smooth: !0,
                    itemStyle: {
                        color: "#6B8DDC"
                    },
                    data: d
                }, {
                    type: "line",
                    name: "昼夜节律",
                    smooth: !0,
                    yAxisIndex: 1,
                    data: u,
                    itemStyle: {
                        color: "#B08DF1"
                    }
                }, {
                    name: "背景板",
                    type: "line",
                    markLine: {
                        silent: !0,
                        animation: !1,
                        symbol: ["none", "none"],
                        label: {
                            show: !1
                        },
                        lineStyle: {
                            color: "green"
                        },
                        data: [{
                            yAxis: this.heartForm.minHeart
                        }, {
                            yAxis: this.heartForm.maxHeart
                        }]
                    }
                }]
            })

            this.section = []
            dataZoom({
                echart: this.chart,
                series: [{
                    name: "日间最高心率和最低心率区间",
                    data: [i, r],
                    section: !0
                }, {
                    name: "夜间最高心率和最低心率区间",
                    data: [o, c],
                    section: !0
                }, {
                    name: "日间平均心率",
                    data: n
                }, {
                    name: "夜间平均心率",
                    data: d
                }, {
                    name: "昼夜节律",
                    data: u
                }],
                addTime: this.addTime,
                sectionArr: function (e) {
                    a.section = e
                }
            })
        },
        heartConfirm(){
            if(Number(this.heartForm.maxHeart)>170){
                this.$Message.warning('最高心率基准不得高于170')
                return
            }
            if(Number(this.heartForm.minHeart) < 30){
                this.$Message.warning('最低心率基准不得低于30')
                return
            }
            if(Number(this.heartForm.maxHeart) < Number(this.heartForm.minHeart)){
                this.$Message.warning('最高心率基准不得低于最低心率基准')
                return
            }
            this.setOptions()
        }
    }
}
</script>
<style lang="scss" scoped>
.search-box {
    position: absolute;
    top: -60px;
    right: 0;
    z-index: 99;

    ::v-deep .ivu-form {
        .ivu-form-item-label {
            padding-top: 8px;
        }

        input {
            width: 100px;
        }
    }
}

.section-time {
    position: absolute;
    // top: -60px;
    // line-height: 32px;
    right: 0;
    font-size: 16px;
    color: #000;
}

.section-list {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;

    div {
        border-right: 1px solid #e9e9e9;
        padding: 0 40px;

        &:last-child {
            border: none;
        }

        span {
            display: block;
            text-align: center;

            &:first-child {
                font-size: 18px;
                font-weight: 700;
                margin-bottom: 10px;
            }

            &:last-child {
                font-size: 14px;
                color: #7f7f7f;
            }
        }
    }
}
</style>