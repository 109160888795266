import SoftKey3W from "@/Syunew3W.js";

function softkey() {
    this.bConnect = 0;
    this.KeyID = "";
    this.UserName = "";
    this.return_EncData = "";
    this.jsq = null;
    this.vue = null;
    this.setLeader = (vue) => {
        this.vue = vue
    };
    this.mylogin_onclick = (name) => {
        return new Promise((resolve, reject) => {
            if (this.bConnect == 0) {
                // this.vue.$Message.error("未能连接服务程序，请确定服务程序是否安装。");
                console.log("未能连接服务程序，请确定服务程序是否安装。");
                resolve(false);
            }

            try {
                var DevicePath, mylen, ret;
                var s_simnew1 = new SoftKey3W(); //创建UK类
                s_simnew1.Socket_UK.onopen = function() {
                    s_simnew1.ResetOrder(); //这里调用ResetOrder将计数清零，这样，消息处理处就会收到0序号的消息，通过计数及序号的方式，从而生产流程
                };
                s_simnew1.Socket_UK.onmessage = (Msg) => {
                    var UK_Data = JSON.parse(Msg.data);
                    if (UK_Data.type != "Process") return; //如果不是流程处理消息，则跳过
                    switch (UK_Data.order) {
                        case 0:
                            {
                                s_simnew1.FindPort(0); //查找灵兰密钥
                            }
                            break;
                        case 1:
                            {
                                if (UK_Data.LastError != 0) {
                                    //this.vue.$Message.warning("请插入灵兰密钥");
                                    console.log("请插入灵兰密钥");
                                    s_simnew1.Socket_UK.close();
                                    resolve(false);
                                    return false;
                                }
                                DevicePath = UK_Data.return_value; //获得返回的UK的路径
                                s_simnew1.GetChipID(DevicePath); //返回芯片唯一ID
                            }
                            break;
                        case 2:
                            {
                                if (UK_Data.LastError != 0) {
                                    this.vue.$Message.warning(
                                        "返回灵兰密钥ID时出现错误，错误码为：" +
                                        UK_Data.LastError.toString()
                                    );
                                    s_simnew1.Socket_UK.close();
                                    resolve(false);
                                    return false;
                                }
                                this.KeyID = UK_Data.return_value;
                                //从锁中取出用户身份，与签名后的数据一并送到服务端进行验证。以验证用户身份
                                s_simnew1.GetSm2UserName(DevicePath);
                            }
                            break;
                        case 3:
                            {
                                if (UK_Data.LastError != 0) {
                                    this.vue.$Message.warning(
                                        "返回用户身份时出现错误，错误码为：" +
                                        UK_Data.LastError.toString()
                                    );
                                    s_simnew1.Socket_UK.close();
                                    resolve(false);
                                    return false;
                                }
                                this.UserName = UK_Data.return_value;
                                //使用默认的PIN码
                                let Pin = "123";
                                //这里使用锁中的私钥对身份-用户名及消息-随机数的进行数字签名,使用默认的PIN码，返回签名后的数据
                                s_simnew1.YtSign(name, Pin, DevicePath);
                            }
                            break;
                        case 4:
                            {
                                if (UK_Data.LastError != 0) {
                                    this.vue.$Message.warning(
                                        "进行签名时出现错误，错误码为：" +
                                        UK_Data.LastError.toString()
                                    );
                                    s_simnew1.Socket_UK.close();
                                    resolve(false);
                                    return false;
                                }
                                this.return_EncData = UK_Data.return_value;
                                this.vue.$store.commit("connect", true)
                                this.vue.$store.commit("setKeyInfo", {
                                    frontChipId: this.KeyID, //加密狗芯片唯一ID
                                    frontVerfiySign: this.return_EncData, //前端加密后的值
                                    sm2UserName: this.UserName, //用户名
                                });
                                //所有工作处理完成后，关掉Socket
                                s_simnew1.Socket_UK.close();
                                resolve(true);
                            }
                            break;
                    }
                };
                s_simnew1.Socket_UK.onclose = function() {};
            } catch (e) {
                this.vue.$Message.error(
                    e.name + ": " + e.message + "。可能是没有安装相应的控件或插件"
                );
                resolve(false);
            }
        });
    };
    this.myonLoad = () => {
        try {
            var s_pnp = new SoftKey3W();
            s_pnp.Socket_UK.onopen = () => {
                this.bConnect = 1; //代表已经连接，用于判断是否安装了客户端服务
                s_pnp.ResetOrder(); //这里调用ResetOrder将计数清零，这样，消息处理处就会收到0序号的消息，通过计数及序号的方式，从而生产流程
            };
            //在使用事件插拨时，注意，一定不要关掉Sockey，否则无法监测事件插拨
            s_pnp.Socket_UK.onmessage = (Msg) => {
                var UK_Data = JSON.parse(Msg.data);
                if (UK_Data.type == "PnpEvent") {
                    if (UK_Data.IsIn) {
                        clearTimeout(this.jsq);
                        this.jsq = setTimeout(() => {
                            this.vue.$Message.info("灵兰密钥已被插入");
                            this.vue.$store.commit("connect", true)
                        }, 500)
                    } else {
                        clearTimeout(this.jsq);
                        this.jsq = setTimeout(() => {
                            this.vue.$Message.info("灵兰密钥已被拨出");
                            this.vue.$store.commit("connect", false)
                                //console.log("锁的路径是：" + UK_Data.DevicePath);
                        }, 500)
                    }
                }

                if (UK_Data.type != "Process") return; //如果不是流程处理消息，则跳过
                switch (UK_Data.order) {
                    case 0:
                        {
                            s_pnp.FindPort(0); //查找灵兰密钥
                        }
                        break;
                    case 1:
                        {
                            if (UK_Data.LastError != 0) {
                                // this.vue.$Message.warning("请插入灵兰密钥");
                                console.log("请插入灵兰密钥");
                                return false;
                            }
                            this.vue.$store.commit("connect", true)
                        }
                        break;
                }

            };
            s_pnp.Socket_UK.onclose = () => {
                this.vue.$store.commit("connect", false)
            };
        } catch (e) {
            this.vue.$Message.error(e.name + ": " + e.message);
            return false;
        }
    };
};

export default (() => {
    let _instance = null;
    return {
        getInstance: () => {
            if (!_instance) {
                _instance = new softkey();
            }
            return _instance;
        }
    }
})();