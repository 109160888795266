<template>
	<!-- 处方录入 -->
	<div class="hobby" @click="openHobbyWin">
		<div v-if="hobbyString">{{ hobbyString }}</div>
		<div v-else class="placeholder">{{placeholder}}</div>	
		<template v-if="!readonly">
			<Modal footer-hide class="patientInfo1 patientInfo1_xh" v-model="showAddTag" title="常见喜好">
				<div class="p_title">常见喜好</div>
				<div class="p_con">
					<CheckboxGroup v-model="selectLike">
						<Checkbox v-for="(item, index) in Hobby" :key="index" :label="item.label"></Checkbox>
					</CheckboxGroup>
				</div>
				<div class="p_title">其他喜好</div>
				<div class="p_con p_con_tag">
					<Tag color="primary" v-for="(item, index) in addTagList" :key="index" :name="item" closable
						@on-close="handleClose2">{{ item }}</Tag>
					<Input autofocus :maxlength="10" @on-enter="addTag" v-if="showInput" placeholder="输入喜好" v-model="tagName"
						size="small"></Input>
					<span class="addTagBtn" @click="showInput = true">
						<Icon type="md-add" />
					</span>
				</div>
				<div class="p_tip">（请用不超过10个字符描述其他喜好，按回车键添加）</div>
				<div class="p_btn_g">
					<Button type="primary" @click="addPatientHobby">保存</Button>
					<Button @click="showAddTag = false">取消</Button>
				</div>
			</Modal>
		</template>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	props: {
		// patientCode: {
		// 	type: String,
		// 	default: ''
		// },
		value: {
			type: Object,
			default: () => { }
		},
		readonly: {
			type: Boolean,
			default: false
		},
		placeholder: {
			type: String,
			default: ''
		}
	},
	watch: {
		value: {
			handler() {
				this.init();
			},
			deep: true,
		}
	},
	data() {
		return {
			tagName: "",
			showAddTag: false,
			hobbyString: "",
			selectLike: [],
			addTagList: [],
			showInput: false,
		}
	},
	computed: {
		...mapGetters([
			"Hobby",
		]),
	},
	methods: {
		addPatientHobby() {
			let list = this.Hobby.filter(i => this.selectLike.includes(i.label));
			let list2 = list.map(i => {
				return {
					hobbyId: i.value,
					hobbyName: i.label,
					hobbyType: 0,
					patientCode: this.value.patientCode
				};
			});
			let list3 = this.addTagList.map(i => {
				return {
					hobbyId: "",
					hobbyName: i,
					hobbyType: 1,
					patientCode: this.value.patientCode
				};
			});
			let patientHobbyList = list2.concat(list3);
			this.$store.commit('openSpin')
			this.api2("addPatientHobby", {
				patientHobbyList,
				patientCode: this.value.patientCode
			}).then(res => {
				this.$store.commit('closeSpin')
				this.$Message.success(res.result);
				this.showAddTag = false;

				// 更新界面
				this.value.commonHobbyIds = list2.length
					? list2.map(i => i.hobbyId).join(",") + ","
					: "";
				this.value.otherHobbyNames = list3.length
					? list3.map(i => i.hobbyName).join(",") + ","
					: "";

				// 更新缓存
				this.$store.commit("updataPrescribeRegisterList", {
					patientCode: this.value.patientCode,
					commonHobbyIds: this.value.commonHobbyIds,
					otherHobbyNames: this.value.otherHobbyNames
				});

				this.$emit('input', this.value);
			});
		},
		handleClose2(event, name) {
			const index = this.addTagList.indexOf(name);
			this.addTagList.splice(index, 1);
		},
		openHobbyWin() {
			this.formatData();
			this.showAddTag = true;
		},
		addTag() {
			if (this.Hobby.find(i => i.label == this.tagName)) {
				this.selectLike.push(this.tagName);
				this.$Message.info("常见喜好里已有该喜好，已经自动勾选上了哦");
			} else {
				this.addTagList.push(this.tagName);
			}
			this.tagName = "";
		},
		formatData() {
			if (this.value.otherHobbyNames) {
				this.addTagList = this.value.otherHobbyNames
					.substr(0, this.value.otherHobbyNames.length - 1)
					.split(",");
			} else {
				this.addTagList = [];
			}
			if (this.value.commonHobbyIds) {
				let ids = this.value.commonHobbyIds
					.substr(0, this.value.commonHobbyIds.length - 1)
					.split(",");
				this.selectLike = this.Hobby.filter(i => ids.includes(i.value)).map(
					i => i.label
				);
			} else {
				this.selectLike = [];
			}

			let arr = this.selectLike.concat(this.addTagList);
			this.hobbyString = arr.join(", ");
		},
		async init() {
			await this.$store.dispatch("Hobby");
			this.formatData();
		},
	},
	created() {
		this.init();
	},
}
</script>

<style lang="scss" scoped>
.hobby {
	min-height: 26px;
	cursor: pointer;
}
</style>