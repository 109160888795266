<template>
  <!-- 处方笺打印的内容 -->
  <div ref="print1" class="u_con medicalRecord" :class="{ medicalRecord_hide: hide }">
    <div class="record">
      <div class="head_top no-print">
        <span class="han_rn">就诊日期：{{ prescriptionHead.addedTime }}</span>
        <span class="han_rn">处方编号：{{ prescriptionHead.prescriptionNo }}</span>
      </div>
      <div class="print_page cfj_1">
        <div class="hospitalName">{{ userInfo.hosptialCode == "30" ? "上饶信州徐氏中医商贸城门诊部" : prescriptionHead.hospitalName }}</div>
        <!-- <div class="hospitalName">{{ prescriptionHead.hospitalName }}</div> -->
        <div class="cfq_title">
          处方笺
        </div>
        <div class="rec_head sj">
          <div class="rec_right">
            <div class="rec_han">
              <div>
                <span class="han_title">就诊日期：</span>
                <span class="han_rn">{{ prescriptionHead.addedTime }}</span>
              </div>
            </div>
            <div class="rec_han">
              <div>
                <span class="han_title">处方编号：</span>
                <span class="han_rn">{{
                  prescriptionHead.prescriptionNo
                }}</span>
              </div>
            </div>
            <div class="rec_han">
              <div>
                <span class="han_title">姓名：</span>
                <span class="han_rn">{{ prescriptionHead.patientName }}</span>
              </div>
              <div>
                <span class="han_title">年龄：</span>
                <span class="han_rn">{{ prescriptionHead.patientAge }}</span>
              </div>
            </div>
            <div class="rec_han">
              <div>
                <span class="han_title">性别：</span>
                <span class="han_rn">{{ prescriptionHead.genderDisp }}</span>
              </div>
              <div>
                <span class="han_title">婚否：</span>
                <span class="han_rn">{{
                  prescriptionHead.maritalStatusDisp
                }}</span>
              </div>
            </div>
            <div class="rec_han">
              <div>
                <span class="han_title">主诉：</span>
                <span class="han_rn">{{
                  prescriptionHead.diagnosticName1
                }}</span>
              </div>
            </div>
            <div class="rec_han">
              <div>
                <span class="han_title">中医诊断：</span>
                <span class="han_rn">{{
                  prescriptionHead.diagnosticName2
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="rec_head pc">
          <div class="rec_right">
            <div class="rec_han v_hide">
              <div>
                <span class="han_title">就诊日期：</span>
                <span class="han_rn">{{ prescriptionHead.addedTime }}</span>
              </div>
              <div>
                <span class="han_title">处方编号：</span>
                <span class="han_rn">{{
                  prescriptionHead.prescriptionNo
                }}</span>
              </div>
            </div>
            <div class="rec_han">
              <div>
                <span class="han_title">姓名：</span>
                <span class="han_rn">{{ prescriptionHead.patientName }}</span>
              </div>
              <div>
                <span class="han_title">年龄：</span>
                <span class="han_rn">{{ prescriptionHead.patientAge }}</span>
              </div>
              <div>
                <span class="han_title">性别：</span>
                <span class="han_rn">{{ prescriptionHead.genderDisp }}</span>
              </div>
              <div>
                <span class="han_title">婚否：</span>
                <span class="han_rn">{{
                  prescriptionHead.maritalStatusDisp
                }}</span>
              </div>
            </div>
            <div class="rec_han">
              <div>
                <span class="han_title">主诉：</span>
                <span class="han_rn">{{
                  prescriptionHead.diagnosticName1
                }}</span>
              </div>
              <div>
                <span class="han_title">中医诊断：</span>
                <span class="han_rn">{{
                  prescriptionHead.diagnosticName2
                }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="rec_zz no-print">
          <div class="zz_title">症状：</div>
          <div class="zz_nr">{{ common.trim(prescriptionHead.dsName) }}</div>
          <div class="zz_title">专家拟定处方：</div>
          <div class="zz_nr zjcf">
            <drugsFormat :list="medicalRecordDetailByBillNo.xuShiPrescription" :size="3"></drugsFormat>
          </div>
        </div>
        <div class="rec_zz no-print" v-if="prescriptionHead.chiefComplaint">
          <div class="zz_title">患者自述：</div>
          <div class="zz_nr">{{ prescriptionHead.chiefComplaint }}</div>
        </div>
        <div class="rec_zz xszy no-print">
          <div class="zz_title">
            处方：
            <span>说明：<>内是服用方式，()内是煎药方式</span>
          </div>
          <div class="zz_nr">
            <drugsFormat :list="frontPrescription" :size="3"></drugsFormat>
          </div>
          <div class="rec_ts">X{{ quantity || prescriptionHead.quantity }}</div>
        </div>
        <div class="rec_zz xszy xszy2">
          <div class="zz_title">处方：</div>
          <div class="zz_nr">
            <drugsFormat :list="frontPrescription" :size="3"></drugsFormat>
          </div>
          <div class="rec_ts">X{{ quantity || prescriptionHead.quantity }}</div>

          
          <div class="syff">
            <div>用法：水煎内服，一日一剂，取汁400ml上午下午分二次服</div>
          </div> 
          <!-- <div v-if="showCode" class="awm">
            <img class="awm-img" :src="codeUrl" alt="" />
            <div class="tip">微信扫码支付</div>
          </div> -->
        </div>
        <div class="u_sign">
          <div class>
            <div class="rec_han">
              <div>
                <span class="han_title">医生：</span>
                <span class="han_rn"></span>
              </div>
              <!-- <div>
                <span class="han_title">药师：</span>
                <span class="han_rn"></span>
              </div> -->
              <div>
                <span class="han_title">调配：</span>
                <span class="han_rn"></span>
              </div>
              <div>
                <span class="han_title">复核：</span>
                <span class="han_rn"></span>
              </div>
              <div>
                <span class="han_title">金额：</span>
                <span class="han_rn"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="drugDecoctPattern.length" class="print_page2">
        <div class="rec_zz xszy xszy3">
          <div class="zz_title">
            煎药方式：
            <span>说明：未列出的药材，按普通方法煎煮即可</span>
          </div>
          <div class="zz_nr">
            <drugsFormat :list="drugDecoctPattern" :size="2"></drugsFormat>
          </div>
          <div class="zz_nr2">
            <div class="s_item">
              <span>先煎：放入其他药前，先行煎煮</span>
              <span>另煎：另外单独煎好后，倒入已煎好的药中一起喝</span>
            </div>
            <div class="s_item">
              <span>捣碎：捣碎后再煎煮</span>
              <span>包煎：拿纱布包好后再煎煮</span>
            </div>
            <div class="s_item">
              <span>后下：其他药煎好前5-10分钟放入锅中煎煮</span>
              <span>烊化：捣碎或打粉后，加入其他药材中煎煮</span>
            </div>
          </div>
        </div>
        <!-- 禁忌和医嘱内容 -->
        <!-- <div class="rec_zz">
            <div class="zz_title">医嘱：</div>
            <div class="zz_nr">
              {{prescriptionHead.drugtaboo?prescriptionHead.drugtaboo+'。 ':''}}
              {{prescriptionHead.adoreremark?prescriptionHead.adoreremark:''}}
            </div>
            <div class="zz_nr">{{prescriptionHead.doctorsAdvice}}</div>
        </div>-->
      </div>

      <div class="rec_zz time_log no-print">
        <div class="zz_nr">提交症状:{{ prescriptionHead.addedTime }}</div>
        <div class="zz_nr" v-if="expert">
          接诊时间：{{ prescriptionHead.rushDate }}
        </div>
        <div class="zz_nr">
          拟方时间:{{ prescriptionHead.rushCompleteDate }}
        </div>
        <div class="zz_nr">完成开方:{{ prescriptionHead.visitDateTime }}</div>
      </div>
    </div>
    <Spin v-if="loading" fix>
      <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
      <div>加载中</div>
    </Spin>
  </div>
</template>

<script>
import QRCode from "qrcode";
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  props: {
    info: {
      type: Object,
      default: function () {
        return {};
      },
    },
    value: {
      type: Boolean,
      default: false,
    },
    hide: {
      type: Boolean,
      default: false,
    },
    drugList: {
      type: Array,
      default: function () {
        return [];
      },
    },
    quantity: {
      type: Number | String,
      default: 0,
    },
  },
  data() {
    return {
      codeUrl: "",
      loading: false,
      medicalRecordDetailByBillNo: {
        prescriptionHead: [],
        frontPrescription: [],
      },
    };
  },
  watch: {
    info(value) {
      if (!this.hide) {
        this.getData();
      }
    },
    value(newValue, oldValue) {
      this.init();
    },
  },
  computed: {
    ...mapGetters(["userInfo", "drugTaboos", "expert"]),
    prescriptionHead() {
      return this.medicalRecordDetailByBillNo.prescriptionHead[0] || {};
    },
    frontPrescription() {
      // return this.drugList.length
      //   ? this.drugList
      //   : this.medicalRecordDetailByBillNo.frontPrescription;
      return this.medicalRecordDetailByBillNo.frontPrescription && this.medicalRecordDetailByBillNo.frontPrescription.length ? this.medicalRecordDetailByBillNo.frontPrescription : this.drugList;
    },
    drugDecoctPattern() {
      return this.frontPrescription.filter((i) => i.drugDecoctPattern);
    },
    showCode() {
      // return this.paymentCode && this.prescriptionHead.paidFree;
      // return this.showCodeHosptialIds.includes(this.userInfo.hosptialCode*1) && this.prescriptionHead.paidFree;
      return this.showCodeHosptialIds && this.showCodeHosptialIds.includes(this.userInfo.hosptialCode);
    },
  },
  methods: {
    getData() {
      if (!this.value) return;
      this.loading = true;
      // 清空数据
      this.medicalRecordDetailByBillNo = {
        prescriptionHead: [],
        frontPrescription: [],
      };
      if (this.info.prescriptionNo) {
        this.$store.commit("openSpin");
        let obj = {
          prescriptionNo: this.info.prescriptionNo,
          payStatus: this.info.payStatus || 0,
        };
        this.api2("getZHMedicalRecordNewList", obj)
          .then((res) => {

            this.loading = false;
            this.$store.commit("closeSpin");
            if (this.common.isError(res)) return;
            this.medicalRecordDetailByBillNo = res.result;

            this.createCode();

            this.$emit(
              "exportDrugList",
              this.medicalRecordDetailByBillNo.frontPrescription
            );

            this.$emit("exportQuantity", this.prescriptionHead.quantity);

            this.$emit(
              "exportTaboo",
              (this.prescriptionHead.drugtaboo || "") +
              (this.prescriptionHead.doctorsAdvice || "")
            );

            setTimeout(() => {
              if (this.hide) this.print();
              this.close();
            }, 300);

            this.$store.commit("sendLog", {
              apiName: "getZHMedicalRecordNewList",
              remarks: `处方笺打印数据获取成功  处方ID:${this.info.prescriptionNo}`,
              inParams: obj,
              outParams: res,
            });

          })
          .catch((err) => {
            this.loading = false;
            this.close();
          });
      }
    },
    print() {
      this.$print(this.$refs.print1);
    },
    close() {
      this.$emit("input", false);
      this.$emit("close");
    },
    init() {
      if (this.value) {
        this.getData();
      }
    },
    createCode() {
      QRCode.toDataURL(
        "https://m.cimaizhongyi.com/pay?id=" + this.info.prescriptionNo,
        (err, url) => {
          this.codeUrl = url;
        }
      );
    },
  },
  created() {
    this.init();
  },
  mounted() { },
};

</script>

<style>
.medicalRecord {
  display: none;
}

@media print {
  .medicalRecord {
    display: block;
  }
}
</style>


